import styled from "styled-components";

export const Container = styled.div`
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    width: 36px;
    height: 36px;
    padding: 7px 0;
`;

export const Bar = styled.div`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background:#FFCB05;
    width: 36px;
    height: 4px;
    border-radius: 10px;
`;