
import React from "react";
import * as Tag from "./styles";
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';

const StepsAds = ({ title, step, totalSteps, textNextStep, percentage}) => {
    return (
        <Tag.Container>
            <Tag.Circle>
                <CircularProgressbarWithChildren value={percentage} strokeWidth={12} styles={{
                    root: {},
                    path: {
                        stroke: '#0000FF',
                        strokeLinecap: 'butt',
                        transition: 'stroke-dashoffset 0.5s ease 0s',
                        transform: 'rotate(0.0turn)',
                        transformOrigin: 'center center',
                    },
                    trail: {
                        stroke: '#D9D9D9',
                        strokeLinecap: 'butt',
                        transform: 'rotate(0.0turn)',
                        transformOrigin: 'center center',
                    },
                    text: {
                        fill: '#000',
                        fontSize: '38px',
                        textAlign: 'center'
                    },
                    background: {
                        fill: '#fff',
                    },
                }}>
                    <Tag.Text>{step}/{totalSteps}</Tag.Text>
                </CircularProgressbarWithChildren>
            </Tag.Circle>
            <Tag.Texts>
                <Tag.Title>{title}</Tag.Title>
                <Tag.Step>{textNextStep}</Tag.Step>
            </Tag.Texts>
        </Tag.Container>
    );
}

export default StepsAds;