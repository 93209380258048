import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 100vh;
    flex-direction: column;
`;

export const Content = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    padding: 52px 60px 0;
    background:#fff;
    width: 100%;
    max-width: 820px;
    flex: 1;

    @media(max-width: 768px) {
        padding: 45px 20px;
    }
`;

export const Title = styled.h2`
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 44px;
    color: #333333;

    @media(max-width: 768px) {
        font-weight: 500;
        font-size: 24px;
        line-height: 29px;
    }
`;

export const Category = styled.a`
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #000000;
    background: #EDEDED;
    border-radius: 54px;
    height: 36px;
    padding: 10px 20px;
    margin-top:24px;
    cursor:pointer;
    text-decoration:none;
`;

export const Texts = styled.div`
    max-width: 820px;
    width: 100%;

    p {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #000000;
        margin-top: 44px;
    }

    img {
        max-width: 100%;
        margin-top: 44px;
    }
`;

export const Subtitle = styled.h2`
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
    color: #000000;
    margin-top:71px;

    @media(max-width: 768px) {
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
    }
`;

export const Links = styled.div`
    display:flex;
    width: 100%;
    flex-direction:row;
    align-items: center;
    justify-content: flex-start;
`;

export const ButtonLink = styled.a`
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: #000000;
    background: #FFFFFF;
    border: 1.5px solid #BDBDBD;
    border-radius: 93px;
    height: 36px;
    padding: 10px 20px;
    margin-top:16px;
    cursor:pointer;
    text-decoration:none;
    min-width: 136px;
    margin-right:17px;
`;

export const RelatedList = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    padding: 100px 60px 120px;
    background:#fff;
    width: 100%;
    max-width: 1440px;
    flex: 1;

    @media(max-width: 768px) {
        padding: 40px 20px 50px;
    }
`;

export const ScrollRelated = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
    margin-top:26px;

    @media(max-width: 768px) {
        overflow-x:scroll;
        padding-bottom: 30px;
    }
`;

export const Related = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: row;
`;