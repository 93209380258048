
import React from "react";
import * as Tag from "./styles";
import SuccessSignup from '../../assets/success_signup.svg';
import IcoGoogle from '../../assets/ico_google_round.svg';
import IcoOutlook from '../../assets/ico_outlook_round.svg';

const SuccessMsgSignup = (props) => {
    return (
        <Tag.Container>
            <Tag.Text>Pronto! Verifique sua caixa de entrada para validar seu email</Tag.Text>
            <Tag.Image src={SuccessSignup} />
            <Tag.Obs>Ir a email</Tag.Obs>
            <Tag.Links style={{textAlign:"center"}}>
                <Tag.Link href="#">
                    <Tag.Icon src={IcoGoogle} />
                </Tag.Link>
                <Tag.Link href="#">
                    <Tag.Icon src={IcoOutlook} />
                </Tag.Link>
            </Tag.Links>
        </Tag.Container>
    );
}

export default SuccessMsgSignup;