
import React from "react";
import * as Tag from "./styles";
import Popup from "../Popup";
import Button from "../Button";
import LinkButton from "../LinkButton";
import DropDownList from "../DropDownList";
import orderBy from "../../constants/ordersby";

const PopUpFilterProfileMobile = (props) => {
    return (
        <Popup title="" maxWidth={532} smallTitle noCloseBlur paddingTop={10} paddingLeft={20} paddingRight={20} paddingBottom={20}>
            <Tag.Container>

                <Tag.Row>
                    <DropDownList items={orderBy} placeholder={"Ordenar por"} style={{zIndex: "2"}} />
                </Tag.Row>
                <Tag.Row>
                    <DropDownList items={orderBy} placeholder="Categoria" style={{zIndex: "1"}} />
                </Tag.Row>

                <Button text="Aplicar" onClick={() => props.onFilter()} width={150} height={40} style={{marginTop: '20px'}} />
                <LinkButton text="Cancelar" onClick={() => props.setFiltersMobile()} width={150} height={40} color="#4F4F4F" style={{marginTop: '8px'}} />
            </Tag.Container>
        </Popup>
    );
}

export default PopUpFilterProfileMobile;