import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-betwenn;
    flex-direction: row;
    background: #FFFFFF;
    padding: 6px 8px;
    border: 1px solid #CBCBCB;
    border-radius: 10px;
    width: 100%;
    margin-top: 49px;

    @media(max-width: 480px) {
        padding: 0;
        margin-top: 32px;
    }
`;

export const Image = styled.img`
    width: 172px;
    height: 138px;
    object-fit: cover;
    margin-right: 24px;
    border-radius: 5px;

    @media(max-width: 480px) {
        width: 93px;
        height: 94px;
        margin-right: 12px;
    }
`;

export const Texts = styled.div`
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 5px 0;
    flex-direction: column;
    height: 100%;
`;

export const Title = styled.p`
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color:#333333;

    @media(max-width: 480px) {
        font-size: 14px;
        line-height: 21px;
    }
`;

export const Price = styled.p`
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    color:#333333;
    margin-top: 7px;

    @media(max-width: 480px) {
        display:none;
    }
`;

export const Link = styled.a`
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color:#0000FF;
    text-decoration:none;
    margin-top: 25px;

    @media(max-width: 480px) {
        margin-top: 5px;
    }
`;