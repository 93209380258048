import styled from "styled-components";
import IcoArrowLeft from '../../assets/ico_arrow_left_blue.svg';
import IcoArrowRight from '../../assets/ico_arrow_right_blue.svg';

export const Container = styled.div`
    width: calc(100% + 24px);
    display:inline-block;
    margin-top: 16px;
    position:relative;

    .slick-list{
        max-width: 100%;
        display:inline-block;
        overflow:hidden;
    }

    .slick-slide{
        display:inline-block;
    }

    .slick-arrow{
        width: 44px;
        height: 44px;
        background:#fff;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
        border:0;
        border-radius: 50px;
        top: calc(50% - 22px);
        position:absolute;
        z-index: 2;
        text-indent: -9999em;
        cursor:pointer;
    }

    .slick-prev{
        left:0;
        background:#fff url(${IcoArrowLeft}) no-repeat 50%; 
    }

    .slick-next{
        right:0;
        background:#fff url(${IcoArrowRight}) no-repeat 50%; 
    }

    .slick-arrow.slick-disabled{
        display:none!important;
    }
    
`;

export const Item = styled.div`
    padding: 0 12px;
    display:inline-block;
    float:left;
`;

export const Icon = styled.img`
    margin-top:42px;
    max-width: 191px;
    max-height: 191px;
    object-fit:contain;
`;