import styled from "styled-components";

export const Container = styled.div`
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    position:relative;
    width: 100%;
    padding: 15px 20px;
    cursor: pointer;
`;

export const Icon = styled.img`
    width: 20px;
    height: 20px;
    background-size: contain;
    margin-right: 15px;
`;

export const Name = styled.p`
    font-size: 16px;
    line-height: 24px;
    color:#333333;
    font-weight: 400;
`;

