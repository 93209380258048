
import React from "react";
import * as Tag from "./styles";

const TitleBox = (props) => {
    return (
        <Tag.TitleBox {...props}>
            <Tag.Title>{props.title}</Tag.Title>
            {props.link && <Tag.Link href={props.link}>{props.textLink ? props.textLink : 'Ver mais'}</Tag.Link>}
        </Tag.TitleBox>
    );
}

export default TitleBox;