
import React from "react";
import * as Tag from "./styles";
import Button from "../Button";

const Preview = (props) => {
    return (
        <Tag.Container>
            <Tag.Title>Parcela mensal aproximada</Tag.Title>
            <Tag.Parcelas>12x</Tag.Parcelas>
            <Tag.Total>R$ 1.223,00</Tag.Total>
            <Button text="Solicitar financiamento" width={263} />
            <Tag.Obs>Isso é um cálculo aproximado e está sujeito à análise de crédito</Tag.Obs>
        </Tag.Container>
    );
}

export default Preview;