import styled from "styled-components";
import IcoArrowUp from '../../assets/ico_arrow_up_blue.svg';
import IcoArrowDown from '../../assets/ico_arrow_down_blue.svg';
import IcoArrowLeft from '../../assets/ico_arrow_left_blue.svg';
import IcoArrowRight from '../../assets/ico_arrow_right_blue.svg';

export const Container = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: ${props => props.horizontal ? 'column' : 'row'};
    margin-top:  ${props => props.horizontal ? '20' : '12'}px;

    @media(max-width: 768px) {
        flex-direction: column;
    }
`;

export const Full = styled.div`
    width: 100%;
    flex: 1;
`;

export const ImageFull = styled.img`
    width: 100%;
    height: ${props => props.horizontal ? '177' : '365'}px;
    object-fit: cover;
    border-top-left-radius: ${props => props.horizontal ? '0' : '9'}px;
    border-bottom-left-radius: ${props => props.horizontal ? '0' : '9'}px;
    display:flex;

    @media(max-width: 768px) {
        height: 205px;
        border-radius: 10px;
    }
`;

export const Thumbs = styled.div`
    width: 60px;
    margin-left: 13px;
    position: relative;
    height: 365px;
    background: #DFDFDF;

    @media(max-width: 768px) {
        width: 100%;
        height: 42px;
        margin-left: 0;
        background: transparent;
    }

    img{
        height: 60px;
        opacity: 0.5;

        @media(max-width: 768px) {
            height: 37px;
            border-radius: 4px;
        }
    }

    .slick-list{
        width: 100%;
        height: 333px!important;
        display:inline-block;
        overflow:hidden;
        margin-top: 16px;

        @media(max-width: 768px) {
            width: 100%;
            height: 42px!important;
        }
    }

    .slick-slide{
        display:inline-block;

        @media(max-width: 768px) {
            padding: 5px;
        }
    }

    .slick-current img{
        opacity: 1;
    }

    .slick-arrow{
        width: 60px;
        height: 16px;
        background:transparent;
        box-shadow: none;
        border:0;
        left: 0;
        position:absolute;
        z-index: 2;
        text-indent: -9999em;
        cursor:pointer;
        
        @media(max-width: 768px) {
            width: 16px;
            height: 42px;
        }
    }

    .slick-prev{
        top:0;
        background:#fff url(${IcoArrowUp}) no-repeat 50%; 

        @media(max-width: 768px) {
            top:18px;
            left:0;
            background: url(${IcoArrowLeft}) no-repeat 50%;
        }
    }

    .slick-next{
        bottom:0;
        background:#fff url(${IcoArrowDown}) no-repeat 50%;

        @media(max-width: 768px) {
            top:18px;
            bottom: initial;
            right: 0;
            left:initial;
            background: url(${IcoArrowRight}) no-repeat 50%;
        } 
    }

    .slick-arrow.slick-disabled{
        display:none!important;
    }

    .slick-dots{
        position:absolute;
        display: flex!important;
        align-items: center;
        justify-content: center;
        left: 0;
        bottom: 10px;
        width: 100%;
    }
    .slick-dots li{
        display:inline-block;
        margin:0 3px;
        height: 12px;
    }
    .slick-dots li button{
        background: #FFFFFF;
        border: 1px solid #828282;
        width: 10px;
        height: 10px;
        border-radius: 10px;
        text-indent: -500em;
        margin:0;
    }
    .slick-dots .slick-active button{
        background:#0000FF;
        border-color:#0000FF;
    }
`;

export const ThumbsHorizontal = styled.div`
    margin-left: 13px;
    position: relative;
    width: 100%;
    height: 55px;
    margin-left: 0;
    background: transparent;

    img{
        height: 55px;
        opacity: 0.5;

        @media(max-width: 768px) {
            height: 37px;
            border-radius: 4px;
        }
    }

    .slick-list{
        width: 100%;
        height: 55px!important;
        display:inline-block;
        overflow:hidden;
        margin-top: 10px;

        @media(max-width: 768px) {
            width: 100%;
            height: 42px!important;
        }
    }

    .slick-slide{
        display:inline-block;
        padding: 5px;
    }

    .slick-current img{
        opacity: 1;
    }

    .slick-arrow{
        width: 16px;
        height: 55px;
        background:transparent;
        box-shadow: none;
        border:0;
        left: 0;
        position:absolute;
        z-index: 2;
        text-indent: -9999em;
        cursor:pointer;
        
        @media(max-width: 768px) {
            width: 16px;
            height: 42px;
        }
    }

    .slick-prev{
        top:0;
        top:18px;
        left:0;
        background: url(${IcoArrowLeft}) no-repeat 50%;
    }

    .slick-next{
        bottom:0;
        top:18px;
        bottom: initial;
        right: 0;
        left:initial;
        background: url(${IcoArrowRight}) no-repeat 50%;
    }

    .slick-arrow.slick-disabled{
        display:none!important;
    }

    .slick-dots{
        position:absolute;
        display: flex!important;
        align-items: center;
        justify-content: center;
        left: 0;
        bottom: 10px;
        width: 100%;
    }
    .slick-dots li{
        display:inline-block;
        margin:0 3px;
        height: 12px;
    }
    .slick-dots li button{
        background: #FFFFFF;
        border: 1px solid #828282;
        width: 10px;
        height: 10px;
        border-radius: 10px;
        text-indent: -500em;
        margin:0;
    }
    .slick-dots .slick-active button{
        background:#0000FF;
        border-color:#0000FF;
    }
`;

export const BoxImage = styled.div`
    width: ${props => props.width ? `${props.width}px` : '100%'};
    height: ${props => props.height ? `${props.height}px` : '100%'};
`;


export const Image = styled.img`
    width: 100%;
    height: 100%;
    background-size: cover;
    object-fit: cover;
`;