
import React from "react";
import * as Tag from "./styles";
import Button from "../Button";

const PreviewFinancing = (props) => {
    return (
        <Tag.Container>
            <Tag.Entrada>Entrada: R$ 12.143,00</Tag.Entrada>
            <Tag.Valor>Valor a financiar: R$ 12.143,00</Tag.Valor>
            <Tag.Total><Tag.Parcelas>12x</Tag.Parcelas>R$ 1.223,00</Tag.Total>
            <Button onClick={() => props.setshowPopup(true)} text="Solicitar financiamento" width={263} />
            <Tag.Obs>Isso é um cálculo aproximado e está sujeito à análise de crédito</Tag.Obs>
        </Tag.Container>
    );
}

export default PreviewFinancing;