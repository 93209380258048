import React, { useState, useRef, useEffect } from "react";
import * as Tag from "./styles";
import Header from  '../../components/Header';
import Footer from  '../../components/Footer';
import DropDownList from "../../components/DropDownList";
import InputText from "../../components/InputText";
import IcoSearch from "../../assets/ico_search.svg";
import ImgProduct01 from '../../assets/foto_product_01.jpg';
import ImgProduct02 from '../../assets/foto_product_02.jpg';
import ImgProduct03 from '../../assets/foto_product_03.jpg';
import ImgProduct04 from '../../assets/foto_product_04.jpg';
import IcoActions from '../../assets/ico_actions_product.svg';
import IcoFilters from '../../assets/ico_filters_blue_mobile.svg';
import IcoCompany from '../../assets/logo_company.jpg';
import IcoSeller from '../../assets/foto_user.jpg';
import ProductThumb from "../../components/ProductThumb";
import Popup from "../../components/Popup";
import Button from "../../components/Button";
import PopUpFilterProfileMobile from "../../components/PopUpFilterProfileMobile";
import InputCalendar from "../../components/InputCalendar";
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import IcoCalendar from '../../assets/ico_calendar_mini.svg';
import BoxUserSidebar from "../../components/BoxUserSidebar";
import BoxCompanySidebar from "../../components/BoxCompanySidebar";
import PopupCheckUser from "../../components/PopupCheckUser";
import PopupRating from "../../components/PopupRating";
import PopupReport from "../../components/PopupReport";

const orderBy = [
    {
        id : "Data de publicação",
        title : "Data de publicação"
    },
    {
        id : "menor_preco",
        title : "Menor preço"
    },
    {
        id : "maior_preco",
        title : "Maior preço"
    },
    {
        id : "Maior alcance",
        title : "Maior alcance"
    }
];

const products = [
    {
        gallery : [ImgProduct01,ImgProduct01,ImgProduct01,ImgProduct01],
        title : "Apartamento 3 quartos no centro",
        date : "09/03/2022",
        price : "R$ 2455,00",
        favorite : false,
        seller : {
            icon : IcoSeller,
            title : "Ana Paula da Silva"
        },
        views : 34
    },
    {
        gallery : [ImgProduct02,ImgProduct02],
        title : "Bicicleta passeio",
        date : "09/03/2022",
        price : "R$ 2455,00",
        favorite : true,
        company : {
            icon : IcoCompany,
            title : "Empresa Lorem ipsum"
        },
        views : 34
    },
    {
        gallery : [ImgProduct03,ImgProduct03,ImgProduct03],
        title : "Bike speed fixa",
        date : "09/03/2022",
        old_price : "R$ 2455,00",
        price : "R$ 2455,00",
        favorite : false,
        views : 34
    },
    {
        gallery : [ImgProduct04,ImgProduct04,ImgProduct04,ImgProduct04],
        title : "MTB 21v aro 26",
        date : "09/03/2022",
        price : "R$ 2455,00",
        favorite : false,
        views : 34
    },
    {
        gallery : [ImgProduct02,ImgProduct02],
        title : "Bicicleta passeio",
        date : "09/03/2022",
        price : "R$ 2455,00",
        favorite : true,
        views : 34
    },
    {
        gallery : [ImgProduct03,ImgProduct03,ImgProduct03],
        title : "Bike speed fixa",
        date : "09/03/2022",
        old_price : "R$ 2455,00",
        price : "R$ 2455,00",
        favorite : false,
        views : 34
    },
    {
        gallery : [ImgProduct04,ImgProduct04,ImgProduct04,ImgProduct04],
        title : "MTB 21v aro 26",
        date : "09/03/2022",
        price : "R$ 2455,00",
        favorite : false,
        views : 34
    },
    {
        gallery : [ImgProduct02,ImgProduct02],
        title : "Bicicleta passeio",
        date : "09/03/2022",
        price : "R$ 2455,00",
        favorite : true,
        views : 34
    }
]

const dataContacts = [
    {
      name: 'Monday',
      contacts: 10,
    },
    {
      name: 'Tuesday',
      contacts: 30,
    },
    {
      name: 'Wednesday',
      contacts: 50,
    },
    {
      name: 'Thursday',
      contacts: 70,
    },
    {
      name: 'Friday',
      contacts: 25,
    },
    {
      name: 'Saturday',
      contacts: 43,
    },
    {
      name: 'Sunday',
      contacts: 60,
    },
];

const SellerDetail = (props) => {
    const [popupCheckUser, setPopupCheckUser] = useState(false);
    const [popupTitle, setTitlePopup] = useState("");
    const [popupActions, setPopupActions] = useState(false);
    const [popupRating, setPopupRating] = useState(false);
    const [popupReport, setPopupReport] = useState(false);
    const [widthProduct, setWidthProduct] = useState(275);
    const [filtersMobile, setFiltersMobile] = useState(false);
    const [width, setWidth] = useState(window.innerWidth);
    const contentProducts = useRef();

    function calcWidthProduct(){
        const divProducts = contentProducts.current.offsetWidth;
        let qtd = 4;
        if(window.innerWidth <= 1024){
            qtd = 3;
        }
        if(window.innerWidth <= 960){
            qtd = 2;
        }
        if(window.innerWidth <= 768){
            qtd = 1;
        }
        setWidthProduct((divProducts / qtd) - 14);
    }

    useEffect(() => {

        const updateDimensions = () => {
            calcWidthProduct();
            setWidth(window.innerWidth);
        }
        
        window.addEventListener("resize", updateDimensions);
        calcWidthProduct();
        return () => window.removeEventListener("resize", updateDimensions);
    }, [contentProducts]);

    function showPopupActions(title){
        setTitlePopup(title);
        setPopupActions(true);
    }

    function onFilter(){
        setFiltersMobile(false);
    }
    
    
    return (
        <Tag.Container>
            <Header account />
            <Tag.Content>

            {popupCheckUser && <PopupCheckUser setShowPopup={() => setPopupCheckUser(false)} />}

            {/** -- Popup de confirmação de ação de denunciar ou ocultar --- **/}
            {popupActions &&
                <Popup title={popupTitle} onBlur={() => setPopupActions(false)} smallTitle maxWidth={532}>
                    <Tag.Icon src={IcoActions} />
                    <Button text="Ok" width={218} style={{marginTop: '60px'}} />
                </Popup>
            }

            {popupRating && <PopupRating setPopupRating={() => setPopupRating(false)} />}

            {popupReport && <PopupReport setPopupReport={() => setPopupReport(false)} showPopupActions={(title) => showPopupActions(title)} />}

                <Tag.Top>
                    <Tag.BoxLeft>
                        <Tag.BoxChart>
                            <Tag.FiltersChart>
                                <Tag.Date>
                                    <InputCalendar placeholder="Datas" height={34} width={182} iconRight={IcoCalendar} iconSize={16} />
                                    <DropDownList items={[{title : 'Diário'},{title : 'Semanal'},{title : 'Mensal'}]} placeholder="Diário" height={34} width={182} style={{marginLeft: '10px', zIndex: 2}} />
                                </Tag.Date>
                                <DropDownList items={[{title : 'Contatos'}]} placeholder="Contatos" height={34} width={width > 960 ? 200 : null} style={{zIndex: 2}} />
                            </Tag.FiltersChart>
                            <ResponsiveContainer width="100%" height={width > 768 ? 512 : 200}>
                                <AreaChart
                                    width="100%"
                                    height={width > 768 ? 512 : 200}
                                    data={dataContacts}
                                    margin={{
                                        top: 10,
                                        right: 30,
                                        left: -15,
                                        bottom: 0,
                                    }}
                                >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="name" style={{
                                        fontWeight: '400',
                                        fontSize: '12px',
                                        lineHeight: '14px'
                                    }} />
                                    <YAxis domain={[0, 100]} style={{
                                        fontWeight: '400',
                                        fontSize: '12px',
                                        lineHeight: '14px'
                                    }} />
                                    <Tooltip />
                                    <Area
                                        type="linear"
                                        bandSize={0}
                                        strokeWidth={2}
                                        dataKey="contacts"
                                        stroke="#0E9CFF"
                                        fillOpacity={0.2}
                                        fill="#0E9CFF"
                                        activeDot={{ r: 4, strokeWidth : 2, stroke : "#0E9CFF", fill : "#0E9CFF", fillOpacity: 1 }}
                                        dot={{ r: 4, strokeWidth : 2, stroke : "#0E9CFF", fill : "#fff", fillOpacity: 1  }}
                                    />
                                </AreaChart>
                            </ResponsiveContainer>
                        </Tag.BoxChart>
                    </Tag.BoxLeft>
                    
                    <Tag.Sidebar>
                        <BoxUserSidebar checked profile
                            setShowPopup={() => setPopupCheckUser(true)}
                            setPopupRating={() => setPopupRating(true)}
                            setPopupReport={() => setPopupReport(true)}
                        />
                        <BoxCompanySidebar />
                    </Tag.Sidebar>
                    
                </Tag.Top>

                <Tag.Title>Anúncios de Ana Paula da Silva</Tag.Title>

                <Tag.BoxProducts ref={contentProducts}>

                    {/** -- Popup de confirmação de ação de denunciar ou ocultar --- **/}
                    {popupActions &&
                        <Popup title={popupTitle} onBlur={() => setPopupActions(false)} smallTitle maxWidth={532}>
                            <Tag.Icon src={IcoActions} />
                            <Button text="Ok" width={218} style={{marginTop: '60px'}} />
                        </Popup>
                    }

                    {filtersMobile && <PopUpFilterProfileMobile onFilter={() => onFilter()} setFiltersMobile={() => setFiltersMobile(false)} />}

                    {width > 768 &&
                    <Tag.Filters>
                        <Tag.Filter width={200}>
                            <DropDownList items={orderBy} placeholder={"Ordenar por"} style={{zIndex : 1}} />
                        </Tag.Filter>
                        <Tag.Filter width={200}>
                            <DropDownList items={orderBy} placeholder="Categorias" style={{zIndex : 1}} />
                        </Tag.Filter>
                        <Tag.Filter width={800}>
                            <InputText placeholder="Estou procurando por..." iconRight={IcoSearch} />
                        </Tag.Filter>
                    </Tag.Filters>
                    }

                    {width <= 768 &&
                    <Tag.Filters>
                        <Tag.IconFilter src={IcoFilters} onClick={() => setFiltersMobile(true)} />
                        <Tag.Filter>
                            <InputText placeholder="Buscar" iconRight={IcoSearch} round={50} height={36} />
                        </Tag.Filter>
                    </Tag.Filters>
                    }

                    <Tag.Products>
                        {products.map((item, index) => 
                        <Tag.Item key={index} width={widthProduct}>
                            <ProductThumb product={item} showPopupActions={(title) => showPopupActions(title)} owner />
                        </Tag.Item>
                        )}
                    </Tag.Products>
                </Tag.BoxProducts>
            </Tag.Content>
            <Footer />
        </Tag.Container>
    );
}

export default SellerDetail;