import React, { useState, useRef, useEffect } from "react";
import * as Tag from "./styles";
import Header from  '../../components/Header';
import Footer from  '../../components/Footer';
import DropDownList from "../../components/DropDownList";
import InputText from "../../components/InputText";
import IcoSearch from "../../assets/ico_search.svg";
import IcoActions from '../../assets/ico_actions_product.svg';
import IcoFilters from '../../assets/ico_filters_blue_mobile.svg';
import ProductThumb from "../../components/ProductThumb";
import Popup from "../../components/Popup";
import Button from "../../components/Button";
import PopUpFilterProfileMobile from "../../components/PopUpFilterProfileMobile";
import ButtonAddList from "../../components/ButtonAddList";
import FotoList from '../../assets/foto_list.jpg';
import ButtonWishlist from "../../components/ButtonWishlist";
import BannerHomeSmall from  '../../components/BannerHomeSmall';
import api from "../../services/api";
import LoaderComponent from "../../components/LoaderComponent";
import { connect } from 'react-redux';

const orderBy = [
    {
        id : "Data de publicação",
        title : "Data de publicação"
    },
    {
        id : "menor_preco",
        title : "Menor preço"
    },
    {
        id : "maior_preco",
        title : "Maior preço"
    },
    {
        id : "Maior alcance",
        title : "Maior alcance"
    }
];

const Wishlist = (props) => {
    const [popupTitle, setTitlePopup] = useState("");
    const [popupActions, setPopupActions] = useState(false);
    const [widthProduct, setWidthProduct] = useState(275);
    const [filtersMobile, setFiltersMobile] = useState(false);
    const [width, setWidth] = useState(window.innerWidth);
    const [loaderFavorites, setLoaderFavorites] = useState(false);
    const [favorites, setFavorites] = useState([]);
    const [loaderLists, setLoaderLists] = useState(false);
    const [lists, setLists] = useState([]);
    const contentProducts = useRef();
    const didMount = useRef(true);
    
    async function getFavorites(){
        try {
            setLoaderFavorites(true);
            const response = await api.post(`/user/favorites`,{
                user_id : props.user.id
            });
            console.log(response);
            if(response.data.status){
                setFavorites(response.data.data);
            }
            setLoaderFavorites(false);
        } catch (err) {
            console.log(err);
        }finally{
            setLoaderFavorites(false);
        }
    }

    async function getFavoritesLists(){
        try {
            setLoaderLists(true);
            const response = await api.post(`/user/favorites/lists`,{
                user_id : props.user.id
            });
            console.log(response);
            if(response.data.status){
                setLists(response.data.data);
            }
            setLoaderLists(false);
        } catch (err) {
            console.log(err);
        }finally{
            setLoaderLists(false);
        }
    }

    useEffect(() => {
        if(props.user.id > 0){
            if(didMount.current){
                didMount.current = false;
                getFavorites();
                getFavoritesLists();
            }
        }
    },[props.user.id]);

    function calcWidthProduct(){
        const divProducts = contentProducts.current.offsetWidth;
        let qtd = 4;
        if(window.innerWidth <= 1024){
            qtd = 3;
        }
        if(window.innerWidth <= 960){
            qtd = 2;
        }
        if(window.innerWidth <= 768){
            qtd = 1;
        }
        setWidthProduct((divProducts / qtd) - 14);
    }

    useEffect(() => {

        const updateDimensions = () => {
            calcWidthProduct();
            setWidth(window.innerWidth);
        }
        
        window.addEventListener("resize", updateDimensions);
        calcWidthProduct();
        return () => window.removeEventListener("resize", updateDimensions);
    }, [contentProducts]);

    function showPopupActions(title){
        setTitlePopup(title);
        setPopupActions(true);
    }

    function onFilter(){
        setFiltersMobile(false);
    }
    
    
    return (
        <Tag.Container>
            <Header account />
            <Tag.Content>

                <Tag.Lists>
                    <ButtonAddList />
                    {lists.length > 0 && lists.map((item, index) => 
                        <ButtonWishlist id={item.id} title={item.name} items={item.list_ads} />
                    )}
                    {loaderLists && <LoaderComponent />}
                </Tag.Lists>

                <Tag.BoxProducts ref={contentProducts}>

                    {/** -- Popup de confirmação de ação de denunciar ou ocultar --- **/}
                    {popupActions &&
                        <Popup title={popupTitle} onBlur={() => setPopupActions(false)} smallTitle maxWidth={532}>
                            <Tag.Icon src={IcoActions} />
                            <Button text="Ok" width={218} style={{marginTop: '60px'}} />
                        </Popup>
                    }

                    {filtersMobile && <PopUpFilterProfileMobile onFilter={() => onFilter()} setFiltersMobile={() => setFiltersMobile(false)} />}

                    {width > 768 &&
                    <Tag.Filters>
                        <Tag.Filter width={200}>
                            <DropDownList items={orderBy} placeholder={"Ordenar por"} style={{zIndex : 1}} />
                        </Tag.Filter>
                        <Tag.Filter width={200}>
                            <DropDownList items={orderBy} placeholder="Categorias" style={{zIndex : 1}} />
                        </Tag.Filter>
                        <Tag.Filter width={800}>
                            <InputText placeholder="Estou procurando por..." iconRight={IcoSearch} />
                        </Tag.Filter>
                    </Tag.Filters>
                    }

                    {width <= 768 &&
                    <Tag.Filters>
                        <Tag.IconFilter src={IcoFilters} onClick={() => setFiltersMobile(true)} />
                        <Tag.Filter>
                            <InputText placeholder="Buscar" iconRight={IcoSearch} round={50} height={36} />
                        </Tag.Filter>
                    </Tag.Filters>
                    }
                    {favorites.length > 0 && 
                    <Tag.Products>
                        {favorites.map((item, index) => 
                            <Tag.Item key={index} width={widthProduct}>
                                <ProductThumb product={item} showPopupActions={(title) => showPopupActions(title)} />
                            </Tag.Item>
                        )}
                    </Tag.Products>}
                    {loaderFavorites && <LoaderComponent />}
                </Tag.BoxProducts>

                <Tag.DoubleBanner>
                    <BannerHomeSmall />
                    <BannerHomeSmall />
                </Tag.DoubleBanner>

            </Tag.Content>
            <Footer />
        </Tag.Container>
    );
}

const mapStateToProps = store => ({
    user: store.userState,
    location: store.locationState
});

export default connect(mapStateToProps)(Wishlist);