import styled from "styled-components";

export const Container = styled.div`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    margin-right: ${props => props.marginRight ? props.marginRight : '10'}px;
    margin-top: ${props => props.marginTop ? props.marginTop : '8'}px;
    cursor:pointer;
`;

export const Check = styled.span`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 16px;
    height: 16px;
    background: #FFFFFF;
    border: 1px solid #4F4F4F;
    border-radius: 2px;
    margin-right: 10px;
    border-radius: 29px;

    :after{
        content: "";
        background-color: ${props => props.active ? "#0000FF" : "transparent"};
        width: 8px;
        height: 8px;
        border-radius: 2px;
        border-radius: 29px;
    }
`;

export const Icon = styled.img`
    max-width: ${props => props.iconMaxWidth ? props.iconMaxWidth : "85"}px;
    max-height: ${props => props.iconMaxHeight ? props.iconMaxHeight : "25"}px;
    margin-right: 10px;
    object-fit:contain;
`;

export const Title = styled.span`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: ${props => props.color ? props.color : "#333333"};
    font-weight: ${props => props.fontWeight ? props.fontWeight : "400"};
    font-size: ${props => props.fontSize ? props.fontSize : "16"}px;
    line-height: ${props => props.lineHeight ? props.lineHeight : "20"}px;
    flex: 1;
`;

