import React, { useEffect, useRef, useState } from "react";
import Header from  '../../components/Header';
import Footer from  '../../components/Footer';
import Button from  '../../components/Button';
import ButtonOutline from  '../../components/ButtonOutline';
import InputText from  '../../components/InputText';
import Popup from  '../../components/Popup';
import SuccessMsgCreateAds from  '../../components/SuccessMsgCreateAds';
import SuccessDraft from  '../../components/SuccessDraft';
import * as Tag  from "./styles";
import Textarea from "../../components/Textarea";
import DropDownList from "../../components/DropDownList";
import CheckBox from "../../components/CheckBox";
import SlideCheck from "../../components/SlideCheck";
import FileUpload from "../../components/FileUpload";
import IcoPin from "../../assets/ico_pin_map.svg";
import { connect } from 'react-redux';
import { getLocation } from "../../services/google";
import LoaderComponent from "../../components/LoaderComponent";
import LoaderFullScreen from "../../components/LoaderFullScreen";
import api from "../../services/api";
import IcoRemove from "../../assets/ico_close_blue.svg";

const CreateAds = (props) => {
    const [createSuccess, setCreateSuccess] = useState(false);
    const [loader, setLoader] = useState(false);
    const [categories, setCategories] = useState([]);
    const [loadCategories, setLoadCategories] = useState(false);
    const [zip_code, setZipCode] = useState('');
    const [location, setLocation] = useState('');
    const [draft, setDraft] = useState(false);
    const [errors, setErrors] = useState([]);
    const [ads, setAds] = useState({});
    const didMount = useRef(true);

    const selectFiles = async (listFiles) => {
        if(ads.images?.length > 0){
            setAds({...ads, images : [...ads.images, ...Array.from(listFiles)] });
        }else{
            setAds({...ads, images : Array.from(listFiles)});
        }
    }

    function buildFormData(formData, data, parentKey) {
        if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
            Object.keys(data).forEach(key => {
                buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
            });
        } else {
            const value = data == null ? '' : data;
            formData.append(parentKey, value);
        }
    }
      
    function jsonToFormData(data) {
        const formData = new FormData();
        buildFormData(formData, data);
        return formData;
    }

    async function handleAds(e){
        e.preventDefault();

        let errosList = [];
        if(!ads?.name){
            errosList.push('Prencha o título do anúncio');
        }
        if(!ads?.description){
            errosList.push('Digite uma descrição para o anúncio');
        }
        if(!ads?.images?.length){
            errosList.push('Selecione pelo menos 1 imagem para o anúncio');
        }
        if(!ads?.category?.id){
            errosList.push('Selecione a categoria antes de prosseguir');
        }
        if(!ads?.subcategory?.id && !ads?.subcategory?.length){
            errosList.push('Selecione a subcategoria antes de prosseguir');
        }
        /* if(!ads?.filters?.length){
            errosList.push('Selecione um filtro antes de prosseguir');
        } */
        if(!ads.accept_terms){
            errosList.push('Concorde com os Termos de Uso e Politica de Privacidade');
        }
        if(!ads.price){
            errosList.push('Digite um valor para o anúncio');
        }

        if(errosList.length === 0){
            setErrors([]);
            try {
                setLoader(true);
                let response = null;

                const adsTemp = {...ads, user_id : props.user.id, ads_type : 'default'};
                console.log('adsTemp',jsonToFormData(adsTemp));

                response = await api.post(`/ads/store`, jsonToFormData(adsTemp),{
                    headers: {
                        "Content-Type": "multipart/form-data",
                    }
                });
                console.log(response);
                if(response.data.status){
                    setCreateSuccess(true);
                    window.scrollTo(0, 0);
                }
                setLoader(false);
            } catch (err) {
                console.log(err);
            }finally{
                setLoader(false);
            }
        }else{
            setErrors(errosList);
        }

    }

    async function getCategories(){
        try {
            setLoadCategories(true);
            const response = await api.get("/category/toFilters?type=default");
            console.log(response);
            if(response.data.status){
                setCategories(response.data.data);
            }
            setLoadCategories(false);

        } catch (err) {
            console.log(err);
        }finally{
            setLoadCategories(false);
        }
    }

    async function getCurrentLocation(){
        let city = '';
        let state = '';
        let country = '';
        let district = '';
        let zip = '';

        try{
            const address = await getLocation(props.location.latitude, props.location.longitude);
            console.log(address);
            city = address.filter((item) => item.types.filter((type) => type === "administrative_area_level_2").length > 0)[0]?.long_name;
            state = address.filter((item) => item.types.filter((type) => type === "administrative_area_level_1").length > 0)[0]?.short_name;
            country = address.filter((item) => item.types.filter((type) => type === "country").length > 0)[0]?.long_name;
            district = address.filter((item) => item.types.filter((type) => type === "sublocality_level_1").length > 0)[0]?.long_name;
            zip = address.filter((item) => item.types.filter((type) => type === "postal_code").length > 0)[0]?.long_name;

            setLocation(`${district}, ${city} - ${state}`);
            setZipCode(zip);
            setAds({...ads , location : {
                latitude: props.location.latitude,
                longitude: props.location.longitude,
                city,
                state,
                country,
                district,
                zip
            }})
        }catch(e){
            
        }
    }

    function changeFilters(status, item){
        if(status){
            if(ads.filters){
                setAds({...ads, filters : [...ads.filters, item]})
            }else{
                setAds({...ads, filters : [item]})
            }
        }else{
            setAds({...ads, filters : ads.filters.filter(i => i.id !== item.id)})
        }
    }

    function changeSubcategories(status, item){
        if(status){
            if(ads.subcategory){
                setAds({...ads, subcategory : [...ads.subcategory, item]})
            }else{
                setAds({...ads, subcategory : [item]})
            }
        }else{
            setAds({...ads, subcategory : ads.subcategory.filter(i => i.id !== item.id)})
        }
    }

    function removeImage(indexOld){
        console.log(ads);
        setAds({
            ...ads,
            images : ads.images.filter((item,index) => index !== indexOld)
        })
    }

    useEffect(() => {
        if(props.user.id > 0){
            if(didMount.current){
                didMount.current = false;
                getCategories();
            }
        }
    },[props.user.id]);

    useEffect(() => {
        if(props.location.latitude != ''){
            getCurrentLocation();
        }
    },[props.location]);

    return (
        <Tag.Container>
            <Header account />
            <Tag.Content>

                {loader && <LoaderFullScreen />}

                {!createSuccess && <>
                <Tag.Form onSubmit={handleAds}>
                    <Tag.Title>Crie seu anúncio</Tag.Title>
                    <Tag.Row>
                        <Tag.Label>Título</Tag.Label>
                        <InputText placeholder="Adicione um título ao seu anúncio" onChange={(e) => setAds({...ads, name : e.target.value})} />
                    </Tag.Row>

                    <Tag.Row>
                        <Tag.Label>Descrição</Tag.Label>
                        <Textarea placeholder="Descreva o seu produto ou serviço" onChange={(e) => setAds({...ads, description : e.target.value})} />
                    </Tag.Row>

                    <Tag.Row>
                        <Tag.Label>Categoria</Tag.Label>
                        {loadCategories && <LoaderComponent />}
                        {categories && <DropDownList placeholder="Selecione uma categoria" items={categories} style={{zIndex : 2}} onChange={(category) => setAds({...ads, category : category, subcategory : [], filters : []})} />}
                    </Tag.Row>

                    {ads.category?.subcategories.find(item =>item.filters.length > 0) && 
                        <>
                            <Tag.Row>
                                <Tag.Label>Subcategoria</Tag.Label>
                                <DropDownList placeholder="Selecione uma subcategoria" style={{zIndex : 1}} items={ads.category.subcategories} onChange={(subcategory) => setAds({...ads, subcategory : subcategory, filters : []})} />
                            </Tag.Row>
                            {ads.subcategory && ads.subcategory.filters && 
                            <Tag.Row style={{display: 'grid', gridTemplateColumns: 'auto auto'}}>
                                {ads.subcategory.filters.map((item) =>
                                    <CheckBox name={item.name} key={item.id} onChange={(status)=> changeFilters(status, item)} />
                                )}
                            </Tag.Row>
                            }
                        </>
                    }

                    {ads.category && !ads.category?.subcategories.find(item =>item.filters.length > 0) && 
                    <Tag.Row style={{display: 'grid', gridTemplateColumns: 'auto auto'}}>
                        {ads.category?.subcategories.map((item) =>
                            <CheckBox name={item.name} key={item.id} onChange={(status)=> changeSubcategories(status, item)} />
                        )}
                    </Tag.Row>
                    }

                    <Tag.Row>
                        <SlideCheck title="Mostrar localização aproximada" onChange={(show_location) => setAds({...ads, show_location})} />
                        {ads.show_location && location &&
                        <Tag.Col style={{alignItems : "center"}}>
                            <InputText placeholder="00000-00" value={zip_code} width={282} />
                            <Tag.Local><Tag.IconLocal src={IcoPin} /> {location}</Tag.Local>
                        </Tag.Col>
                        }
                    </Tag.Row>

                    <Tag.Row>
                        <Tag.Label>Valor</Tag.Label>
                        <InputText mask="money" prefix="R$" placeholder="Valor o seu produto ou serviço" value={ads.price} onChange={(e,value) => value ? setAds({...ads, price : value}) : null} />
                    </Tag.Row>

                    <Tag.Row>
                        <SlideCheck title="Permitir contato via WhatsApp" onChange={(allow_contact_phone) => setAds({...ads, allow_contact_phone})} />
                        <Tag.Obs>{props.user.phone}</Tag.Obs>
                    </Tag.Row>

                    <Tag.Row>
                        <FileUpload onChangeFiles={(files) => selectFiles(files)} multiple={true} />
                    </Tag.Row>

                    <Tag.ScrollImages>
                        {ads.images && ads.images.map((image, index) => (
                            <Tag.BoxImage key={index}>
                                <Tag.Remove className="btRemove" src={IcoRemove} onClick={() => removeImage(index)} />
                                <Tag.Image className="image" src={URL.createObjectURL(image)} />
                            </Tag.BoxImage>
                        ))}
                    </Tag.ScrollImages>

                    <Tag.Row>
                        <CheckBox title="Concordo com os Termos de Uso e Politica de Privacidade" onChange={(accept_terms) => setAds({...ads, accept_terms})} />
                    </Tag.Row>

                    {errors?.length > 0 && <Tag.Row>
                        <Tag.Errors>{errors.map((error) => <>{`- ${error}`}<br/></> )}</Tag.Errors>
                    </Tag.Row>}

                    <Tag.Row style={{textAlign: "center"}}>
                        <Button width={230} type="submit" text="Publicar" />
                    </Tag.Row>
                    <Tag.Row style={{textAlign: "center", marginTop: '20px'}}>
                        <ButtonOutline width={230} type="link" onClick={() => setDraft(true)} text="Cancelar" style={{marginTop: 0}} />
                    </Tag.Row>
                </Tag.Form>
                </>}

                {createSuccess && <SuccessMsgCreateAds />}

                {draft && <Popup maxWidth={515} noCloseBlur>
                    <SuccessDraft setDraft={() => setDraft(false)} />
                </Popup>}
                
            </Tag.Content>
            <Footer />
        </Tag.Container>    
    );
}


const mapStateToProps = store => ({
    user: store.userState,
    location: store.locationState
});

export default connect(mapStateToProps)(CreateAds);