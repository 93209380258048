
import React, { useState, useRef, useEffect } from "react";
import * as Tag from "./styles";

const InputSliderRange = (props) => {
    const minRange = (props.minRange ? props.minRange : 100);
    const [stateLeft, setMinLeft] = useState(props.startLeft);
    const [percentLeft, setPercentLeft] = useState(0);
    const [stateRight, setMinRight] = useState(props.startRight);
    const [percentRight, setPercentRight] = useState(0);
    const [hover, setHover] = useState('');
    const rangeLeft = useRef();
    const bubbleRight = useRef();
    const rangeRight = useRef();
    const bubbleLeft = useRef();
    const thumbLeft = useRef();
    const thumbRight = useRef();
    const inverseLeft = useRef();
    const inverseRight = useRef();
    const range = useRef();

    useEffect(() => {
        setBubbleLeft();
        setBubbleRight();
    },[stateLeft,stateRight]);

    function setBubbleLeft() {
        const fieldLeft = rangeLeft.current;
        const newValLeft = (fieldLeft.value / parseInt(fieldLeft.max)) * 100
        inverseLeft.current.style.width = newValLeft +'%';
        range.current.style.left = newValLeft +'%';
        setPercentLeft(newValLeft);
        bubbleLeft.current.style.left = `calc((${newValLeft}% + (${8 - newValLeft * 0.15}px) - ${bubbleLeft.current.offsetWidth / 2}px))`;
        thumbLeft.current.style.left = `calc(${newValLeft}% + (${8 - newValLeft * 0.15}px)`;

    }

    function setBubbleRight() {
        const fielRight = rangeRight.current;
        const newValRight = (fielRight.value / parseInt(fielRight.max)) * 100
        inverseRight.current.style.width = (100 - newValRight) +'%';
        range.current.style.right = (100 - newValRight) +'%';
        setPercentRight(newValRight);
        bubbleRight.current.style.left = `calc((${newValRight}% + (${8 - newValRight * 0.15}px) - ${bubbleRight.current.offsetWidth / 2}px))`;
        thumbRight.current.style.left = `calc(${newValRight}% + (${8 - newValRight * 0.15}px)`;
    }

    return (
        <Tag.Container>
            <Tag.Contain>
                <Tag.InverseLeft ref={inverseLeft} style={{left:0}} />
                <Tag.InverseRight ref={inverseRight} style={{right:0}} />

                <Tag.Range ref={range} />

                <Tag.Thumb ref={thumbLeft} hover={hover === 'left'} />
                <Tag.Thumb ref={thumbRight} hover={hover === 'right'} />

                <Tag.Text ref={bubbleLeft}>{props.toltipLeft}{stateLeft}{props.toltipRight}</Tag.Text>
                <Tag.Text ref={bubbleRight}>{props.toltipLeft}{stateRight}{props.toltipRight}</Tag.Text>
            </Tag.Contain>

            <Tag.InputRange
                type="range" 
                percent={percentLeft} ref={rangeLeft} value={stateLeft} min={props.min} step={props.step} max={props.max}
                onLoad={setBubbleLeft}
                onChange={(e) => {
                    if(parseInt(e.target.value) <= (parseInt(stateRight) - minRange)){
                        setMinLeft(e.target.value)
                    }
                }}
                onMouseOver={() => setHover('left')}
                onMouseOut={() => setHover('')}
            />

            <Tag.InputRange
                type="range" 
                percent={percentRight} ref={rangeRight} value={stateRight} min={props.min} step={props.step} max={props.max}
                onLoad={setBubbleRight}
                onChange={(e) => {
                    if(parseInt(e.target.value) >= (parseInt(stateLeft) + minRange)){
                        setMinRight(e.target.value)
                    }
                }}
                onMouseOver={() => setHover('right')}
                onMouseOut={() => setHover('')}
            />
        </Tag.Container>

    );
}

export default InputSliderRange;