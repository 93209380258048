import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    display:flex;
    flex-direction: row;
    align-items:center;
    justify-content: space-between;
    padding:14px 10px;
    background: ${props => props.active ? '#F5F5F5': '#ffffff'};
    position:relative;
`;

export const BoxImage = styled.div`
    width: 48px;
    height: 48px;
    margin-right:22px;
    position:relative;
`;

export const Image = styled.img`
    width: 48px;
    height: 48px;
    object-fit:cover;
    border-radius: 50px;
`;

export const ImageSmall = styled.img`
    width: 28px;
    height: 28px;
    position:absolute;
    filter: drop-shadow(-1px -1px 5px rgba(0, 0, 0, 0.34));
    object-fit:cover;
    border-radius: 50px;
    bottom:-5px;
    right:-5px;
`;

export const Texts = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
`;

export const Name = styled.p`
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #333333;
`;

export const PrevMsg = styled.p`
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #828282;
    margin-top:5px;
`;

export const Infos = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
`;

export const Date = styled.p`
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #333333;
`;

export const Actions = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin-top:9px;
`;

export const Count = styled.span`
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #333333;
    width: 22px;
    height: 22px;
    background: #FFCB05;
    margin-right:14px;
    border-radius: 50px;
`;

export const IconMore = styled.img`
    width: 21px;
    height: 21px;
    object-fit:contain;
    cursor:pointer;
`;
