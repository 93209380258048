
import React from "react";
import * as Tag from "./styles";
import RadioBoxList from "../../components/RadioBoxList";
import IcoCard from "../../assets/ico_card.svg";
import IcoBoleto from "../../assets/ico_boleto.svg";
import IcoPix from "../../assets/ico_pix.svg";
import IcoPicPay from "../../assets/ico_pic_pay.svg";
import IcoMercadoPago from "../../assets/ico_mercado_pago.svg";

const items = [
    {
        id : 'cartao',
        icon: IcoCard,
        title: 'Cartão'
    },
    {
        id : 'boleto',
        icon: IcoBoleto,
        title: ''
    },
    {
        id : 'pix',
        icon: IcoPix,
        title: ''
    },
    {
        id : 'picpay',
        icon: IcoPicPay,
        title: ''
    },
    {
        id : 'mercado_pago',
        icon: IcoMercadoPago,
        title: ''
    }
]

const PaymentMethodsCheckout = (props) => {
    return (
        <Tag.Container>
            <Tag.Title>Método de pagamento</Tag.Title>
            <RadioBoxList items={items} fontWeight={500} fontSize={18} lineHeight={27} marginTop={30} />
        </Tag.Container>
    );
}

export default PaymentMethodsCheckout;