import React, { useState, useRef, useEffect } from "react";
import * as Tag from "./styles";
import Header from  '../../components/Header';
import Footer from  '../../components/Footer';
import BoxUserSidebar from "../../components/BoxUserSidebar";
import DropDownList from "../../components/DropDownList";
import InputText from "../../components/InputText";
import IcoSearch from "../../assets/ico_search.svg";
import orderBy from "../../constants/ordersby";
import ImgProduct01 from '../../assets/foto_product_01.jpg';
import ImgProduct02 from '../../assets/foto_product_02.jpg';
import ImgProduct03 from '../../assets/foto_product_03.jpg';
import ImgProduct04 from '../../assets/foto_product_04.jpg';
import IcoActions from '../../assets/ico_actions_product.svg';
import IcoFilters from '../../assets/ico_filters_blue_mobile.svg';
import ProductThumb from "../../components/ProductThumb";
import Popup from "../../components/Popup";
import Button from "../../components/Button";
import BoxCompanySidebar from "../../components/BoxCompanySidebar";
import PopupCheckUser from "../../components/PopupCheckUser";
import PopupRating from "../../components/PopupRating";
import PopupReport from "../../components/PopupReport";
import PopUpFilterProfileMobile from "../../components/PopUpFilterProfileMobile";
import api from "../../services/api";
import LoaderFullScreen from "../../components/LoaderFullScreen";
import { connect } from 'react-redux';
import { useParams } from "react-router-dom";

const UserProfile = (props) => {
    const [popupCheckUser, setPopupCheckUser] = useState(false);
    const [popupTitle, setTitlePopup] = useState("");
    const [popupActions, setPopupActions] = useState(false);
    const [popupRating, setPopupRating] = useState(false);
    const [popupReport, setPopupReport] = useState(false);
    const [widthProduct, setWidthProduct] = useState(275);
    const [filtersMobile, setFiltersMobile] = useState(false);
    const [width, setWidth] = useState(window.innerWidth);
    const [loader, setLoader] = useState(false);
    const [currentUser, setCurrentUser] = useState({});
    const didMount = useRef(true);
    const contentProducts = useRef();
    const { id } = useParams();
    
    async function getUser(){
        try {
            setLoader(true);
            const response = await api.get(`/user/profile/${id}`);
            console.log(response);
            if(response.data.status){
                setCurrentUser(response.data.data);
            }
            setLoader(false);
        } catch (err) {
            console.log(err);
        }finally{
            setLoader(false);
        }
    }

    useEffect(() => {
        if(didMount.current){
            didMount.current = false;
            getUser();
        }
    });

    function calcWidthProduct(){
        const divProducts = contentProducts.current.offsetWidth;
        let qtd = 3;
        if(window.innerWidth <= 960){
            qtd = 2;
        }
        if(window.innerWidth <= 768){
            qtd = 1;
        }
        setWidthProduct((divProducts / qtd) - 14);
    }

    useEffect(() => {

        const updateDimensions = () => {
            calcWidthProduct();
            setWidth(window.innerWidth);
        }
        
        window.addEventListener("resize", updateDimensions);
        calcWidthProduct();
        return () => window.removeEventListener("resize", updateDimensions);
    }, [contentProducts]);

    function showPopupActions(title){
        setTitlePopup(title);
        setPopupActions(true);
    }

    function onFilter(){
        setFiltersMobile(false);
    }
    
    
    return (
        <Tag.Container>
            <Header />
            <Tag.Content>

                {loader && <LoaderFullScreen />}

                <Tag.Sidebar>
                    {currentUser && <BoxUserSidebar user={currentUser} checked profile
                        setShowPopup={() => setPopupCheckUser(true)}
                        setPopupRating={() => setPopupRating(true)}
                        setPopupReport={() => setPopupReport(true)}
                    />}
                    <BoxCompanySidebar />
                </Tag.Sidebar>
                <Tag.BoxProducts ref={contentProducts}>
                    {popupCheckUser && <PopupCheckUser setShowPopup={() => setPopupCheckUser(false)} />}

                    {/** -- Popup de confirmação de ação de denunciar ou ocultar --- **/}
                    {popupActions &&
                        <Popup title={popupTitle} onBlur={() => setPopupActions(false)} smallTitle maxWidth={532}>
                            <Tag.Icon src={IcoActions} />
                            <Button text="Ok" width={218} style={{marginTop: '60px'}} />
                        </Popup>
                    }

                    {popupRating && <PopupRating setPopupRating={() => setPopupRating(false)} />}

                    {popupReport && <PopupReport setPopupReport={() => setPopupReport(false)} showPopupActions={(title) => showPopupActions(title)} />}
                    
                    {filtersMobile && <PopUpFilterProfileMobile onFilter={() => onFilter()} setFiltersMobile={() => setFiltersMobile(false)} />}

                    {width > 768 &&
                    <Tag.Filters>
                        <Tag.Filter width={200}>
                            <DropDownList items={orderBy} placeholder={"Ordenar por"} style={{zIndex : 1}} />
                        </Tag.Filter>
                        <Tag.Filter width={200}>
                            <DropDownList items={orderBy} placeholder="Categorias" style={{zIndex : 1}} />
                        </Tag.Filter>
                        <Tag.Filter width={480}>
                            <InputText placeholder="Estou procurando por..." iconRight={IcoSearch} />
                        </Tag.Filter>
                    </Tag.Filters>
                    }

                    {width <= 768 &&
                    <Tag.Filters>
                        <Tag.IconFilter src={IcoFilters} onClick={() => setFiltersMobile(true)} />
                        <Tag.Filter>
                            <InputText placeholder="Buscar" iconRight={IcoSearch} round={50} height={36} />
                        </Tag.Filter>
                    </Tag.Filters>
                    }

                    <Tag.Total>{currentUser.ads?.length || 0} anúncios</Tag.Total>
                    {currentUser.ads?.length && <Tag.Products>
                        {currentUser.ads.map((item, index) => 
                        <Tag.Item key={index} width={widthProduct}>
                            <ProductThumb product={item} showPopupActions={(title) => showPopupActions(title)} />
                        </Tag.Item>
                        )}
                    </Tag.Products>}

                </Tag.BoxProducts>
            </Tag.Content>
            <Footer />
        </Tag.Container>
    );
}

export default UserProfile;