
import React from "react";
import Button from "../Button";
import * as Tag from "./styles";

const Plan = (props) => {
    return (
        <Tag.Container center={props.item.recommended}>
            <Tag.Texts>
                <Tag.Title>{props.item.title}</Tag.Title>
                {props.item.recommended && <Tag.Subtitle>Mais recomendado</Tag.Subtitle>}
                <Tag.Price center={props.item.recommended}>{props.item.price}</Tag.Price>
                <Tag.List>
                    {props.item.items.map((item, index) => <Tag.ItemList key={index}>{item}</Tag.ItemList>)}
                </Tag.List>
            </Tag.Texts>
            {props.item.current && <Tag.Current>Plano Atual</Tag.Current>}
            {!props.item.current && <Button text={props.change ? "Mudar de plano" : "Adquirir"} width={props.change ? 170 : 144} />}
        </Tag.Container>
    );
}

export default Plan;