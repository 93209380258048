import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
`;

export const Content = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 70px 20px 120px;
    background:#fff;
    width: 100%;
    max-width: 792px;
    flex: 1;

    @media(max-width: 768px) {
        padding: 50px 20px 80px;
    }
`;

export const Title = styled.h2`
    font-style: normal;
    font-weight: 500;
    font-size: 34px;
    line-height: 29px;
    color: #0000FF;
    margin-top:64px;
`;

export const Description = styled.h2`
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    color: #333333;
    margin-top:20px;
`;