
import React from "react";
import * as Tag from "./styles";
import OutsiteUnsplash from "../../assets/outsite-unsplash.jpg";

const PreviewAnnouncement = (props) => {
    return (
        <Tag.Container>
            <Tag.Image src={OutsiteUnsplash} />
            <Tag.Texts>
                <Tag.Title>Apartamento 3 quartos no centro da cidade</Tag.Title>
                <Tag.Price>R$ 145.885,00</Tag.Price>
                <Tag.Link>Ver anúncio</Tag.Link>
            </Tag.Texts>
        </Tag.Container>
    );
}

export default PreviewAnnouncement;