import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
`;

export const Content = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 70px 60px 120px;
    background:#fff;
    width: 100%;
    max-width: 1440px;
    flex: 1;

    @media(max-width: 768px) {
        padding: 40px 20px;
    }
`;

export const Obs = styled.span`
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #333333;
    margin-left:42px;
`;

export const Title = styled.h2`
    font-size:24px;
    line-height: 29px;
    color:#333333;
    font-weight: 500;
    width: 100%;
    text-align:left;
`;

export const Form = styled.form`
    width: 590px;
    max-width: 100%;
`;

export const Row = styled.div`
    width: 100%;
    margin-top:36px;
`;

export const Col = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: row;
    margin-top:13px;

    @media(max-width: 768px) {
        flex-direction: column;
    }
`;

export const Local = styled.span`
    margin-left:25px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;

    @media(max-width: 768px) {
        margin-left:0;
        margin-top:15px;
    }
`;

export const IconLocal = styled.img`
    width: 18px;
    height: 18px;
    object-fit:contain;
    margin-right:5px;
`;

export const Label = styled.label`
    width: 100%;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    color: #333333;
    margin-bottom:5px;
`;

export const Errors = styled.p`
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color:#333333;
    text-decoration:none;
    display:inline-block;  
    border:solid 1px #f00;
    width: 100%;
    padding:5px 10px; 
`;

export const ScrollImages = styled.div`
    width: 100%;
    height: auto;
    margin-top:20px;
    display: grid;
    column-gap: 10px;
    row-gap: 10px;
    grid-template-columns: repeat(5, 100px);
    grid-auto-rows: 100px;
`

export const BoxImage = styled.div`
    border: solid 1px #ccc;
    border-radius: 10px;
    padding: 5px;
    position:relative;

    :hover{
        .btRemove{
            display:block;
        }
        .image{
            opacity:0.5;
        }
    }
`

export const Remove = styled.img`
    position: absolute;
    right: 0;
    top: 0;
    padding: 5px;
    display: none;
    border-radius: 10px;
    cursor:pointer;
    z-index:1;
`

export const Image = styled.img`
    width: 100%;
    height: 100%;
    object-fit:contain;
`

export const StepForm = styled.div`

`

