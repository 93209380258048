import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;

export const Text = styled.span`
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    color: #333333;

    @media(max-width: 768px) {
        width: 100%;
        text-align:center;
    }
`;

export const Image = styled.img`
    margin-top: 60px;
    width: 100%;
    max-width: ${props => props.maxWidth ? props.maxWidth : '330'}px;
`;