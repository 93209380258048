import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 100vh;
    flex-direction: column;
`;

export const Content = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 52px 60px;
    background:#fff;
    width: 100%;
    max-width: 1440px;
    flex: 1;

    @media(max-width: 768px) {
        padding: 45px 20px;
    }
`;

export const Title = styled.h2`
    font-size:24px;
    line-height: 29px;
    color:#333333;
    font-weight: 500;
    
    @media(max-width: 768px) {
        font-size:18px;
        line-height: 27px;
    }
`;

export const Row = styled.div`
    width: 100%;
    margin-top:36px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
`;

export const Errors = styled.p`
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color:#333333;
    text-decoration:none;
    display:inline-block;  
    border:solid 1px #f00;
    width: 100%;
    padding:5px 10px; 
`;

export const ButtonType = styled.a`
    background: #FFFFFF;
    border: 1px solid #E5E5E5;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    padding:16px;
    width: 100%;
    max-width: 220px;
    height: 250px;
    margin:0 18px;
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
    cursor:pointer;
    text-decoration:none;

    @media(max-width: 768px) {
        height: 185px;
        margin:0 10px;
        :first-child{
            margin-left:0;
        }
        :last-child{
            margin-right:0;
        }
    }
`;

export const Text = styled.p`
    font-size:18px;
    line-height: 27px;
    color:#333333;
    font-weight: 500;
`;

export const Image = styled.img`
    margin-bottom: 32px;
    max-width: 100%;
    
    @media(max-width: 768px) {
        margin-bottom: 22px;
    }
`;