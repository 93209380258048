import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: row;
    padding:20px 0 0;
    width: 100%;
    border-top: 1px solid #E5E5E5;
    margin-top: 22px;

    @media(max-width: 768px) {
        flex-direction: column;
    }
`;

export const Checks = styled.div`
    width: 150px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;

    @media(max-width: 768px) {
        display:none;
    }
`;

export const CheckCont = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex:1;
    
    @media(max-width: 768px) {
        margin-top: 10px;
    }
`;

export const TextCheck = styled.p`
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #333333;
`;