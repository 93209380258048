import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 100vh;
    flex-direction: column;
`;

export const Content = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 52px 60px;
    background:#fff;
    width: 100%;
    max-width: 1440px;
    flex: 1;

    @media(max-width: 768px) {
        padding: 40px 20px;
    }
`;

export const Title = styled.h2`
    font-size:24px;
    line-height: 29px;
    color:#333333;
    font-weight: 500;
`;

export const Form = styled.form`
    width: 588px;
    max-width: 100%;
`;

export const Row = styled.div`
    width: 100%;
    margin-top:36px;

    @media(max-width: 768px) {
        :last-child{
            justify-content: center;
            align-items: center;
            display: flex;
            flex-direction: column;
        }
        >button{
            margin:0!important;
        }
        >button:last-child{
            margin:30px 0 0 0!important;
        }
    }
`;

export const Obs = styled.p`
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    color: #333333;
`;

export const Errors = styled.p`
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color:#333333;
    text-decoration:none;
    display:inline-block;  
    border:solid 1px #f00;
    width: 100%;
    padding:5px 10px; 
`;
