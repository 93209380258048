import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: #FFFFFF;
    border: 1px solid #E5E5E5;
    border-radius: 5px;
    width: 100%;
    max-width: 588px;
    padding: 40px 30px;

    @media(max-width: 768px) {
        border: 0;
        padding: 0;
    }
`;

export const Text = styled.span`
    font-weight: 500;
    font-size: ${props => props.titleBig ? '24' : '18'}px;
    line-height: ${props => props.titleBig ? '29' : '27'}px;
    color: #333333;

    @media(max-width: 768px) {
        width: 100%;
        text-align:center;
    }
`;

export const Image = styled.img`
    margin-top: 60px;
    width: 100%;
    max-width: 330px;
`;

export const Separator = styled.span`
    margin: 40px 0 18px;
    height: 1px;
    width: 228px;
    background:#E0E0E0;
`;

export const Phone = styled.p`
    font-size:18px;
    line-height: 27px;
    color:#333333;
    font-weight: 500;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    margin-top: 16px;
`;

export const Icon = styled.img`
    margin-right: 14px;
    width: 24px;
    height:24px;
    background-size:contain;
`;

export const Resend = styled.p`
    font-size:14px;
    line-height: 21px;
    color:#333333;
    font-weight: 500;
    margin-top: 20px;
`;

export const Numbers = styled.div`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    margin-top: 58px;
`;

export const Number = styled.input`
    font-size:36px;
    line-height: 54px;
    color:#333333;
    font-weight: 500;
    width: 44px;
    height: 50px;
    text-align:center;
    border: 0;
    border-bottom:solid 1px #333333;
    margin: 0 6px;

    ::placeholder,
    ::-webkit-input-placeholder {
        color: #BDBDBD;
    }
    :-ms-input-placeholder {
        color: #BDBDBD;
    }
`;

export const Form = styled.form`
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

`;

export const Link = styled.a`
    font-size:14px;
    line-height: 21px;
    color:#333333;
    font-weight: 500;
    margin-top: 20px;
    text-decoration:underline;
`;

export const Error = styled.p`
    font-size:14px;
    line-height: 21px;
    color:#F00;
    font-weight: 500;
    margin-top: 20px;
`;