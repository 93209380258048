import React, { useState, useEffect } from "react";
import * as Tag from "./styles";

const ButtonSearchHome = (props) => {
    const [width, setWidth] = useState(window.innerWidth);
    const updateDimensions = () => {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    }, []);

    return (
        <Tag.Container>
            {width > 768 && <Tag.BoxImg>
                <Tag.Icon src={`${process.env.REACT_APP_BASE_URL_UPLOADS}${props.icon}`} />
            </Tag.BoxImg>}
            <Tag.Texts>
                <Tag.Row style={{marginTop: '0'}}>
                    {width <= 768 && <Tag.BoxImg>
                        <Tag.Icon src={`${process.env.REACT_APP_BASE_URL_API}${props.icon}`} />
                    </Tag.BoxImg>}
                    <Tag.Title href={props.link}>{props.title}</Tag.Title>
                </Tag.Row>
                <Tag.Row>
                    {props.links && props.links.map((item, index) =>
                        <>
                            {index > 0 && <Tag.Separator />}
                            <Tag.Subtitle href={item.url}>{item.title}</Tag.Subtitle>
                        </>
                    )}
                </Tag.Row>
            </Tag.Texts>
        </Tag.Container>
    );
}

export default ButtonSearchHome;