import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
    height: 221px;
    background: #FFFFFF;
    border: 1px solid #E5E5E5;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    margin-top:18px;
    padding: 12px;
   
    @media(max-width: 768px) {
        height: auto;
        padding:0;
        border: 0;
        box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.05);

    }
`;

export const Title = styled.div`
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #333333;
`;

export const Icon = styled.img`
    width: 10px;
    height: 10px;
    margin-left:8px;
    object-fit:contain;
`;

export const Top = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
`;

export const Link = styled.a`
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-align: right;
    color: #0000FF;
`;

export const Scroll = styled.div`
   overflow-y:auto;
   width: 100%;
`;