import React from "react";
import * as Tag from "./styles";
import ImgBanner from '../../assets/emplo_banner_home_small.jpg';

const BannerHomeSmall = (props) => {
    return (
        <Tag.Container>
            <Tag.Image src={ImgBanner} />
        </Tag.Container>
    );
}

export default BannerHomeSmall;