
import React from "react";
import * as Tag from "./styles";
import IcoFav from "../../assets/ico_fav_blue.svg";
import IcoFavActive from "../../assets/ico_fav_blue_active.svg";
import IcoShare from "../../assets/ico_share.svg";
import IcoReport from "../../assets/ico_flag.svg";

const ProductDetailActions = (props) => {
    return (
        <Tag.Container>
            <Tag.Link href="#">
                <Tag.Icon src={props.favorite ? IcoFavActive : IcoFav} />
                {props.favorite ? 'Favorito' : 'Favoritar'}
            </Tag.Link>
            <Tag.Link>
                <Tag.Icon src={IcoShare} />
            </Tag.Link>
            <Tag.Link>
                <Tag.Icon src={IcoReport} />
            </Tag.Link>
        </Tag.Container>
    );
}

export default ProductDetailActions;