
import React from "react";
import * as Tag from "./styles";

const Rating = (props) => {
    return (
        <Tag.Container marginTop={props.marginTop} align={props.align}>
            {!props.hideNumber && props.rate > 0 && <Tag.Rate big={props.big}>{props.rate}</Tag.Rate>}
            <Tag.Stars>
                <Tag.Star big={props.big} active={props.rate > 0} />
                <Tag.Star big={props.big} active={props.rate >= 2} />
                <Tag.Star big={props.big} active={props.rate >= 3} />
                <Tag.Star big={props.big} active={props.rate >= 4} />
                <Tag.Star big={props.big} active={props.rate >= 5} />
            </Tag.Stars>
        </Tag.Container>
    );
}

export default Rating;