import styled from "styled-components";

export const Container = styled.div`
    display: inline-flex;
    align-items: center;
    justify-content: center;
`;

export const Link = styled.a`
    width: 24px;
    height: 24px;
    background:#ffffff;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-left:12px;
    border-radius: 50px;
    white-space: nowrap; 
`;

export const Text = styled.span`
    font-size: 14px;
    line-height: 21px;
    font-weight: 600;
    color:#ffffff;
    white-space: nowrap; 
`;

export const Separator = styled.span`
    margin: 0 25px;
    width: 1px;
    background:#ffffff;
    height: 20px;
`;

export const Icon = styled.img`
    width: 14px;
    height: 14px;
    background-size:contain;
`;