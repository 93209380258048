import styled from "styled-components";
import IcoCheck from "../../assets/ico_check_white.svg";

export const Container = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: #FAFAFA;
    border: 1px solid #CBCBCB;
    border-radius: 10px;

    @media(max-width: 768px) {
        border: ${props => props.profile ? '0' : '1'} solid #CBCBCB;
        background: ${props => props.profile ? 'transparent' : '#FAFAFA'};
    }
`;

export const Row = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    margin-top: 18px;
`;

export const Icon = styled.img`
    width: 20px;
    height: 20px;
    background:#2D9248 url(${IcoCheck}) no-repeat 50%;
    border-radius: 50%;
    margin-left:10px;
`;

export const Image = styled.img`
    width: 78px;
    height: 78px;
    border-radius: 50%;
    margin-top:35px;
`;

export const Name = styled.p`
    font-weight: 500;
    font-size: ${props => props.checked ? '18' : '24'}px;
    line-height: 29px;
    color: #333333;
`;

export const LinkBottom = styled.a`
    width: 100%;
    border-top:solid 1px #CBCBCB;
    margin-top: 33px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    align-items: center;
    text-align: center;
    color: #0000FF;
    padding: 15px 0;
    text-decoration:none;
`;

export const Link = styled.a`
    text-decoration:none;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #000000;
    margin-top:5px;
    cursor:pointer;
`;

export const LinkBottomFlag = styled.a`
    width: 100%;
    border-top:solid 1px #CBCBCB;
    margin-top: 33px;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    align-items: center;
    text-align: center;
    color: #000000;
    padding: 15px 0;
    text-decoration:none;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    cursor:pointer;

    @media(max-width: 768px) {
        margin-top: 10px;
        border: 0;
    }
`;

export const IconFlag = styled.img`
    width: 17px;
    height: 17px;
    margin-right:8px;
`;
