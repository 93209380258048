import { SET_USER } from '../actions/actionTypes';
const initialState = {
    id: 0,
    name: '',
    email: '',
    type: '',
    phone: ''
};
export const userReducer = (state = initialState, action) => {
    switch (action.type) {
    case SET_USER:
        return {
            ...state,
            ...action.data
        };
    default:
        return state;
    }
};