import React, { useState } from "react";
import * as Tag from "./styles";
import Calendar from 'react-calendar';
import Moment from 'moment';

const InputCalendar = (props) => {
    const [showCalendar, setShowCalendar] = useState(false);
    const [date, setDate] = useState(new Date());
    return (
        <Tag.Container {...props} height={props.height} round={props.round}>
            {showCalendar && <Calendar
                onChange={(date) => {
                    setShowCalendar(false);
                    setDate(date);
                }}
                value={date}
             />}
            {props.icon && <Tag.Icon src={props.icon} width={props.iconSize} height={props.iconSize} />}
            <Tag.Input {...props} onFocus={() => setShowCalendar(true)} onChange={() => null}  value={Moment(date).format("DD/MM/YYYY")}  />
            {}
            {props.iconRight && <Tag.Icon right={true} src={props.iconRight} width={props.iconSize} height={props.iconSize} />}
        </Tag.Container>
    );
}

export default InputCalendar;