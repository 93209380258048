import React, { useState, useRef, useEffect } from "react";
import * as Tag from "./styles";
import Header from  '../../components/Header';
import Footer from  '../../components/Footer';
import DropDownList from "../../components/DropDownList";
import InputText from "../../components/InputText";
import IcoSearch from "../../assets/ico_search.svg";
import IcoActions from '../../assets/ico_actions_product.svg';
import IcoFilters from '../../assets/ico_filters_blue_mobile.svg';
import ProductThumb from "../../components/ProductThumb";
import Popup from "../../components/Popup";
import Button from "../../components/Button";
import PopUpFilterProfileMobile from "../../components/PopUpFilterProfileMobile";
import api from "../../services/api";
import LoaderFullScreen from "../../components/LoaderFullScreen";
import { connect } from 'react-redux';

const orderBy = [
    {
        id : "Data de publicação",
        title : "Data de publicação"
    },
    {
        id : "menor_preco",
        title : "Menor preço"
    },
    {
        id : "maior_preco",
        title : "Maior preço"
    },
    {
        id : "Maior alcance",
        title : "Maior alcance"
    }
];

const MyAds = (props) => {
    const [loader, setLoader] = useState(false);
    const [adsList, setAdsList] = useState([]);
    const [filterStatus, setFilterStatus] = useState('active');
    const [popupTitle, setTitlePopup] = useState("");
    const [popupActions, setPopupActions] = useState(false);
    const [widthProduct, setWidthProduct] = useState(275);
    const [filtersMobile, setFiltersMobile] = useState(false);
    const [width, setWidth] = useState(window.innerWidth);
    const contentProducts = useRef();
    const didMount = useRef(true);

    function calcWidthProduct(){
        const divProducts = contentProducts.current.offsetWidth;
        let qtd = 4;
        if(window.innerWidth <= 1024){
            qtd = 3;
        }
        if(window.innerWidth <= 960){
            qtd = 2;
        }
        if(window.innerWidth <= 768){
            qtd = 1;
        }
        setWidthProduct((divProducts / qtd) - 14);
    }

    useEffect(() => {

        const updateDimensions = () => {
            calcWidthProduct();
            setWidth(window.innerWidth);
        }
        
        window.addEventListener("resize", updateDimensions);
        calcWidthProduct();
        return () => window.removeEventListener("resize", updateDimensions);
    }, [contentProducts]);

    function showPopupActions(title){
        setTitlePopup(title);
        setPopupActions(true);
    }

    function onFilter(){
        setFiltersMobile(false);
    }
    
    async function getMyAds(){
        try {
            setLoader(true);
            const response = await api.post(`/ads/my`, {user_id : props.user.id});
            console.log(response);
            if(response.data.status){
                setAdsList(response.data.data);
            }
            setLoader(false);
        } catch (err) {
            console.log(err);
        }finally{
            setLoader(false);
        }
    }

    useEffect(() => {
        if(props.user.id > 0){
            if(didMount.current){
                didMount.current = false;
                getMyAds();
            }
        }
    },[props.user.id]);
    
    return (
        <Tag.Container>
            <Header account />
            <Tag.Content>

                {loader && <LoaderFullScreen />}

                <Tag.Title>Meus anúncios</Tag.Title>

                <Tag.TabsFilters>
                    <Tag.Tab active={filterStatus === 'active'} onClick={() => setFilterStatus('active')}>Ativos ({adsList.filter(item => item.status === 'active').length})</Tag.Tab>
                    <Tag.Tab active={filterStatus === 'draft'} onClick={() => setFilterStatus('draft')}>Rascunhos ({adsList.filter(item => item.status === 'draft').length})</Tag.Tab>
                    <Tag.Tab active={filterStatus === 'pending'} onClick={() => setFilterStatus('pending')} inative>Aguardando aprovação ({adsList.filter(item => item.status === 'pending').length})</Tag.Tab>
                    <Tag.Tab active={filterStatus === 'refused'} onClick={() => setFilterStatus('refused')} inative>Não aprovados ({adsList.filter(item => item.status === 'refused').length})</Tag.Tab>
                    <Tag.Tab active={filterStatus === 'paused'} onClick={() => setFilterStatus('paused')}>Pausados ({adsList.filter(item => item.status === 'paused').length})</Tag.Tab>
                    <Tag.Tab active={filterStatus === 'expired'} onClick={() => setFilterStatus('expired')}>Expirados ({adsList.filter(item => item.status === 'expired')?.length})</Tag.Tab>
                </Tag.TabsFilters>

                <Tag.BoxProducts ref={contentProducts}>

                    {/** -- Popup de confirmação de ação de denunciar ou ocultar --- **/}
                    {popupActions &&
                        <Popup title={popupTitle} onBlur={() => setPopupActions(false)} smallTitle maxWidth={532}>
                            <Tag.Icon src={IcoActions} />
                            <Button text="Ok" width={218} style={{marginTop: '60px'}} />
                        </Popup>
                    }

                    {filtersMobile && <PopUpFilterProfileMobile onFilter={() => onFilter()} setFiltersMobile={() => setFiltersMobile(false)} />}

                    {width > 768 &&
                    <Tag.Filters>
                        <Tag.Filter width={200}>
                            <DropDownList items={orderBy} placeholder={"Ordenar por"} style={{zIndex : 1}} />
                        </Tag.Filter>
                        <Tag.Filter width={200}>
                            <DropDownList items={orderBy} placeholder="Categorias" style={{zIndex : 1}} />
                        </Tag.Filter>
                        <Tag.Filter width={800}>
                            <InputText placeholder="Estou procurando por..." iconRight={IcoSearch} />
                        </Tag.Filter>
                    </Tag.Filters>
                    }

                    {width <= 768 &&
                    <Tag.Filters>
                        <Tag.IconFilter src={IcoFilters} onClick={() => setFiltersMobile(true)} />
                        <Tag.Filter>
                            <InputText placeholder="Buscar" iconRight={IcoSearch} round={50} height={36} />
                        </Tag.Filter>
                    </Tag.Filters>
                    }

                    <Tag.Products>
                        {adsList.length && adsList.filter(item => item.status === filterStatus).map((item, index) => 
                        <Tag.Item key={item.id} width={widthProduct}>
                            <ProductThumb product={item} showPopupActions={(title) => showPopupActions(title)} owner />
                        </Tag.Item>
                        )}
                    </Tag.Products>
                </Tag.BoxProducts>
            </Tag.Content>
            <Footer />
        </Tag.Container>
    );
}

const mapStateToProps = store => ({
    user: store.userState,
    location: store.locationState
});

export default connect(mapStateToProps)(MyAds);