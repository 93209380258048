import React from "react";
import * as Tag from "./styles";
import SocialFooterMobile from '../SocialFooterMobile';

const FooterMobile = (props) => {
    return (
        <Tag.Container>
            <Tag.ContLinks>
                <SocialFooterMobile linksFooter={props.links} />
            </Tag.ContLinks>
            <Tag.Bottom>
                <Tag.ContainerBottom>
                    <Tag.Link href="/terms-of-use">Termos de uso</Tag.Link>
                    <Tag.Separator />
                    <Tag.Link href="/privacy-policies">Política de privacidade</Tag.Link>
                    <Tag.Copyright>Copyright 2022 Foi Fácil</Tag.Copyright>
                </Tag.ContainerBottom>
            </Tag.Bottom>
        </Tag.Container>
    );
}

export default FooterMobile;