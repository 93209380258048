import React from "react";
import * as Tag from "./styles";
import IcoGoogle from "../../assets/ico_google.svg";
import IcoApple from "../../assets/ico_apple.svg";

const LinkAppsMobile = (props) => {
    return (
        <Tag.Container>
            <Tag.Link href="/help-center">Central de ajuda</Tag.Link>
            <Tag.Separator />
            <Tag.Text>Baixe o app</Tag.Text>
            <Tag.Link href="#">
                <Tag.Icon src={IcoGoogle} />
            </Tag.Link>
            <Tag.Link href="#">
                <Tag.Icon src={IcoApple} />
            </Tag.Link>
        </Tag.Container>
    );
}

export default LinkAppsMobile;