import React, { useState } from "react";
import HeaderHelpCenterDetail from  '../../components/HeaderHelpCenterDetail';
import ImageDetail from  '../../assets/foto_detail_help_center.jpg';
import Footer from  '../../components/Footer';
import TitleBox from "../../components/TitleBox";
import Related from "../../components/Related";
import * as Tag  from "./styles";
import PopupReportHelpCenter from "../../components/PopupReportHelpCenter";

const HelpCenterDetail = (props) => {
    const [showPopupAvaliacao, setPopupAvaliacao] = useState(false);
    return (
        <Tag.Container>
            <HeaderHelpCenterDetail />
            <Tag.Content>

                {showPopupAvaliacao && <PopupReportHelpCenter setPopupAvaliacao={(status) => setPopupAvaliacao(status)} />}

                <Tag.Title>Lorem ipsum dolor sit amet, consecte adipiscin elit</Tag.Title>
                <Tag.Category>Pagamentos</Tag.Category>
                <Tag.Texts>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eu vulputate arcu risus eget erat sagittis lorem. Scelerisque risus aliquet in enim mattis et. Non elit sapien in semper. Quis ultrices phasellus dignissim sed sed etiam mi semper ornare. Dignissim adipiscing malesuada vivamus sagittis ullamcorper rutrum. Libero nunc vestibulum eget consequat, malesuada duis. Morbi quis tellus parturient odio neque.</p>
                    <img src={ImageDetail} alt="" />
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eu vulputate arcu risus eget erat sagittis lorem. Scelerisque risus aliquet in enim mattis et. Non elit sapien in semper. Quis ultrices phasellus dignissim sed sed etiam mi semper ornare. Dignissim adipiscing malesuada vivamus sagittis ullamcorper rutrum. Libero nunc vestibulum eget consequat, malesuada duis. Morbi quis tellus parturient odio neque.</p>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eu vulputate arcu risus eget erat sagittis lorem. Scelerisque risus aliquet in enim mattis et. Non elit sapien in semper. Quis ultrices phasellus dignissim sed sed etiam mi semper ornare. Dignissim adipiscing malesuada vivamus sagittis ullamcorper rutrum. Libero nunc vestibulum eget consequat, malesuada duis. Morbi quis tellus parturient odio neque.</p>
                </Tag.Texts>
                <Tag.Subtitle>Este artigo foi útil?</Tag.Subtitle>
                <Tag.Links>
                    <Tag.ButtonLink onClick={() => setPopupAvaliacao(true)}>Sim</Tag.ButtonLink>
                    <Tag.ButtonLink onClick={() => setPopupAvaliacao(true)}>Não</Tag.ButtonLink>
                </Tag.Links>
            </Tag.Content>

            <Tag.RelatedList>
                <TitleBox title="Outras categorias" style={{marginTop:0}} />
                <Tag.ScrollRelated>
                    <Tag.Related>
                        <Related />
                        <Related />
                        <Related />
                    </Tag.Related>
                </Tag.ScrollRelated>
            </Tag.RelatedList>

            <Footer />
        </Tag.Container>
    );
}

export default HelpCenterDetail;