import React, { useState } from "react";
import * as Tag from "./styles";
import Header from  '../../components/Header';
import Footer from  '../../components/Footer';
import MenuSidebar from "../../components/MenuSidebar";
import BoxFormAccount from "../../components/BoxFormAccount";
import Button from "../../components/Button";
import ImgNoCards from "../../assets/img_no_cards.svg";
import ImgNoPlan from "../../assets/img_no_plan.svg";
import ImgHistoric from "../../assets/img_historic.svg";
import IcoPlus from "../../assets/ico_plus.svg";
import CardListCheckout from "../../components/CardListCheckout";
import MyPlan from "../../components/MyPlan";
import Historic from "../../components/Historic";
import { paymentStatus } from "../../enum/paymentStatus.ts";

const plans = [
    {
        title : "Imóveis - Plano Plus",
        price : "R$ 80,00",
        expiration : "12/02/2022",
        items : ["0/5 anúncios usados", "1/2 destaques usados"],
        status : paymentStatus.PENDING
    },
    {
        title : "Automóveis - Plano Premium",
        price : "R$ 120,00",
        expiration : "12/02/2022",
        items : ["0/5 anúncios usados", "1/2 destaques usados"],
        status : paymentStatus.PAID
    }
];

const historic = [
    {
        title : "Automóveis - Plano Premium",
        price : "R$ 150,00",
        date : "12/01/2022",
        status : paymentStatus.PAID
    },
    {
        title : "Imóveis - Plano Básico",
        price : "R$  80,00",
        date : "12/01/2022",
        status : paymentStatus.REFUSED
    },
    {
        title : "Estorno Roupas - Plano Básico",
        price : "R$  20,00",
        date : "12/01/2022",
        status : paymentStatus.REFOUND
    }
];

const Financial = (props) => {
    const [showCards, setShowCards] = useState(false);
    const [showPlans, setShowPlans] = useState(false);
    const [showHistoric, setShowHistoric] = useState(false);
    return (
        <Tag.Container>
            <Header account />
            <Tag.Content>
                <MenuSidebar active="financial" />
                <Tag.Form>

                    <BoxFormAccount active={true} >
                        <Tag.Title>Cartões</Tag.Title>
                        {!showCards && <Tag.Texts>
                            <Tag.Description>Você ainda não cadastrou nenhum cartão</Tag.Description>
                            <Tag.Icon src={ImgNoCards} />
                            <Button onClick={() => setShowCards(true)} text="Cadastrar" width={214} style={{marginTop: '42px'}} />
                        </Tag.Texts>}
                        
                        {showCards && <Tag.ListCards>
                            <CardListCheckout noCheck />
                            <Tag.AddCard><Tag.IconAdd src={IcoPlus} /> Adicionar novo cartão</Tag.AddCard>
                        </Tag.ListCards>}

                    </BoxFormAccount>
                    
                    <BoxFormAccount active={true} >
                        <Tag.Title>Planos</Tag.Title>
                        {!showPlans && <Tag.Texts>
                            <Tag.Description>Adquira um plano para aumentar suas chances de vender e se destacar entre seus concorrentes!</Tag.Description>
                            <Tag.Icon src={ImgNoPlan} />
                            <Button onClick={() => setShowPlans(true)} text="Ver planos" width={214} style={{marginTop: '42px'}} />
                        </Tag.Texts>}

                        {showPlans && <Tag.Plans>
                            {plans.map((item,index) => <MyPlan item={item} key={index} />)}
                        </Tag.Plans>}

                    </BoxFormAccount>

                    <BoxFormAccount active={true} >
                        <Tag.Title>Histórico</Tag.Title>
                        {!showHistoric && <Tag.Texts>
                            <Tag.Description>Aqui aparecerá seu histórico de transacções quando realizar alguma</Tag.Description>
                            <Tag.Icon src={ImgHistoric} onClick={() => setShowHistoric(true)} />
                        </Tag.Texts>}

                        {showHistoric && <Tag.Historic>
                            {historic.map((item,index) => <Historic item={item} key={index} />)}
                        </Tag.Historic>}
                    </BoxFormAccount>

                </Tag.Form>
            </Tag.Content>
            <Footer />
        </Tag.Container>
    );
}

export default Financial;