
import React, { useState } from "react";
import * as Tag from "./styles";

const Tabs = (props) => {
    const [active, setActive] = useState(props.active);

    function onClickItem(id)
    {
        setActive(id);
        props?.onChangeItem(id);
    }

    return (
        <Tag.Container {...props} round={props.round}>
            {props.items.map((item, index) => 
                <Tag.Item activeColor={props.activeColor} height={props.height}  onClick={() => onClickItem(item.id)} key={index} active={item.id === active}>
                    <Tag.Text inativeColorText={props.inativeColorText} active={item.id === active}>{item.title}</Tag.Text>
                </Tag.Item>
            )}
        </Tag.Container>
    );
}

export default Tabs;