import styled from "styled-components";

export const Container = styled.div`
    max-width: 100%;

    :first-child{
        margin-right: 10px;
    }
    :last-child{
        margin-left: 10px;
    }

    @media(max-width: 960px) {
        :first-child{
            margin-right: 0;
        }
        :last-child{
            margin-left: 0;
            margin-top:20px;
        }
    }
`;

export const Image = styled.img`
    width: 100%;
`;