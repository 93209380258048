import React from "react";
import Header from  '../../components/Header';
import Footer from  '../../components/Footer';
import * as Tag  from "./styles";
import InputText from "../../components/InputText";
import Button from "../../components/Button";
import RadioBoxList from "../../components/RadioBoxList";
import IconCard from "../../assets/ico_card.svg";
import IconUser from "../../assets/ico_name.svg";
import IconDate from "../../assets/ico_calendar.svg";
import IconLock from "../../assets/ico_lock.svg";
import IcoVisaWhite from "../../assets/ico_visa_white.svg";

const AddCard = (props) => {
    return (
        <Tag.Container>
            <Header account />
            <Tag.Content>
                <Tag.Title>Cadastre seu cartão para começar a usá-lo!</Tag.Title>

                <Tag.Form>

                    <Tag.Card>
                        <Tag.Flag src={IcoVisaWhite} fill="#FFF" />
                        <Tag.Type>Crédito</Tag.Type>
                        <Tag.Number>***** ******** *****</Tag.Number>
                        <Tag.Name>JOAO VITOR DA SILVA</Tag.Name>
                        <Tag.Bottom>
                            <Tag.Date>56/74</Tag.Date>
                            <Tag.Cvv>CVV</Tag.Cvv>
                        </Tag.Bottom>
                    </Tag.Card>

                    <Tag.Fields>
                        <Tag.Row>
                            <InputText placeholder="Número do cartão" icon={IconCard} />
                        </Tag.Row>
                        <Tag.Row>
                            <InputText placeholder="Nome impresso no cartão" icon={IconUser} />
                        </Tag.Row>
                        <Tag.Row>
                            <Tag.Col>
                                <InputText placeholder="Validade" icon={IconDate} />
                            </Tag.Col>
                            <Tag.Col>
                                <InputText placeholder="Código" icon={IconLock} />
                            </Tag.Col>
                        </Tag.Row>
                        <Tag.Row>
                            <RadioBoxList horizontal marginRight={25} items={[{id : 'debito', title : 'Débito'},{id : 'credito', title : 'Crédito'}]} fontSize={18} lineHeight={27} fontWeight={500} />
                        </Tag.Row>
                        <Tag.Row>
                            <Button text="Salvar cartão" width={230} />
                        </Tag.Row>
                    </Tag.Fields>
                </Tag.Form>
            </Tag.Content>
            <Footer />
        </Tag.Container>    
    );
}

export default AddCard;