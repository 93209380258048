import React, { useState, useEffect } from "react";
import FooterMobile from '../FooterMobile';
import FooterDesktop from '../FooterDesktop';

const links = [
    {
        title : "Cateogrias",
        links : [
            {
                title : 'Imóveis',
                link : '/imoveis'
            },
            {
                title : 'Veículos',
                link : '/imoveis'
            },
            {
                title : 'Prestação de serviços',
                link : '/imoveis'
            },
            {
                title : 'Eletrônicos',
                link : '/imoveis'
            },
            {
                title : 'Vagas de emprego',
                link : '/imoveis'
            },
            {
                title : 'Lazer e esporte',
                link : '/imoveis'
            },
            {
                title : 'Ver todas as categorias',
                link : '/imoveis'
            }
        ],
        contacts : false
    },
    {
        title : "Minha Conta",
        links : [
            {
                title : 'Chat',
                link : '/account/chat'
            },
            {
                title : 'Meus anúncios',
                link : '/account/my-ads'
            },
            {
                title : 'Lista de desejos',
                link : '/account/wishlist'
            },
            {
                title : 'Meus planos',
                link : '/account/my-plans'
            },
            {
                title : 'Perfil',
                link : '/account/my-profile'
            },
            {
                title : 'Confirgurações',
                link : '/account/my-cadastre'
            }
        ],
        contacts : false
    },
    {
        title : "Precisa de ajuda?",
        links : [
            {
                title : 'Central de ajuda',
                link : '/help-center'
            },
        ],
        contacts : true
    }
    
];

const Footer = (props) => {
    const [width, setWidth] = useState(window.innerWidth);
    const updateDimensions = () => {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    }, []);

    return (
        width > 560 ? <FooterDesktop links={links} /> : <FooterMobile links={links} />
    );
}

export default Footer;