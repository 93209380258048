
import React, { useState } from "react";
import IcoDown from "../../assets/ico_arrow_down_gray.svg";
import IcoUp from "../../assets/ico_arrow_up_gray.svg";
import * as Tag from "./styles";

const items = ["0/5 anúncios usados", "1/2 destaques usados"];

const PlanCheckout = (props) => {
    const [showDetails, setShowDetail] = useState(false);
    return (
        <Tag.Container>
            <Tag.Title>Imóveis - Plano Premium</Tag.Title>
            <Tag.Texts>
                <Tag.TextDetail onClick={() => setShowDetail(!showDetails)}>Detalhes <Tag.IconDetail src={showDetails ? IcoUp : IcoDown} /></Tag.TextDetail>
                <Tag.Details open={showDetails}>
                    {items.map((item, index) => <Tag.Description key={index}>{item}</Tag.Description>)}
                </Tag.Details>
            </Tag.Texts>
            <Tag.Total>
                <Tag.TextTotal>Total mensal: </Tag.TextTotal>
                <Tag.Price>R$ 150,00</Tag.Price>
            </Tag.Total>
        </Tag.Container>
    );
}

export default PlanCheckout;