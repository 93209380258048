
import React from "react";
import * as Tag from "./styles";
import ImageRelated from "../../assets/foto_related.jpg";

const Related = (props) => {
    return (
        <Tag.Container {...props}>
            <Tag.BoxImage>
                <Tag.Image src={ImageRelated} />
            </Tag.BoxImage>
            <Tag.Texts>
                <Tag.Title>Lorem ipsum dolor sit amet, consetetur adipiscing elit</Tag.Title>
                <Tag.Category>Planos e destaques</Tag.Category>
            </Tag.Texts>
        </Tag.Container>
    );
}

export default Related;