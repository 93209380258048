import React from "react";
import Header from  '../../components/Header';
import Footer from  '../../components/Footer';
import * as Tag  from "./styles";

const MyPlans = (props) => {
    return (
        <Tag.Container>
            <Header />
            <Tag.Content>
                <Tag.Title>Erro 404</Tag.Title>
                <Tag.Description>A página solicitada não foi encontrada!</Tag.Description>
            </Tag.Content>
            <Footer />
        </Tag.Container>    
    );
}

export default MyPlans;