import React from "react";
import * as Tag from "./styles";

const CategoryBox = (props) => {
    return (
        <Tag.Container href={props.link}>
            <Tag.Title>{props.title}</Tag.Title>
        </Tag.Container>
    );
}

export default CategoryBox;