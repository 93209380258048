import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import HeaderMobile from '../HeaderMobile';
import HeaderDesktop from '../HeaderDesktop';
import api from "../../services/api";
import { logout, isAuthenticated } from "../../services/auth";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setUser, setLocation } from '../../actions';

const Header = (props) => {
    const history = useNavigate();
    const [width, setWidth] = useState(window.innerWidth);
    const updateDimensions = () => {
        setWidth(window.innerWidth);
    }
    const didMount = useRef(true);

    async function getUser(){
        try {
            const result = await api.get("/user/getByToken");
            const { data } = result?.data;
            const phone = data?.contacts.find(item => item.type === 'phone');
            props.setUser({id : data.id, name : data.name, email : data.email, type : data.type, phone : phone?.contact});
            if(props.account && !phone?.contact){
                history("/account-validate-phone", { replace: true });
            }
        } catch (err) {
            console.log(err);
            logout();
            history("/home", { replace: true });
        }
    }

    async function position(){
        await navigator.geolocation.getCurrentPosition(position => props.setLocation({ 
                latitude: position.coords.latitude, 
                longitude: position.coords.longitude,
            }), 
            err => console.log(err)
        );
            
    }
    
    useEffect(() => {
        if(didMount.current){
            didMount.current = false;
            position();
            if(isAuthenticated()){
                getUser();
            }
        }
    }, []);

    useEffect(() => {
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    }, []);
    
    return (
        width > 768 ? <HeaderDesktop {...props} account={props.account} /> : <HeaderMobile {...props} />
    );
}
const mapDispatchToProps = dispatch => bindActionCreators({ setUser, setLocation }, dispatch);

const mapStateToProps = store => ({
    user: store.userState,
    location: store.locationState
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);