import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 100vh;
    flex-direction: column;
`;

export const Content = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: row;
    padding: 77px 120px 120px;
    background:#fff;
    width: 100%;
    max-width: 1440px;
    flex: 1;

    @media(max-width: 1280px) {
        padding: 77px 40px 120px;
    }

    @media(max-width: 1200px) {
        padding: 77px 20px 120px;
    }

    @media(max-width: 768px) {
        padding: 20px 20px 40px;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
    }
`;

export const Sidebar = styled.div`
    width: 282px;
    margin-right: 41px;

    @media(max-width: 768px) {
        margin-right: 0;
        width: 100%;
    }
`;

export const BoxProducts = styled.div`
    width: 100%;
    display:inline-flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    overflow: hidden;
    flex: 1;

    @media(max-width: 768px) {
        margin-top: 30px;
    }
`;

export const Filters = styled.div`
    width: calc(100% + 16px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    margin-left:-8px;
`;

export const Filter = styled.div`
    padding: 0 8px;
    width: 100%;
    max-width: ${props => props.width ? `${props.width}px`: '100%'};
    flex: 1;
`;

export const Total = styled.p`
    width: 100%;
    text-align:right;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #333333;
    margin-top:24px;

    @media(max-width: 768px) {
        text-align:left;
    }
`;

export const Products = styled.div`
    width: 100%;
    display: grid;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    height: auto;
    grid-template-columns: auto auto auto;
	grid-gap: 20px;

    @media(max-width: 960px) {
        grid-template-columns: auto auto;
    }

    @media(max-width: 768px) {
        grid-template-columns: auto;
    }
`;

export const Item = styled.div`
    margin:12px 0;
    display:inline-block;
    float:left;
    max-width: ${props => props.width ? props.width : 275}px;

`;

export const Icon = styled.img`
    margin-top:42px;
    max-width: 191px;
    max-height: 191px;
    object-fit:contain;
`;

export const IconFilter = styled.img`
    width: 39px;
    height: 27px;
    object-fit:contain;
`;