import React, { useState, useEffect } from "react";
import * as Tag from "./styles";
import ImgNotification from "../../assets/img_notification.svg";

const Notification = (props) => {
    const [width, setWidth] = useState(window.innerWidth);
    const updateDimensions = () => {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    }, []);

    return (
        <Tag.Container>
            <Tag.Image src={ImgNotification} width={props.single && width > 768 ? 60 : 32} height={props.single && width > 768 ? 60 : 32} />
            <Tag.Texts>
                <Tag.BoxTitle single={props.single}>
                    <Tag.Title single={props.single}>Nova mensagem de Usuário</Tag.Title>
                    <Tag.Date>12/02/2022</Tag.Date>
                </Tag.BoxTitle>
                <Tag.Link href="#">Abrir chat</Tag.Link>
            </Tag.Texts>
        </Tag.Container>
    );
}

export default Notification;