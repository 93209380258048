import styled from "styled-components";

export const Container = styled.div`
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    min-height: 40px;
    width: 100%;
`;

export const Link = styled.a`
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
`;

export const Icon = styled.img`
    width: ${props => (props.width ? props.width : `20`)}px;
    height: ${props => (props.height ? props.height : `20`)}px;
    margin-right: ${props => (props.right != null ? props.right : `8`)}px;
`;

export const Name = styled.span`
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color:#333333;
    display: inline-block;
`;