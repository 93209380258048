import React, { useState, useEffect } from "react";
import * as Tag from "./styles";
import Header from  '../../components/Header';
import Footer from  '../../components/Footer';
import MenuSidebar from "../../components/MenuSidebar";
import PersonalData from "../../components/PersonalData";
import Address from "../../components/Address";
import BoxFormAccount from "../../components/BoxFormAccount";
import Contact from "../../components/Contact";
import IcoInfo from "../../assets/ico_info.svg";
import IcoError from "../../assets/ico_error.svg";
import PersonalDataForm from "../../components/PersonalDataForm";
import AddressForm from "../../components/AddressForm";
import Button from "../../components/Button";
import PopupDisableAccount from "../../components/PopupDisableAccount";
import PopupDeleteAccount from "../../components/PopupDeleteAccount";

const MyCadastre = (props) => {
    const [personalData, setPersonalData] = useState(false);
    const [address, setAddress] = useState(false);
    const [accountStatus, setAccountStatus] = useState(false);

    const [popupDisableAccount, setDisableAccount] = useState(false);
    const [popupDeleteAccount, setDeleteAccount] = useState(false);

    const [width, setWidth] = useState(window.innerWidth);
    const updateDimensions = () => {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    }, []);
    
    return (
        <Tag.Container>
            <Header account />
            <Tag.Content>
                <MenuSidebar active="my-cadastre" />
                <Tag.Form>

                    {popupDisableAccount && <PopupDisableAccount onCancel={() => setDisableAccount(false)} onDisabled={() => {setDisableAccount(false); setAccountStatus(false);}} />}
                    {popupDeleteAccount && <PopupDeleteAccount onCancel={() => setDeleteAccount(false)} onDelete={() => null} />}

                    <BoxFormAccount title="Dados pessoais">
                        {!personalData && <PersonalData onEdit={() => setPersonalData(true)} />}
                        {personalData && <PersonalDataForm onSave={() => setPersonalData(false)} onCancel={() => setPersonalData(false)} />}
                    </BoxFormAccount>


                    <BoxFormAccount title="Contato">
                        <Contact />
                    </BoxFormAccount>

                    <BoxFormAccount title="Endereço">
                        {!address && <Address onEdit={() => setAddress(true)} />}
                        {address && <AddressForm onSave={() => setAddress(false)} onCancel={() => setAddress(false)} />}
                    </BoxFormAccount>

                    <BoxFormAccount active={true}>
                        
                        {!accountStatus &&
                            <Tag.Message>
                                <Tag.TextMsg><Tag.Icon src={IcoInfo} />Conta desativada</Tag.TextMsg>
                                {width > 768 && <Tag.Link onClick={() => setAccountStatus(true)}>Reativar conta</Tag.Link>}
                                {width <= 768 && <Button onClick={() => setAccountStatus(true)} text="Reativar conta" width={195} style={{marginTop: '32px'}} />}
                            </Tag.Message>
                        }

                        {accountStatus &&
                            <Tag.Message>
                                <Tag.Left>
                                    <Tag.TextMsg><Tag.Icon src={IcoInfo} />Desativar conta</Tag.TextMsg>
                                    <Tag.Obs>Você poderá reativar esta conta posteriormente. Porém, todos seus anúncios serão eliminados.</Tag.Obs>
                                </Tag.Left>
                                {width > 768 && <Tag.LinkDisabled onClick={() => setDisableAccount(true)}>Desativar</Tag.LinkDisabled>}
                                {width <= 768 && <Button onClick={() => setDisableAccount(true)} text="Desativar" width={195} style={{marginTop: '32px'}} background="#F2994A" color="#fff" />}
                            </Tag.Message>
                        }

                    </BoxFormAccount>

                    <BoxFormAccount active={true}>
                        <Tag.Message>
                            <Tag.Left>
                                <Tag.TextMsg><Tag.Icon src={IcoError} />Emilinar conta</Tag.TextMsg>
                                <Tag.Obs>Sua conta e todas as informações serão permanentemente eliminadas.</Tag.Obs>
                            </Tag.Left>
                            {width > 768 && <Tag.LinkDelete onClick={() => setDeleteAccount(true)}>Eliminar</Tag.LinkDelete>}
                            {width <= 768 && <Button onClick={() => setDeleteAccount(true)} text="Eliminar" width={195} style={{marginTop: '32px'}} background="#E62E1F" color="#fff" />}
                        </Tag.Message>
                    </BoxFormAccount>
                    
                </Tag.Form>
            </Tag.Content>
            <Footer />
        </Tag.Container>
    );
}

export default MyCadastre;