
import React from "react";
import * as Tag from "./styles";
import Button from  "../Button";
import ImageUser from "../../assets/foto_user.jpg";
import IcoAds from '../../assets/ico_ads.svg';
import IconArrow from "../../assets/ico_arrow_right_nivel.svg";

const BoxMyUserSidebar = (props) => {
    return (
        <Tag.Container profile={props.profile}>
            <Tag.Image src={ImageUser} />
            <Tag.Row>
                <Tag.Name checked={props.checked}>Ana Paula da Silva</Tag.Name>
                <Tag.Icon />
            </Tag.Row>
            {props.level && <Tag.Level>Nível 6 <Tag.IconLevel src={IconArrow} /></Tag.Level>}
            <Tag.Link onClick={() => props.setPopupRating()}>Editar perfil</Tag.Link>
            <Button icon={IcoAds} text="Ver meus anúncios" width={243} height={44} style={{marginTop: '24px', justifyContent: "center"}} />
        </Tag.Container>
    );
}

export default BoxMyUserSidebar;