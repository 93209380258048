import React from "react";
import * as Tag from "./styles";
import Header from  '../../components/Header';
import Footer from  '../../components/Footer';
import BannerSearch from  '../../components/BannerSearch';
import ProductsCarousel from  '../../components/ProductsCarousel';
import IconGold from  "../../assets/ico_gold_loan.svg";
import IconPersonal from  "../../assets/ico_personal_loan.svg";
import UndrawBuyHouse from  "../../assets/undraw_buy_house.png";
import Button from "../../components/Button";
import ImageSearchProperty from  "../../assets/banner_search_property.jpg";
import TitleBox from "../../components/TitleBox";

const filters = [
    {
        title : "Alugar",
        name : "alugar",
        filters : [
            {
                title : "Alugar 1",
                value : "1"
            },
            {
                title : "Alugar 2",
                value : "2"
            },{
                title : "Alugar 3",
                value : "3"
            }
        ]
    },
    {
        title : "Categorias",
        name : "categorias",
        filters : [
            {
                title : "Categoria 1",
                value : "1"
            },
            {
                title : "Categoria 2",
                value : "2"
            },{
                title : "Categoria 3",
                value : "3"
            }
        ]
    },
    {
        title : "Quartos",
        name : "quartos",
        filters : [
            {
                title : "Quarto 1",
                value : "1"
            },
            {
                title : "Quarto 2",
                value : "2"
            },{
                title : "Quarto 3",
                value : "3"
            },{
                title : "Quarto 4",
                value : "4"
            }
        ]
    }
];

const SearchProperty = (props) => {
    return (
        <Tag.Container>
            <Header />

            <BannerSearch category="imoveis" filters={filters} image={ImageSearchProperty} title="Encontre aqui seu próximo imóvel!" />

            <Tag.Content>

                <Tag.ButtonsSimulate>
                    <Tag.LinkSimulate href="/simulator#emprestimo">
                        <Tag.BoxIcon>
                            <Tag.Icon src={IconGold} />
                        </Tag.BoxIcon>
                        <Tag.TitleLink>Simule um emprestimo</Tag.TitleLink>
                    </Tag.LinkSimulate>
                    <Tag.LinkSimulate href="/simulator#financiamento">
                        <Tag.BoxIcon>
                            <Tag.Icon src={IconPersonal} />
                        </Tag.BoxIcon>
                        <Tag.TitleLink>Simule um financiamento</Tag.TitleLink>
                    </Tag.LinkSimulate>
                </Tag.ButtonsSimulate>

                <TitleBox title="Mais relevantes" />
                <ProductsCarousel />

                <Tag.BannerCenter>
                    <Tag.BannerCenterContent>
                        <Tag.TextsBanner>
                            <Tag.TitleBanner>Você é vendedor profissional?</Tag.TitleBanner>
                            <Tag.TextBanner>Crie uma conta empresarial e comece a vender!</Tag.TextBanner>
                            <Button href="#" type="link" text="Criar conta empresarial" width={263} />
                        </Tag.TextsBanner>
                        <Tag.ImageBanner src={UndrawBuyHouse} />
                    </Tag.BannerCenterContent>
                </Tag.BannerCenter>

                <TitleBox title="Baixaram o preço" />
                <ProductsCarousel />

            </Tag.Content>
            <Footer />
        </Tag.Container>
    );
}

export default SearchProperty;