
import React, { useState } from "react";
import * as Tag from "./styles";
import Popup from "../Popup";
import Button from "../Button";
import ButtonOutline from "../ButtonOutline";
import LinkButton from "../LinkButton";
import PreviewAnnouncement from  '../PreviewAnnouncement';
import InputText from  '../InputText';
import IcoArrowLeft from "../../assets/ico_arrow_left_blue_type2.svg";

const PopupSimulator = (props) => {
    const [announcement, setAnnouncement] = useState(props.announcementID);
    return (
        <Popup title={announcement === 0 ? "Insira o link para o anúncio" : "Pedir financiamento para este anúncio?"} onBlur={() => props.setshowPopup(false)}>
            {announcement === 0 && <Tag.Link onClick={() => setAnnouncement(props.announcementID)}><Tag.Icon src={IcoArrowLeft} /> Voltar</Tag.Link>}
            {announcement !== 0 && <PreviewAnnouncement />}
            {announcement === 0 && <Tag.Row>
                <InputText placeholder="Insira o link do anúncio que quer selecionar" />
            </Tag.Row>}
            
            <Button text="Pedir financiamento" width={278} style={{marginTop: '44px'}} />
            <ButtonOutline text="Pedir sem selecionar anúncio" width={278} style={{marginTop: '16px'}} />
            {announcement !== 0 && <LinkButton text="Selecionar outro anúncio" onClick={() => setAnnouncement(0)} width={278} style={{marginTop: '5px'}} color="#4F4F4F" />}
            
        </Popup>
    );
}

export default PopupSimulator;