
import React, { useState } from "react";
import * as Tag from "./styles";

const NotificationConfig = (props) => {
    const [emailActive, setEmailActive] = useState(false);
    const [appActive, setAppActive] = useState(false);
    return (
        <Tag.Container>
            <Tag.Text>{props.title}</Tag.Text>
            <Tag.Checks>
                <Tag.CheckCont onClick={() => setEmailActive(!emailActive)}>
                    <Tag.Check active={emailActive} />
                    <Tag.TextCheck>Email</Tag.TextCheck>
                </Tag.CheckCont>
                <Tag.CheckCont onClick={() => setAppActive(!appActive)}>
                    <Tag.Check active={appActive} />
                    <Tag.TextCheck>App</Tag.TextCheck>
                </Tag.CheckCont>
            </Tag.Checks>
        </Tag.Container>
    );
}

export default NotificationConfig;