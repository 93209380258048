
import React, { useRef } from "react";
import * as Tag from "./styles";
import ImageUser from "../../assets/foto_contact.jpg";
import IcoMore from "../../assets/ico_more.svg";
import IcoAttachment from "../../assets/ico_attachment.svg";
import IcoSend from "../../assets/ico_send.svg";
import InputText from "../InputText";
import Slider from "react-slick";

const ChatUser = (props) => {
    const slider = useRef(null);
    var settings = {
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true,
        touchMove : false
    };

    return (
        <Tag.Container>
            <Tag.Top>
                <Tag.User>
                    <Tag.Image src={ImageUser} />
                    <Tag.Name>Ana Paula da Silva</Tag.Name>
                </Tag.User>
                <Tag.Actions>
                    <Tag.Link>Ver anúncio</Tag.Link>
                    <Tag.IconLink src={IcoMore} />
                </Tag.Actions>
            </Tag.Top>
            <Tag.Main>
                <Tag.Date>19/02/2022</Tag.Date>
                <Tag.MessageFrom>
                    <Tag.MessageLeft>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Posuere sed magna enim vel vulputate lectus vitae.</Tag.MessageLeft>
                </Tag.MessageFrom>
                <Tag.MessageTo>
                    <Tag.MessageRight>Eget praesent sapien aliquet nisi, leo, amet nullam mi. Id quam volutpat aliquam vitae vestibulum varius. Vitae id integer semper scelerisque sodales. Est pellentesque lorem mauris commodo faucibus non et orci.</Tag.MessageRight>
                </Tag.MessageTo>
            </Tag.Main>
            <Tag.Bottom>
                <Tag.FieldMsg>
                    <InputText icon={IcoAttachment} background="#FBFBFB" />
                    <Tag.IconSend src={IcoSend} />
                </Tag.FieldMsg>
                <Tag.Suggestions>
                    <Slider ref={slider}  {...settings}>
                        <Tag.Suggestion>Olá, tudo bem?</Tag.Suggestion>
                        <Tag.Suggestion>Tenho interesse</Tag.Suggestion>
                        <Tag.Suggestion>Ainda está disponível?</Tag.Suggestion>
                        <Tag.Suggestion>Poderia dar mais detalhes?</Tag.Suggestion>
                    </Slider>
                </Tag.Suggestions>
            </Tag.Bottom>
        </Tag.Container>
    );
}

export default ChatUser;