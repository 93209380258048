import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    height: auto;
    background: #FFFFFF;
    border: 1px solid #E5E5E5;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    padding:32px 28px;
    margin-top:32px;

    @media(max-width: 768px) {
        padding:20px 16px;
    }
`;

export const Title = styled.p`
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    display: flex;
    align-items: center;
    color: #333333;

    @media(max-width: 768px) {
        font-size: 18px;
        line-height: 27px;
    }
`;

export const BoxTop = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
`;

export const IconToggle = styled.img`
    width: 25px;
    height: 25px;
    object-fit: contain;
    cursor:pointer;
`;

export const BoxContent = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    max-height: ${props => props.open ? "100%" : "0"};
    overflow: hidden;
    transition: all 0.3s ease-out;
`;