import styled from "styled-components";
import IcoArrowLeft from '../../assets/ico_arrow_left_blue.svg';
import IcoArrowRight from '../../assets/ico_arrow_right_blue.svg';

export const Container = styled.div`
    max-width: 100%;
    width: 792px;
    height: auto;
    background: #FFFFFF;
    border: 1px solid #CBCBCB;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    overflow:hidden;

    @media(max-width: 768px) {
        width: 100%;
        margin-right:0;
        border: 0;
        padding: 0;
        box-shadow: 0px 0px 0px rgb(0 0 0 / 5%);
    }
`;

export const Top = styled.div`
    width: 100%;
    padding: 14px 18px;
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

export const User = styled.div`
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;

export const Image = styled.img`
    margin-right:11px;
    border-radius: 50px;
    width: 38px;
    height:38px;
    object-fit:cover;
`;

export const Name = styled.span`
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #333333;
`;

export const Actions = styled.div`
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;

export const Link = styled.a`
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #0000FF;
    text-decoration:none;
    cursor:pointer;
`;

export const IconLink = styled.img`
    width:21px;
    height:21px;
    object-fit:contain;
    margin-left:26px;
    cursor:pointer;
`;

export const Main = styled.div`
    width: 100%;
    background: #FBFBFB;
    padding:50px 25px;
    border-top: 1px solid #CBCBCB;
    border-bottom: 1px solid #CBCBCB;
`;

export const Date = styled.p`
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    display: inline-block;
    align-items: center;
    text-align: center;
    color: #828282;
    text-align:center;
    width: 100%;

`;

export const MessageFrom = styled.div`
    margin-top:31px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
`;

export const MessageTo = styled.div`
    margin-top:31px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;

export const MessageLeft = styled.p`
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #333333;
    background: #FFFFFF;
    border: 1px solid #BDBDBD;
    border-radius:5px;
    padding:8px 12px;
    width: 90%;
    dislpay:inline-block;
    position:relative;

    :before {
        content: ' ';
        position: absolute;
        width: 0;
        height: 0;
        left: 20px;
        right: auto;
        top: auto;
        bottom: -14px;
        border: 7px solid;
        border-color: #BDBDBD transparent transparent #BDBDBD;
    }

    :after{
        content: ' ';
        position: absolute;
        width: 0;
        height: 0;
        left: 21px;
        right: auto;
        top: auto;
        bottom: -11px;
        border: 6px solid;
        border-color: #fff transparent transparent #fff;
    }
`;


export const MessageRight = styled.p`
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #333333;
    background: #FFFFFF;
    border: 1px solid #BDBDBD;
    border-radius:5px;
    padding:8px 12px;
    width: 90%;
    dislpay:inline-block;
    position:relative;

    :before {
        content: ' ';
        position: absolute;
        width: 0;
        height: 0;
        left: auto;
        right: 20px;
        top: auto;
        bottom: -14px;
        border: 7px solid;
        border-color: #BDBDBD #BDBDBD transparent transparent;
    }

    :after{
        content: ' ';
        position: absolute;
        width: 0;
        height: 0;
        left: auto;
        right: 21px;
        top: auto;
        bottom: -11px;
        border: 6px solid;
        border-color: #fff #fff transparent transparent;
    }
`;

export const Bottom = styled.div`
    width: 100%;
    padding: 16px 20px;
`;

export const FieldMsg = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const IconSend = styled.img`
    margin-left:14px;
    width:23px;
    height:23px;
    object-fit:contain;
    cursor:pointer;
`;

export const Suggestions = styled.div`
    width: 100%;
    margin-top:15px;
    position: relative;

    .slick-list{
        width: 100%;
        height: 32px!important;
        display:inline-block;
        overflow:hidden;

        @media(max-width: 768px) {
            width: 100%;
            height: 42px!important;
        }
    }

    .slick-slide{
        display:inline-block;
        padding: 0 5px;
    }

    .slick-current img{
        opacity: 1;
    }

    .slick-arrow{
        width: 55px;
        height: 32px;
        background:transparent;
        box-shadow: none;
        border:0;
        left: 0;
        position:absolute;
        z-index: 2;
        text-indent: -9999em;
        cursor:pointer;
        display:flex;
        align-items:center;
        justify-content: center;
    }

    .slick-prev{
        top:0;
        top:0px;
        left:0;
        background:linear-gradient(90deg, #FFFFFF 35.77%, rgba(255, 255, 255, 0) 101.39%);
        :after{
            content:"";
            background: url(${IcoArrowLeft}) no-repeat 50%;
            width: 15px;
            height: 15px;
            position:absolute;
            background-size:contain;
            left:0;
        }
    }

    .slick-next{
        bottom:0;
        top:0;
        bottom: initial;
        right: 0;
        left:initial;
        background:linear-gradient(270deg, #FFFFFF 35.77%, rgba(255, 255, 255, 0) 101.39%);
        
        :after{
            content:"";
            background: url(${IcoArrowRight}) no-repeat 50%;
            width: 15px;
            height: 15px;
            position:absolute;
            background-size:contain;
            right:0;
        }
    }

    .slick-arrow.slick-disabled{
        display:none!important;
    }

    .slick-dots{
        position:absolute;
        display: flex!important;
        align-items: center;
        justify-content: center;
        left: 0;
        bottom: 10px;
        width: 100%;
    }
    .slick-dots li{
        display:inline-block;
        margin:0 3px;
        height: 12px;
    }
    .slick-dots li button{
        background: #FFFFFF;
        border: 1px solid #828282;
        width: 10px;
        height: 10px;
        border-radius: 10px;
        text-indent: -500em;
        margin:0;
    }
    .slick-dots .slick-active button{
        background:#0000FF;
        border-color:#0000FF;
    }

`;

export const Suggestion = styled.span`
    width: auto;
    height: 32px;
    border: 1px solid #0000FF;
    border-radius: 24px;
    cursor:pointer;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    display: inline-flex;
    align-items: center;
    text-align: center;
    color: #0000FF;
    padding:5px 15px;
    margin-right: 12px;
    white-space: nowrap;
`;