import { SET_USER, SET_LOCATION } from './actionTypes';

export const setUser = data => ({
    type: SET_USER,
    data
});

export const setLocation = data => ({
    type: SET_LOCATION,
    data
});