
import React from "react";
import * as Tag from "./styles";
import Popup from "../Popup";
import Button from "../Button";
import LinkButton from "../LinkButton";
import InputPassword from "../InputPassword";

const PopupChangePassword = (props) => {
    return (
        <Popup maxWidth={790} onBlur={() => props.onCancel()} noCloseBlur>
            <Tag.Container>
                <Tag.TitlePopup>Trocar senha</Tag.TitlePopup>
                <Tag.Row>
                    <Tag.Label>Senha atual</Tag.Label>
                    <InputPassword placeholder="Digite a senha atual" togglePassword />
                </Tag.Row>
                <Tag.Row>
                    <Tag.Label>Nova senha</Tag.Label>
                    <InputPassword placeholder="Digite a nova senha" togglePassword />
                </Tag.Row>
                <Tag.Row>
                    <Tag.Label>Confirmar nova senha</Tag.Label>
                    <InputPassword placeholder="Repita a nova senha" togglePassword />
                </Tag.Row>
                <Tag.Col>
                    <Button text="Salvar" onClick={() => props.onSave()} width={195} />
                    <LinkButton text="Cancelar" onClick={() => props.onCancel()} width={195} color="#333333" style={{marginLeft: '20px'}} />
                </Tag.Col>
            </Tag.Container>
        </Popup>
    );
}

export default PopupChangePassword;