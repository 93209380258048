import React, { useState } from "react";
import * as Tag from "./styles";
import Header from  '../../components/Header';
import Footer from  '../../components/Footer';
import PreviewAnnouncement from  '../../components/PreviewAnnouncement';
import Tabs from  '../../components/Tabs';
import FormFinancing from  '../../components/FormFinancing';
import FormLending from  '../../components/FormLending';
import PopupSimulator from "../../components/PopupSimulator";
import { useLocation } from 'react-router-dom'

const itemsTab = [
    {
        id : "#financiamento",
        title : "Financiamento"
    },
    {
        id : "#emprestimo",
        title : "Empréstimo"
    }
]

const Simulator = (props) => {
    const [showPopup, setshowPopup] = useState(false);
    const { hash } = useLocation();
    const [tabActive, setTabActive] = useState(hash ? hash : "financiamento");

    return (
        <Tag.Container>
            <Header />
            <Tag.Content>
                <Tag.Title>Simule aqui seu financiamento ou empréstimo!</Tag.Title>

                {showPopup && <PopupSimulator announcementID={1} setshowPopup={(status) => setshowPopup(status)} />}

                <PreviewAnnouncement />

                {tabActive && <Tabs items={itemsTab} active={tabActive} onChangeItem={(id) => setTabActive(id)} />}

                {tabActive === "#financiamento" && <FormFinancing setshowPopup={(status) => setshowPopup(status)} />}
                {tabActive === "#emprestimo" && <FormLending setshowPopup={(status) => setshowPopup(status)} />}

            </Tag.Content>
            <Footer />
        </Tag.Container>
    );
}

export default Simulator;