
import React from "react";
import * as Tag from "./styles";
import IconArrow from "../../assets/ico_arrow_right_nivel.svg";

const NivelProfile = (props) => {
    return (
        <Tag.Container>
           <Tag.Title>Nível 6 <Tag.Icon src={IconArrow} /></Tag.Title>
        </Tag.Container>
    );
}

export default NivelProfile;