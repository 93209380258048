import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    width: 100%;
    background: #FFFFFF;
    border: 1px solid #BDBDBD;
    border-radius: 10px;
    margin-top: ${props => props.profile ? 10 : 20}px;
    padding: ${props => props.profile ? '6px 10px' : '10px 12px'};

    @media(max-width: 768px) {
        align-items: flex-start;
        justify-content: center;
    }
`;

export const Texts = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    margin-right:27px;
    width: 100%;

    @media(max-width: 768px) {
        align-items: flex-start;
        justify-content: flex-start;
        
        @media(max-width: 768px) {
            flex-direction: ${props => props.profile ? 'row' : 'column'};
            flex-wrap: wrap;
        }
    }
`;

export const Saller = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    
    @media(max-width: 768px) {
        width: 100%;
        justify-content: flex-start;
    }
`;

export const Img = styled.img`
    width: 36px;
    height: 36px;
    object-fit:contain;
    margin-right: 8px;
    border-radius:50px;
`;

export const Ads = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;

    @media(max-width: 768px) {
        margin:10px 0;
        width: ${props => props.profile ? '50%' : 'auto'};
        justify-content: flex-start;
    }
`;

export const IcoAds = styled.img`
    width: 20px;
    height: 20px;
    object-fit:contain;
    margin-right: 8px;
`;

export const Name = styled.p`
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    color: #333333;
`;

export const Details = styled.a`
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    color: #0000FF;
    text-decoration:none;
    cursor:pointer;

    @media(max-width: 768px) {
        display:none;
    }
`;

export const IconMore = styled.img`
    width: 18px;
    height: 18px;
    object-fit:contain;
    cursor:pointer;

    @media(max-width: 768px) {
        margin-top:10px;
    }
`;
