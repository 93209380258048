import React from "react";
import * as Tag from "./styles";
import IcoPlus from "../../assets/ico_plus.svg";

const ButtonAddList = (props) => {
    return (
        <Tag.Link>
            <Tag.Content>
                <Tag.Text><Tag.Icon src={IcoPlus} />Nova lista</Tag.Text>
            </Tag.Content>
        </Tag.Link>
    );
}

export default ButtonAddList;