const adsTypes = [
    {
        id : "alugar",
        title : "Alugar"
    },
    {
        id : "comprar",
        title : "Comprar"
    },
    {
        id : "temporada",
        title : "Temporada"
    },
    {
        id : "leilao",
        title : "Leilão"
    }
]

export default adsTypes;