import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    position: relative;
    height: 7px;
    margin: 10px 0 0 0;
`;

export const Contain = styled.div`
    position: absolute;
    left: 0;
    right: 0;
    height: 7px;
`;

export const InverseLeft = styled.div`
    position: absolute;
    left: 0;
    height: 7px;
    border-radius: 10px;
    background-color: #E0E0E0;
`;

export const InverseRight = styled.div`
    position: absolute;
    right: 0;
    height: 7px;
    border-radius: 10px;
    background-color: #E0E0E0;
`;

export const Range = styled.div`
  position: absolute;
  left: 0;
  height: 7px;
  border-radius: 14px;
  background-color: #0000FF;
`;

export const Thumb = styled.span`
  position: absolute;
  top: -5px;
  z-index: 1;
  height: 18px;
  width: 18px;
  text-align: left;
  margin-left: -11px;
  cursor: pointer;
  box-shadow: 0px -1px 10px rgba(0, 0, 0, 0.25);
  background-color: ${porps => porps.hover ? '#ffca02' : '#0000FF'};
  border-radius: 50%;
  outline: none;
  transition: background .3s ease-in-out;
`;

export const Text = styled.span`
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color:#0000FF;
    position:absolute;
    top: calc(100% + 15px);
    white-space: nowrap;
`;


export const InputRange = styled.input`
  position: absolute;
  pointer-events: none;
  -webkit-appearance: none;
  z-index: 2;
  height: 14px;
  top: -2px;
  width: 100%;
  opacity: 0;

  :focus::-webkit-slider-runnable-track {
    background: transparent;
    border: transparent;
  }

  :focus {
    outline: none;
  }

  ::-webkit-slider-thumb {
    pointer-events: all;
    width: 28px;
    height: 28px;
    border-radius: 0px;
    border: 0 none;
    background: red;
    -webkit-appearance: none;
  }
  ::-ms-fill-lower {
    background: transparent;
    border: 0 none;
  }
  ::-ms-fill-upper {
    background: transparent;
    border: 0 none;
  }
  ::-ms-tooltip {
    display: none;
  }

`;