import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    overflow:hidden;
`;

export const Content = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    padding: 77px 20px 120px;
    background:#fff;
    width: 100%;
    max-width: 1000px;
    flex: 1;

    @media(max-width: 768px) {
        padding: 20px 20px 40px;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column-reverse;
    }
`;