import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    background: #FFFFFF;
    border: 1px solid #E5E5E5;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    margin-right: 10px;
    width: 100%;
    height: 92px;
    padding:10px 15px;
    margin-top:12px;

    :last-child{
        margin-right:0;
    }

    @media(max-width: 768px) {
        width: calc((100% / 2) - 5px);
        align-items: center;
        height: 129px;
        
        :last-child{
            margin-right:0;
        }
        :nth-child(even){
            margin-right:0;
        }
    }
`;

export const Top = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;

    @media(max-width: 768px) {
        flex-direction: column;
        align-items: center;
    }
`;

export const Image = styled.img`
    border-radius:50px;
    width:35px;
    height:35px;
    object-fit:cover;
    margin-right:10px;
`;

export const Name = styled.span`
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: #333333;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const Check = styled.span`
    width:14px;
    height:14px;
    background: #2D9248;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius:10px;
    margin-left:10px;
`;

export const Link = styled.a`
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #0000FF;
    margin-top:5px;
`;

export const Icon = styled.img`
    width: ${props => props.width ? props.width : '19'}px;
    height: ${props => props.height ? props.height : '19'}px;
    margin-right: ${props => props.marginRight ? props.marginRight : '0'}px;
    object-fit:contain;
`;