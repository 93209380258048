import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top:52px;

    @media(max-width: 768px) {
        margin-top:40px;
    }
`;

export const Item = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    padding: 14px 0;
    background: ${props => props.even ? 'transparent': '#E5E5E5'};
`;

export const Name = styled.span`
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #333333;
    width: 40%;
    padding: 0 20px;

    @media(max-width: 768px) {
        font-size: 14px;
        line-height: 22px;
    }
`;

export const Description = styled.span`
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #333333;
    flex: 1;
    padding: 0 20px;

    @media(max-width: 768px) {
        font-size: 14px;
        line-height: 22px;
    }
`;