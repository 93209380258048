
import React from "react";
import * as Tag from "./styles";
import Button from  "../Button";
import Rating from  "../Rating";
import ImageUser from "../../assets/foto_user.jpg";
import IcoWhatsapp from "../../assets/ico_whatsapp_white_mini.svg";
import IcoMessage from "../../assets/ico_message_white.svg";
import IcoFlag from '../../assets/ico_flag.svg';

const BoxUserSidebar = (props) => {

    function showWhatsapp(){
        var link = `https://wa.me/+55${props.user?.contacts.find(item => item.type === 'phone')?.contact_original}`
    }

    return (
        <Tag.Container profile={props.profile}>
            <Tag.Image src={`${process.env.REACT_APP_BASE_URL_UPLOADS}${props.user?.image?.url}`} />
            <Tag.Row>
                <Tag.Name checked={props.checked}>{props.user.name}</Tag.Name>
                <Tag.Icon />
            </Tag.Row>
            <Rating rate={props.user.rating} />
            <Tag.Link onClick={() => props.setPopupRating()}>Ver avaliações</Tag.Link>
            <Button text="Mandar mensagem" width={233} style={{marginTop:"30px"}} icon={IcoMessage} />
            {props.showContact == 1 && <Button onClick={() => props.setShowPopup()} text="Abrir WhatsApp" width={233} style={{marginTop:"20px"}} background="#34AF23" icon={IcoWhatsapp} />}
            {props.profile && <Tag.LinkBottomFlag onClick={()=> props.setPopupReport()}><Tag.IconFlag src={IcoFlag} />Denunciar</Tag.LinkBottomFlag>}
            {!props.profile && <Tag.LinkBottom href={`/profile/${props.user?.id}`}>Ver todos os anúncios</Tag.LinkBottom>}
        </Tag.Container>
    );
}

export default BoxUserSidebar;