import React, { useState, useEffect } from "react";
import Header from  '../../components/Header';
import Footer from  '../../components/Footer';
import * as Tag  from "./styles";
import ChatUser from "../../components/ChatUser";
import SidebarChat from "../../components/SidebarChat";

const Chat = (props) => {
    const [width, setWidth] = useState(window.innerWidth);
    const [showChat, setShowChat] = useState(false);
    const updateDimensions = () => {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    }, []);

    function showChatDetail(){
        if(width > 768){

        }else{
            setShowChat(true);
        }
    }

    return (
        <Tag.Container>
            <Header account />
            <Tag.Content>
                {!showChat && <SidebarChat showChatDetail={()=> showChatDetail()} />}
                {(width > 768 || showChat) && <ChatUser />}
            </Tag.Content>
            <Footer />
        </Tag.Container>    
    );
}

export default Chat;