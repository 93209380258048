import React from "react";
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Navigate,
    Outlet
} from "react-router-dom";
import { isAuthenticated } from "./services/auth";
import Home from "./pages/Home";
import SearchProperty from "./pages/SearchProperty";
import SearchVehicle from "./pages/SearchVehicle";
import Simulator from "./pages/Simulator";
import Search from "./pages/Search";
import Admin from "./pages/Admin";
import SignUp from "./pages/SignUp";
import SignIn from "./pages/SignIn";
import AccountType from "./pages/AccountType";
import AccountRecovery from "./pages/AccountRecovery";
import AccountVerification from "./pages/AccountVerification";
import EmailRecovery from "./pages/EmailRecovery";
import PasswordRecovery from "./pages/PasswordRecovery";
import ChangePassword from "./pages/ChangePassword";
import SignUpCompany from "./pages/SignUpCompany";
import ProductDetail from "./pages/ProductDetail";
import UserProfile from "./pages/UserProfile";
import HelpCenter from "./pages/HelpCenter";
import HelpCenterBusiness from "./pages/HelpCenterBusiness";
import HelpCenterCategory from "./pages/HelpCenterCategory";
import HelpCenterDetail from "./pages/HelpCenterDetail";
import MyAds from "./pages/MyAds";
import Wishlist from "./pages/Wishlist";
import WishlistDetail from "./pages/WishlistDetail";
import MyProduct from "./pages/MyProduct";
import Notifications from "./pages/Notifications";
import CreateAds from "./pages/CreateAds";
import Chat from "./pages/Chat";
import Plans from "./pages/Plans";
import ComparePlans from "./pages/ComparePlans";
import MyPlans from "./pages/MyPlans";
import Checkout from "./pages/Checkout";
import AddCard from "./pages/AddCard";
import MyProfile from "./pages/MyProfile";
import MyCadastre from "./pages/MyCadastre";
import PrivacyAndSecurity from "./pages/PrivacyAndSecurity";
import Financial from "./pages/Financial";
import MyNotifications from "./pages/MyNotifications";
import SellerDetail from "./pages/SellerDetail";
import NewSeller from "./pages/NewSeller";
import Sellers from "./pages/Sellers";
import MyProfileCompany from "./pages/MyProfileCompany";
import AccountValidatePhone from "./pages/AccountValidatePhone";
import Error404 from "./pages/Error404";
import CreateAdsVehicle from "./pages/CreateAdsVehicle";
import CreateAdsProperty from "./pages/CreateAdsProperty";
import AdsType from "./pages/AdsType";

const ProtectedRoute = ({ redirectPath = '/signin', children }) => {
    if (!isAuthenticated()) {
      return <Navigate to={redirectPath} replace />;
    }
    return <Outlet />;
};

const Root = (props) => (
    <Router>
        <Routes>
            <Route index element={<Home  />} />
            <Route path="home" element={<Home />} />
            <Route path="category/imoveis" element={<SearchProperty />} />
            <Route path="category/veiculos" element={<SearchVehicle />} />
            <Route path="search" element={<Search />} />
            <Route path="simulator" element={<Simulator />} />
            <Route path="detail" element={<ProductDetail {...props} />} />
            <Route path="detail/:id" element={<ProductDetail {...props} />} />
            <Route path="detail/property" element={<ProductDetail {...props} property />} />
            <Route path="detail/vehicle" element={<ProductDetail {...props} vehicle />} />
            <Route path="profile/:id" element={<UserProfile />} />
            
            <Route path="account-type" element={<AccountType />} />
            <Route path="signup" element={<SignUp />} />
            <Route path="signup-company" element={<SignUpCompany />} />
            <Route path="signin" element={<SignIn />} />
            <Route path="account-recovery" element={<AccountRecovery />} />
            <Route path="account-verification" element={<AccountVerification />} />
            <Route path="account-verification/:token" element={<AccountVerification />} />
            <Route path="account-validate-phone" element={<AccountValidatePhone />} />
            <Route path="email-recovery" element={<EmailRecovery />} />
            <Route path="password-recovery" element={<PasswordRecovery />} />
            <Route path="change-password/:token" element={<ChangePassword />} />
            {/* <Route path="change-password" element={<ChangePassword />} /> */}

            <Route path="help-center" element={<HelpCenter />} />
            <Route path="help-center/business" element={<HelpCenterBusiness />} />
            <Route path="help-center/category" element={<HelpCenterCategory />} />
            <Route path="help-center/detail" element={<HelpCenterDetail />} />
            
            
            <Route element={<ProtectedRoute />}>
                
                <Route path="account/my-ads" element={<MyAds />} />
                <Route path="account/wishlist" element={<Wishlist />} />
                <Route path="account/wishlist/:id" element={<WishlistDetail />} />
                <Route path="account/my-product" element={<MyProduct />} />
                <Route path="account/my-notifications" element={<MyNotifications />} />
                <Route path="account/ads-type" element={<AdsType />} />
                <Route path="account/create-ads" element={<CreateAds />} />
                <Route path="account/create-ads/vehicle" element={<CreateAdsVehicle />} />
                <Route path="account/create-ads/property" element={<CreateAdsProperty />} />
                
                <Route path="account/chat" element={<Chat />} />
                
                <Route path="account/plans" element={<Plans />} />
                <Route path="account/compare-plans" element={<ComparePlans />} />
                <Route path="account/my-plans" element={<MyPlans />} />
                <Route path="account/checkout" element={<Checkout />} />
                <Route path="account/add-card" element={<AddCard />} />
                <Route path="account/my-profile" element={<MyProfile />} />

                <Route path="account/my-cadastre" element={<MyCadastre />} />
                <Route path="account/privacy-and-security" element={<PrivacyAndSecurity />} />
                <Route path="account/financial" element={<Financial />} />
                <Route path="account/notifications" element={<Notifications />} />
                <Route path="account/seller-detail" element={<SellerDetail />} />

                <Route path="account/sellers" element={<Sellers />} />
                <Route path="account/new-seller" element={<NewSeller />} />
                <Route path="account/my-profile-seller" element={<MyProfileCompany />} />
                
            </Route>

            <Route path="/500" element={<Error404 />} />
            <Route path="*" element={<Error404 />} />
        </Routes>
    </Router>
);

export default Root;  