import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    background: #F5F5F5;
    width:278px;
    height: 100%;
    padding:24px 8px 36px;
    position:relative;
    min-height: 100vh;

    :after{
        content: "";
        width: 100%;
        height: 99px;
        background: linear-gradient(1.6deg, rgba(196, 196, 196, 0.49) -70.57%, rgba(196, 196, 196, 0) 77.83%);
        position:absolute;
        bottom:0;
        left:0;
    }

    @media(max-width: 768px) {
        width:100%;
        height: auto;
        min-height: auto;
        :after{
            display:none;
        }
    }
`;


export const Menu = styled.a`
    display:flex;
    flex-direction:row;
    align-items: center;
    justify-content: flex-start;
    background: ${props => props.active ? '#FFFFFF': 'transparent'};
    border-radius: 5px;
    margin:12px 0 0 0;
    height:52px;
    cursor:pointer;
    text-decoration:none;
    padding:10px 12px;
    width: 100%;

    :hover{
        background: #FFFFFF;
    }
`;

export const Icon = styled.img`
    width:24px;
    height:24px;
    object-fit:contain;
    margin-right:12px;
`;

export const Title = styled.span`
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #333333;
`;