import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 100vh;
    flex-direction: column;
`;

export const Content = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 77px 120px 120px;
    background:#fff;
    width: 100%;
    max-width: 1440px;
    flex: 1;

    @media(max-width: 1280px) {
        padding: 77px 40px 120px;
    }

    @media(max-width: 1200px) {
        padding: 77px 20px 120px;
    }

    @media(max-width: 768px) {
        padding: 35px 20px 40px;
    }
`;

export const Detail = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
    flex: 1;

    @media(max-width: 960px) {
        flex-direction: column;
    }
`;

export const Product = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
    flex: 1;
`;

export const Infos = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
    border: 1px solid #CBCBCB;
    border-radius: 10px;
    padding: 25px;

    @media(max-width: 768px) {
        padding: 0;
        border:0;
    }
`;

export const Title = styled.h4`
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    color: #333333;
    margin-top:20px;

    @media(max-width: 768px) {
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
    }
`;
export const Data = styled.p`
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #333333;
    margin-top:6px;
`;
export const Description = styled.p`
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #333333;
    margin-top:42px;
`;
export const Category = styled.p`
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #333333;
    margin-top:21px;
`;
export const SubCategory = styled.p`
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #333333;
    margin-top:13px;
`;
export const Location = styled.p`
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #333333;
    margin-top:13px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
`;
export const IconLocation = styled.img`
    width: 12px;
    height: 15px;
    object-fit:contain;
    margin-right: 5px;
`;

export const Sidebar = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    max-width: 385px;
    margin-left: 24px;
    flex: 1;

    @media(max-width: 960px) {
        margin-top:20px;
        margin-left: 0;
        max-width: 100%;
    }
`;

export const BoxBanner = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    flex: 1;
    margin-top: 72px;

    @media(max-width: 960px) {
        margin-top:20px;
    }
`;

export const Banner = styled.img`
    display: inline-block;
    width: 100%;
    height: 250px;
    object-fit:cover;
`;