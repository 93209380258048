import React from "react";
import * as Tag from "./styles";
import IcoPlus from "../../assets/ico_plus.svg"

const FileUpload = ({ multiple, onChangeFiles, style }) => {
    const inputRef = React.useRef(null);
    const [dragActive, setDragActive] = React.useState(false);

    const sendToUpload = function(files) {
        onChangeFiles(files);
    }

    const handleDrag = function(e) {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true);
        } else if (e.type === "dragleave") {
            setDragActive(false);
        }
    };

    const handleDrop = function(e) {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            sendToUpload(e.dataTransfer.files);
        }
    };

    const handleChange = function(e) {
        e.preventDefault();
        if (e.target.files && e.target.files[0]) {
            sendToUpload(e.target.files);
        }
    };

    const onButtonClick = () => {
        inputRef.current.click();
    };
    
    return (
        <Tag.Container style={style} onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()}>
            <Tag.InputFile ref={inputRef} id="input-file-upload" type="file" multiple={multiple} onChange={handleChange} />
            <Tag.Texts htmlFor="input-file-upload" dragActive={dragActive}>
                <Tag.Text onClick={onButtonClick} style={{cursor: 'pointer'}}><Tag.Icon src={IcoPlus} /> Clique para escolher um arquivo ou arraste</Tag.Text>
                <Tag.Text left={true}>PNG ou JPG até 100 KB</Tag.Text>
            </Tag.Texts>
            { dragActive && <Tag.DragFileElement onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}></Tag.DragFileElement> }
        </Tag.Container>
    );
}

export default FileUpload;