import React, { useState } from "react";
import * as Tag from "./styles";

const CheckBox = (props) => {
    const [active, setActive] = useState(props.active);

    function onClick(status){
        setActive(status);
        props.onChange(status);
    }

    return (
        <Tag.Container onClick={() => onClick(!active)} marginTop={props.marginTop} marginRight={props.marginRight}>
            <Tag.Check size={props.size} active={active} />
            <Tag.Title>{props.title ? props.title : props.name}</Tag.Title>
        </Tag.Container>
    );
}

export default CheckBox;