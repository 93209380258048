const tipoVendedor = [
    {
        id : "particular",
        title : "Particular"
    },
    {
        id : "profissional",
        title : "Profissional"
    }
]

export default tipoVendedor;