import React, { useEffect, useRef } from "react";
import * as Tag from "./styles";

const ProductActionsOwner = (props) => {
    const containerRef = useRef(null);

    useEffect(() => {
        containerRef.current.focus();
    },[containerRef]);

    function focus(){
        containerRef.current.focus();
    }

    return (
        <Tag.Container ref={containerRef} onLoad={focus} tabIndex={0} onBlur={() => props.onBlur()}>
            <Tag.Link onClick={() => props.actionEdit()}>Editar</Tag.Link>
            <Tag.Link onClick={() => props.actionSold()}>Marcar como vendido</Tag.Link>
            <Tag.Link onClick={() => props.actionPause()}>Pausar</Tag.Link>
            <Tag.Link onClick={() => props.actionDelete()}>Eliminar</Tag.Link>
        </Tag.Container>
    );
}

export default ProductActionsOwner;