import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 9;
    left: 0;
    top: 0;
    background:rgba(255,255,255,0.6);
`;