
import React, { useRef, useState, useEffect } from "react";
import * as Tag from "./styles";
import Slider from "react-slick";

const GalleryImage = (props) => {
    const [width, setWidth] = useState(window.innerWidth);
    const [currentImage, setCurrentImage] = useState(props.images ? props.images[0] : {});
    const slider = useRef(null);

    const updateDimensions = () => {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    }, []);

    var settings = {
        //dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: (width > 768 && !props.horizontal) ? 1 : props.horizontal ? 6 : 7,
        slidesToScroll: 1,
        touchMove : false,
        vertical:  (width > 768 && !props.horizontal),
        verticalSwiping: true,
        afterChange:  (current) => {
            setCurrentImage(props.images[current]);
        },
        beforeChange: (current, next) => {
            //setCurrentImage(images[current]);
        }
    };

    function goToImage(index){
        slider.current.slickGoTo(index)
    }

    return (
        <Tag.Container horizontal={props.horizontal}>
            <Tag.Full>
                {currentImage && <Tag.ImageFull src={`${process.env.REACT_APP_BASE_URL_UPLOADS}${currentImage?.url}`} horizontal={props.horizontal} />}
            </Tag.Full>
            {props.horizontal ? 
            <Tag.ThumbsHorizontal>
                <Slider ref={slider}  {...settings}>
                    {props.images?.length && props.images.map((item, index) => 
                        <Tag.BoxImage onClick={() => goToImage(index)} key={index} width={props.width} height={props.height}>
                            <Tag.Image src={`${process.env.REACT_APP_BASE_URL_UPLOADS}${item?.url}`} />
                        </Tag.BoxImage>
                    )}
                </Slider>
            </Tag.ThumbsHorizontal>
            :
            <Tag.Thumbs>
                <Slider ref={slider}  {...settings}>
                    {props.images?.length && props.images.map((item, index) => 
                        <Tag.BoxImage onClick={() => goToImage(index)} key={index} width={props.width} height={props.height}>
                            <Tag.Image src={`${process.env.REACT_APP_BASE_URL_UPLOADS}${item?.url}`} />
                        </Tag.BoxImage>
                    )}
                </Slider>
            </Tag.Thumbs>
            }

        </Tag.Container>
    );
}

export default GalleryImage;