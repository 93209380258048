import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 100vh;
    flex-direction: column;
`;

export const Content = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: row;
    padding: 71px 0 120px;
    background:#fff;
    width: 100%;
    max-width: 1200px;
    flex: 1;

    @media(max-width: 1280px) {
        padding: 71px 15px 120px;
    }

    @media(max-width: 960px) {
        padding: 35px 15px 40px;
    }
`;

export const Sidebar = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    max-width: 290px;
    flex: 1;
    margin-right:41px;

    @media(max-width: 768px) {
        display:none;
    }
`;

export const Banner = styled.img`
    width: 100%;
    height: auto;
    margin-top:32px;
`;

export const BannerInside = styled.img`
    width: 100%;
    height: auto;
    margin:18px 0;
    object-fit:cover;

    @media(max-width: 768px) {
        height: 175px;
    }
`;

export const Results = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
    flex: 1;
`;

export const Term = styled.p`
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #333333;
`;

export const Sugestion = styled.p`
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #333333;
    margin-top: 4px;
`;

export const SugestionLink = styled.a`
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #0000FF;
    font-style:italic;
    text-decoration:none;
    cursor:pointer;

    :hover{
        text-decoration:underline;
    }
`;

export const Tags = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top:12px;
`;


export const Item = styled.div`
    display:inline-block;
    float:left;
    width: 100%;
    margin:23px 0;
`;

export const Icon = styled.img`
    margin-top:42px;
    max-width: 191px;
    max-height: 191px;
    object-fit:contain;
`;

export const Notfound = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top:65px;
    padding:0 52px;

    @media(max-width: 768px) {
        margin-top:35px;
        padding:0 20px;
    }
`;
export const NotfoundTitle = styled.h3`
    font-weight: 500;
    font-size: 36px;
    line-height: 44px;
    color: #333333;
    text-align:center;

    @media(max-width: 768px) {
        font-size: 26px;
        line-height: 34px;
    }
`;

export const NotFoundText = styled.p`
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #333333;
    margin-top:9px;

    @media(max-width: 768px) {
        text-align:center;
    }
`;

export const NotFoundImage = styled.img`
    max-width: 280px;
    margin-top:70px;

    @media(max-width: 768px) {
        max-width: 200px;
    }
`;