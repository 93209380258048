import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction:row;
    margin-top: 50px;
    width: 100%;
    padding:0 20px;

    @media(max-width: 768px) {
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
        margin-top: 10px;
        padding:0 0;
    }
`;

export const Texts = styled.div`
    margin-bottom:80px;
    width: 100%;
    flex: 1;
    
    @media(max-width: 768px) {
        margin-bottom: 20px;
    }
`;

export const Title = styled.p`
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #333333;
    text-align:left;
`;

export const Item = styled.p`
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #333333;
    margin-top:24px;
`;

export const Icon = styled.img`
    width: 18px;
    height: 18px;
    object-fit: contain;
`;

export const Image = styled.img`
    width: 184px;
    height: auto;
    object-fit: contain;
    margin-left:30px;
    margin-right:50px;

    @media(max-width: 768px) {
        width: 120px;
        margin-bottom: 30px;
    }
`;