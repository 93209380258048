import styled from "styled-components";
import IcoStarActive from "../../assets/ico_star_active.svg";
import IcoStarInative from "../../assets/ico_star_inative.svg";

export const Container = styled.div`
    width: ${props => props.align ? 'auto' : '100%'};
    display: flex;
    align-items: center;
    justify-content: ${props => props.align ? props.align : 'center'};
    flex-direction: row;
    margin-top: ${props => props.marginTop != null ? props.marginTop : '13'}px;
`;

export const Rate = styled.span`
    font-weight: 500;
    font-size: ${props => props.big ? '36' : '12'}px;
    line-height: ${props => props.big ? '43' : '18'}px;
    color:#000;
    margin-right: 3px;
`;

export const Stars = styled.span`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
`;

export const Star = styled.span`
    width: ${props => props.big ? '32' : '16'}px;
    height: ${props => props.big ? '32' : '16'}px;
    background: url(${props => props.active ? IcoStarActive : IcoStarInative }) no-repeat 50%;
    background-size: ${props => props.big ? '32' : '16'}px;
    margin:0 3px;
`;