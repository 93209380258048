import React, { useState } from "react";
import Header from  '../../components/Header';
import Footer from  '../../components/Footer';
import * as Tag  from "./styles";
import ShoppingPlans from  '../../assets/shopping_plans.jpg';
import DropDownListSearch from "../../components/DropDownListSearch";
import Plan from "../../components/Plan";

const categories = [
    {
        id : 1,
        title : "Imóveis",
    },
    {
        id : 2,
        title : "Veículos",
    },
    {
        id : 3,
        title : "Prestação de serviços",
    },
    {
        id : 4,
        title : "Eletrônicos",
    },
    {
        id : 5,
        title : "Animais de extimação",
    },
    {
        id : 6,
        title : "Vagas de emprego",
    },
    {
        id : 7,
        title : "Vestuário e beleza",
    },
    {
        id : 8,
        title : "Lazer e esporte",
    },
    {
        id : 9,
        title : "Agronegócios",
    },
    {
        id : 10,
        title : "Indústria e comércio",
    },
    {
        id : 11,
        title : "Artigos infantis",
    },
    {
        id : 12,
        title : "Hobbies e música",
    },
    {
        id : 13,
        title : "Artigos para casa",
    },
    {
        id : 14,
        title : "Outros",
    }
];

const plans =[
    {
        title : "Básico",
        price : "R$50,00",
        items : ["+5 anúncios", "2 destaques", "10% de economia"],
        recommended : false
    },
    {
        title : "Plus",
        price : "R$80,00",
        items : ["+15 anúncios", "5 destaques", "15% de economia"],
        recommended : true
    },
    {
        title : "Premium",
        price : "R$150,00",
        items : ["+25 anúncios", "10 destaques", "20% de economia"],
        recommended : false
    }
]

const Plans = (props) => {
    const [selectedCategory, setSelectedCategory] = useState(false);
    return (
        <Tag.Container>
            <Header account />
            <Tag.Content>
                <Tag.Title>Adquira um plano para se destacar entre seus concorrentes</Tag.Title>
                <DropDownListSearch onChange={() => setSelectedCategory(true)} items={categories} width={588} style={{marginTop: '39px'}} placeholder="Selecione uma categoria" />

                {!selectedCategory && <Tag.Image src={ShoppingPlans} />}

                {selectedCategory && <Tag.Plans>
                    {plans.map((item,index) => <Plan item={item} key={index} />)}
                </Tag.Plans>}

            </Tag.Content>
            <Footer />
        </Tag.Container>    
    );
}

export default Plans;