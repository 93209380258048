
import React from "react";
import * as Tag from "./styles";
import IcoMore from "../../assets/ico_more.svg";
import IcoAds from "../../assets/ico_package.svg";
import ImgSaller from "../../assets/foto_user.jpg";
import IcoDolar from "../../assets/ico_dollar.svg";

const Saller = (props) => {
    
    return (
        <Tag.Container profile={props.profile}>
            <Tag.Texts profile={props.profile}>
                <Tag.Saller>
                    <Tag.Img src={ImgSaller} />
                    <Tag.Name>Ana Paula da Silva</Tag.Name>
                </Tag.Saller>

                <Tag.Ads profile={props.profile}>
                    <Tag.IcoAds src={IcoAds} />
                    <Tag.Name>34 anúncios</Tag.Name>
                </Tag.Ads>

                {props.profile && <Tag.Ads profile={props.profile}>
                    <Tag.IcoAds src={IcoDolar} />
                    <Tag.Name>34 vendas</Tag.Name>
                </Tag.Ads>}

                {!props.profile && <Tag.Name>Criado em 23/03/22</Tag.Name>}
                {!props.profile && <Tag.Details>Detalhes</Tag.Details>}
            </Tag.Texts>
            {!props.profile && <Tag.IconMore src={IcoMore} />}
        </Tag.Container>
    );
}

export default Saller;