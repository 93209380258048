import React, { useState } from "react";
import * as Tag from "./styles";
import IconShow from "../../assets/ico_eye.svg";
import IconHide from "../../assets/ico_eye.svg";

const InputPassword = (props) => {
    const [showPassword, setShowPassword] = useState(false);
    return (
        <Tag.Container>
            {props.icon && <Tag.Icon src={props.icon} />}
            <Tag.Input type={showPassword ? "text" : "password"} {...props} />
            {props.togglePassword && 
                <Tag.Icon onClick={() => setShowPassword(!showPassword)} src={showPassword ? IconShow : IconHide} right={0} />
            }
        </Tag.Container>
    );
}

export default InputPassword;