
import React, { useState } from "react";
import * as Tag from "./styles";
import IconPhone from "../../assets/ico_phone.svg";
import IconEmail from "../../assets/ico_email.svg";
import IconFacebook from "../../assets/ico_facebook.svg";
import Popup from "../Popup";
import InputText from "../InputText";
import Button from "../Button";
import LinkButton from "../LinkButton";

const Contact = (props) => {
    const [popupTelefone, setPopupTelefone] = useState(false);
    const [popupEmail, setPopupEmail] = useState(false);
    
    return (
        <Tag.Container>

            {popupTelefone && <Popup noCloseBlur={true}>
                <Tag.FormPopup>
                    <Tag.TitlePopup>Contato</Tag.TitlePopup>
                    <Tag.Row>
                        <InputText placeholder="Digite seu Telefone" icon={IconPhone} />
                    </Tag.Row>
                    <Tag.Col>
                        <Button text="Salvar" onClick={() => setPopupTelefone(false)} width={195} />
                        <LinkButton text="Cancelar" onClick={() => setPopupTelefone(false)} width={195} color="#333333" style={{marginLeft: '20px'}} />
                    </Tag.Col>
                </Tag.FormPopup>
            </Popup>}

            {popupEmail && <Popup noCloseBlur={true}>
                <Tag.FormPopup>
                    <Tag.TitlePopup>Contato</Tag.TitlePopup>
                    <Tag.Row>
                        <InputText placeholder="Digite seu E-mail" icon={IconEmail} />
                    </Tag.Row>
                    <Tag.Col>
                        <Button text="Salvar" onClick={() => setPopupEmail(false)} width={195} />
                        <LinkButton text="Cancelar" onClick={() => setPopupEmail(false)} width={195} color="#333333" style={{marginLeft: '20px'}} />
                    </Tag.Col>
                </Tag.FormPopup>
            </Popup>}

            

            <Tag.Row>
                <Tag.Left>
                    <Tag.Icon src={IconPhone} />
                    <Tag.Text>11 1243252356</Tag.Text>
                </Tag.Left>
                <Tag.Link onClick={() => setPopupTelefone(true)}>Editar</Tag.Link>
            </Tag.Row>
            <Tag.Row>
                <Tag.Left>
                    <Tag.Icon src={IconEmail} />
                    <Tag.Text>sof.beiras@gmail.com</Tag.Text>
                </Tag.Left>
                <Tag.Link onClick={() => setPopupEmail(true)}>Editar</Tag.Link>
            </Tag.Row>
            <Tag.Row>
                <Tag.Left>
                    <Tag.Icon src={IconFacebook} />
                    <Tag.LinkText>Conetar com Facebook</Tag.LinkText>
                </Tag.Left>
            </Tag.Row>
        </Tag.Container>
    );
}

export default Contact;