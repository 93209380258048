import styled from "styled-components";

export const Container = styled.div`
    display: inline-flex;
    align-items: flex-start;
    justify-content: center;
    height: auto;
    flex-direction: column;
    margin-top:48px;

    @media(max-width: 560px) {
        margin-top:30px;
    }
`;

export const Title = styled.p`
    color:#333333;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
`;

export const Link = styled.a`
    display: inline-flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: row;
    color:#333333;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    margin-top: 12px;
`;

export const Icon = styled.img`
    width: 20px;
    height: 20px;
    background-size: cover;
    margin-right: 12px;
`;