
import React from "react";
import * as Tag from "./styles";
import ImageCompany from "../../assets/logo_company.jpg";

const BoxCompanySidebar = (props) => {
    return (
        <Tag.Container>
            <Tag.Row>
                <Tag.Image src={ImageCompany} />
                <Tag.Name>Empresa Lorem Ipsum</Tag.Name>
            </Tag.Row>
            <Tag.LinkBottom href="#">Ver todos os anúncios</Tag.LinkBottom>
        </Tag.Container>
    );
}

export default BoxCompanySidebar;