import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    background: #FFFFFF;
    border: 1px solid #CBCBCB;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    padding:24px 16px;
    width: 100%;
`;

export const Title = styled.div`
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #333333;

    @media(max-width: 768px) {
        font-size: 18px;
        line-height: 27px;
    }
`;

export const Texts = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    margin-top:17px;
`;

export const TextDetail = styled.span`
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    justify-content:center;
    color: #333333;
    cursor:pointer;
`;

export const IconDetail = styled.img`
    width:22px;
    height:14px;
    object-fit:contain;
    margin-left:7px;
`;

export const Details = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    max-height: ${props => props.open ? "100%" : "0"};
    overflow: hidden;
    transition: all 0.3s ease-out;
`;

export const Description = styled.p`
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #666666;
    margin-top:6px;
`;

export const Total = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
    margin-top:23px;
`;

export const TextTotal = styled.p`
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #333333;
`;

export const Price = styled.p`
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #333333;
`;