import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    margin-top:28px;
    background: #FFFFFF;
    border: 1px solid #CBCBCB;
    border-radius: 10px;
    padding: 48px 91px 48px 24px;

    @media(max-width: 768px) {
        align-items: center;
        justify-content: center;
        flex-direction: column-reverse;
        padding: 24px;
    }
`;

export const Texts = styled.div`
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    max-width: 390px;

    @media(max-width: 768px) {
        align-items: center;
        justify-content: center;
    }
`;

export const Title = styled.p`
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    color: #333333;

    @media(max-width: 768px) {
        text-align: center;
        margin-top: 20px;
    }

`;

export const Image = styled.img`
    width: 206px;
    height: auto;

    @media(max-width: 768px) {
        width: 150px;
    }
`;
