
import React from "react";
import * as Tag from "./styles";

const SocialFooter = (props) => {
    return (
        <Tag.Container>
            <Tag.Line />
                <Tag.Text>{props.text}</Tag.Text>
            <Tag.Line />
        </Tag.Container>
    );
}

export default SocialFooter;