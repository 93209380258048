import styled from "styled-components";

export const Container = styled.div`
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;
    max-width: 200px;
    position:relative;
    margin-right: 40px;
    z-index:4;
`;

export const Link = styled.a`
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
`;

export const Icon = styled.img`
    width: ${props => (props.width ? props.width : `20`)}px;
    height: ${props => (props.height ? props.height : `20`)}px;
`;

export const Name = styled.span`
    font-size: 18px;
    line-height: 18px;
    font-weight: 700;
    color:#ffffff;
    margin:0 12px;
    display: inline-block;
    white-space: nowrap;
`;

export const Menus = styled.div`
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    width: 250px;
    background: #FFFFFF;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    position:absolute;
    right:0;
    top: 100%;
    flex-direction: column;
`;


export const ContButtonTopMenu = styled.div`
    padding: 12px 16px;
    width: 100%;
`;

