import styled from "styled-components";

export const Container = styled.div`
    display: inline-flex;
    align-items: center;
    justify-content: center;
`;

export const Link = styled.a`
    font-size: 18px;
    line-height: 24px;
    font-weight: 700;
    color:#ffffff;
    text-decoration: none;
    white-space: nowrap; 
`;

export const Separator = styled.span`
    margin: 0 25px;
    width: 1px;
    background:#ffffff;
    height: 20px;
`;