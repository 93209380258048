import React, { useState } from "react";
import * as Tag from "./styles";
import BoxSearch from '../BoxSearch';
import MenuMobile from '../MenuMobile';
import MenuBarMobile from '../MenuBarMobile';
import IcoUser from "../../assets/ico_user.svg";
import IcoNotifications from "../../assets/ico_notifications.svg";
import { isAuthenticated } from "../../services/auth";


const HeaderMobile = (props) => {
    const [showMenu, setShowMenu] = useState(false);

    return (
        <Tag.Container>
            <Tag.Top>
                <Tag.Link onClick={() => setShowMenu(true)}>
                    <MenuBarMobile {...props} />
                </Tag.Link>
                <BoxSearch placeholder="Buscar" />
                {isAuthenticated() ?
                    <Tag.Link onClick={() => setShowMenu(true)}>
                        <Tag.Icon src={IcoNotifications} width={32} height={24} />
                    </Tag.Link>
                    : <Tag.Link onClick={() => setShowMenu(true)}>
                        <Tag.Icon src={IcoUser} />
                    </Tag.Link>
                }
                
            </Tag.Top>
            <MenuMobile {...props} isAuthenticated={() => isAuthenticated()} active={showMenu} setShowMenu={(active) => setShowMenu(active)} />
        </Tag.Container>
    );
}

export default HeaderMobile;