
import React from "react";
import * as Tag from "./styles";
import Popup from "../Popup";
import Button from "../Button";
import ButtonOutline from "../ButtonOutline";
import CheckBoxList from "../CheckBoxList";
import Textarea from "../Textarea";

const motivos = [
    {
        id : "Anúncio falso",
        title : "Anúncio falso"
    },
    {
        id : "Anúncio com mais de um produto",
        title : "Anúncio com mais de um produto"
    },
    {
        id : "Spam",
        title : "Spam"
    },
    {
        id : "Violência",
        title : "Violência"
    },
    {
        id : "Conteúdo inapropriado",
        title : "Conteúdo inapropriado"
    },
    {
        id : "Discurso de ódio",
        title : "Discurso de ódio"
    },
    {
        id : "Outro",
        title : "Outro"
    }
]

const PopupReport = (props) => {
    return (
        <Popup title="" maxWidth={532} smallTitle noCloseBlur closeButton onBlur={() => props.setPopupReport(false)}>
            <Tag.Container>
                <Tag.Title>Qual o motivo da sua denúncia?</Tag.Title>
                <CheckBoxList items={motivos} marginTop={16} />
                <Tag.Row>
                    <Tag.Label>Adicione um comentário</Tag.Label>
                    <Textarea height={84} />
                </Tag.Row>
                <Tag.Row align="center">
                    <Button text="Enviar" width={184} onClick={() => {props.setPopupReport(false); props.showPopupActions("Denuncia enviada com sucesso"); }} />
                    <ButtonOutline text="Cancelar" width={184} style={{marginTop: '24px'}} onClick={() => props.setPopupReport(false)} />
                </Tag.Row>
            </Tag.Container>
        </Popup>
    );
}

export default PopupReport;