import React, { useState } from "react";
import * as Tag from "./styles";
import IcoNotifications from "../../assets/ico_notifications.svg";
import Notification from "../Notification";

const NotificationsTop = (props) => {
    const [showNotifications, setShowNotifications] = useState(false);
    return (
        <Tag.Container>
            <Tag.Link href="#" onMouseEnter={() => setShowNotifications(true)} onMouseLeave={() => setShowNotifications(false)}>
                <Tag.Count>
                    <Tag.CountText>2</Tag.CountText>
                </Tag.Count>
                <Tag.Icon src={IcoNotifications} width={32} height={32} />
            </Tag.Link>
            {showNotifications &&
                <Tag.Notifications onMouseEnter={() => setShowNotifications(true)} onMouseLeave={() => setShowNotifications(false)}>
                    <Notification />
                    <Notification />
                    <Notification />
                    <Notification />
                    <Tag.LinkNotifications href="#">Ver todas as notificações</Tag.LinkNotifications>
                </Tag.Notifications>
            }
        </Tag.Container>
    );
}

export default NotificationsTop;