
import React from "react";
import * as Tag from "./styles";
import IcoClose from "../../assets/ico_close.svg";

const AccessLog = (props) => {
    return (
        <Tag.Container>

            <Tag.BoxIcon>
                <Tag.Icon src={IcoClose} />
            </Tag.BoxIcon>

            <Tag.Texts>
                <Tag.Title>{props.item.plataform}</Tag.Title>
                <Tag.Local>{props.item.local} <Tag.Separator /> {props.item.date}</Tag.Local>
            </Tag.Texts>

            <Tag.IconClose src={IcoClose} />

        </Tag.Container>
    );
}

export default AccessLog;