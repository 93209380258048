
import React, { useState, useRef, useEffect } from "react";
import * as Tag from "./styles";
import FotoUser from  "../../assets/foto_user.jpg";
import FotoProduct from  "../../assets/foto_product_02.jpg";
import IcoMore from  "../../assets/ico_more.svg";

const PreviewMessageChat = (props) => {
    const boxRef = useRef();
    const [x, setX] = useState();
    const [y, setY] = useState();
    const getPosition = () => {
        const x = boxRef.current.offsetLeft;
        setX(x);
        const y = boxRef.current.offsetTop;
        setY(y);
    };
    useEffect(() => {
        getPosition();
    }, []);
    useEffect(() => {
        window.addEventListener("resize", getPosition);
    }, []);

    return (
        <Tag.Container ref={boxRef} active={props.active}>

            <Tag.BoxImage>
                <Tag.Image src={FotoUser} />
                <Tag.ImageSmall src={FotoProduct} />
            </Tag.BoxImage>
            <Tag.Texts>
                <Tag.Name>Ana Paula da Silva</Tag.Name>
                <Tag.PrevMsg>Lorem ipsum dolor </Tag.PrevMsg>
            </Tag.Texts>
            <Tag.Infos>
                <Tag.Date>12:05</Tag.Date>
                <Tag.Actions>
                    <Tag.Count>2</Tag.Count>
                    <Tag.IconMore src={IcoMore} onClick={() => props.showActionsItem(x,y)} />
                </Tag.Actions>
            </Tag.Infos>
        </Tag.Container>
    );
}

export default PreviewMessageChat;