import styled from "styled-components";

export const Container = styled.div`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: auto;
    background:#0000FF;
    width: 100%;
    background: linear-gradient(180deg, #FAFAFA 87.41%, #E7E7E7 120.8%);
`;

export const Content = styled.div`
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    height: auto;
    padding: 25px 60px 25px;
    width: 100%;
    max-width: 1440px;
    flex-direction: column;
    position:relative;
    min-height: 92px;

    @media(max-width: 768px) {
        :after{
            left:10px;
        }
        :before{
            right:10px;
        }
    }

    @media(max-width: 1280px) {
        padding: 12px 15px 0;
    }
`;

export const Top = styled.div`
    display:flex;
    align-items: center;
    justify-content: ${props => props.detail ? 'space-between' : 'flex-start'};
    flex-direction:row;
    width: 100%;
    position:relative;
    z-index: 2;
`;

export const Left = styled.div`
    display:flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction:row;
    width: 100%;
`;

export const Logo = styled.img`
    width: 125px;
    height: 50px;
    object-fit: contain;
    cursor:pointer;
`;

export const Link = styled.a`
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: #666666;
    margin-left:64px;

    @media(max-width: 768px) {
        margin-left:40px;
    }
`;