import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction:column;
    width: 100%;
    padding:0 20px;

    @media(max-width: 768px) {
        padding:0;
    }
`;

export const Title = styled.p`
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: #333333;
`;

export const Row = styled.div`
    width: 100%;
    display:flex;
    align-items: ${props => props.align ? props.align : 'flex-start'};
    justify-content: flex-start;
    flex-direction:column;
    margin-top:36px;
`;

export const Label = styled.label`
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #333333;
`;