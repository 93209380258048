import styled from "styled-components";
import IcoCheck from "../../assets/ico_check_white.svg";

export const Container = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: #FFFFFF;
    border: 1px solid #E5E5E5;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    height: 319px;
    max-width:282px;
    padding:25px 20px;

    @media(max-width: 960px) {
        max-width:100%;
        height: auto;
    }
    
`;

export const Row = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    margin-top: 18px;
`;

export const Icon = styled.img`
    width: 20px;
    height: 20px;
    background:#2D9248 url(${IcoCheck}) no-repeat 50%;
    border-radius: 50%;
    margin-left:10px;
`;

export const Image = styled.img`
    width: 112px;
    height: 112px;
    border-radius: 50%;
`;

export const Name = styled.p`
    font-weight: 500;
    font-size: ${props => props.checked ? '18' : '24'}px;
    line-height: 29px;
    color: #333333;
`;

export const Link = styled.a`
    text-decoration:none;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #0000FF;
    cursor:pointer;
    margin-top:17px;
`;

export const Level = styled.div`
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #333333;
    margin-top:8px;
`;

export const IconLevel = styled.img`
    width: 10px;
    height: 10px;
    margin-left:8px;
    object-fit:contain;
`;
