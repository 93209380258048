import React, { useState, useEffect, useRef } from "react";
import * as Tag from "./styles";
import Header from  '../../components/Header';
import Footer from  '../../components/Footer';
import FiltersSearch from  '../../components/FiltersSearch';
import BannerSidebar from  '../../assets/banner_sidebar.jpg';
import BannerInside from  '../../assets/banner_inside_products.jpg';
import TagFilter from  '../../components/TagFilter';
import Popup from "../../components/Popup";
import ProductThumbHorizontal from  '../../components/ProductThumbHorizontal';
import ProductThumb from  '../../components/ProductThumb';
import IcoActions from '../../assets/ico_actions_product.svg';
import ImgSearchNotFound from '../../assets/search_not_found.svg';
import IcoFilterMobile from '../../assets/ico_filters_mobile.svg';
import Button from "../../components/Button";
import { useSearchParams } from "react-router-dom";
import PopUpFilterMobile from "../../components/PopUpFilterMobile";
import api from "../../services/api";
import LoaderComponent from "../../components/LoaderComponent";
import { connect } from 'react-redux';

const Search = (props) => {
    const [loader, setLoader] = useState(false);
    const [adsList, setAdsList] = useState([]);
    const [popupTitle, setTitlePopup] = useState("");
    const [popupActions, setPopupActions] = useState(false);
    const [notFound, setNotFound] = useState(false);
    const [filtersMobile, setFiltersMobile] = useState(false);
    const [width, setWidth] = useState(window.innerWidth);
    const didMount = useRef(true);

    const updateDimensions = () => {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    }, []);

    const [searchParams] = useSearchParams();
    console.log(searchParams.get('term'));

    function showPopupActions(title){
        setTitlePopup(title);
        setPopupActions(true);
    }

    function onFilter(){
        setNotFound(true);
    }

    function onFilterMobile(){
        setFiltersMobile(false);
        setNotFound(true);
    }

    async function getAds(){
        try {
            setLoader(true);
            const response = await api.post(`/ads/all`, {
            });
            console.log(response);
            if(response.data.status){
                setAdsList(response.data.data);
            }
            setLoader(false);
        } catch (err) {
            console.log(err);
        }finally{
            setLoader(false);
        }
    }

    useEffect(() => {
        if(didMount.current){
            didMount.current = false;
            getAds();
        }
    });

    return (
        <Tag.Container>
            <Header />
            <Tag.Content>

                {width <= 768 && <Button text="Filtros" onClick={() => setFiltersMobile(true)} width={118} height={40} icon={IcoFilterMobile} style={{position: "fixed", right: '12px', top: 'calc(50% - 20px)', zIndex: 2}} />}

                {filtersMobile && <PopUpFilterMobile onFilter={() => onFilterMobile()} setFiltersMobile={() => setFiltersMobile(false)} />}

                {popupActions &&
                    <Popup title={popupTitle} onBlur={() => setPopupActions(false)} smallTitle maxWidth={532}>
                        <Tag.Icon src={IcoActions} />
                        <Button text="Ok" width={218} style={{marginTop: '60px'}} />
                    </Popup>
                }
                
                <Tag.Sidebar>
                    <FiltersSearch onFilter={() => onFilter()} />
                    <Tag.Banner src={BannerSidebar} />
                </Tag.Sidebar>

                {notFound && <Tag.Results>
                    <Tag.Term>x resultados para {searchParams.get('term')}</Tag.Term>
                    <Tag.Notfound>
                        <Tag.NotfoundTitle>Não encontramos nenhum resultado para sua busca</Tag.NotfoundTitle>
                        <Tag.NotFoundText>Tente ajustar os filtros e tente novamente</Tag.NotFoundText>
                        <Tag.NotFoundImage src={ImgSearchNotFound} />
                    </Tag.Notfound>
                </Tag.Results>}

                {loader && <LoaderComponent />}

                {!notFound && <Tag.Results>
                    <Tag.Term>x resultados para {searchParams.get('term')}</Tag.Term>
                    <Tag.Sugestion>Talvez você quis dizer <Tag.SugestionLink>{searchParams.get('term')}</Tag.SugestionLink></Tag.Sugestion>

                    {width > 768 && <Tag.Tags>
                        <TagFilter text="Curitiba - PR" />
                        <TagFilter text="Sorocaba - SP" />
                        <TagFilter text="5 km" />
                        <TagFilter text="Novo" />
                        <TagFilter text="Palavra chave pesquisada" />
                        <TagFilter text="Mín R$ 22" />
                    </Tag.Tags>}

                    {adsList.length > 0 && adsList.map((item, index) =>
                        <div key={index} style={{width: '100%'}}>
                            {index === 2 &&  <Tag.BannerInside src={BannerInside}/>}
                            <Tag.Item>
                                {width > 768 ?
                                <ProductThumbHorizontal product={item} showPopupActions={(title) => showPopupActions(title)} /> :
                                <ProductThumb product={item} showPopupActions={(title) => showPopupActions(title)}/>}
                            </Tag.Item>
                        </div>
                    )}
                </Tag.Results>}
            </Tag.Content>
            <Footer />
        </Tag.Container>
    );
}

const mapStateToProps = store => ({
    user: store.userState,
    location: store.locationState
});

export default connect(mapStateToProps)(Search);