import React from "react";
import * as Tag from "./styles";

const Button = (props) => {
    return (
        props.type === "link" ? 
        <Tag.Link href={props.href} onClick={props.onClick} width={props.width} height={props.height} background={props?.background} icon={props.icon} style={props.style} disabled={props.disabled}>
            {props.icon && <Tag.Icon src={props.icon} width={props.widthIcon} height={props.heightIcon} />}
            <Tag.Text color={props?.color}>{props?.text}</Tag.Text>
        </Tag.Link> :
        <Tag.Button type={props.type} onClick={props.onClick} width={props.width} height={props.height} background={props?.background} icon={props.icon} style={props.style} disabled={props.disabled}>
            {props.icon && <Tag.Icon src={props.icon} width={props.widthIcon} height={props.heightIcon} />}
            <Tag.Text color={props?.color}>{props?.text}</Tag.Text>
        </Tag.Button>
    );
}

export default Button;