
import React from "react";
import * as Tag from "./styles";
import Button from "../Button";

const Address = (props) => {
    
    return (
        <Tag.Container>
            <Tag.Label>CEP</Tag.Label>
            <Tag.Description>2124564-900</Tag.Description>

            <Tag.Label>Rua</Tag.Label>
            <Tag.Description>Rua de exemplo</Tag.Description>

            <Tag.Row>
                <Tag.Col>
                    <Tag.Label>Número</Tag.Label>
                    <Tag.Description>234</Tag.Description>
                </Tag.Col>
                <Tag.Col>
                    <Tag.Label>Complemento</Tag.Label>
                    <Tag.Description>Casa 2</Tag.Description>
                </Tag.Col>
            </Tag.Row>

            <Button text="Editar" onClick={() => props.onEdit()} width={195} style={{marginTop: '32px'}} />
        </Tag.Container>
    );
}

export default Address;