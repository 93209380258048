
import React from "react";
import * as Tag from "./styles";
import InputText from "../InputText";
import Logo from "../../assets/logo_azul.svg";
import IcoSearch from "../../assets/ico_search.svg";
import { useNavigate } from "react-router-dom";

const HeaderHelpCenter = (props) => {
    const history = useNavigate();
    return (
        <Tag.Container>
            <Tag.Content>
                <Tag.Top>
                    <Tag.Logo src={Logo} onClick={() => history('/')} />
                    <Tag.Link href="/help-center">Central de Ajuda</Tag.Link>
                    <Tag.Link href="/">Home</Tag.Link>
                </Tag.Top>
                <Tag.Search>
                    <Tag.FieldSearch>
                        <Tag.Title>Como podemos ajudar?</Tag.Title>
                        <InputText placeholder="Pesquise sobre algum tema" iconRight={IcoSearch} round={30} />
                    </Tag.FieldSearch>
                    {!props.category && props.image && <Tag.Image src={props.image} />}
                </Tag.Search>
            </Tag.Content>
        </Tag.Container>
    );
}

export default HeaderHelpCenter;