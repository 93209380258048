
import React, { useState, useEffect } from "react";
import * as Tag from "./styles";
import RadioBox from "../RadioBox";
import IcoMore from "../../assets/ico_more_vertical.svg";
import CardActions from "../CardActions";

const CardCheckout = (props) => {
    const [showActions, setShowActions] = useState(false);
    const [width, setWidth] = useState(window.innerWidth);
    const updateDimensions = () => {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    }, []);

    return (
        <Tag.Container>
            {showActions && <CardActions onBlur={() => setShowActions(false)} />}
            {!props.noCheck && <RadioBox active={props.active} onClick={() => props.setActive(props.item.id)} title={props.item.title} icon={props.item?.icon} value={props.item.id} iconMaxHeight={width > 768 ? 44 : 26} marginTop={0} fontWeight={500} fontSize={width > 768 ? 24 : 18} lineHeight={width > 768 ? 29 : 27} />}
            
            {props.noCheck && <Tag.Texts><Tag.Icon src={props.item?.icon} />{props.item.title}</Tag.Texts>}
            <Tag.IconMore src={IcoMore} onClick={() => setShowActions(true)} />
        </Tag.Container>
    );
}

export default CardCheckout;