import React, { useEffect, useRef, useState } from "react";
import Header from  '../../components/Header';
import Footer from  '../../components/Footer';
import Button from  '../../components/Button';
import ButtonOutline from  '../../components/ButtonOutline';
import InputText from  '../../components/InputText';
import Popup from  '../../components/Popup';
import SuccessMsgCreateAds from  '../../components/SuccessMsgCreateAds';
import SuccessDraft from  '../../components/SuccessDraft';
import * as Tag  from "./styles";
import Textarea from "../../components/Textarea";
import DropDownList from "../../components/DropDownList";
import DropDownListSearch from "../../components/DropDownListSearch";
import CheckBox from "../../components/CheckBox";
import SlideCheck from "../../components/SlideCheck";
import FileUpload from "../../components/FileUpload";
import IcoPin from "../../assets/ico_pin_map.svg";
import { connect } from 'react-redux';
import { getLocation } from "../../services/google";
import LoaderComponent from "../../components/LoaderComponent";
import api from "../../services/api";
import IcoRemove from "../../assets/ico_close_blue.svg";
import StepsAds from "../../components/StepsAds";
import LoaderFullScreen from "../../components/LoaderFullScreen";
import tipoVendedor from "../../constants/tipoVendedor";
import estados from "../../constants/estados";

const CreateAdsVehicle = (props) => {
    const [createSuccess, setCreateSuccess] = useState(false);
    const [loader, setLoader] = useState(false);
    const [categories, setCategories] = useState([]);
    const [loadCategories, setLoadCategories] = useState(false);
    const [makes, setMakes] = useState([]);
    const [loadMakes, setLoadMakes] = useState(false);
    const [models, setModels] = useState([]);
    const [loadModels, setLoadModels] = useState(false);
    const [years, setYears] = useState([]);
    const [loadYears, setLoadYears] = useState(false);
    const [fipe, setFipe] = useState([]);
    const [loadFipe, setLoadFipe] = useState(false);
    const [zip_code, setZipCode] = useState('');
    const [location, setLocation] = useState('');
    const [draft, setDraft] = useState(false);
    const [errors, setErrors] = useState([]);
    const [ads, setAds] = useState({});
    const didMount = useRef(true);
    const [stepInfos, setStepInfos] = useState({
        step: 1,
        totalSteps : 2,
        percentage : 0,
        textNextStep : "Próximo passo: Fixa técnica"
    });

    function nextStep(){
        let errosList = [];
        if(!ads?.name){
            errosList.push('Prencha o título do anúncio');
        }
        if(!ads?.description){
            errosList.push('Digite uma descrição para o anúncio');
        }
        if(!ads?.images?.length){
            errosList.push('Selecione pelo menos 1 imagem para o anúncio');
        }
        if(!ads?.category?.id){
            errosList.push('Selecione a categoria antes de prosseguir');
        }
        if(!ads?.subcategory?.id){
            errosList.push('Selecione a subcategoria antes de prosseguir');
        }
        if(!ads.accept_terms){
            errosList.push('Concorde com os Termos de Uso e Politica de Privacidade');
        }
        if(errosList.length === 0){
            setStepInfos({
                step: 2,
                totalSteps : 2,
                percentage : 50,
                textNextStep : "Último passo para publicar"
            });
            getMakes();
            setErrors([]);
            window.scrollTo(0, 0);
        }else{
            setErrors(errosList);
        }
    }

    function prevStep(){
        setStepInfos({
            step: 1,
            totalSteps : 2,
            percentage : 0,
            textNextStep : "Próximo passo: Fixa técnica"
        });
        window.scrollTo(0, 0);
    }

    const selectFiles = async (listFiles) => {
        if(ads.images?.length > 0){
            setAds({...ads, images : [...ads.images, ...Array.from(listFiles)] });
        }else{
            setAds({...ads, images : Array.from(listFiles)});
        }
    }

    function buildFormData(formData, data, parentKey) {
        if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
            Object.keys(data).forEach(key => {
                buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
            });
        } else {
            const value = data == null ? '' : data;
            formData.append(parentKey, value);
        }
    }
      
    function jsonToFormData(data) {
        const formData = new FormData();
        buildFormData(formData, data);
        return formData;
    }

    async function handleAds(e){
        e.preventDefault();

        let errosList = [];
        if(!ads?.placa){
            errosList.push('Prencha a placa do veículo');
        }
        if(!ads?.make){
            errosList.push('Selecione a marca do veículo');
        }
        if(!ads?.model){
            errosList.push('Selecione o modelo do veículo');
        }
        if(!ads?.year){
            errosList.push('Selecione o ano do veículo');
        }
        if(!ads.price){
            errosList.push('Digite um valor para o anúncio');
        }
        if(errosList.length === 0){
            setErrors([]);
            try {
                setLoader(true);
                let response = null;

                const adsTemp = {...ads, user_id : props.user.id, ads_type : 'vehicle'};
                console.log('adsTemp',adsTemp);
                console.log('adsTemp',jsonToFormData(adsTemp));

                response = await api.post(`/ads/store`, jsonToFormData(adsTemp),{
                    headers: {
                        "Content-Type": "multipart/form-data",
                    }
                });
                console.log(response);
                if(response.data.status){
                    setStepInfos({
                        step: 2,
                        totalSteps : 2,
                        percentage : 100,
                        textNextStep : "Próximo passo: Fixa técnica"
                    })
                    setCreateSuccess(true);
                    window.scrollTo(0, 0);
                }
                setLoader(false);
            } catch (err) {
                console.log(err);
            }finally{
                setLoader(false);
            }
        }else{
            setErrors(errosList);
        }

    }

    async function getCategories(){
        try {
            setLoadCategories(true);
            const response = await api.get("/category/toFilters?type=vehicle");
            console.log(response);
            if(response.data.status){
                setCategories(response.data.data);
            }
            setLoadCategories(false);

        } catch (err) {
            console.log(err);
        }finally{
            setLoadCategories(false);
        }
    }

    async function getCurrentLocation(){
        let city = '';
        let state = '';
        let country = '';
        let district = '';
        let zip = '';

        try{
            const address = await getLocation(props.location.latitude, props.location.longitude);
            console.log(address);
            city = address.filter((item) => item.types.filter((type) => type === "administrative_area_level_2").length > 0)[0]?.long_name;
            state = address.filter((item) => item.types.filter((type) => type === "administrative_area_level_1").length > 0)[0]?.short_name;
            country = address.filter((item) => item.types.filter((type) => type === "country").length > 0)[0]?.long_name;
            district = address.filter((item) => item.types.filter((type) => type === "sublocality_level_1").length > 0)[0]?.long_name;
            zip = address.filter((item) => item.types.filter((type) => type === "postal_code").length > 0)[0]?.long_name;

            setLocation(`${district}, ${city} - ${state}`);
            setZipCode(zip);
            setAds({...ads , location : {
                latitude: props.location.latitude,
                longitude: props.location.longitude,
                city,
                state,
                country,
                district,
                zip
            }})
        }catch(e){
            
        }
    }

    function changeFilters(status, item){
        if(status){
            if(ads.filters){
                setAds({...ads, filters : [...ads.filters, item]})
            }else{
                setAds({...ads, filters : [item]})
            }
        }else{
            setAds({...ads, filters : ads.filters.filter(i => i.id !== item.id)})
        }
    }

    function changeSubcategories(status, item){
        if(status){
            if(ads.subcategory){
                setAds({...ads, subcategory : [...ads.subcategory, item]})
            }else{
                setAds({...ads, subcategory : [item]})
            }
        }else{
            setAds({...ads, subcategory : ads.subcategory.filter(i => i.id !== item.id)})
        }
    }

    function removeImage(indexOld){
        console.log(ads);
        setAds({
            ...ads,
            images : ads.images.filter((item,index) => index !== indexOld)
        })
    }

    function setSubFilters(otherFilter, sub_filter, number = 0){

        if(sub_filter.type === 'slider'){
            sub_filter.number = number;
        }

        if(ads?.other_filters){
            if(otherFilter.type === 'checkbox'){
                
                let checkExistsSubFilter = ads.other_filters.find(item => item.id === otherFilter.id)?.sub_filters.find(item => item.id === sub_filter.id);

                if(checkExistsSubFilter?.id){

                    let newList = [...ads.other_filters.find(item => item.id === otherFilter.id).sub_filters.filter(item => item.id !== sub_filter.id)];
                    
                    if(sub_filter.type === 'slider'){
                        newList = [...ads.other_filters.find(item => item.id === otherFilter.id).sub_filters.filter(item => item.id !== sub_filter.id), sub_filter];
                    }

                    setAds({...ads, other_filters : [...ads.other_filters.filter(item => item.id !== otherFilter.id), {
                        id : otherFilter.id,
                        name : otherFilter.name,
                        type: otherFilter.type,
                        sub_filters : newList,
                    }]});
                }else{

                    if(ads.other_filters.find(item => item.id === otherFilter.id)?.sub_filters){
                        setAds({...ads, other_filters : [...ads.other_filters.filter(item => item.id !== otherFilter.id), {
                            id : otherFilter.id,
                            name : otherFilter.name,
                            type: otherFilter.type,
                            sub_filters : [...ads.other_filters.find(item => item.id === otherFilter.id)?.sub_filters, sub_filter],
                        }]});
                    }else{
                        setAds({...ads, other_filters : [...ads.other_filters.filter(item => item.id !== otherFilter.id), {
                            id : otherFilter.id,
                            name : otherFilter.name,
                            type: otherFilter.type,
                            sub_filters : [sub_filter],
                        }]});
                    }
                }

            }else{
                setAds({...ads, other_filters : [...ads.other_filters.filter(item => item.id !== otherFilter.id), {
                    id : otherFilter.id,
                    name : otherFilter.name,
                    type: otherFilter.type,
                    sub_filters : sub_filter,
                }]});
            }
        }else{
            if(otherFilter.type === 'checkbox'){
                setAds({...ads, other_filters : [{
                    id : otherFilter.id,
                    name : otherFilter.name,
                    type: otherFilter.type,
                    sub_filters : [sub_filter],
                }]});
            }else{
                setAds({...ads, other_filters : [{
                    id : otherFilter.id,
                    name : otherFilter.name,
                    type: otherFilter.type,
                    sub_filters : sub_filter,
                }]});
            }
        }
    }

    async function getMakes(){
        try {
            setLoadMakes(true);
            const response = await fetch(`https://parallelum.com.br/fipe/api/v2/cars/brands`).then(response => response.json());
            setMakes(response);
            setLoadMakes(false);
        } catch (err) {
            console.log(err);
        }finally{
            setLoadMakes(false);
        }
    }

    async function getModels(make){
        if(make.code){
            try {
                setLoadModels(true);
                const response = await fetch(`https://parallelum.com.br/fipe/api/v2/cars/brands/${make.code}/models`).then(response => response.json());
                setModels(response);
                setAds({...ads, make : make});
                setLoadModels(false);
            } catch (err) {
                console.log(err);
            }finally{
                setLoadModels(false);
            }
        }
    }

    async function getYears(model){
        if(model.code){
            try {
                setLoadYears(true);
                const response = await fetch(`https://parallelum.com.br/fipe/api/v2/cars/brands/${ads.make.code}/models/${model.code}/years`).then(response => response.json());
                setYears(response);
                setAds({...ads, model : model});
                setLoadYears(false);
            } catch (err) {
                console.log(err);
            }finally{
                setLoadYears(false);
            }
        }
    }

    async function getFipe(year){
        if(year.code){
            try {
                setLoadFipe(true);
                const response = await fetch(`https://parallelum.com.br/fipe/api/v1/carros/marcas/${ads.make.code}/modelos/${ads.model.code}/anos/${year.code}`).then(response => response.json());
                setFipe(response);
                setAds({...ads, year : year, price: response.Valor, fipe : response});
                setLoadFipe(false);
            } catch (err) {
                console.log(err);
            }finally{
                setLoadFipe(false);
            }
        }
    }

    useEffect(() => {
        if(props.user.id > 0){
            if(didMount.current){
                didMount.current = false;
                getCategories();
            }
        }
    },[props.user.id]);

    useEffect(() => {
        if(props.location.latitude != ''){
            getCurrentLocation();
        }
    },[props.location]);


    return (
        <Tag.Container>
            <Header account />
            <Tag.Content>

                {loader && <LoaderFullScreen />}

                {!createSuccess && <>
                <Tag.Form onSubmit={handleAds}>
                    <Tag.Title>Crie um anúncio para seu veículo</Tag.Title>

                    <StepsAds title="Informação básica do veículo" step={stepInfos.step} totalSteps={stepInfos.totalSteps} textNextStep={stepInfos.textNextStep} percentage={stepInfos.percentage} />

                    {stepInfos.step === 1 && <Tag.StepForm>

                        <Tag.Row>
                            <Tag.Label>Título</Tag.Label>
                            <InputText placeholder="Adicione um título ao seu anúncio" value={ads.name} onChange={(e) => setAds({...ads, name : e.target.value})} />
                        </Tag.Row>

                        <Tag.Row>
                            <Tag.Label>Descrição</Tag.Label>
                            <Textarea placeholder="Descreva o seu produto ou serviço" value={ads.description} onChange={(e) => setAds({...ads, description : e.target.value})} />
                        </Tag.Row>

                        <Tag.Row>
                            <Tag.Label>Categoria</Tag.Label>
                            {loadCategories && <LoaderComponent />}
                            {categories && <DropDownList placeholder="Selecione uma categoria" selected={ads.category} items={categories} style={{zIndex : 2}} onChange={(category) => setAds({...ads, category : category, subcategory : [], filters : []})} />}
                        </Tag.Row>

                        {ads.category?.subcategories && 
                        <Tag.Row>
                            <Tag.Label>Subcategoria</Tag.Label>
                            <DropDownList placeholder="Selecione uma subcategoria" selected={ads.subcategory} style={{zIndex : 1}} items={ads.category.subcategories} onChange={(subcategory) => setAds({...ads, subcategory : subcategory, filters : []})} />
                        </Tag.Row>
                        }

                        <Tag.Row>
                            <SlideCheck title="Mostrar localização aproximada" active={ads.show_location} onChange={(show_location) => setAds({...ads, show_location})} />
                            {ads.show_location && location &&
                            <Tag.Col style={{alignItems : "center"}}>
                                <InputText placeholder="00000-00" value={zip_code} width={282} />
                                <Tag.Local><Tag.IconLocal src={IcoPin} /> {location}</Tag.Local>
                            </Tag.Col>
                            }
                        </Tag.Row>

                        <Tag.Row>
                            <SlideCheck title="Permitir contato via WhatsApp" active={ads.allow_contact_phone} onChange={(allow_contact_phone) => setAds({...ads, allow_contact_phone})} />
                            <Tag.Obs>{props.user.phone}</Tag.Obs>
                        </Tag.Row>

                        <Tag.Row>
                            <FileUpload onChangeFiles={(files) => selectFiles(files)} multiple={true} />
                        </Tag.Row>

                        <Tag.ScrollImages>
                            {ads.images && ads.images.map((image, index) => (
                                <Tag.BoxImage key={index}>
                                    <Tag.Remove className="btRemove" src={IcoRemove} onClick={() => removeImage(index)} />
                                    <Tag.Image className="image" src={URL.createObjectURL(image)} />
                                </Tag.BoxImage>
                            ))}
                        </Tag.ScrollImages>

                        <Tag.Row>
                            <CheckBox title="Concordo com os Termos de Uso e Politica de Privacidade" active={ads.accept_terms} onChange={(accept_terms) => setAds({...ads, accept_terms})} />
                        </Tag.Row>

                    </Tag.StepForm>}

                    {stepInfos.step === 2 && <Tag.StepForm>

                        <Tag.Row>
                           <Tag.Label>Placa do veículo</Tag.Label>
                           <InputText placeholder="ABC1D23" value={ads.placa} onChange={(e) => setAds({...ads, placa : e.target.value})} />
                        </Tag.Row>

                        <Tag.Row>
                           <Tag.Label>Marca</Tag.Label>
                            {loadMakes && <LoaderComponent />}
                            {makes && <DropDownListSearch placeholder="Selecione uma marca" style={{zIndex : (ads.category?.other_filters?.length + 5)}} items={makes} onChange={(make) => getModels(make)} />}
                        </Tag.Row>

                        <Tag.Row>
                            <Tag.Label>Versão</Tag.Label>
                            {loadModels && <LoaderComponent />}
                            {models && <DropDownListSearch placeholder="Selecione uma versão" style={{zIndex : (ads.category?.other_filters?.length + 4)}} items={models} onChange={(model) => getYears(model)} />}
                        </Tag.Row>

                        <Tag.Row>
                            <Tag.Label>Ano</Tag.Label>
                            {loadYears && <LoaderComponent />}
                            {years && <DropDownList placeholder="Selecione o Ano" style={{zIndex : (ads.category?.other_filters?.length + 3)}} items={years} onChange={(year) => getFipe(year)} />}
                        </Tag.Row>

                        {ads.category?.show_filter_state === 'yes' &&
                        <Tag.Row>
                            <Tag.Label>Estado</Tag.Label>
                            <DropDownList placeholder="Selecione o estado" style={{zIndex : (ads.category?.other_filters?.length + 2)}} selected={ads.state_type} items={estados} onChange={(state_type) => setAds({...ads, state_type : state_type})} />
                        </Tag.Row>
                        }

                        {ads.category?.show_filter_seller === 'yes' &&
                            <Tag.Row>
                                <Tag.Label>Tipo de vendedor</Tag.Label>
                                <DropDownList placeholder="Selecione o tipo do vendedor" style={{zIndex : (ads.category?.other_filters?.length + 1)}} selected={ads.seller_type} items={tipoVendedor} onChange={(seller_type) => setAds({...ads, seller_type : seller_type})} />
                            </Tag.Row>
                        }

                        {ads.category?.other_filters && ads.category.other_filters.map((otherFilter, index) =>
                            <Tag.Row key={otherFilter.id}>
                                <Tag.Label>{otherFilter.name}</Tag.Label>
                                {otherFilter.type === 'select' && <DropDownList placeholder={`Selecione ${otherFilter.name}`} items={otherFilter.sub_filters} style={{zIndex : (ads.category?.other_filters?.length - index)}} onChange={(sub_filter) => setSubFilters(otherFilter, sub_filter)} />}
                                {otherFilter.type === 'checkbox' && 
                                    <Tag.Row style={{display: 'grid', gridTemplateColumns: 'auto auto', marginTop: 0}}>
                                        {otherFilter.sub_filters.map((item) =>
                                            <CheckBox name={item.name} key={item.id} onChange={() => setSubFilters(otherFilter, item)} />
                                        )}
                                    </Tag.Row>
                                }
                            </Tag.Row>
                        )}

                        <Tag.Row>
                            <Tag.Label>Valor</Tag.Label>
                            <InputText mask="money" prefix="R$" placeholder="Valor o seu produto ou serviço" value={ads.price} onChange={(e,value) => value ? setAds({...ads, price : value}) : null} />
                        </Tag.Row>
                        
                    </Tag.StepForm>}

                    {errors?.length > 0 && <Tag.Row>
                        <Tag.Errors>{errors.map((error) => <>{`- ${error}`}<br/></> )}</Tag.Errors>
                    </Tag.Row>}

                    <Tag.Row style={{textAlign: "center"}}>
                        {stepInfos.step === 1 &&  <Button width={286} type="button" text="Preencher ficha do veículo" onClick={() => nextStep()} />}
                        {stepInfos.step === 2 &&  <Button width={286} type="submit" text="Publicar anúncio" />}
                    </Tag.Row>

                    <Tag.Row style={{textAlign: "center", marginTop: '20px'}}>
                        {stepInfos.step === 1 && <ButtonOutline width={286} type="link" onClick={() => setDraft(true)} text="Cancelar" style={{marginTop: 0}} />}
                        {stepInfos.step === 2 && <ButtonOutline width={286} type="button" text="Voltar" onClick={() => prevStep()} />}
                    </Tag.Row>

                </Tag.Form>
                </>}

                {createSuccess && <SuccessMsgCreateAds />}

                {draft && <Popup maxWidth={515} noCloseBlur>
                    <SuccessDraft setDraft={() => setDraft(false)} />
                </Popup>}
                
            </Tag.Content>
            <Footer />
        </Tag.Container>    
    );
}


const mapStateToProps = store => ({
    user: store.userState,
    location: store.locationState
});

export default connect(mapStateToProps)(CreateAdsVehicle);