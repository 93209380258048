import React, { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';

import { Globalstyle } from "./styles/global";
import App from './App';
import { Provider } from 'react-redux';
import { Store } from './store';

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
  <React.StrictMode>
    <Provider store={Store}>
        <Globalstyle />
        <App />
    </Provider>
  </React.StrictMode>
);