const orderBy = [
    {
        id : "mais_novos",
        title : "Mais novos"
    },
    {
        id : "mais_antigos",
        title : "Mais antigos"
    },
    {
        id : "menor_preco",
        title : "Menor preço"
    },
    {
        id : "maior_preco",
        title : "Maior preço"
    },
    {
        id : "mais_relevante",
        title : "Mais relevante"
    }
]

export default orderBy;