import styled from "styled-components";
import BackgroundCard from "../../assets/background_card.svg";

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
`;

export const Content = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    padding: 70px 20px 120px;
    background:#fff;
    width: 100%;
    max-width: 1050px;
    flex: 1;

    @media(max-width: 768px) {
        padding: 40px 20px 60px;
    }
`;

export const Title = styled.p`
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #333333;
`;

export const Form = styled.form`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: row;
    margin-top:55px;
    width: 100%;

    @media(max-width: 768px) {
        flex-direction: column;
        margin-top:0;
    }
`;

export const Card = styled.div`
    width: 100%;
    max-width: 447px;
    height:242px;
    margin-right:65px;
    margin-top:35px;
    background:url(${BackgroundCard}) no-repeat 50%;
    background-size: cover;
    border-radius:10px;
    position:relative;
    padding:25px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;

    @media(max-width: 768px) {
        height:182px;
    }
`;

export const Flag = styled.img`
    position:absolute;
    height:35px;
    right:15px;
    top:15px;

    @media(max-width: 768px) {
        height:25px;
    }
`;

export const Type = styled.p`
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: #FFFFFF;
    margin-bottom:30px;

    @media(max-width: 768px) {
        margin-bottom:10px;
    }
`;

export const Number = styled.p`
    font-weight: 500;
    font-size: 36px;
    line-height: 44px;
    color: #FFFFFF;

    @media(max-width: 768px) {
        font-size: 24px;
        line-height: 29px;
    }
`;

export const Name = styled.p`
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: #FFFFFF;
    margin-bottom:30px;

    @media(max-width: 768px) {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        margin-bottom:10px;
    }
`;

export const Bottom = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: row;
`;

export const Date = styled.p`
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: #FFFFFF;

    @media(max-width: 768px) {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
    }
`;

export const Cvv = styled.p`
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: #FFFFFF;
    margin-left:70px;

    @media(max-width: 768px) {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        margin-left:30px;
    }
`;

export const Fields = styled.div`
    width: 100%;
    max-width: 486px;
`;

export const Row = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction:row;
    margin-top:35px;
    width: 100%;

    @media(max-width: 768px) {
        flex-direction:column;
    }
`;

export const Col = styled.div`
    flex: 1;

    :first-child{
        margin-right:6px;
    }
    :last-child{
        margin-left:6px;
    }

    @media(max-width: 768px) {
        width: 100%;
        :first-child{
            margin-right:0;
        }
        :last-child{
            margin-left:0;
            margin-top:35px;
        }
    }
`;

