import React, { useState, useEffect } from "react";
import Header from  '../../components/Header';
import Footer from  '../../components/Footer';
import InputText from  '../../components/InputText';
import * as Tag  from "./styles";
import PopUpFilterProfileMobile from "../../components/PopUpFilterProfileMobile";
import IcoFilters from '../../assets/ico_filters_blue_mobile.svg';
import IcoSearch from "../../assets/ico_search.svg";
import IcoPlus from '../../assets/ico_plus_circle.svg';
import DropDownList from "../../components/DropDownList";
import Button from "../../components/Button";
import Saller from "../../components/Saller";

const orderBy = [
    {
        id : "Data de publicação",
        title : "Data de publicação"
    },
    {
        id : "menor_preco",
        title : "Menor preço"
    },
    {
        id : "maior_preco",
        title : "Maior preço"
    },
    {
        id : "Maior alcance",
        title : "Maior alcance"
    }
];

const Sellers = (props) => {
    const [filtersMobile, setFiltersMobile] = useState(false);
    const [width, setWidth] = useState(window.innerWidth);
    const updateDimensions = () => {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    }, []);

    function onFilter(){
        setFiltersMobile(false);
    }
    
    return (
        <Tag.Container>
            <Header />
            <Tag.Content>

                {filtersMobile && <PopUpFilterProfileMobile onFilter={() => onFilter()} setFiltersMobile={() => setFiltersMobile(false)} />}

                {width > 768 &&
                <Tag.Filters>
                    <Tag.Filter width={150}>
                        <Button text="Novo" icon={IcoPlus} width={130} />
                    </Tag.Filter>
                    <Tag.Filter width={200}>
                        <DropDownList items={orderBy} placeholder={"Ordenar por"} style={{zIndex : 1}} round={50} />
                    </Tag.Filter>
                    <Tag.Filter width={200}>
                        <DropDownList items={orderBy} placeholder="Categorias" style={{zIndex : 1}} round={50} />
                    </Tag.Filter>
                    <Tag.Filter width={800}>
                        <InputText placeholder="Estou procurando por..." iconRight={IcoSearch} round={50} />
                    </Tag.Filter>
                </Tag.Filters>
                }

                {width <= 768 && <Button text="Novo" icon={IcoPlus} width={130} style={{marginBottom: '30px'}} />}

                {width <= 768 &&
                <Tag.Filters>
                    <Tag.IconFilter src={IcoFilters} onClick={() => setFiltersMobile(true)} />
                    <Tag.Filter>
                        <InputText placeholder="Buscar" iconRight={IcoSearch} round={50} height={36} />
                    </Tag.Filter>
                </Tag.Filters>
                }

                <Tag.Total>23 vendedores</Tag.Total>

                <Saller />
                <Saller />
                <Saller />
                <Saller />
                <Saller />
                
            </Tag.Content>
            <Footer />
        </Tag.Container>    
    );
}

export default Sellers;