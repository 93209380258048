import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
`;

export const Content = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: row;
    padding: 70px 20px 120px;
    background:#fff;
    width: 100%;
    max-width: 1200px;
    flex: 1;

    @media(max-width: 768px) {
        padding: 40px 20px 60px;
    }

    @media(max-width: 960px) {
        flex-direction: column;
    }
`;

export const Sidebar = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    margin-right: 24px;
    flex: 1;

    @media(max-width: 960px) {
        width: 100%;
    }
`;

export const Cards = styled.div`
    background: #FFFFFF;
    border: 1px solid #CBCBCB;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    padding: 24px 18px;
    width: 100%;
    max-width: 752px;

    @media(max-width: 1200px) {
        max-width: 600px;
    }

    @media(max-width: 960px) {
        max-width: 100%;
        margin-top:24px;
    }
`;

export const Title = styled.p`
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #333333;
`;

export const List = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    padding: 0 50px;

    @media(max-width: 768px) {
        padding: 0;
        align-items: center;
    }
`;

export const AddCard = styled.a`
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    display: flex;
    align-items: center;
    color: #333333;
    margin: 55px 0 60px;

    @media(max-width: 768px) {
        width: 100%;
    }
`;

export const IconAdd = styled.img`
    width: 27px;
    height: 27px;
    object-fit: contain;
    margin-right:10px;
`;
