import React from "react";
import * as Tag from "./styles";
import Logo from "../../assets/logo.svg";
import BoxSearch from '../BoxSearch';
import LinksTop from '../LinksTop';
import LinkApps from '../LinkApps';
import LinkSocial from '../LinkSocial';
import { isAuthenticated } from "../../services/auth";
import UserTop from '../UserTop';
import NotificationsTop from '../NotificationsTop';
import MessagesTop from '../MessagesTop';
import { useNavigate } from "react-router-dom";
import Button from "../Button";

const HeaderDesktop = (props) => {
    const history = useNavigate();
    return (
        <Tag.BoxFull>
            <Tag.Container>
                <Tag.Top>
                    <Tag.Logo src={Logo} onClick={() => history('/')} />
                    <BoxSearch placeholder="Pesquise por produtos, marcas e lojas" width={props.account ? '640px': null} />
                    {isAuthenticated() &&
                        <Tag.LinksTopLogged>
                            {!props.account && <UserTop {...props} />}
                            {props.account && <MessagesTop style={{marginRight: '30px'}} />}

                            <NotificationsTop />

                            {props.account && <UserTop {...props} account style={{marginLeft: '40px'}} />}
                            {props.account && <Button href="/account/ads-type" type="link" text="Anunciar" background="#FFCB05" round={30} width={136} height={36} color="#333333" />}
                        </Tag.LinksTopLogged>
                    }
                    {!isAuthenticated() && <LinksTop />}
                </Tag.Top>
                {!props.account && <Tag.Bottom>
                    <LinkApps />
                    <LinkSocial />
                </Tag.Bottom>}
            </Tag.Container>
        </Tag.BoxFull>
    );
}

export default HeaderDesktop;