import React from "react";
import * as Tag from "./styles";
import IcoMore from "../../assets/ico_more_vertical.svg";
import { CropString } from "../../lib/Util";

const ButtonWishlist = (props) => {
    var space = 100 / props.items.length + 5;
    return (
        <Tag.Content>
            <Tag.BoxImage href={`/account/wishlist/${props.id}`}>
                {props.items && props.items.map((item, index) =>
                    <Tag.Image src={`${process.env.REACT_APP_BASE_URL_UPLOADS}${item?.images[0]?.url}`} style={{zIndex : (props.items.length - index), width : `calc(100% - ${(props.items.length === (index + 1)) ?  0 : (props.items.length - index - 1) * space}px)`}} />
                )}
            </Tag.BoxImage>
            <Tag.BoxBottom>
                <Tag.Title>{CropString(props.title, 19,'...')}</Tag.Title>
                <Tag.Icon src={IcoMore} />
            </Tag.BoxBottom>
        </Tag.Content>
    );
}

export default ButtonWishlist;