import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 100vh;
    flex-direction: column;
    overflow:hidden;
`;

export const Content = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: row;
    padding: 0px 20px;
    background:#fff;
    width: 100%;
    max-width: 1350px;
    flex: 1;


    @media(max-width: 1400px) {
        padding-left: 0px;
    }

    @media(max-width: 768px) {
        padding: 0px 20px;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
    }
`;

export const Form = styled.div`
    flex: 1;
    padding: 32px 120px 120px;

    @media(max-width: 1200px) {
        padding: 32px 20px 120px 40px;
    }

    @media(max-width: 960px) {
        padding: 32px 0px 50px 20px;
    }

    @media(max-width: 768px) {
        padding: 28px 0px 50px 0px;
        width: 100%;
    }

`;

export const Message = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: row;
    width:100%;

    @media(max-width: 768px) {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
`;

export const TextMsg = styled.p`
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    display: flex;
    align-items: center;
    color: #333333;

    @media(max-width: 768px) {
        width:100%;
    }
`;

export const Icon = styled.img`
    width:24px;
    height:24px;
    margin-right: 12px;
    object-fit:contain;
`;

export const Obs = styled.p`
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #333333;
    margin-top:8px;
    margin-left:35px;

    @media(max-width: 768px) {
        margin-left:0px;
    }
`;

export const Left = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    width:100%;
`;

export const Link = styled.a`
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: right;
    color: #0000FF;
    cursor:pointer;
    text-decoration:none;
`;

export const LinkDisabled = styled.a`
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: right;
    color: #F2994A;
    cursor:pointer;
    text-decoration:none;
`;

export const LinkDelete = styled.a`
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: right;
    color: #E62E1F;
    cursor:pointer;
    text-decoration:none;
`;