import styled from "styled-components";

export const Container = styled.div`
    background: #FFFFFF;
    border: 1px solid #CBCBCB;
    border-radius: 10px;
    width: 100%;
    height: 348px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    display: flex;
    position:relative;
`;

export const Texts = styled.div`
    padding: 21px 16px 0;
    display:flex;
    width: 100%;
    flex-direction:column;
    height: 100%;
    position:relative;
`;

export const Title = styled.a`
    width: 100%;
    line-height: 27px;
    font-weight: 400;
    font-size: 18px;
    color: #000;
    height: 27px;
    overflow: hidden;
    width: 300px;
    max-width: 100%;
    cursor:pointer;
    text-decoration:none;
`;

export const Date = styled.p`
    margin-top: 4px;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color:#000;
`;

export const Bottom = styled.div`
    padding: 5px 16px 12px;
    display:flex;
    width: 100%;
    flex-direction:row;
    align-items: center;
    justify-content: space-between;
    position:relative;
`;

export const Prices = styled.div`
    display:flex;
    flex-direction:column;
    align-items: flex-start;
    justify-content: center;
`;

export const OldPrice = styled.p`
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color:#828282;
    text-decoration: line-through;
`;

export const NewPrice = styled.p`
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color:#0000FF;
`;

export const Price = styled.p`
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color:#000;
`;

export const Views = styled.p`
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #333333;
`;

export const Buttons = styled.div`
    display:flex;
    flex-direction:row;
    align-items: center;
    justify-content: center;
`;

export const Link = styled.a`
    margin-left: 14px;
    cursor:pointer;
`;

export const Icon = styled.img`
    width: 20px;
    height: 20px;
    object-fit: contain;
`;

export const Company = styled.p`
    cursor:pointer;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color:#000;
    display:flex;
    flex-direction:row;
    align-items: center;
    justify-content: center;
    margin: 0 15px 3px;
`;

export const CompanyIcon = styled.img`
    width: 24px;
    height: 24px;
    object-fit: contain;
    border-radius:50px;
    border:solid 1px #E5E5E5;
    margin-right:10px;
`;

