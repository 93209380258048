import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    background: #FFFFFF;
    border: 1px solid #CBCBCB;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    padding: 18px 24px;
    width: 100%;
    margin:16px 0;
    position:relative;

    @media(max-width: 768px) {
        
    }
`;

export const BoxTop = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;

    @media(max-width: 768px) {
        align-items: flex-start;
    }
`;

export const BoxTitle = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;

    @media(max-width: 768px) {
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
    }
`;

export const Title = styled.p`
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    display: flex;
    align-items: center;
    color: #333333;

    @media(max-width: 768px) {
        font-size: 18px;
        line-height: 27px;
    }
`;

export const Date = styled.p`
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    text-align: right;
    color: #333333;
`;

export const Icon = styled.img`
    width:21px;
    height:21px;
    object-fit: contain;
    margin-left:16px;
    margin-right:-10px;
    cursor:pointer;
`;

export const Price = styled.p`
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    display: flex;
    align-items: center;
    color: #333333;
    margin-top:7px;
`;

export const BoxBottom = styled.div`
    display: flex;
    align-items: ${props => props.msg ? 'flex-start' : 'flex-end'};
    justify-content: ${props => props.msg ? 'center' : 'space-between'};
    flex-direction: ${props => props.msg ? 'column' : 'row'};
    background: #FFFFFF;
    width: 100%;

    @media(max-width: 768px) {
        flex-direction: column-reverse;
        align-items: flex-start;
        margin-top:12px;
    }
`;

export const Texts = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    margin-top:17px;
`;

export const TextDetail = styled.span`
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    display: flex;
    align-items: center;
    justify-content:center;
    color: #333333;
    cursor:pointer;
`;

export const IconDetail = styled.img`
    width:22px;
    height:14px;
    object-fit:contain;
    margin-left:7px;
`;

export const Details = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    max-height: ${props => props.open ? "100%" : "0"};
    overflow: hidden;
    transition: all 0.3s ease-out;
`;

export const Description = styled.p`
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #666666;
    margin-top:6px;
`;

export const Alert = styled.span`
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    text-align: left;
    color: #D3A727;

    @media(max-width: 768px) {
        align-items: flex-start;
        justify-content: flex-start;
        margin-bottom:13px;
    }
`;

export const IconAlert = styled.img`
    width:18px;
    height:18px;
    object-fit:contain;
    margin-right:7px;

    @media(max-width: 768px) {
        margin-top:4px;
    }   
`;

export const Payment = styled.div`
    width: ${props => props.msg ? '100%' : 'auto'};
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: space-between;
    flex-direction:row;
    padding-top:5px;

    @media(max-width: 768px) {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
    }
`;