import React from "react";
import * as Tag from "./styles";

const LinksTop = (props) => {
    return (
        <Tag.Container>
            <Tag.Link href="/signin">Entre</Tag.Link>
            <Tag.Separator />
            <Tag.Link href="/account-type">Cadastre-se</Tag.Link>
        </Tag.Container>
    );
}

export default LinksTop;