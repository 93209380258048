import React from "react";
import * as Tag from "./styles";

const ButtonSmall = (props) => {
    return (
        props.type === "link" ? 
        <Tag.Link href={props.href} width={props.width} height={props.height} background={props?.background} icon={props.icon}>
            {props.icon && <Tag.Icon src={props.icon} />}
            <Tag.Text color={props?.color}>{props?.text}</Tag.Text>
        </Tag.Link>
        :
        <Tag.Button onClick={props.onClick} width={props.width} height={props.height} background={props?.background} icon={props.icon}>
            {props.icon && <Tag.Icon src={props.icon} />}
            <Tag.Text color={props?.color}>{props?.text}</Tag.Text>
        </Tag.Button>
    );
}

export default ButtonSmall;