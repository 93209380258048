import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 100vh;
    flex-direction: column;
    overflow:hidden;
`;

export const Content = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: row;
    padding: 0px 20px;
    background:#fff;
    width: 100%;
    max-width: 1350px;
    flex: 1;


    @media(max-width: 1400px) {
        padding-left: 0px;
    }

    @media(max-width: 768px) {
        padding: 0px 20px;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
    }
`;

export const Form = styled.div`
    flex: 1;
    padding: 32px 120px 120px;

    @media(max-width: 1200px) {
        padding: 32px 20px 120px 40px;
    }

    @media(max-width: 960px) {
        padding: 32px 0px 50px 20px;
    }

    @media(max-width: 768px) {
        padding: 28px 0px 50px 0px;
        width: 100%;
    }

`;

export const Title = styled.p`
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    display: flex;
    align-items: center;
    color: #333333;
    width: 100%;

    @media(max-width: 768px) {
        font-size: 18px;
        line-height: 27px;
    }
`;

export const Texts = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    padding:0 100px;

    @media(max-width: 768px) {
        padding:0;
    }
`;

export const Description = styled.p`
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #333333;
    margin-top: 20px;

    @media(max-width: 768px) {
        font-size: 14px;
        line-height: 21px;
        margin-top: 10px;
    }
`;

export const Icon = styled.img`
    width:auto;
    height: 166px;
    object-fit:contain;
    margin-top: 42px;

    @media(max-width: 768px) {
        height: 98px;
    }
`;



export const ListCards = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    width: 100%;

    @media(max-width: 768px) {
        align-items: center;
    }
`;

export const AddCard = styled.a`
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    display: flex;
    align-items: center;
    color: #333333;
    margin: 55px 0 60px 20px;

    @media(max-width: 768px) {
        width: 100%;
    }
`;

export const IconAdd = styled.img`
    width: 27px;
    height: 27px;
    object-fit: contain;
    margin-right:10px;
`;

export const Plans = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
`;


export const Historic = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
`;