
import React from "react";
import * as Tag from "./styles";

const NotificationConfigEmpty = (props) => {
    
    return (
        <Tag.Container>
            <span></span>
            <Tag.Checks>
                <Tag.CheckCont>
                    <Tag.TextCheck>Email</Tag.TextCheck>
                </Tag.CheckCont>
                <Tag.CheckCont>
                    <Tag.TextCheck>App</Tag.TextCheck>
                </Tag.CheckCont>
            </Tag.Checks>
        </Tag.Container>
    );
}

export default NotificationConfigEmpty;