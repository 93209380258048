import styled from "styled-components";

export const Container = styled.a`
    display: flex;
    width: 100%;
    height: 152px;
    background: #FFFFFF;
    border: 1px solid #CBCBCB;
    border-radius: 10px;
    padding: 20px 16px;
    margin-top:20px;
    text-decoration:none;
    cursor:pointer;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;

    @media(max-width: 768px) {
        justify-content: flex-end;
    }
`;

export const Title = styled.span`
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: #333333;

    @media(max-width: 768px) {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
    }
`;