import React from "react";
import * as Tag from "./styles";
import Slider from "react-slick";

const SliderImages = (props) => {
    var settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        touchMove : false,
        responsive: [
            {
                breakpoint: 960,
                settings: {
                    slidesToShow: 1,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    };
    return (
        <Tag.Container width={props.width} height={props.height}>
            <Slider {...settings}>
                {props.images.map((item, index) => 
                    <Tag.BoxImage key={index} width={props.width} height={props.height}>
                        <Tag.Image src={`${process.env.REACT_APP_BASE_URL_UPLOADS}${item?.url}`} />
                    </Tag.BoxImage>
                )}
            </Slider>
        </Tag.Container>
    );
}

export default SliderImages;