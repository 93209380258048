import React from "react";
import * as Tag from "./styles";
import InputMask from "react-input-mask";
import IntlCurrencyInput from "react-intl-currency-input"

const currencyConfig = {
    locale: "pt-BR",
    formats: {
      number: {
        BRL: {
          style: "currency",
          currency: "BRL",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        },
      },
    },
  };

const InputText = (props) => {
    return (
        <Tag.Container {...props} height={props.height} background={props.background} round={props.round}>
            {props.icon && <Tag.Icon src={props.icon} height={props.height} />}
            {props.mask && props.mask !== 'money' && <InputMask {...props} />}
            {!props.mask && <Tag.Input {...props} />}
            {props.mask === 'money' && <IntlCurrencyInput {...props} currency="BRL" config={currencyConfig} />}
            {props.iconRight && <Tag.IconRight src={props.iconRight} height={props.height} />}
        </Tag.Container>
    );
}

export default InputText;