import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    background: #FFFFFF;
    border: 1px solid #CBCBCB;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    padding: 18px 24px;
    width: 100%;
    margin:16px 0;
    position:relative;

    @media(max-width: 768px) {
        
    }
`;

export const BoxTop = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;

    @media(max-width: 768px) {
        align-items: flex-start;
        flex-direction: column;
    }
`;

export const Title = styled.p`
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    display: flex;
    align-items: center;
    color: #333333;

    @media(max-width: 768px) {
        font-size: 16px;
        line-height: 24px;
    }
`;

export const Price = styled.p`
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    display: flex;
    align-items: center;
    color: #333333;
    margin-top:7px;
`;

export const BoxBottom = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
    margin-top:10px;

    @media(max-width: 768px) {
        align-items: flex-start;
        flex-direction: column;
    }
`;

export const Date = styled.p`
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: right;
    color: #333333;

    @media(max-width: 768px) {
        margin-top:10px;
    }
`;