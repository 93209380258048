import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    margin:22px 0;
    width: 100%;
`;

export const BoxIcon = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 65px;
    height: 65px;
    background: rgba(196, 196, 196, 0.4);
    border-radius: 50px;
    margin-right: 21px;

    @media(max-width: 768px) {
        display:none;
    }
`;

export const Icon = styled.img`
    width: 38px;
    height: 38px;
    object-fit:contain;
`;

export const Texts = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    flex:1;

    @media(max-width: 768px) {
        padding-right:20px;
    }
`;

export const Title = styled.p`
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    display: flex;
    align-items: center;
    color: #333333;
    
    @media(max-width: 768px) {
        font-size: 16px;
        line-height: 24px;
    }
`;

export const Local = styled.p`
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #333333;
    
    @media(max-width: 768px) {
        font-size: 14px;
        line-height: 21px;
    }
`;

export const IconClose = styled.img`
    width: 25px;
    height: 25px;
    object-fit:contain;
`;

export const Separator = styled.span`
    width: 6px;
    height: 6px;
    background: #333333;
    margin: 0 20px 0 10px;
    border-radius: 10px;
`;