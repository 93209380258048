import React, { useState, useRef} from "react";
import * as Tag from "./styles";
import ArrowDown from  "../../assets/ico_arrow-down_gray.svg";
import IcoSearch from  "../../assets/ico_search.svg";
import InputText from "../InputText";

const DropDownListSearch = (props, { options, callback }) => {
    const [selected, setSelected] = useState(props.selected ? props.selected : {});
    const [expanded, setExpanded] = useState(false);
    const [focusSearch, setFocusSearch] = useState(false);
    const containerRef = useRef(null);
    const [textSearch, setTextSearch] = useState("");

    function expand() {
        setExpanded(true);
    }

    function close() {
        setTimeout(() => {
            if(!focusSearch){
                setExpanded(false);
            }
        },100)
    }

    function select(item) {
        containerRef.current.blur();
        props.onChange(item);
        setSelected(item);
        setFocusSearch(false);
        setTextSearch("");
    }

    return (
        <Tag.Container ref={containerRef} tabIndex={0} onFocus={expand} onBlur={close} {...props} height={props.height} round={props.round} active={expanded}>
            <Tag.Title>{(selected.id || selected.code) ? (selected.title ? selected.title : selected.name) : props.placeholder}</Tag.Title>
            <Tag.Icon src={ArrowDown} />
            {(expanded || focusSearch) &&
            <Tag.Iems round={props.round}>
                <Tag.Find>
                    <InputText value={textSearch} placeholder="Procure uma categoria" onChange={(event) => setTextSearch(event.target.value)} round={props.round} onFocus={() => setFocusSearch(true)} onBlur={() => setFocusSearch(false)} iconRight={IcoSearch} />
                </Tag.Find>
                {props.items && props.items.filter(item => item.title ? item.title.match(new RegExp(textSearch, "i")) : item.name.match(new RegExp(textSearch, "i"))).map((item, index) =>
                    <Tag.Item key={index} onClick={() => select(item)}>{item.title || item.name}</Tag.Item>
                )}
            </Tag.Iems>
            }
        </Tag.Container>
    );
}

export default DropDownListSearch;