import React, { useState } from "react";
import * as Tag from "./styles";
import RadioBox from "../RadioBox";

const RadioBoxList = (props) => {
   const [active, setActive] = useState(null);
    return (
        <Tag.Container horizontal={props.horizontal}>
            {props.items && props.items.map((item, index) => <RadioBox active={active === item.id} onClick={() => setActive(item.id)} title={item.title} icon={item?.icon} value={item.id} key={index} marginTop={props.marginTop} marginRight={props.marginRight} color={props.color} fontWeight={props.fontWeight} fontSize={props.fontSize} lineHeight={props.lineHeight} />)}
        </Tag.Container>
    );
}

export default RadioBoxList;