
import React from "react";
import * as Tag from "./styles";
import { paymentStatus } from "../../enum/paymentStatus.ts";

const StatusPlan = (props) => {
    return (
        <>
            {props.type === paymentStatus.PENDING && <Tag.Container background="rgba(251, 188, 4, 0.25)" color="#BB8E0A">Pagamento pendente</Tag.Container>}
            {props.type === paymentStatus.PAID && <Tag.Container background="#E3F3D8" color="#2D9248">Pago</Tag.Container>}
            {props.type === paymentStatus.REFUSED && <Tag.Container background="#FFD9D9" color="#AF291E">Negado</Tag.Container>}
            {props.type === paymentStatus.REFOUND && <Tag.Container background="#DBE8FB" color="#0C3B88">Estorno</Tag.Container>}
        </>
        
    );
}

export default StatusPlan;