import styled from "styled-components";

export const Container = styled.div`
    max-width: 100%;
`;

export const Image = styled.img`
    width: 100%;
    object-fit: cover;
    
    @media(max-width: 768px) {
        height: 205px;
    }
`;