import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    position:relative;
    display:inline-block;
`;

export const Text = styled.span`
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color:#0000FF;
    position:absolute;
    top: calc(100% + 15px);
    white-space: nowrap;
`;

export const Range = styled.input`
    width: 100%;
    display:inline-block;
    
    -webkit-appearance: none;
    margin-right: 15px;
    height: 7px;
    background: #E0E0E0;
    border-radius: 5px;
    background-image: linear-gradient(#0000FF, #0000FF);
    background-size: ${props => props.percent ? props.percent : 0}% 100%;
    background-repeat: no-repeat;
      
      /* Input Thumb */
      ::-webkit-slider-thumb {
        -webkit-appearance: none;
        height: 18px;
        width: 18px;
        border-radius: 50%;
        background: #0000FF;
        cursor: pointer;
        box-shadow: 0 0 2px 0 #555;
        transition: background .3s ease-in-out;
      }
      
      ::-moz-range-thumb {
        -webkit-appearance: none;
        height: 18px;
        width: 18px;
        border-radius: 50%;
        background: #0000FF;
        cursor: pointer;
        box-shadow: 0px -1px 10px rgba(0, 0, 0, 0.25);
        transition: background .3s ease-in-out;
      }
      
      ::-ms-thumb {
        -webkit-appearance: none;
        height: 18px;
        width: 18px;
        border-radius: 50%;
        background: #0000FF;
        cursor: pointer;
        box-shadow: 0px -1px 10px rgba(0, 0, 0, 0.25);
        transition: background .3s ease-in-out;
      }
      
      ::-webkit-slider-thumb:hover {
        background: #ffca02;
      }
      
      ::-moz-range-thumb:hover {
        background: #ffca02;
      }
      
      ::-ms-thumb:hover {
        background: #ffca02;
      }
      
      /* Input Track */
      ::-webkit-slider-runnable-track  {
        -webkit-appearance: none;
        box-shadow: none;
        border: none;
        background: transparent;
      }
      
      ::-moz-range-track {
        -webkit-appearance: none;
        box-shadow: none;
        border: none;
        background: transparent;
      }
      
      ::-ms-track {
        -webkit-appearance: none;
        box-shadow: none;
        border: none;
        background: transparent;
      }
    
`;