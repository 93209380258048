import React from "react";
import * as Tag from "./styles";

const Brand = (props) => {
    return (
        <Tag.Container href="#">
            <Tag.BoxImage>
                <Tag.Image src={props.icon} />
            </Tag.BoxImage>
            <Tag.Title>{props.title}</Tag.Title>
        </Tag.Container>
    );
}

export default Brand;