import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction:column;
    background: #FBFBFB;
    border: 1px solid #CBCBCB;
    border-radius: 10px;
    padding: 27px 43px;
    margin-left: 72px;
    width: 100%;
    margin-top: 30px;
    max-width: 400px;

    @media(max-width: 768px) {
        max-width: 100%;
        margin-left: 0;
        padding: 27px 20px;
    }
`;

export const Title = styled.p`
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color:#4F4F4F;
`;

export const Total = styled.p`
    font-weight: 500;
    font-size: 48px;
    line-height: 59px;
    color:#333333;
    margin-top: 12px;
    margin-bottom: 48px;

    @media(max-width: 960px) {
        font-size: 38px;
        line-height: 49px;
    }

    @media(max-width: 768px) {
        font-size: 28px;
        line-height: 39px;
    }
`;

export const Parcelas = styled.span`
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    color:#333333;
    margin-top: 43px;
`;

export const Obs = styled.span`
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color:#333333;
    text-align:center;
    margin-top: 28px;
`;