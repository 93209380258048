import React from "react";
import * as Tag from "./styles";

const RadioBox = (props) => {
    return (
        <Tag.Container onClick={() => props.onClick()} marginTop={props.marginTop} marginRight={props.marginRight}>
            <Tag.Check active={props.active} />
            {props.icon && <Tag.Icon src={props.icon} iconMaxWidth={props.iconMaxWidth} iconMaxHeight={props.iconMaxHeight} />}
            <Tag.Title color={props.color} fontWeight={props.fontWeight} fontSize={props.fontSize} lineHeight={props.lineHeight}>{props.title}</Tag.Title>
        </Tag.Container>
    );
}

export default RadioBox;