import React from "react";
import * as Tag from "./styles";

const Categories = (props) => {
    return (
        <Tag.Container>
            {props.categories.map((item, index) => 
                <Tag.Category key={index}>
                    <Tag.Link href={`/search/?category=${item.slug}`}>
                        <Tag.BoxImage>
                            <Tag.Icon src={`${process.env.REACT_APP_BASE_URL_UPLOADS}${item.image?.url}`} />
                        </Tag.BoxImage>
                        <Tag.Title>{item.name}</Tag.Title>
                    </Tag.Link>
                </Tag.Category>
            )}
        </Tag.Container>
    );
}

export default Categories;