
import React from "react";
import * as Tag from "./styles";
import InputText from "../InputText";
import Logo from "../../assets/logo_azul.svg";
import IcoSearch from "../../assets/ico_search.svg";
import { useNavigate } from "react-router-dom";

const HeaderHelpCenterDetail = (props) => {
    const history = useNavigate();
    return (
        <Tag.Container>
            <Tag.Content>
                <Tag.Top detail={props.detail}>
                    <Tag.Left>
                        <Tag.Logo src={Logo} onClick={() => history('/')} />
                        <Tag.Link>Central de Ajuda</Tag.Link>
                        <Tag.Link>Home</Tag.Link>
                    </Tag.Left>
                    <InputText placeholder="Pesquise sobre algum tema" iconRight={IcoSearch} round={30} width={750} />
                </Tag.Top>
            </Tag.Content>
        </Tag.Container>
    );
}

export default HeaderHelpCenterDetail;