
import React, { useState } from "react";
import * as Tag from "./styles";
import StatusPlan from "../StatusPlan";
import PlanActions from "../PlanActions";

const MyPlan = (props) => {
    const [showActions, setShowActions] = useState(false);
    return (
        <Tag.Container>
            {showActions && <PlanActions onBlur={() => setShowActions(false)} />}
            <Tag.BoxTop>
                <Tag.Title>{props.item.title}</Tag.Title>
                <Tag.Price>{props.item.price}</Tag.Price>
            </Tag.BoxTop>
            <Tag.BoxBottom>
                <StatusPlan type={props.item.status} />
                <Tag.Date>{props.item.date}</Tag.Date>
            </Tag.BoxBottom>
        </Tag.Container>
    );
}

export default MyPlan;