import styled from "styled-components";

export const Container = styled.p`
    min-width: 104px;
    height: 35px;
    background: ${props => props.background ? props.background : '#efefe'};
    border-radius: 66px;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: ${props => props.color ? props.color : '#cccc'};
    padding:10px 20px;
`;