import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: row;
    border: 1px solid #CBCBCB;
    border-radius: 10px;
    padding: 6px 36px 36px;
    width: 100%;

    @media(max-width: 768px) {
        flex-direction: column;
        border:0;
        padding: 6px 0 36px;
    }
`;

export const Form = styled.form`
    width: 100%;
`;

export const Row = styled.div`
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    padding: 30px 0;
`;

export const Label = styled.label`
    width: 100%;
    padding-bottom: 22px;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color:#333333;
`;

export const RowText = styled.div`
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: row;
    margin-top:4px;
`;

export const Text = styled.span`
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color:#4F4F4F
`;