import React, { useEffect, useRef } from "react";
import * as Tag from "./styles";

const ProductActions = (props) => {
    const containerRef = useRef(null);

    useEffect(() => {
        containerRef.current.focus();
    },[containerRef]);

    function focus(){
        containerRef.current.focus();
    }

    return (
        <Tag.Container ref={containerRef} onLoad={focus} tabIndex={0} onBlur={() => props.onBlur()}>
            <Tag.Link onClick={() => props.actionHideAll()}>Ocultar anúncios deste vendedor</Tag.Link>
            <Tag.Link onClick={() => props.actionDenounce()}>Denunciar anúncio</Tag.Link>
            <Tag.Link onClick={() => props.actionDenounceHide()}>Denunciar e ocultar</Tag.Link>
        </Tag.Container>
    );
}

export default ProductActions;