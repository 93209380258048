import React from "react";
import Header from  '../../components/Header';
import Footer from  '../../components/Footer';
import * as Tag  from "./styles";
import Button from "../../components/Button";
import IcoPlus from "../../assets/ico_plus.svg";
import PlanCheckout from "../../components/PlanCheckout";
import PaymentMethodsCheckout from "../../components/PaymentMethodsCheckout";
import CardListCheckout from "../../components/CardListCheckout";

const Checkout = (props) => {
    return (
        <Tag.Container>
            <Header account />
            <Tag.Content>
                <Tag.Sidebar>
                    <PlanCheckout />
                    <PaymentMethodsCheckout />
                </Tag.Sidebar>
                <Tag.Cards>
                    <Tag.Title>Seus cartões</Tag.Title>
                    <Tag.List>
                        <CardListCheckout />
                        <Tag.AddCard><Tag.IconAdd src={IcoPlus} /> Adicionar novo cartão</Tag.AddCard>
                        <Button text="Finalizar compra" width={234} />
                    </Tag.List>
                </Tag.Cards>
            </Tag.Content>
            <Footer />
        </Tag.Container>    
    );
}

export default Checkout;