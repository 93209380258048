import React from "react";
import * as Tag from "./styles";
import Header from  '../../components/Header';
import Footer from  '../../components/Footer';
import Notification from "../../components/Notification";

const MyNotifications = (props) => {
    return (
        <Tag.Container>
            <Header account />
            <Tag.Content>
                <Notification single />
                <Notification single />
                <Notification single />
            </Tag.Content>
            <Footer />
        </Tag.Container>
    );
}

export default MyNotifications;