import React, { useState } from "react";
import * as Tag from "./styles";

const SlideCheck = (props) => {
    const [active, setActive] = useState(props.active);

    function onClick(status){
        setActive(status);
        props.onChange(status);
    }
   
    return (
        <Tag.Container onClick={() => onClick(!active)} marginTop={props.marginTop}>
            <Tag.Check active={active} />
            <Tag.Title>{props.title}</Tag.Title>
        </Tag.Container>
    );
}

export default SlideCheck;