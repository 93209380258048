import React, { useState } from "react";
import Header from  '../../components/Header';
import Footer from  '../../components/Footer';
import Button from  '../../components/Button';
import InputText from  '../../components/InputText';
import IcoEmail from '../../assets/ico_email.svg';
import ImgRecoveryEmailSend from "../../assets/recovery_password_send.svg";
import * as Tag  from "./styles";
import api from "../../services/api";
import SuccessMsg from "../../components/SuccessMsg";
import SuccessSignup from "../../assets/success_signup_big.svg";

const PasswordRecovery = (props) => {
    const [email, SetEmail] = useState("");
    const [errors, setErrors] = useState([]);
    const [success, setSuccess] = useState(false);

    async function recoveryPassword(){
        try {
            await api.post("/user/recovery/password", { email });
            setSuccess(true);
        } catch (err) {
            console.log(err);
            if(err.response.data.errors){
                setErrors(err.response.data.errors);
            }else{
                setErrors([err.response.data.error]);
            }
        }
    }

    async function handleRecovery(e){
        e.preventDefault();
        recoveryPassword();
    };

    return (
        <Tag.Container>
            <Header />
            <Tag.Content>

                {!success && <Tag.Form onSubmit={handleRecovery}>
                    <Tag.Title>Informe seu email e enviaremos um link para resetar a sua senha</Tag.Title>

                    <Tag.Row style={{flexDirection: "row"}}>
                        <InputText icon={IcoEmail} placeholder="E-mail" onChange={e => SetEmail(e.target.value)} value={email} />
                    </Tag.Row>

                    {errors?.length > 0 && <Tag.Row>
                        <Tag.Errors>{errors.map((error) => <>{`- ${error}`}<br/></> )}</Tag.Errors>
                    </Tag.Row>}

                    <Tag.Image src={ImgRecoveryEmailSend} style={{marginTop: 40}} />

                    <Button type="submit" text="Receber email" style={{marginTop: 20}} width={230} />
                </Tag.Form>}

                {success && <SuccessMsg text="Pronto! Enviaemos um link para resetar a sua senha." icon={SuccessSignup} maxWidthImg={281} textButton="Voltar à navegação" />}

            </Tag.Content>
            <Footer />
        </Tag.Container>
    );
}

export default PasswordRecovery;