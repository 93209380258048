import React from "react";
import * as Tag from "./styles";
import Header from  '../../components/Header';
import Footer from  '../../components/Footer';
import MenuSidebar from "../../components/MenuSidebar";
import BoxFormAccount from "../../components/BoxFormAccount";
import NotificationConfig from "../../components/NotificationConfig";
import NotificationConfigEmpty from "../../components/NotificationConfigEmpty";

const Notifications = (props) => {
    return (
        <Tag.Container>
            <Header account />
            <Tag.Content>
                <MenuSidebar active="notifications" />
                <Tag.Form>

                    <Tag.Description>Escolha as notificações que deseja receber e os canais.</Tag.Description>

                    <BoxFormAccount title="Anúncios">
                        <NotificationConfigEmpty />
                        <NotificationConfig title="Status do anúncio" />
                        <NotificationConfig title="Qualidade e dicas" />
                        <NotificationConfig title="Performance e visibilidade" />
                    </BoxFormAccount>

                    <BoxFormAccount title="Conta">
                        <NotificationConfigEmpty />
                        <NotificationConfig title="Denúncias" />
                        <NotificationConfig title="Boas-vindas e novidades" />
                        <NotificationConfig title="Dicas" />
                        <NotificationConfig title="Segurança" />
                        <NotificationConfig title="LGPD" />
                    </BoxFormAccount>

                    <BoxFormAccount title="Mensagens">
                        <NotificationConfigEmpty />
                        <NotificationConfig title="Nova mensagem no chat" />
                        <NotificationConfig title="Lembrete de mensagem no chat" />
                    </BoxFormAccount>

                    <BoxFormAccount title="Plataforma e usuário">
                        <NotificationConfigEmpty />
                        <NotificationConfig title="Novidades na plataforma, campanhas de marketing etc." />
                        <NotificationConfig title="Pesquisas abertas" />
                    </BoxFormAccount>

                    <BoxFormAccount title="Oportunidades">
                        <NotificationConfigEmpty />
                        <NotificationConfig title="Alerta de diminuição de preço" />
                        <NotificationConfig title="Recomendações de produtos para você" />
                        <NotificationConfig title="Novidades sobre nossos serviços" />
                    </BoxFormAccount>

                    <BoxFormAccount title="Planos de pagamento">
                        <NotificationConfigEmpty />
                        <NotificationConfig title="Novidades sobre seu plano (estado, renovação, cancelamento etc)" />
                        <NotificationConfig title="Informações sobre pagamentos" />
                        <NotificationConfig title="Promoções e novidades sobre os planos disponíveis" />
                    </BoxFormAccount>

                </Tag.Form>
            </Tag.Content>
            <Footer />
        </Tag.Container>
    );
}

export default Notifications;