
import React, { useState, useEffect, useRef } from "react";
import * as Tag from "./styles";
import IcoSize from "../../assets/ico_size.svg";
import IcoBed from "../../assets/ico_bed.svg";
import IcoShower from "../../assets/ico_shower.svg";
import IcoGarage from "../../assets/ico_garage.svg";
import IcoPets from "../../assets/ico_pets.svg";


const FeaturesProduct = ({area, quartos, banheiros, vagas, pets}) => {
    const [features, setFeatures] = useState([]);
    const didMount = useRef(true);

    useEffect(() => {
        if(didMount.current){
            didMount.current = false;
            let tempFeatures = [];
            if(area && area != ''){
                tempFeatures.push({
                    icon : IcoSize,
                    title : `Área: ${area} m²`
                });
            }

            if(quartos && quartos != ''){
                tempFeatures.push({
                    icon : IcoBed,
                    title : `Quartos: ${quartos}`
                });
            }

            if(banheiros && banheiros != ''){
                tempFeatures.push({
                    icon : IcoShower,
                    title : `Banheiros: ${banheiros}`
                });
            }

            if(vagas && vagas != ''){
                tempFeatures.push({
                    icon : IcoGarage,
                    title : `Vagas: ${vagas}`
                });
            }

            if(pets && pets != ''){
                tempFeatures.push({
                    icon : IcoPets,
                    title : "Aceita pets"
                });
            }

            setFeatures(tempFeatures);
        }
    })

    return (
        <Tag.Container>
            {features.map((item, index) =>
            <Tag.Item key={index}>
                <Tag.Icon src={item.icon} />
                <Tag.Title>{item.title}</Tag.Title>
            </Tag.Item>
            )}
        </Tag.Container>
    );
}

export default FeaturesProduct;