import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
`;

export const Content = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: row;
    padding: 70px 20px 120px;
    background:#fff;
    width: 100%;
    max-width: 1200px;
    flex: 1;

    @media(max-width: 768px) {
        padding: 0 0;
    }
`;