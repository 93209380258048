import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: center;
    height: auto;
    flex-direction: column;
    padding-right: 30px;

    @media(max-width: 960px) {
        :nth-child(2){
            display:none;
        }
    }

    @media(max-width: 768px) {
        :nth-child(1){
            display:none;
        }
    }
`;

export const Title = styled.p`
    color:#0000FF;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;

    @media(max-width: 560px) {
        margin-top:30px;
        font-size: 24px;
        line-height: 29px;
    }
`;

export const Links = styled.ul`
`;

export const LineLink = styled.li`
    margin-top:12px;
    list-style-type: none;
    list-style-position: inside;
`;

export const Link = styled.a`
    color:#333333;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    text-decoration:none;
`;