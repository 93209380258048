import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    width: 100%;
`;

export const Label = styled.label`
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #333333;
    margin-bottom:5px;
`;

export const Col = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    margin-top:44px;

    @media(max-width: 768px) {
        flex-direction: column;
        a{
            margin-top:10px;
            margin-left:0!important;
        }
    }
`;

export const Row = styled.div`
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    margin-top:32px;
`;

export const Double = styled.div`
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: row;

    > div:first-child{
        margin-right: 10px;
    }

    > div:last-child{
        margin-left: 10px;
    }

    @media(max-width: 768px) {
        flex-direction: column;

        > div:first-child{
            margin-right: 0px;
        }
    
        > div:last-child{
            margin-left: 0px;
        }
    }

`;

export const Obs = styled.label`
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    color: #333333;
    margin-top:8px;
`;