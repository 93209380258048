import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: row;
    width: 100%;
`;

export const Link = styled.a`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    margin:0 10px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #0000FF;
    text-decoration:none;
    cursor:pointer;
`;

export const Icon = styled.img`
    width: 20px;
    height: 20px;
    object-fit:contain;
    margin-right: 5px;
`;