import React, { useState, useEffect } from "react";
import * as Tag from "./styles";
import Header from  '../../components/Header';
import Footer from  '../../components/Footer';
import DropDownList from "../../components/DropDownList";
import InputCalendar from "../../components/InputCalendar";
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import ContactMini from "../../components/ContactMini";
import FotoContact from '../../assets/foto_contact.jpg';
import IcoCalendar from '../../assets/ico_calendar_mini.svg';
import SidebarMyProduct from "../../components/SidebarMyProduct";

const contacts = [
    {name: 'Maria', image: FotoContact},
    {name: 'Maria', image: FotoContact},
    {name: 'Maria', image: FotoContact},
    {name: 'Maria', image: FotoContact}
];

const dataContacts = [
    {
      name: 'Monday',
      contacts: 10,
    },
    {
      name: 'Tuesday',
      contacts: 30,
    },
    {
      name: 'Wednesday',
      contacts: 50,
    },
    {
      name: 'Thursday',
      contacts: 70,
    },
    {
      name: 'Friday',
      contacts: 25,
    },
    {
      name: 'Saturday',
      contacts: 43,
    },
    {
      name: 'Sunday',
      contacts: 60,
    },
];

const MyProduct = (props) => {
    const [width, setWidth] = useState(window.innerWidth);
    const updateDimensions = () => {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    }, []);

    return (
        <Tag.Container>
            <Header account />
            <Tag.Content>
                <Tag.BoxLeft>
                    <Tag.BoxChart>
                        <Tag.Filters>
                            <Tag.Date>
                                <InputCalendar placeholder="Desde" height={34} width={182} iconRight={IcoCalendar} iconSize={16} />
                                <InputCalendar placeholder="Até" height={34} width={182} iconRight={IcoCalendar} iconSize={16} style={{marginLeft: '10px'}} />
                            </Tag.Date>
                            <DropDownList items={[{title : 'Contatos'}]} placeholder="Contatos" height={34} width={width > 960 ? 200 : null} style={{zIndex: 2}} />
                        </Tag.Filters>
                        <ResponsiveContainer width="100%" height={250}>
                            <AreaChart
                                width="100%"
                                height={250}
                                data={dataContacts}
                                margin={{
                                    top: 10,
                                    right: 30,
                                    left: -15,
                                    bottom: 0,
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name" style={{
                                    fontWeight: '400',
                                    fontSize: '12px',
                                    lineHeight: '14px'
                                }} />
                                <YAxis domain={[0, 100]} style={{
                                    fontWeight: '400',
                                    fontSize: '12px',
                                    lineHeight: '14px'
                                }} />
                                <Tooltip />
                                <Area
                                    type="linear"
                                    bandSize={0}
                                    strokeWidth={2}
                                    dataKey="contacts"
                                    stroke="#0E9CFF"
                                    fillOpacity={0.2}
                                    fill="#0E9CFF"
                                    activeDot={{ r: 4, strokeWidth : 2, stroke : "#0E9CFF", fill : "#0E9CFF", fillOpacity: 1 }}
                                    dot={{ r: 4, strokeWidth : 2, stroke : "#0E9CFF", fill : "#fff", fillOpacity: 1  }}
                                />
                            </AreaChart>
                        </ResponsiveContainer>
                    </Tag.BoxChart>

                    <Tag.Title>Interessados</Tag.Title>

                    <Tag.BoxContacts>
                        {contacts.map((item, index) => <ContactMini key={index} item={item} />)}
                    </Tag.BoxContacts>

                </Tag.BoxLeft>
                
                <SidebarMyProduct />

            </Tag.Content>
            <Footer />
        </Tag.Container>
    );
}

export default MyProduct;