import styled from "styled-components";

export const Button = styled.button`
    display: inline-flex;
    align-items: center;
    justify-content: ${props => props.icon ? 'flex-start' : 'center'};
    background: ${props => props.background ? props.background : '#0000FF'};
    border-radius: 30px;
    width: ${props => props.width ? `${props.width}px` : '100%'};
    flex:direction: row;
    height: ${props => props.height ? `${props.height}px` : '48px'};
    padding:0 16px;
    border:0;
    cursor: ${props => props.disabled ? 'default' : 'pointer'};
    outline:none;
    opacity: ${props => props.disabled ? `0.4` : '1'};
`;

export const Link = styled.a`
    display: inline-flex;
    align-items: center;
    justify-content: ${props => props.icon ? 'flex-start' : 'center'};
    background: ${props => props.background ? props.background : '#0000FF'};
    border-radius: 30px;
    width: ${props => props.width ? `${props.width}px` : '100%'};
    flex:direction: row;
    height: ${props => props.height ? `${props.height}px` : '48px'};
    padding:0 16px;
    text-decoration:none;
    cursor: ${props => props.disabled ? 'default' : 'pointer'};
    opacity: ${props => props.disabled ? `0.4` : '1'};
`;

export const Text = styled.span`
    font-size: 16px;
    line-height: 24px;
    color: ${props => props.color ? props.color : '#ffffff'};
    font-weight: 600;
`;

export const Icon = styled.img`
    width: ${props => props.width ? `${props.width}` : '24'}px;
    height: ${props => props.height ? `${props.height}` : '24'}px;
    background-size: contain;
    margin-right: 8px;
`;
