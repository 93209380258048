import React from "react";
import ButtonSmall from "../ButtonSmall";
import * as Tag from "./styles";
import IcoFacebook from "../../assets/ico_facebook_white.svg";
import IcoInstagram from "../../assets/ico_instagram_white.svg";
import IcoTwitter from "../../assets/ico_twitter_white.svg";
import AppStore from "../../assets/app-store.svg";
import GooglePlay from "../../assets/google-play.svg";

const SocialFooter = (props) => {

    return (
        <Tag.Container>
            <Tag.Title style={{marginBottom: '10px'}} >Quer vender? Comece agora!</Tag.Title>
            <ButtonSmall href="/account/ads-type" type="link" width={179} text="Anunciar" background="#0000FF" color="#fff" />

            <Tag.SubTitle>Siga-nos</Tag.SubTitle>
            <Tag.Links>
                <Tag.Link href="#">
                    <Tag.Icon src={IcoFacebook} />
                </Tag.Link>
                <Tag.Link href="#">
                    <Tag.Icon src={IcoInstagram} />
                </Tag.Link>
                <Tag.Link href="#">
                    <Tag.Icon src={IcoTwitter} />
                </Tag.Link>
            </Tag.Links>
            <Tag.SubTitle>Baixe nosso app</Tag.SubTitle>
            <Tag.Links>
                <Tag.LinkStore href="#">
                    <Tag.IconStore src={AppStore} />
                </Tag.LinkStore>
                <Tag.LinkStore href="#">
                    <Tag.IconStore src={GooglePlay} />
                </Tag.LinkStore>
            </Tag.Links>
        </Tag.Container>
    );
}

export default SocialFooter;