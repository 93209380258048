import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    margin-top:14px;
    flex-wrap :wrap;
`;

export const Item = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    margin-top:20px;
    margin-right:42px;
`;

export const Icon = styled.img`
    width: 24px;
    height: 24px;
    margin-right: 10px;
    object-fit: contain;
`;

export const Title = styled.span`
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #333333;

    @media(max-width: 768px) {
        font-size: 14px;
        line-height: 22px;
    }
`;