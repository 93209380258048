import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from  '../../components/Header';
import Footer from  '../../components/Footer';
import Button from  '../../components/Button';
import ButtonOutline from  '../../components/ButtonOutline';
import InputText from  '../../components/InputText';
import SuccessMsgSignup from  '../../components/SuccessMsgSignup';
import api from "../../services/api";
import * as Tag  from "./styles";
import IcoName from '../../assets/ico_name.svg';
import IcoEmail from '../../assets/ico_email.svg';
import IcoPhone from '../../assets/ico_phone.svg';

const NewSeller = (props) => {
    const history = useNavigate();
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [signupSuccess, setSignupSuccess] = useState(false);
    
    const [errors, SetErrors] = useState([]);

    async function handleSignUp(e){
        e.preventDefault();
        setSignupSuccess(true);
        if (!name || !email || !password) {
            SetErrors(["Preencha todos os dados para se cadastrar"]);
        } else {
            try {
                await api.post("/store", { name, email, password });
                history("/");
            } catch (err) {
                console.log(err);
                SetErrors(err.response.data.errors);
            }
        }
    };

    return (
        <Tag.Container>
            <Header />
            <Tag.Content>
                {!signupSuccess && <>
                <Tag.Form onSubmit={handleSignUp}>
                    <Tag.Title>Novo vendedor</Tag.Title>

                    <Tag.Row>
                        <InputText icon={IcoName} placeholder="Nome completo" onChange={e => setName(e.target.value)} />
                    </Tag.Row>

                    <Tag.Row>
                        <InputText icon={IcoEmail} placeholder="E-mail" onChange={e => setEmail(e.target.value)} />
                    </Tag.Row>

                    <Tag.Row>
                        <InputText icon={IcoPhone} placeholder="Celular" onChange={e => setPassword(e.target.value)}  />
                    </Tag.Row>

                    <Tag.Row>
                        <Tag.Obs>Um link será enviado para o email informado para que o vendedor possa finalizar seu cadastro.</Tag.Obs>
                    </Tag.Row>

                    {errors?.length > 0 && <Tag.Row>
                        <Tag.Errors>{errors.map((error) => <>{`- ${error}`}<br/></> )}</Tag.Errors>
                    </Tag.Row>}

                    <Tag.Row>
                        <Button width={230} type="submit" text="Salvar" />
                        <ButtonOutline width={230} text="Cancelar" style={{marginLeft: 20}} />
                    </Tag.Row>

                </Tag.Form>
                </>}

                {signupSuccess && <SuccessMsgSignup />}
                
            </Tag.Content>
            <Footer />
        </Tag.Container>    
    );
}

export default NewSeller;