import React from "react";
import * as Tag from "./styles";
import Business from "../../assets/ico_sidebar_business.svg";
import PurchasePrivate from "../../assets/ico_sidebar_purchase_private.svg";
import Purchase from "../../assets/ico_sidebar_purchase.svg";

const SidebarHelpCenter = (props) => {
    return (
        <Tag.Container>
            <Tag.TitleSidebar>Dúvidas frequentes de</Tag.TitleSidebar>
            <Tag.Link>
                <Tag.Title>Empresas</Tag.Title>
                <Tag.BoxImage>
                    <Tag.Icon src={Business} />
                </Tag.BoxImage>
            </Tag.Link>
            <Tag.Link>
                <Tag.Title>Vendedor particular</Tag.Title>
                <Tag.BoxImage>
                    <Tag.Icon src={PurchasePrivate} />
                </Tag.BoxImage>
            </Tag.Link>
            <Tag.Link>
                <Tag.Title>Comprador</Tag.Title>
                <Tag.BoxImage>
                    <Tag.Icon src={Purchase} />
                </Tag.BoxImage>
            </Tag.Link>
        </Tag.Container>
    );
}

export default SidebarHelpCenter;