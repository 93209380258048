
import React from "react";
import * as Tag from "./styles";
import Saller from "../Saller";

const SallerActive = (props) => {
    return (
        <Tag.Container>
            <Tag.Top>
                <Tag.Title>Vendedores mais ativos</Tag.Title>
                <Tag.Link>Ver todos (35)</Tag.Link>
            </Tag.Top>
            <Tag.Scroll>
                <Saller profile />
                <Saller profile />
                <Saller profile />
                <Saller profile />
                <Saller profile />
            </Tag.Scroll>
        </Tag.Container>
    );
}

export default SallerActive;