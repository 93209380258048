import styled from "styled-components";

export const Container = styled.form`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 100px;
    position:relative;
`;

export const Texts = styled.div`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: #FBFBFB;
    width: 100%;
    height: 100%;
    border: 1px dashed #BDBDBD;
    border-radius: 5px;
    padding:20px;
    background: ${props => props.dragActive ? '#f1fbff' : '#FFFFFF'};

    @media(max-width: 768px) {
        align-items: flex-start;
    }
`;

export const Text = styled.span`
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #333333;
    margin:3px 0;

    @media(max-width: 768px) {
        margin-left: ${props => props.left ? '35' : '0'}px;
    }
`;

export const Icon = styled.img`
    width: 24px;
    height: 24px;
    background-size:contain;
    margin-right:15px;

    @media(max-width: 768px) {
        width: 20px;
        height: 20px;
    }
`;

export const InputFile = styled.input`
    display:none;
`;

export const DragFileElement = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
`;