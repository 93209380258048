
import React from "react";
import * as Tag from "./styles";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { Bars } from  'react-loader-spinner';

const LoaderFullScreen = (props) => {
    return (
        <Tag.Container>
            <Bars
                height="50"
                width="50"
                color='#0000FF'
                ariaLabel='loading'
            />
        </Tag.Container>
    );
}

export default LoaderFullScreen;