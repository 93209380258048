import React from "react";
import Header from  '../../components/Header';
import Footer from  '../../components/Footer';
import LinkButton from  '../../components/LinkButton';
import ImgRecoveryEmail from "../../assets/recovery_email.svg";
import ImgRecoveryPassword from "../../assets/recovery_password.svg";
import * as Tag  from "./styles";

const AccountRecovery = (props) => {
    return (
        <Tag.Container>
            <Header />
            <Tag.Content>
                <Tag.Title>Qual informação quer recuperar?</Tag.Title>

                <Tag.Row style={{flexDirection: "row"}}>
                    <Tag.ButtonType href="/email-recovery">
                        <Tag.Image src={ImgRecoveryEmail} />
                        <Tag.Text>Email</Tag.Text>
                    </Tag.ButtonType>
                    <Tag.ButtonType href="/password-recovery">
                        <Tag.Image src={ImgRecoveryPassword} />
                        <Tag.Text>Senha</Tag.Text>
                    </Tag.ButtonType>
                </Tag.Row>

                <LinkButton href="/signin" text="Voltar a login" style={{marginTop: 40}} />
            </Tag.Content>
            <Footer />
        </Tag.Container>
    );
}

export default AccountRecovery;