
import React, { useState } from "react";
import * as Tag from "./styles";
import Popup from "../Popup";
import Button from "../Button";
import LinkButton from "../LinkButton";
import DropDownList from "../DropDownList";
import InputSliderRange from "../InputSliderRange";
import orderBy from "../../constants/ordersby";
import estados from "../../constants/estados";
import tipoVendedor from "../../constants/tipoVendedor";
import CheckBoxList from "../CheckBoxList";
import IcoLeft from "../../assets/ico_arrow_left_blue.svg";

const itemsCheckbox = [
    {
        id : "Mecânica",
        title: "Mecânica"
    },
    {
        id : "Mecânico - carro elétrico",
        title: "Mecânico - carro elétrico"
    },
    {
        id : "Eletricista",
        title: "Eletricista"
    },
    {
        id : "Injeção eletromica",
        title: "Injeção eletromica"
    },
    {
        id : "Pintura",
        title: "Pintura"
    },
    {
        id : "Lanternagem",
        title: "Lanternagem"
    },
    {
        id : "Borracharia",
        title: "Borracharia"
    }
];

const PopUpFilterMobile = (props) => {
    const [advancedFilters, setAdvancedFilters] = useState(false);
    return (
        <Popup title="" maxWidth={532} smallTitle noCloseBlur paddingTop={10} paddingLeft={20} paddingRight={20} paddingBottom={20}>
            <Tag.Container>

                {advancedFilters &&
                <Tag.Top onClick={() => setAdvancedFilters(false)}>
                    <Tag.IconBack src={IcoLeft} />
                    <Tag.Title>Filtros avançados</Tag.Title>
                </Tag.Top>}

                {!advancedFilters && <>
                    <Tag.Row>
                        <DropDownList items={orderBy} placeholder={"Ordenar por"} style={{zIndex: "6"}} />
                    </Tag.Row>
                    <Tag.Row>
                        <DropDownList items={orderBy} placeholder="Localização" style={{zIndex: "5"}} />
                    </Tag.Row>
                    <Tag.Row>
                        <DropDownList items={estados} placeholder="Estado" style={{zIndex: "4"}} />
                    </Tag.Row>
                    <Tag.Row>
                        <DropDownList items={tipoVendedor} placeholder="Tipo de vendedor" style={{zIndex: "3"}} />
                    </Tag.Row>
                    <Tag.Row>
                        <DropDownList items={orderBy} placeholder="Categoria" style={{zIndex: "2"}} />
                        <Tag.Link onClick={() => setAdvancedFilters(true)}>Filtros vançados</Tag.Link>
                    </Tag.Row>
                    <Tag.Row style={{marginBottom: "30px"}}>
                        <Tag.Label>Preço</Tag.Label>
                        <InputSliderRange step={1} startLeft={50} startRight={950} min={1} max={1000} width="100%" toltipLeft="R$ " minRange={200} />
                    </Tag.Row>
                </>
                }

                {advancedFilters && <>
                    <Tag.Row>
                        <DropDownList items={orderBy} placeholder="Categoria" style={{zIndex: "2"}} />
                    </Tag.Row>
                    <Tag.Row>
                        <DropDownList items={orderBy} placeholder="Subcategoria" style={{zIndex: "1"}} />
                    </Tag.Row>
                    <Tag.Row>
                        <CheckBoxList items={itemsCheckbox} />
                    </Tag.Row>
                </>
                }

                <Button text="Aplicar" onClick={() => props.onFilter()} width={150} height={40} style={{marginTop: '20px'}} />
                <LinkButton text="Cancelar" onClick={() => props.setFiltersMobile()} width={150} height={40} color="#4F4F4F" style={{marginTop: '8px'}} />
            </Tag.Container>
        </Popup>
    );
}

export default PopUpFilterMobile;