
import React from "react";
import * as Tag from "./styles";
import Popup from "../Popup";
import Button from "../Button";
import LinkButton from "../LinkButton";
import IcoError from "../../assets/ico_error.svg";

const PopupDeleteAccount = (props) => {
    return (
        <Popup noCloseBlur maxWidth={550} onBlur={() => props.onCancel(false)}>
            <Tag.Icon src={IcoError} />
            <Tag.Title>Deseja eliminar sua conta?</Tag.Title>
            <Tag.Description>Apesar de você poder reativar sua conta posteriormente, todos os seus anúncios atuais serão excluidos permanentemente.</Tag.Description>
            <Tag.Buttons>
                <LinkButton text="Cancelar" width={145} onClick={() => props.onCancel(false)} color="#333333" />
                <Button text="Eliminar" width={145} onClick={() => props.onDelete()} />
            </Tag.Buttons>
        </Popup>
    );
}

export default PopupDeleteAccount;