
import React, { useState, useEffect, useRef } from "react";
import estados from "../../constants/estados";
import tipoVendedor from "../../constants/tipoVendedor";
import * as Tag from "./styles";

const TableStatesProduct = (props) => {
    const [items, setItems] = useState([])
    const didMount = useRef(true);

    function getItems(){
        let itemsTemp = []
        if(props.moreInfos && props.moreInfos.state_type){
            itemsTemp = [...itemsTemp, {
                name : 'Estado',
                description : estados.find(item => item.id === props.moreInfos.state_type).title
            }];
        }
        if(props.moreInfos && props.moreInfos.seller_type){
            itemsTemp = [...itemsTemp, {
                name : 'Tipo de anunciante',
                description : tipoVendedor.find(item => item.id === props.moreInfos.seller_type).title
            }];
        }
        if(props.moreInfos && props.moreInfos.has_condominio){
            itemsTemp = [...itemsTemp, {
                name : 'Condomínio',
                description : props.moreInfos.has_condominio === 1 ? 'Em condomínio' : 'Sem condomínio'
            }];
        }

        props.states.map((item, index) => {
            itemsTemp = [...itemsTemp, {
                name : item.name,
                description :props.sub_filters.filter(sub => sub.other_filter_id == item.id).map(sub => sub.name).join(', ')
            }];
        })

        setItems(itemsTemp);
    }

    useEffect(() => {
        if(didMount.current){
            didMount.current = false;
            getItems();
        }
    });

    return (
        <Tag.Container>

            {/* {props.moreInfos && props.moreInfos.state_type &&
                <Tag.Item even={props.states.length - 3 % 2}>
                    <Tag.Name>Estado</Tag.Name>
                    <Tag.Description>{estados.find(item => item.id === props.moreInfos.state_type).title}</Tag.Description>
                </Tag.Item>
            }
            {props.moreInfos && props.moreInfos.seller_type &&
                <Tag.Item even={props.states.length - 2 % 2}>
                    <Tag.Name>Tipo de anunciante</Tag.Name>
                    <Tag.Description>{tipoVendedor.find(item => item.id === props.moreInfos.seller_type).title}</Tag.Description>
                </Tag.Item>
            }
            {props.moreInfos && props.moreInfos.has_condominio &&
                <Tag.Item even={props.states.length - 1 % 2}>
                    <Tag.Name>Condomínio</Tag.Name>
                    <Tag.Description>{props.moreInfos.has_condominio === 1 ? 'Em condomínio' : 'Sem condomínio'}</Tag.Description>
                </Tag.Item>
            } */}

            {items.map((item, index) =>
            <Tag.Item even={index % 2} key={index}>
                <Tag.Name>{item.name}</Tag.Name>
                <Tag.Description>{item.description}</Tag.Description>
            </Tag.Item>
            )}


            {/* {props.states.map((item, index) =>
            <Tag.Item even={index % 2} key={index}>
                <Tag.Name>{item.name}</Tag.Name>
                <Tag.Description>{props.sub_filters.filter(sub => sub.other_filter_id == item.id).map(sub => sub.name).join(', ')}</Tag.Description>
            </Tag.Item>
            )} */}
        </Tag.Container>
    );
}

export default TableStatesProduct;