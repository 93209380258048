
import React from "react";
import * as Tag from "./styles";
import IcoUser from "../../assets/ico_name.svg";
import IcoLock from "../../assets/ico_lock.svg";
import IcoMoney from "../../assets/ico_dollar.svg";
import IcoNotifications from "../../assets/ico_bell.svg";

const MenuSidebar = (props) => {
    return (
        <Tag.Container>
            <Tag.Menu href="/account/my-cadastre" active={props.active === "my-cadastre"}>
                <Tag.Icon src={IcoUser} />
                <Tag.Title>Meu cadastro</Tag.Title>
            </Tag.Menu>
            <Tag.Menu href="/account/privacy-and-security" active={props.active === "privacy-and-security"}>
                <Tag.Icon src={IcoLock} />
                <Tag.Title>Privacidade e segurança</Tag.Title>
            </Tag.Menu>
            <Tag.Menu href="/account/financial" active={props.active === "financial"}>
                <Tag.Icon src={IcoMoney} />
                <Tag.Title>Financeiro</Tag.Title>
            </Tag.Menu>
            <Tag.Menu href="/account/notifications" active={props.active === "notifications"}>
                <Tag.Icon src={IcoNotifications} />
                <Tag.Title>Notificações</Tag.Title>
            </Tag.Menu>
        </Tag.Container>
    );
}

export default MenuSidebar;