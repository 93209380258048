import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    border: 1px solid #CBCBCB;
    border-radius: 5px;
    padding: 20px;
    display:flex;
    flex-direction:row;
    align-items: center;
    justify-content: flex-start;
    
    :first-child{
        margin-right: 12px;
    }
    :last-child{
        margin-left: 12px;
    }

    @media(max-width: 960px) {
        :first-child{
            margin-right: 0;
        }
        :last-child{
            margin-top: 20px;
            margin-left: 0;
        }
    }
`;

export const Link = styled.a`
`;

export const BoxImg = styled.div`
    background: #f9f9f9;
    width: 72px;
    height: 72px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    margin-right: 24px;

    @media(max-width: 768px) {
        width: 40px;
        height: 40px;
        margin-right: 20px;
    }
`;

export const Icon = styled.img`
    width: 54px;
    height: 54px;
    object-fit:contain;

    @media(max-width: 768px) {
        width: 28px;
        height: 28px;
    }
`;

export const Texts = styled.div`
    width: 100%;
`;

export const Row = styled.div`
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    margin-top: 10px;
    width: 100%;
`;

export const Title = styled.a`
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    color:#333333;
    text-decoration:none;
    cursor:pointer;
    
    @media(max-width: 768px) {
        font-size: 18px;
        line-height: 27px;
    }
`;

export const Subtitle = styled.a`
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color:#333333;
    text-decoration:none;
    cursor:pointer;
    
    @media(max-width: 768px) {
        font-size: 14px;
        line-height: 21px;
    }
`;

export const Separator = styled.span`
    width: 1px;
    height: 20px;
    background: #CBCBCB;
    margin: 0 15px;
`;