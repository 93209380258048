
import React from "react";
import * as Tag from "./styles";
import Button from "../Button";
import BannerSimulatorProduct from "../../assets/banner_simulator_product.svg";

const BoxSimulatorProduct = (props) => {
    return (
        <Tag.Container>
            <Tag.Texts>
                <Tag.Title>Simule um emprestimo ou financiamento!</Tag.Title>
                <Button text="Simular" width={190} style={{marginTop: '33px'}} />
            </Tag.Texts>
            <Tag.Image src={BannerSimulatorProduct} />
        </Tag.Container>
    );
}

export default BoxSimulatorProduct;