import React, { useState } from "react";
import Header from  '../../components/Header';
import Footer from  '../../components/Footer';
import Button from  '../../components/Button';
import ButtonOutline from  '../../components/ButtonOutline';
import LinkButton from  '../../components/LinkButton';
import InputText from  '../../components/InputText';
import InputPassword from  '../../components/InputPassword';
import SuccessMsgSignup from  '../../components/SuccessMsgSignup';
import api from "../../services/api";
import * as Tag  from "./styles";
import IcoName from '../../assets/ico_name.svg';
import IcoEmail from '../../assets/ico_email.svg';
import IcoPassword from '../../assets/ico_lock.svg';
import IcoCnpj from '../../assets/ico_briefcase_gray.svg';
import LoaderFullScreen from "../../components/LoaderFullScreen";

const SignUp = (props) => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [cpf, setCpf] = useState("");
    const [password, setPassword] = useState("");
    const [passwordConfirm, setPasswordConfirm] = useState("");
    const [signupSuccess, setSignupSuccess] = useState(false);
    const [errors, setErrors] = useState([]);
    const [loader, setLoader] = useState(false);

    async function handleSignUp(e){
        e.preventDefault();
        if (!name || !email || !password || !cpf) {
            setErrors(["Preencha todos os dados para se cadastrar"]);
        } else {
            if(passwordConfirm !== password){
                setErrors(["As senhas digitadas não conferem"]);
                return;
            }
            try {
                setLoader(true);
                const result = await api.post("/user/store", { name, cnpj_cpf : cpf, email, type : "juridica", password });
                setLoader(false);
                console.log(result);
                setErrors([]);
                setSignupSuccess(true);
            } catch (err) {
                console.log(err);
                if(err.response.data.errors){
                    setErrors(err.response.data.errors);
                }else{
                    setErrors([err.response.data.error]);
                }
            }finally{
                setLoader(false);
            }
        }
    };

    return (
        <Tag.Container>
            <Header />
            <Tag.Content>

                {loader && <LoaderFullScreen />}

                {!signupSuccess && <>
                <Tag.Title>Cadastre-se</Tag.Title>
                <Tag.Form onSubmit={handleSignUp}>
                    <Tag.Row>
                        <InputText icon={IcoName} placeholder="Nome" onChange={e => setName(e.target.value)} value={name} />
                    </Tag.Row>

                    <Tag.Row>
                        <InputText mask="999.999.999-99" icon={IcoCnpj} placeholder="CPF" onChange={e => setCpf(e.target.value)} value={cpf} />
                    </Tag.Row>

                    <Tag.Row>
                        <InputText icon={IcoEmail} placeholder="E-mail" onChange={e => setEmail(e.target.value)} value={email} />
                    </Tag.Row>

                    <Tag.Row>
                        <InputPassword icon={IcoPassword} placeholder="Senha" onChange={e => setPassword(e.target.value)} togglePassword value={password} />
                    </Tag.Row>

                    <Tag.Row>
                        <InputPassword icon={IcoPassword} placeholder="Confirmar senha" onChange={e => setPasswordConfirm(e.target.value)} togglePassword value={passwordConfirm} />
                    </Tag.Row>

                    {errors?.length > 0 && <Tag.Row>
                        <Tag.Errors>{errors.map((error) => <>{`- ${error}`}<br/></> )}</Tag.Errors>
                    </Tag.Row>}

                    <Tag.Row style={{textAlign: "center"}}>
                        <Button width={230} type="submit" text="Cadastrar" />
                        <ButtonOutline width={230} type="link" href="/signin" text="Fazer login" style={{marginTop: 20}} />
                        <LinkButton href="/signup-company" text="Criar conta empresarial" style={{marginTop: 20}} />
                    </Tag.Row>
                </Tag.Form>
                </>}

                {signupSuccess && <SuccessMsgSignup />}
                
            </Tag.Content>
            <Footer />
        </Tag.Container>    
    );
}

export default SignUp;