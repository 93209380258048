const estados = [
    {
        id : "novo",
        title : "Novo"
    },
    {
        id : "seminovo",
        title : "Seminovo"
    },
    {
        id : "usado",
        title : "Usado"
    }
]

export default estados;