
import React, { useState } from "react";
import * as Tag from "./styles";
import IcoMore from "../../assets/ico_more_vertical.svg";
import IcoDown from "../../assets/ico_arrow_down_gray.svg";
import IcoUp from "../../assets/ico_arrow_up_gray.svg";
import IcoAlert from "../../assets/ico_alert.svg";
import StatusPlan from "../StatusPlan";
import { paymentStatus } from "../../enum/paymentStatus.ts";
import PlanActions from "../PlanActions";

const MyPlan = (props) => {
    const [showDetails, setShowDetail] = useState(false);
    const [showActions, setShowActions] = useState(false);
    return (
        <Tag.Container>
            {showActions && <PlanActions onBlur={() => setShowActions(false)} />}
            <Tag.BoxTop>
                <Tag.BoxTitle>
                    <Tag.Title>{props.item.title}</Tag.Title>
                    <Tag.Date>Vence em {props.item.expiration}</Tag.Date>
                </Tag.BoxTitle>
                <Tag.Icon src={IcoMore} onClick={() => setShowActions(true)} />
            </Tag.BoxTop>
            <Tag.Price>{props.item.price}</Tag.Price>
            <Tag.BoxBottom msg={props.item.status === paymentStatus.PENDING}>
                <Tag.Texts>
                    <Tag.TextDetail onClick={() => setShowDetail(!showDetails)}>Detalhes <Tag.IconDetail src={showDetails ? IcoUp : IcoDown} /></Tag.TextDetail>
                    <Tag.Details open={showDetails}>
                        {props.item.items.map((item, index) => <Tag.Description key={index}>{item}</Tag.Description>)}
                    </Tag.Details>
                </Tag.Texts>
                <Tag.Payment msg={props.item.status === paymentStatus.PENDING}>
                    {props.item.status === paymentStatus.PENDING && <Tag.Alert><Tag.IconAlert src={IcoAlert} /> Realize o pagamento para ativar seus beneficios</Tag.Alert>}
                    <StatusPlan type={props.item.status} />
                </Tag.Payment>
            </Tag.BoxBottom>
        </Tag.Container>
    );
}

export default MyPlan;