import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    width: 100%;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    max-width: 400px;
    margin-left:30px;

    @media(max-width: 768px) {
        margin-left:0;
        margin-top:48px;
    }
`;

export const Link = styled.a`
    display: flex;
    width: 100%;
    height: 100px;
    background: #FFFFFF;
    border: 1px solid #CBCBCB;
    border-radius: 10px;
    margin-top:20px;
    text-decoration:none;
    cursor:pointer;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: row;

    @media(max-width: 768px) {
        height: 80px;
        align-items: center;
    }
`;

export const Title = styled.span`
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    padding: 20px 16px;
    color: #333333;
    flex: 1;

    @media(max-width: 768px) {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
    }
`;


export const TitleSidebar = styled.span`
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: #333333;
    flex: 1;
`;

export const BoxImage = styled.span`
    display: flex;
    height: 100%;
    width: 100px;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
    margin-right:30px;

    @media(max-width: 768px) {
        display:none;
    }
`;

export const Icon = styled.img`
    max-width: 100%;
    max-height: 100%;
`;