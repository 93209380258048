import styled from "styled-components";
import BgLeft from "../../assets/background_left_help_center.svg";
import BgRight from "../../assets/background_right_help_center.svg";

export const Container = styled.div`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: auto;
    background:#0000FF;
    width: 100%;
    background: linear-gradient(180deg, #FAFAFA 87.41%, #E7E7E7 120.8%);
`;

export const Content = styled.div`
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    height: auto;
    padding: 25px 60px 0;
    width: 100%;
    max-width: 1440px;
    flex-direction: column;
    position:relative;
    min-height: 340px;

    :after{
        content:"";
        width: 23px;
        height: 35px;
        position:absolute;
        z-index: 1;
        left:100px;
        bottom:0;
        background:url(${BgLeft}) no-repeat 50% 100%;
    }
    :before{
        content:"";
        width: 38px;
        height: 83px;
        position:absolute;
        z-index: 1;
        right:0;
        bottom:0;
        background:url(${BgRight}) no-repeat 50% 100%;
    }

    @media(max-width: 768px) {
        :after{
            left:10px;
        }
        :before{
            right:10px;
        }
    }

    @media(max-width: 1280px) {
        padding: 12px 15px 0;
    }
`;

export const Top = styled.div`
    display:flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction:row;
    width: 100%;
    position:relative;
    z-index: 2;
`;

export const Logo = styled.img`
    width: 125px;
    height: 50px;
    object-fit: contain;
    cursor:pointer;
`;

export const Link = styled.a`
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: #666666;
    margin-left:64px;
    text-decoration:none;
    cursor:pointer;

    @media(max-width: 768px) {
        margin-left:40px;
    }
`;

export const Search = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    flex-direction:row;
    margin-top:84px;
    position:relative;
    z-index: 2;

    @media(max-width: 768px) {
        align-items: center;
        justify-content: center;
        flex-direction:column;
    }
`;

export const FieldSearch = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction:column;
    max-width: 715px;
    flex: 1;
    width: 100%;
`;

export const Title = styled.h2`
    font-weight: 500;
    font-size: 36px;
    line-height: 44px;
    color: #333333;
    margin-bottom:28px;

    @media(max-width: 768px) {
        font-size: 24px;
        line-height: 29px;
        text-align:center;
        width: 100%;
    }
`;

export const Image = styled.img`
    width: 265px;
    height: 249px;
    margin:0 40px;

    @media(max-width: 768px) {
        margin-left:80px;
        margin-top:20px;
    }
`;