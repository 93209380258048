import styled from "styled-components";

export const Container = styled.div`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    margin-right: 10px;
    margin-top: ${props => props.marginTop ? props.marginTop : '8'}px;
    cursor:pointer;
`;

export const Check = styled.span`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 34px;
    height: 18px;
    background: ${props => props.active ? "#0000FF" : "#BDBDBD"};
    border-radius: 16px;
    margin-right: 10px;
    position:relative;
    transition: all 250ms ease-in-out;

    :after{
        content: "";
        background-color: #FFFFFF;
        width: 12px;
        height: 12px;
        border-radius: 50px;
        position:absolute;
        left: ${props => props.active ? "18" : "4"}px;
        top:3px;
        transition: all 250ms ease-in-out;
    }
`;

export const Title = styled.span`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #333333;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    flex: 1;
`;

