
import React from "react";
import * as Tag from "./styles";
import IcoCheck from '../../assets/ico_check_white.svg';
import IcoChat from '../../assets/ico_chat_blue.svg';

const ContactMini = (props) => {
   
    return (
        <Tag.Container>
            <Tag.Top>
                <Tag.Image src={props.item.image} />
                <Tag.Name>{props.item.name} <Tag.Check>
                    <Tag.Icon src={IcoCheck} width={8} height={8}/>
                </Tag.Check></Tag.Name>
                
            </Tag.Top>
            <Tag.Link><Tag.Icon src={IcoChat} marginRight={5} />Chat</Tag.Link>
        </Tag.Container>
    );
}

export default ContactMini;