
import React, { useState } from "react";
import * as Tag from "./styles";
import { CropString } from "../../lib/Util";

const Answers = (props) => {
    const [readyMore, setReadyMore] = useState(false);
    return props.item && (
        <Tag.Answers>
            <Tag.NameAnswers>{props.item.name}</Tag.NameAnswers>
            <Tag.DescriptionAnswers>{!readyMore ? CropString(props.item.comment, 100, '...') : props.item.comment} {!readyMore && <Tag.ReadyMore onClick={() => setReadyMore(true)}>Ler Mais</Tag.ReadyMore>}</Tag.DescriptionAnswers>
        </Tag.Answers>
    );
}

export default Answers;