import React, { useState } from "react";
import * as Tag from "./styles";
import Header from  '../../components/Header';
import Footer from  '../../components/Footer';
import MenuSidebar from "../../components/MenuSidebar";
import BoxFormAccount from "../../components/BoxFormAccount";
import IconDownload from "../../assets/ico_download.svg";
import AccessLog from "../../components/AccessLog";
import PopupChangePassword from "../../components/PopupChangePassword";
import PopupSuccessMsg from "../../components/PopupSuccessMsg";
import ImgSuccessPsswordRecovery from '../../assets/success_password_recovery.svg';

const logs = [
    {
        plataform: "Computador com Windows - Chrome",
        local: "Curitiba, PR - Brazil",
        date: "há 1 hora",
        type : "desktop"
    },
    {
        plataform: "Computador com Windows - Chrome",
        local: "Curitiba, PR - Brazil",
        date: "há 1 hora",
        type : "desktop"
    },
    {
        plataform: "Computador com Windows - Chrome",
        local: "Curitiba, PR - Brazil",
        date: "há 1 hora",
        type : "desktop"
    },
    {
        plataform: "Moto E6 plus - App",
        local: "Curitiba, PR - Brazil",
        date: "há 1 hora",
        type : "mobile"
    }
]

const PrivacyAndSecurity = (props) => {
    const [changePassword , setChangePassword] = useState(false);
    const [changePasswordOk , setChangePasswordOk] = useState(false);

    return (
        <Tag.Container>
            <Header account />
            <Tag.Content>
                <MenuSidebar active="privacy-and-security" />
                <Tag.Form>

                    {changePassword && <PopupChangePassword onCancel={() => setChangePassword(false)} onSave={() => {setChangePassword(false); setChangePasswordOk(true);}} />}
                    {changePasswordOk && <PopupSuccessMsg textButton="Ok" text="Pronto! Sua senha foi atualizada com sucesso." icon={ImgSuccessPsswordRecovery} onOk={() => setChangePasswordOk(false)}/>}

                    <BoxFormAccount active={true} >
                        <Tag.TitleBox>
                            <Tag.Title>Senha</Tag.Title>
                        </Tag.TitleBox>
                        <Tag.Row>
                            <Tag.Password>***********</Tag.Password>
                            <Tag.Link onClick={() => setChangePassword(true)}>Trocar senha</Tag.Link>
                        </Tag.Row>
                    </BoxFormAccount>

                    <BoxFormAccount active={true}>
                        <Tag.TitleBox>
                            <Tag.Title>Histórico de conexões</Tag.Title>
                            <Tag.Link>Encerrar todas as sessões</Tag.Link>
                        </Tag.TitleBox>

                        <Tag.MsgAlert>Se você não reconhece uma conexão, recomendamos <span>encerrar todas as sessões e trocar sua senha</span></Tag.MsgAlert>

                        {logs.map((item,index) => <AccessLog key={index} item={item} />)}

                    </BoxFormAccount>
                    
                    <BoxFormAccount active={true} >
                        <Tag.Download><Tag.IconDownload src={IconDownload} />Baixar meus dados pessoais</Tag.Download>
                    </BoxFormAccount>

                </Tag.Form>
            </Tag.Content>
            <Footer />
        </Tag.Container>
    );
}

export default PrivacyAndSecurity;