import React from "react";
import * as Tag from "./styles";
import IcoGoogle from "../../assets/ico_google-gray.svg";
import IcoApple from "../../assets/ico_apple-gray.svg";

const LinkApps = (props) => {
    return (
        <Tag.Container>
            <Tag.Link href="#">Central de ajuda</Tag.Link>
            <Tag.Links>
                <Tag.Text>Baixe o app</Tag.Text>
                <Tag.Link href="#">
                    <Tag.Icon src={IcoGoogle} />
                </Tag.Link>
                <Tag.Link href="#">
                    <Tag.Icon src={IcoApple} />
                </Tag.Link>
            </Tag.Links>
        </Tag.Container>
    );
}

export default LinkApps;