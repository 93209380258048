import React, { useState } from "react";
import Header from  '../../components/Header';
import Footer from  '../../components/Footer';
import Button from  '../../components/Button';
import ButtonOutline from  '../../components/ButtonOutline';
import LinkButton from  '../../components/LinkButton';
import InputText from  '../../components/InputText';
import InputPassword from  '../../components/InputPassword';
import SuccessMsgSignup from  '../../components/SuccessMsgSignup';
import api from "../../services/api";
import * as Tag  from "./styles";
import IcoName from '../../assets/ico_name.svg';
import IcoEmail from '../../assets/ico_email.svg';
import IcoCnpj from '../../assets/ico_briefcase_gray.svg';
import IcoPassword from '../../assets/ico_lock.svg';

const SignUpCompany = (props) => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [cnpj, setCnpj] = useState("");
    const [password, setPassword] = useState("");
    const [passwordConfirm, setPasswordConfirm] = useState("");
    const [signupSuccess, setSignupSuccess] = useState(false);
    const [errors, setErrors] = useState([]);

    async function handleSignUp(e){
        e.preventDefault();
        if (!name || !email || !password || !cnpj) {
            setErrors(["Preencha todos os dados para se cadastrar"]);
        } else {
            if(passwordConfirm !== password){
                setErrors(["As senhas digitadas não conferem"]);
                return;
            }
            try {
                const result = await api.post("/user/store", { name, cnpj_cpf : cnpj, email, type : "juridica", password });
                console.log(result);
                setErrors([]);
                setSignupSuccess(true);
            } catch (err) {
                console.log(err);
                if(err.response.data.errors){
                    setErrors(err.response.data.errors);
                }else{
                    setErrors([err.response.data.error]);
                }
            }
        }
    };

    return (
        <Tag.Container>
            <Header />
            <Tag.Content>

                {!signupSuccess && <>
                <Tag.Title>Cadastre seu negócio</Tag.Title>
                <Tag.Form onSubmit={handleSignUp}>

                    <Tag.Row>
                        <InputText icon={IcoName} placeholder="Nome da empresa" onChange={e => setName(e.target.value)} value={name} />
                    </Tag.Row>

                    <Tag.Row>
                        <InputText icon={IcoCnpj} mask="99.999.999/9999-99" placeholder="CNPJ" onChange={e => setCnpj(e.target.value)} value={cnpj} />
                    </Tag.Row>

                    <Tag.Row>
                        <InputText icon={IcoEmail} placeholder="Email" onChange={e => setEmail(e.target.value)} value={email} />
                    </Tag.Row>

                    <Tag.Row>
                        <InputPassword icon={IcoPassword} placeholder="Senha" onChange={e => setPassword(e.target.value)} value={password} togglePassword />
                    </Tag.Row>

                    <Tag.Row>
                        <InputPassword icon={IcoPassword} placeholder="Confirme a senha" onChange={e => setPasswordConfirm(e.target.value)} value={passwordConfirm} togglePassword />
                    </Tag.Row>

                    {errors?.length > 0 && <Tag.Row>
                        <Tag.Errors>{errors.map((error) => <>{`- ${error}`}<br/></> )}</Tag.Errors>
                    </Tag.Row>}

                    <Tag.Row style={{textAlign: "center"}}>
                        <Button width={230} type="submit" text="Cadastrar" />
                        <ButtonOutline width={230} type="link" href="/signin" text="Fazer login" style={{marginTop: 20}} />
                        <LinkButton href="/signup" text="Criar conta pessoal" style={{marginTop: 20}} />
                    </Tag.Row>

                </Tag.Form>
                </>}

                {signupSuccess && <SuccessMsgSignup />}

            </Tag.Content>
            <Footer />
        </Tag.Container>    
    );
}

export default SignUpCompany;