import styled from "styled-components";

export const Container = styled.div`
    width: 152px;
    height: auto;
    padding: 5px 0;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    position:absolute;
    right:20px;
    top:0;
    z-index:2;
`;

export const Link = styled.a`
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color:#333333;
    width: 100%;
    padding: 10px 16px;
    display: inline-block;
    text-decoration:none;
    cursor:pointer;
`;