import React from "react";
import Header from  '../../components/Header';
import Footer from  '../../components/Footer';
import ImgVeiculo from "../../assets/ico_veiculo.svg";
import ImgImovel from "../../assets/icon_imovel.svg";
import ImgOutras from "../../assets/icon_outras_categorias.svg";

import * as Tag  from "./styles";
import ButtonOutline from "../../components/ButtonOutline";

const AdsType = (props) => {
    return (
        <Tag.Container>
            <Header />
            <Tag.Content>
                <Tag.Title>Que tipo de produto você quer anunciar?</Tag.Title>

                <Tag.Row>
                    <Tag.ButtonType href="/account/create-ads/vehicle">
                        <Tag.Image src={ImgVeiculo} />
                        <Tag.Text>Veículo</Tag.Text>
                    </Tag.ButtonType>
                    <Tag.ButtonType href="/account/create-ads/property">
                        <Tag.Image src={ImgImovel} />
                        <Tag.Text>Imóvel</Tag.Text>
                    </Tag.ButtonType>
                    <Tag.ButtonType href="/account/create-ads">
                        <Tag.Image src={ImgOutras} />
                        <Tag.Text>Outras categorias</Tag.Text>
                    </Tag.ButtonType>
                </Tag.Row>

                <ButtonOutline href="/" type="link" text="Voltar à navegação" style={{marginTop: 72}} width={230} />
            </Tag.Content>
            <Footer />
        </Tag.Container>
    );
}

export default AdsType;