import styled from "styled-components";

export const Container = styled.div`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: #FFFFFF;
    border: 1px solid #BDBDBD;
    border-radius: 5px;
    width: 100%;
    max-width: ${props => props.width ? `${props.width}px` : '100%'};
    height: 48px;
    padding: 16px 14px;
`;

export const Input = styled.input`
    border:0!important;
    width: 100%;
    outline:none;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    font-family: 'Montserrat',sans-serif!important;

    ::placeholder,
    ::-webkit-input-placeholder {
        color: #BDBDBD;
    }
    :-ms-input-placeholder {
        color: #BDBDBD;
    }
`;

export const Icon = styled.img`
    width: 24px;
    height: 24px;
    background-size:contain;
    margin-right: ${props => props.right != null ? props.right : '16'}px;
    cursor:pointer;
`;