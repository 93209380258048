import React, { useState, useEffect, useRef } from "react";
import * as Tag from "./styles";
import Header from  '../../components/Header';
import Footer from  '../../components/Footer';
import TitleBox from  '../../components/TitleBox';
import ProductsCarousel from  '../../components/ProductsCarousel';
import BoxUserSidebar from  '../../components/BoxUserSidebar';
import BoxCompanySidebar from  '../../components/BoxCompanySidebar';
import ProductDetailActions from  '../../components/ProductDetailActions';
import FeaturesProduct from  '../../components/FeaturesProduct';
import BannerProductDetail from "../../assets/banner_product_detail.jpg";
import BannerProductDetailSidebar from "../../assets/banner_product_detail_sidebar.jpg";
import IcoPin from "../../assets/ico_pin_map.svg";
import TableStatesProduct from "../../components/TableStatesProduct";
import BoxSimulatorProduct from "../../components/BoxSimulatorProduct";
import GalleryImage from "../../components/GalleryImage";
import PopupCheckUser from "../../components/PopupCheckUser";
import api from "../../services/api";
import LoaderFullScreen from "../../components/LoaderFullScreen";
import { connect } from 'react-redux';
import { useParams } from "react-router-dom";

const ProductDetail = (props) => {
    const [popupCheckUser, setPopupCheckUser] = useState(false);
    const [loader, setLoader] = useState(false);
    const [currentAds, setCurrentAds] = useState({});
    const didMount = useRef(true);
    const { id } = useParams();
    
    async function getAds(){
        try {
            setLoader(true);
            const response = await api.post(`/ads/detail/${id}`,{
                user_id : props.user.id
            });
            console.log(response);
            if(response.data.status){
                setCurrentAds(response.data.data);
            }
            setLoader(false);
        } catch (err) {
            console.log(err);
        }finally{
            setLoader(false);
        }
    }

    useEffect(() => {
        if(didMount.current){
            didMount.current = false;
            getAds();
        }
    });

    return (
        <Tag.Container>
            <Header />
            <Tag.Content>

                {loader && <LoaderFullScreen />}
                
                <Tag.Detail>
                    <Tag.Product>
                        <Tag.Infos>

                            {popupCheckUser && <PopupCheckUser setShowPopup={() => setPopupCheckUser(false)} />}

                            <ProductDetailActions favorite={currentAds.favorite} />
                            
                            <Tag.Title>{currentAds.name}</Tag.Title>
                            <Tag.Data>Publicado em {currentAds.date}</Tag.Data>

                            {currentAds.images?.length && <GalleryImage images={currentAds.images} />}

                            {currentAds?.ads_type == 'property' && <FeaturesProduct area={currentAds.property?.area}
                            quartos={currentAds?.sub_filters.find(item => item.name === "Quartos")?.number}
                            banheiros={currentAds?.sub_filters.find(item => item.name === "Banheiros")?.number}
                            vagas={currentAds?.sub_filters.find(item => item.name === "Vagas")?.number}
                            pets={currentAds.property?.allow_pets == 1}  />}
                            
                            <Tag.Description>{currentAds.description}</Tag.Description>
                            <Tag.Category>Categoria: {currentAds.category?.name}</Tag.Category>
                            {currentAds.subcategories && <Tag.SubCategory>Subcategoria: {currentAds.subcategories.map(item => item.name).join(', ')}</Tag.SubCategory>}
                            {currentAds.district && <Tag.Location><Tag.IconLocation src={IcoPin} /> {currentAds.district}, {currentAds.city}/{currentAds.state}</Tag.Location>}

                            {currentAds?.ads_type == 'property' || currentAds?.ads_type == 'vehicle' ? <TableStatesProduct moreInfos={currentAds.vehicle || currentAds.property} states={currentAds?.other_filters} sub_filters={currentAds?.sub_filters} vehicle={currentAds?.vehicle} /> : null}

                        </Tag.Infos>

                        {currentAds?.ads_type == 'property' || currentAds?.ads_type == 'vehicle' ? <BoxSimulatorProduct /> : null}

                    </Tag.Product>
                    <Tag.Sidebar>
                        {currentAds.user && <BoxUserSidebar showContact={currentAds.allow_contact_phone} user={currentAds.user} setShowPopup={() => setPopupCheckUser(true)} />}
                        {currentAds?.category?.slug == 'veiculos' && <BoxCompanySidebar />}
                        <Tag.BoxBanner style={{marginTop: "25px"}}>
                            <Tag.Banner src={BannerProductDetailSidebar} />
                        </Tag.BoxBanner>
                    </Tag.Sidebar>
                </Tag.Detail>
                
                {/* <TitleBox title="Recomendados para você" link="#" />
                <ProductsCarousel /> */}

                <Tag.BoxBanner>
                    <Tag.Banner src={BannerProductDetail} />
                </Tag.BoxBanner>

            </Tag.Content>
            <Footer />
        </Tag.Container>
    );
}

const mapStateToProps = store => ({
    user: store.userState,
    location: store.locationState
});

export default connect(mapStateToProps)(ProductDetail);