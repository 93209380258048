import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 100vh;
    flex-direction: column;
    overflow-x:hidden;
`;

export const Content = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 52px 120px 120px;
    background:#fff;
    width: 100%;
    max-width: 1440px;
    flex: 1;

    @media(max-width: 1280px) {
        padding: 52px 40px 120px;
    }
    @media(max-width: 1200px) {
        padding: 52px 20px 120px;
    }
`;

export const ButtonsSimulate = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    flex-direction: row;
    
    @media(max-width: 768px) {
        flex-direction: column;
    }
`;

export const LinkSimulate = styled.a`
    background: #FFFFFF;
    border: 1px solid #CBCBCB;
    border-radius: 5px;
    margin:0 13px;
    width: 100%;
    padding: 13px 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-decoration: none;

    :first-child{
        margin-left:0;
    }
    :last-child{
        margin-right:0;
    }
    
    @media(max-width: 768px) {
        :first-child{
            margin-left:0;
            margin-right:0;
        }
        :last-child{
            margin-left:0;
            margin-right:0;
            margin-top: 20px;
        }
    }
`;

export const BoxIcon = styled.div`
    margin-right: 20px;
    width: 60px;
    height: 60px;
    border-radius: 50px;
    background: #f9f9f9;
    display: flex;
    align-items: center;
    justify-content: center;
    
    @media(max-width: 768px) {
        width: 40px;
        height: 40px;
    }
`;

export const Icon = styled.img`
    width: 38px;
    height: 38px;
    background-size: contain;
    
    @media(max-width: 768px) {
        width: 25px;
        height: 25px;
    }
`;

export const TitleLink = styled.span`
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    color:#333333;
    width: calc(100% - 80px);
    
    @media(max-width: 768px) {
        font-size: 17px;
        line-height: 27px;
        width: calc(100% - 60px);
    }
`;

export const Link = styled.a`
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #0000FF;
    cursor:pointer;
    text-decoration:none;
`;

export const BannerCenter = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    flex-direction: row;
    margin-top: 72px;
    background:#F5F5F5;
    height: auto;
    padding: 71px 0 60px 0;

    @media(max-width: 768px) {
        margin-top: 10px;
        border: 0;
        padding: 40px 0 30px 0;
    }
`;

export const BannerCenterContent = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 1200px;

    @media(max-width: 1280px) {
        padding:0 20px;
    }
    
    @media(max-width: 768px) {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
`;

export const TextsBanner = styled.div`
    margin-right: 132px;

    @media(max-width: 1280px) {
        margin-right: 40px;
    }
    @media(max-width: 768px) {
        margin-right: 0;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        display:flex;
    }
`;

export const TitleBanner = styled.div`
    font-weight: 500;
    font-size: 36px;
    line-height: 44px;
    color:#333333;
    margin-bottom:28px;
    white-space: nowrap;

    @media(max-width: 1280px) {
        white-space: initial;
    }
    @media(max-width: 768px) {
        text-align:left;
        font-size: 20px;
        line-height: 24px;
        width: 100%;
    }
`;

export const TextBanner = styled.div`
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    color:#333333;
    margin-bottom:33px;
    white-space: nowrap;

    @media(max-width: 1280px) {
        white-space: initial;
    }
    @media(max-width: 768px) {
        font-size: 16px;
        line-height: 29px;
    }
`;

export const ImageBanner = styled.img`
    max-width: 530px;
    max-height: 225px;
    background-size:contain;
    object-fit: contain;

    @media(max-width: 768px) {
        margin-top: 40px;
        max-width: 250px;
        margin-left: 20px;
    }
`;

export const Brands = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction:row;
    width: 100%;
    margin-top:24px;

    @media(max-width: 768px) {
        overflow-x:scroll;
    }
`;