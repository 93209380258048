import React from "react";
import * as Tag from "./styles";

const MenuAccountTop = (props) => {
    return (
        <Tag.Container onClick={props.onClick}>
            {props?.icon && <Tag.Icon src={props.icon} />}
            <Tag.Name>{props?.text}</Tag.Name>
        </Tag.Container>
    );
}

export default MenuAccountTop;