import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
`;

export const Content = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 70px 20px 120px;
    background:#fff;
    width: 100%;
    max-width: 1200px;
    flex: 1;

    @media(max-width: 768px) {
        padding: 40px 20px 60px;
    }
`;

export const Title = styled.h3`
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #333333;

    @media(max-width: 768px) {
        font-size: 18px;
        line-height: 27px;
    }
`;

export const Image = styled.img`
    width: 100%;
    max-width: 268px;
    margin-top:39px;
`;

export const Plans = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 72px;
    max-width: 960px;

    @media(max-width: 768px) {
        margin-top: 32px;
        flex-direction:column;
    }
`;

