import React from "react";
import * as Tag from "./styles";

const MenuBarMobile = (props) => {
    return (
        <Tag.Container>
            <Tag.Bar />
            <Tag.Bar />
            <Tag.Bar />
        </Tag.Container>
    );
}

export default MenuBarMobile;