import React, { useState } from "react";
import * as Tag from "./styles";
import ProductThumb from "../ProductThumb";
import Popup from "../Popup";
import Slider from "react-slick";
import ImgProduct01 from '../../assets/foto_product_01.jpg';
import ImgProduct02 from '../../assets/foto_product_02.jpg';
import ImgProduct03 from '../../assets/foto_product_03.jpg';
import ImgProduct04 from '../../assets/foto_product_04.jpg';
import IcoActions from '../../assets/ico_actions_product.svg';
import Button from "../Button";

const products = [
    {
        gallery : [ImgProduct01,ImgProduct01,ImgProduct01,ImgProduct01],
        title : "Apartamento 3 quartos no centro",
        date : "09/03/2022",
        price : "R$ 2455,00",
        favorite : false,
    },
    {
        gallery : [ImgProduct02,ImgProduct02],
        title : "Bicicleta passeio",
        date : "09/03/2022",
        price : "R$ 2455,00",
        favorite : true,
    },
    {
        gallery : [ImgProduct03,ImgProduct03,ImgProduct03],
        title : "Bike speed fixa",
        date : "09/03/2022",
        old_price : "R$ 2455,00",
        price : "R$ 2455,00",
        favorite : false,
    },
    {
        gallery : [ImgProduct04,ImgProduct04,ImgProduct04,ImgProduct04],
        title : "MTB 21v aro 26",
        date : "09/03/2022",
        price : "R$ 2455,00",
        favorite : false,
    },
    {
        gallery : [ImgProduct02,ImgProduct02],
        title : "Bicicleta passeio",
        date : "09/03/2022",
        price : "R$ 2455,00",
        favorite : true,
    }
]

const ProductsCarousel = (props) => {
    const [popupTitle, setTitlePopup] = useState("");
    const [popupActions, setPopupActions] = useState(false);

    function showPopupActions(title){
        setTitlePopup(title);
        setPopupActions(true);
    }

    var settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 960,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    };
    return (
        <Tag.Container>

            {popupActions &&
                <Popup title={popupTitle} onBlur={() => setPopupActions(false)} smallTitle maxWidth={532}>
                    <Tag.Icon src={IcoActions} />
                    <Button text="Ok" width={218} style={{marginTop: '60px'}} />
                </Popup>
            }

            <Slider {...settings}>
                {products.map((item, index) => 
                <Tag.Item key={index}>
                    <ProductThumb product={item} showPopupActions={(title) => showPopupActions(title)} />
                </Tag.Item>
                )}
            </Slider>
        </Tag.Container>
    );
}

export default ProductsCarousel;