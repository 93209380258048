
import React from "react";
import * as Tag from "./styles";
import Popup from "../Popup";
import Button from "../Button";
import ButtonOutline from "../ButtonOutline";
import RadioBoxList from "../RadioBoxList";

const motivos = [
    {
        id : "Não tem a informação que estava procurando",
        title : "Não tem a informação que estava procurando"
    },
    {
        id : "Não entendi muito bem e as informações são confusas",
        title : "Não entendi muito bem e as informações são confusas"
    },
    {
        id : "Não funcionou como descrito",
        title : "Não funcionou como descrito"
    },
    {
        id : "Há uma imagem ausente. link quebrado e/ou erro de digitação",
        title : "Há uma imagem ausente. link quebrado e/ou erro de digitação"
    },
    {
        id : "Outro",
        title : "Outro"
    }
]

const PopupReportHelpCenter = (props) => {
    return (
        <Popup title="" maxWidth={532} smallTitle noCloseBlur closeButton onBlur={() => props.setPopupAvaliacao(false)}>
            <Tag.Container>
                <Tag.Title>Como podemos melhorar?</Tag.Title>
                <RadioBoxList items={motivos} marginTop={16} />
                <Tag.Row align="center">
                    <Button text="Enviar" width={184} onClick={() => {props.setPopupAvaliacao(false); props.showPopupActions("Denuncia enviada com sucesso"); }} />
                    <ButtonOutline text="Cancelar" width={184} style={{marginTop: '24px'}} onClick={() => props.setPopupAvaliacao(false)} />
                </Tag.Row>
            </Tag.Container>
        </Popup>
    );
}

export default PopupReportHelpCenter;