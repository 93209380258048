import styled from "styled-components";

export const Container = styled.div`
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    margin-right: ${props => props.marginRight ? props.marginRight : '10'}px;
    margin-top: ${props => props.marginTop ? props.marginTop : '8'}px;
    cursor:pointer;
`;

export const Check = styled.span`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: ${props => props.size ? props.size : 16}px;
    height: ${props => props.size ? props.size : 16}px;
    background: #FFFFFF;
    border: 1px solid #4F4F4F;
    border-radius: 2px;
    margin-right: 10px;

    :after{
        content: "";
        background-color: ${props => props.active ? "#0000FF" : "transparent"};
        width: ${props => props.size ? props.size / 2 : 8}px;
        height: ${props => props.size ? props.size / 2 : 8}px;
        border-radius: 2px;
    }
`;

export const Title = styled.span`
    display: inline-flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    color: #333333;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    flex: 1;
`;

