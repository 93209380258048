import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    max-width: 529px;
`;

export const Text = styled.span`
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    color: #333333;
    text-align:center;
`;

export const Obs = styled.span`
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #333333;
    margin-top:48px;
`;

export const Image = styled.img`
    margin-top: 28px;
    width: 100%;
    max-width: 210px;
`;

export const Links = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    margin-top:20px;
`;

export const Link = styled.a`
    display:inline-flex;   
    align-items: center;
    justify-content: center;
    border: 1px solid #C4C4C4;
    border-radius: 25px;
    width:42px;
    height:42px;
    margin:0 22px;
`;

export const Icon = styled.img`
    width: 21px;
    height: 21px;
    background-size: contain;
`;