import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from  '../../components/Header';
import Footer from  '../../components/Footer';
import Button from  '../../components/Button';
import ButtonOutline from  '../../components/ButtonOutline';
import InputText from  '../../components/InputText';
import InputPassword from  '../../components/InputPassword';
import api from "../../services/api";
import { login } from "../../services/auth";
import * as Tag  from "./styles";
import IcoEmail from '../../assets/ico_email.svg';
import IcoPassword from '../../assets/ico_lock.svg';
import IcoGoogle from '../../assets/ico_google_round.svg';
import IcoFacebook from '../../assets/ico_facebook_round.svg';
import TextSeparator from "../../components/TextSeparator";
import LoaderFullScreen from "../../components/LoaderFullScreen";
import platform from 'platform';
import { getLocation } from "../../services/google";
import { connect } from 'react-redux';

const SignIn = (props) => {
    const history = useNavigate();
    const [email, SetEmail] = useState("");
    const [password, SetPassword] = useState("");
    const [errors, setErrors] = useState([]);
    const [loader, setLoader] = useState(false);

    async function handleSignIn(e){
        e.preventDefault();
        if (!email || !password) {
            setErrors(["Preencha e-mail e senha para continuar!"]);
        } else {
            try {
                setLoader(true);

                let city = '';
                let state = '';
                let country = '';

                try{
                    const address = await getLocation(props.location.latitude, props.location.longitude);
                    console.log(address);
                    city = address.filter((item) => item.types.filter((type) => type === "administrative_area_level_2").length > 0)[0]?.long_name;
                    state = address.filter((item) => item.types.filter((type) => type === "administrative_area_level_1").length > 0)[0]?.short_name;
                    country = address.filter((item) => item.types.filter((type) => type === "country").length > 0)[0]?.long_name;
                }catch(e){
                    
                }

                const response = await api.post("/auth/login", {
                    email,
                    password,
                    os : platform.os.family,
                    browser : platform.name,
                    location : `${city}, ${state} - ${country}`
                });
                setLoader(false);

                console.log(response.data);

                login(response.data.token);

                history("/home", { replace: true });

            } catch (err) {
                console.log(err);
                setErrors(err.response?.data.errors ? err.response.data.errors : [err.response.data.error]);
            }finally{
                setLoader(false);
            }
        }
    };

    return (
        <Tag.Container>
            <Header />
            <Tag.Content>

                {loader && <LoaderFullScreen />}

                <Tag.Title>Acesse sua conta</Tag.Title>
                <Tag.Form onSubmit={handleSignIn}>

                    <Tag.Row style={{textAlign:"center"}}>
                        <Tag.LinkSocial href="#">
                            <Tag.Icon src={IcoGoogle} />
                        </Tag.LinkSocial>
                        <Tag.LinkSocial href="#">
                            <Tag.Icon src={IcoFacebook} />
                        </Tag.LinkSocial>
                    </Tag.Row>

                    <Tag.Row style={{textAlign:"center"}}>
                       <TextSeparator text="ou" />
                    </Tag.Row>

                    <Tag.Row>
                        <InputText icon={IcoEmail} placeholder="E-mail" onChange={e => SetEmail(e.target.value)} />
                    </Tag.Row>

                    <Tag.Row>
                        <InputPassword icon={IcoPassword} placeholder="Senha" onChange={e => SetPassword(e.target.value)} togglePassword />
                        <Tag.Link href="/account-recovery">Recuperar senha ou email</Tag.Link>
                    </Tag.Row>

                    {errors?.length > 0 && <Tag.Row>
                        <Tag.Errors>{errors.map((error) => <>{`- ${error}`}<br/></> )}</Tag.Errors>
                    </Tag.Row>}

                    <Tag.Row style={{textAlign: "center"}}>
                        <Button width={230} type="submit" text="Entrar" />
                        <ButtonOutline width={230} type="link" href="/account-type" text="Criar conta" style={{marginTop: 20}} />
                    </Tag.Row>

                </Tag.Form>
            </Tag.Content>
            <Footer />
        </Tag.Container>
    );
}

const mapStateToProps = store => ({
    location: store.locationState
});

export default connect(mapStateToProps)(SignIn);