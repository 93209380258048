import React, { useState } from "react";
import * as Tag from "./styles";
import SliderImages from "../SliderImages";
import ProductActions from "../ProductActions";
import IcoFav from "../../assets/ico_fav.svg";
import IcoFavAct from "../../assets/ico_fav_act.svg";
import IcoMore from "../../assets/ico_more.svg";
import IcoPinMap from "../../assets/ico_pin_map.svg";
import IcoHighlight from "../../assets/ico_highlight.svg";

const ProductThumb = ({ product, owner, showPopupActions }) => {
    const [actions, setActions] = useState(false);

    return (
        <Tag.Container>
            <Tag.BoxImages>
                {product.images && <SliderImages images={product.images} height={200} width={200} />}
            </Tag.BoxImages>
            <Tag.Right>
                <Tag.Texts>
                    {product.highlight &&
                    <Tag.Highlight>
                        <Tag.IconHighlight src={IcoHighlight} />
                    </Tag.Highlight>
                    }
                    
                    <Tag.BoxTitle>
                        <Tag.Title href={`/detail/${product.id}`}>{product.name}</Tag.Title>
                        {!product.highlight && product.old_price &&
                        <Tag.Prices>
                            <Tag.OldPrice>{product.old_price}</Tag.OldPrice>
                            <Tag.NewPrice>{product.price}</Tag.NewPrice>
                        </Tag.Prices>
                        }
                        {!product.highlight && !product.old_price && <Tag.Price>{product.price}</Tag.Price>}
                    </Tag.BoxTitle>
                    <Tag.Description>{product.description}</Tag.Description>
                </Tag.Texts>
                <Tag.Bottom>
                    
                    <Tag.Left>
                        <Tag.Date>{product.date}</Tag.Date>
                        <Tag.Address><Tag.IconAddress src={IcoPinMap} />{product.district}, {product.city}-{product.state}</Tag.Address>
                    </Tag.Left>

                    <Tag.Buttons>
                        <Tag.Link>
                            <Tag.Icon src={product.favorite ? IcoFavAct : IcoFav} />
                        </Tag.Link>
                        <Tag.Link onClick={() => setActions(true)} >
                            <Tag.Icon src={IcoMore} />
                        </Tag.Link>
                    </Tag.Buttons>

                    {actions && <ProductActions
                        actionHideAll={() => { showPopupActions("Deseja ocultar todos os anúncios deste vendedor? Está ação não pode ser revertida.")}}
                        actionDenounce={() => { showPopupActions("Deseja denunciar o anúncio?")}}
                        actionDenounceHide={() => { showPopupActions("Deseja denunciar o anúncio e ocultar todas as publicações deste vendedor? Está ação não pode ser revertida.")}}
                        onBlur={() => setActions(false)}
                    />}

                </Tag.Bottom>
            </Tag.Right>
        </Tag.Container>
    );
}

export default ProductThumb;