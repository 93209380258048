import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    margin:64px 0 12px;
    border: 1px solid #CBCBCB;
    border-radius: ${props => props.round ?  props.round : '10'}px;
    overflow:hidden;
    max-width: 100%;
`;

export const Item = styled.div`
    padding: 10px 15px;
    background: ${props => props.active ? props.activeColor ? props.activeColor :'#0000FF' : '#FFFFFF'};
    cursor: pointer;
    min-width: 180px;
    height: ${props => props.height ?  props.height : '44'}px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
`;

export const Text = styled.span`
    color: ${props => props.active ? '#FFFFFF' : props.inativeColorText ? props.inativeColorText : '#333333'};
    font-weight: ${props => props.active ? '600' : '400'};
    font-size: 16px;
    line-height: 24px;
`;