import styled from "styled-components";

export const Container = styled.a`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-decoration:none;

    @media(max-width: 768px) {
        margin-right: 20px;
        padding-bottom: 20px;
    }
`;

export const Title = styled.p`
    margin-top: 5px;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color:#333333;
    white-space: nowrap;
`;

export const BoxImage = styled.div`
    width: 145px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media(max-width: 768px) {
        width: 77px;
        height: 57px;
    }
`;

export const Image = styled.img`
    max-width: 145px;
    max-height: 100px;
    object-fit: contain;

    @media(max-width: 768px) {
        max-width: 77px;
        max-height: 57px;
    }
`;