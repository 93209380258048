import React, { useState, useEffect } from "react";
import Header from  '../../components/Header';
import Footer from  '../../components/Footer';
import Button from  '../../components/Button';
import SuccessMsg from  '../../components/SuccessMsg';
import VerificationPhone from  '../../components/VerificationPhone';
import InputText from  '../../components/InputText';
import IcoPhone from '../../assets/ico_phone-gray.svg';
import ImgRecoveryEmailSend from "../../assets/recovery_email_send.svg";
import SuccessSignup from "../../assets/success_signup_big.svg";
import * as Tag  from "./styles";
import api from "../../services/api";
import { useNavigate } from 'react-router-dom';
import { isAuthenticated } from "../../services/auth";
import LoaderFullScreen from "../../components/LoaderFullScreen";

const AccountValidatePhone = (props) => {
    const history = useNavigate();
    const [loader, setLoader] = useState(false);
    const [user, setUser] = useState(null);
    const [phone, setPhone] = useState("");
    const [errors, setErrors] = useState([]);
    const [invalidCode, setInvalidCode] = useState(false);
    const [showVerificationPhone, setVerificationPhone] = useState(false);
    const [showFormPhone , setFormPhone] = useState(false);
    const [success, setSuccess] = useState(false);

    function handleRecovery(e){
        e.preventDefault();
        sendCode();
    };

    async function sendCode(){
        try {
            setLoader(true);
            const result = await api.post("/user/send/sms", { user_id : user?.id, phone });
            setLoader(false);
            setErrors([]);
            console.log(result);
            if(result?.data?.status){
                setFormPhone(false);
                setVerificationPhone(true);
            }
        } catch (err) {
            console.log(err);
            if(err.response.data.errors){
                setErrors(err.response.data.errors);
            }else{
                setErrors([err.response.data.error]);
            }
        }finally{
            setLoader(false);
        }
    }

    async function validateCode(code){
        try {
            setLoader(true);
            const result = await api.post("/user/validate/sms", { user_id : user?.id, phone, code });
            setLoader(false);
            setSuccess(true);
            console.log(result);
        } catch (err) {
            console.log(err);
            setInvalidCode(true);
        }finally{
            setLoader(false);
        }
    }

    useEffect(() => {
        if(isAuthenticated()){
            getUser();
        }else{
            history("/");
        }
    },[history]);

    async function getUser(){
        try {
            setLoader(true);
            const result = await api.get("/user/getByToken");
            setLoader(false);
            if(result?.data?.status){
                if(result.data.data.contacts.find((item) => item.type === "phone")?.id > 0){
                    setSuccess(true);
                }else{
                    setUser(result.data.data);
                    setFormPhone(true);
                }
            }
        } catch (err) {
            console.log(err);
        }finally{
            setLoader(false);
        }
    }

    return (
        <Tag.Container>
            <Header />
            <Tag.Content>

                {loader && <LoaderFullScreen />}

                {showFormPhone &&
                    <Tag.Form onSubmit={handleRecovery}>
                        <Tag.Title>Seu email foi verificado com sucesso! Agora é necessário validar seu número.</Tag.Title>
                        <Tag.Subtitle>Para sua segurança, usamos um método de verificação dupla</Tag.Subtitle>

                        <Tag.Row style={{flexDirection: "row"}}>
                            <InputText mask="(99) 99999-9999" icon={IcoPhone} placeholder="Celular" onChange={e => setPhone(e.target.value)} value={phone} />
                        </Tag.Row>

                        {errors?.length > 0 && <Tag.Row>
                            <Tag.Errors>{errors.map((error) => <>{`- ${error}`}<br/></> )}</Tag.Errors>
                        </Tag.Row>}

                        <Tag.Image src={ImgRecoveryEmailSend} style={{marginTop: 40}} />

                        <Button type="submit" text="Enviar SMS" style={{marginTop: 20}} width={230} />
                    </Tag.Form>
                }

                {(showVerificationPhone && !success) &&
                    <VerificationPhone validateCode={(code) => validateCode(code)} title="SMS Enviado" titleBig={true} sendCode={() => sendCode()} invalidCode={invalidCode} phone={phone} changeNumber={() => setVerificationPhone(false)} />
                }

                {success && <SuccessMsg text="Pronto! Sua conta foi criada e verificada." icon={SuccessSignup} maxWidthImg={281} textButton="Voltar à navegação" />}

            </Tag.Content>
            <Footer />
        </Tag.Container>
    );
}

export default AccountValidatePhone;