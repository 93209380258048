import React, { useState, useRef} from "react";
import * as Tag from "./styles";
import ArrowDown from  "../../assets/ico_arrow-down_gray.svg";

const DropDownList = (props) => {
    const [selected, setSelected] = useState(props.selected ? props.selected : {});
    const [expanded, setExpanded] = useState(false);
    const containerRef = useRef(null);

    function expand() {
        setExpanded(true);
    }

    function close() {
        setExpanded(false);
    }

    function select(item) {
        containerRef.current.blur();
        props.onChange(item);
        setSelected(item);
    }

    return (
        <Tag.Container ref={containerRef} tabIndex={0} onFocus={expand} onBlur={close} {...props} height={props.height} round={props.round} active={expanded}>
            <Tag.Title>{(selected.id || selected.code) ? (selected.title ? selected.title : selected.name) : props.placeholder}</Tag.Title>
            <Tag.Icon src={ArrowDown} />
            {expanded &&
            <Tag.Iems round={props.round}>
                {props.items && props.items.map((item, index) =>
                    <Tag.Item key={index} selected={((selected.id && selected.id === item.id) || (selected.code && selected.code === item.code))} onClick={() => select(item)}>{item.title ? item.title : item.name}</Tag.Item>
                )}
            </Tag.Iems>
            }
            <input type="hidden" id={props.name} name={props.name} value={selected?.id || selected?.code} />
        </Tag.Container>
    );
}

export default DropDownList;