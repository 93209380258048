import React, { useState } from "react";
import * as Tag from "./styles";
import IcoMessages from "../../assets/ico_message.svg";
import Message from "../Message";

const MessagesTop = (props) => {
    const [showNotifications, setShowNotifications] = useState(false);
    return (
        <Tag.Container {...props}>
            <Tag.Link href="#" onMouseEnter={() => setShowNotifications(true)} onMouseLeave={() => setShowNotifications(false)}>
                <Tag.Count>
                    <Tag.CountText>2</Tag.CountText>
                </Tag.Count>
                <Tag.Icon src={IcoMessages} width={32} height={32} />
            </Tag.Link>
            {showNotifications &&
                <Tag.Notifications onMouseEnter={() => setShowNotifications(true)} onMouseLeave={() => setShowNotifications(false)}>
                    <Message />
                    <Message />
                    <Message />
                    <Message />
                    <Tag.LinkNotifications href="#">Ver todas as notificações</Tag.LinkNotifications>
                </Tag.Notifications>
            }
        </Tag.Container>
    );
}

export default MessagesTop;