import React from "react";
import * as Tag from "./styles";

const Textarea = (props) => {
    return (
        <Tag.Container {...props} height={props.height} round={props.round}>
            {props.icon && <Tag.Icon src={props.icon} />}
            <Tag.Textarea {...props} height={props.height} />
            {props.iconRight && <Tag.IconRight src={props.iconRight} />}
        </Tag.Container>
    );
}

export default Textarea;