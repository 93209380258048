
import React from "react";
import * as Tag from "./styles";
import Popup from "../Popup";
import Rating from "../Rating";
import Comment from "../Comment";
import Foto01 from "../../assets/foto_comment_01.jpg";
import Foto02 from "../../assets/foto_comment_02.jpg";
import ImageComment from "../../assets/foto_comment.jpg";

const comments = [
    {
        id : 1,
        image : Foto01,
        name : "Pedro Arthur",
        date : "12/12/2022",
        rate : 5,
        comment : "Fermentum, nunc neque, faucibus vitae purus venenatis amet. Amet nibh purus, facilisis at. Fermentum, nunc neque, faucibus vitae purus venenatis amet. Amet nibh purus, facilisis at.",
        answers : [
            {
                id : 4,
                name : "Resposta do vendedor",
                date : "12/12/2022",
                comment : "Fermentum, nunc neque, faucibus vitae purus venenatis amet. Amet nibh purus, facilisis at. Fermentum, nunc neque, faucibus vitae purus venenatis amet. Amet nibh purus, facilisis at."
            }
        ],
        attacheds : [ImageComment]
    },
    {
        id : 2,
        image : Foto01,
        name : "Laura Temporão",
        date : "12/12/2022",
        rate : 5,
        comment : ""
    },
    {
        id : 3,
        image : Foto02,
        name : "Manuela Pereira",
        date : "12/12/2022",
        rate : 5,
        comment : "Fermentum, nunc neque, faucibus vitae purus venenatis amet. Amet nibh purus, facilisis at. Fermentum, nunc neque, faucibus vitae purus venenatis amet. Amet nibh purus, facilisis at."
    }
]

const PopupRating = (props) => {
    return (
        <Popup maxWidth={900} closeButton onBlur={() => props.setPopupRating(false)} noCloseBlur>
            <Tag.Container>
                <Tag.Qty>5 avaliações</Tag.Qty>
                <Rating rate={4.4} big align="flex-start" />
                {comments.map((item, index) => <Comment key={index} item={item} />)}
            </Tag.Container>
        </Popup>
    );
}

export default PopupRating;