export const getLocation = async (latitude, longitude) => {
    let location = null;
    try {
        location = await fetch(
            `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${process.env.REACT_APP_API_KEY_GOOGLE}`
        );
        const respJson = await location.json();
        if(respJson?.results[0]?.address_components){
            return respJson.results[0].address_components;
        }
    } catch (error) {
        console.log(error);
    }
    return location;
}