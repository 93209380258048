
import React, { useState, useEffect } from "react";
import * as Tag from "./styles";
import Button from "../Button";
import InputText from "../InputText";
import LinkButton from "../LinkButton";
import request from "../../services/request";

const AddressForm = (props) => {
    const [cep, setCep] = useState('');
    const [street, setStreet] = useState('');
    const [number, setNumber] = useState('');
    const [complement, setComplement] = useState('');

    async function getCep(){
        let cepSearch = cep.replace('-','');
        cepSearch = cepSearch.replace('_','');
        if(cepSearch.length === 8){
            try{
                const result = await request.get(`https://viacep.com.br/ws/${cepSearch}/json/`);
                if(result.data){
                    console.log('CEP',result.data);
                    setStreet(result.data.logradouro);
                    //setNumber();
                    //setComplement();
                }
            }catch(ex){
                console.log(ex);
            }
        }
    }

    useEffect(() => {
        getCep();
    }, [cep]);
    
    return (
        <Tag.Container>
            <Tag.Row>
                <Tag.Label>CEP</Tag.Label>
                <InputText mask="99999-999" placeholder="Digite seu CEP" onChange={e => setCep(e.target.value)} value={cep} />
            </Tag.Row>

            <Tag.Row>
                <Tag.Label>Rua</Tag.Label>
                <InputText placeholder="Digite sua Rua" onChange={e => setStreet(e.target.value)} value={street} />
            </Tag.Row>

            <Tag.Double>
                <Tag.Row>
                    <Tag.Label>Número</Tag.Label>
                    <InputText placeholder="Digite seu Número" onChange={e => setNumber(e.target.value)} value={number} />
                </Tag.Row>

                <Tag.Row>
                    <Tag.Label>Complemento</Tag.Label>
                    <InputText placeholder="Digite um Complemento" onChange={e => setComplement(e.target.value)} value={complement} />
                </Tag.Row>
            </Tag.Double>
            
            <Tag.Col>
                <Button text="Salvar" onClick={() => props.onSave()} width={195} />
                <LinkButton text="Cancelar" onClick={() => props.onCancel()} width={195} color="#333333" style={{marginLeft: '20px'}} />
            </Tag.Col>
        </Tag.Container>
    );
}

export default AddressForm;