
import React from "react";
import * as Tag from "./styles";
import Popup from "../Popup";
import Button from "../Button";
import ButtonOutline from "../ButtonOutline";
import IcoCheckUser from "../../assets/ico_check_user.svg";
import IcoCheckCircle from "../../assets/ico_check_circle.svg";

const PopupCheckUser = (props) => {
    return (
        <Popup title="Verifique sua identidade para entrar em contato via WhatsApp" noCloseBlur maxWidth={900} closeButton onBlur={() => props.setShowPopup(false)}>

            <Tag.Container>
                <Tag.Texts>
                    <Tag.Title>Precisaremos de uma foto do seu documento</Tag.Title>
                    <Tag.Item><Tag.Icon src={IcoCheckCircle} /> Esteja com boa iluminação</Tag.Item>
                    <Tag.Item><Tag.Icon src={IcoCheckCircle} /> Certifique-se que o documento esteja centralizado</Tag.Item>
                    <Tag.Item><Tag.Icon src={IcoCheckCircle} /> Certifique-se que os dados estejam legíveis</Tag.Item>
                </Tag.Texts>
                <Tag.Image src={IcoCheckUser} />
            </Tag.Container>

            <Button text="Verificar" width={310} />
            <ButtonOutline text="Fazer mais tarde" width={310} style={{marginTop : '24px'}} />
        </Popup>
    );
}

export default PopupCheckUser;