import React from "react";
import * as Tag from "./styles";
import IcoFacebook from "../../assets/ico_facebook.svg";
import IcoInstagram from "../../assets/ico_instagram.svg";
import IcoTwitter from "../../assets/ico_twitter.svg";

const LinkSocial = (props) => {
    return (
        <Tag.Container>
            <Tag.Text>Siga-nos</Tag.Text>
            <Tag.Link href="#">
                <Tag.Icon src={IcoFacebook} />
            </Tag.Link>
            <Tag.Link href="#">
                <Tag.Icon src={IcoInstagram} />
            </Tag.Link>
            <Tag.Link href="#">
                <Tag.Icon src={IcoTwitter} />
            </Tag.Link>
        </Tag.Container>
    );
}

export default LinkSocial;