import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: center;
    height: auto;
    flex-direction: column;
    @media(max-width: 768px) {
        width:100%;
    }
`;

export const Title = styled.p`
    color:#333333;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;

    @media(max-width: 768px) {
        display:none;
    }
`;

export const SubTitle = styled.p`
    color:#333333;
    font-size: 14px;
    line-height: 21px;
    font-weight: 500;
    margin-top: 40px;

    @media(max-width: 768px) {
        margin-top: 30px;
    }
`;

export const Links = styled.div`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
`;

export const Link = styled.a`
    width: 32px;
    height: 32px;
    background:#0000FF;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right:12px;
    border-radius: 50px;
    white-space: nowrap; 
    margin-top: 8px;
`;

export const Icon = styled.img`
    width: 20px;
    height: 20px;
    background-size: cover;
`;

export const LinkStore = styled.a`
    margin-right:12px;
    margin-top:8px;
`;

export const IconStore = styled.img`
    width: 122px;
    height: 36px;
    background-size: cover;
`;

