import React from "react";
import * as Tag from "./styles";
import IcoPin from "../../assets/ico_pin_map.svg";
import IcoShare from "../../assets/ico_share.svg";
import IcoViews from "../../assets/ico_views.svg";
import IcoLike from "../../assets/ico_like.svg";
import Button from "../Button";
import GalleryImage from "../GalleryImage";
import IcoSeller from '../../assets/foto_user.jpg';

const SidebarMyProduct = (props) => {
    return (
        <Tag.Container>
            <Tag.Top>
                <Tag.Link>Editar</Tag.Link>
                <Tag.Share src={IcoShare} />
            </Tag.Top>
            <Tag.Title>Lorem ipsum</Tag.Title>

           <Tag.Company><Tag.CompanyIcon src={IcoSeller} />Ana Paula da Silva</Tag.Company>

            <Tag.Data>Publicado em 12/12/2021</Tag.Data>
            <Tag.Buttons>
                <Button text="Já vendi" width={126} height={36} icon={IcoLike} widthIcon={15} heightIcon={15} />
                <Tag.Views><Tag.IconViews src={IcoViews} /> 34</Tag.Views>
            </Tag.Buttons>

            <GalleryImage horizontal={true} />

            <Tag.Description>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Augue vestibulum nam sed sed purus congue ullamcorper. Integer habitant est eu in purus, tincidunt pulvinar tellus. At sit orci rhoncus egestas eget eu semper duis.</Tag.Description>
            <Tag.Location><Tag.IconLocation src={IcoPin} /> Bairro, Cidade/Estado</Tag.Location>
            <Tag.Category>Categoria: Lorem ipsum</Tag.Category>
            <Tag.SubCategory>Área: Lorem ipsum</Tag.SubCategory>
        </Tag.Container>
    );
}

export default SidebarMyProduct;