import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    background: #FFFFFF;
    border: 1px solid #E5E5E5;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    width:100%;
    margin:0 12px;
    padding: ${props => props.center ? '32px 20px 53px' : '27px 20px 32px'};
    min-height: ${props => props.center ? '480px' : '410px'};
    position:relative;

    @media(max-width: 768px) {
        max-width 100%;
        margin-top:20px;
        min-height: auto;
    }
`;

export const Texts = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @media(max-width: 768px) {
        align-items: flex-start;
    }
`;

export const Title = styled.p`
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #333333;
`;

export const Subtitle = styled.p`
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #0000FF;
    margin-top:7px;

    @media(max-width: 768px) {
        font-weight: 400;
        margin-top:7px;
    }
`;

export const Price = styled.p`
    font-weight: 500;
    font-size: 48px;
    line-height: 59px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #333333;
    margin-top: ${props => props.center ? '38px' : '24px'};

    @media(max-width: 768px) {
        font-size: 24px;
        line-height: 29px;
        margin-top:7px;
    }
`;

export const List = styled.ul`
    display: flex;
    align-items: center;
    text-align: center;
    flex-direction:column;
    padding: 40px 0 40px;

    @media(max-width: 768px) {
        align-items: flex-start;
        padding: 25px 0 25px;
    }
`;

export const ItemList = styled.li`
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #333333;
    margin-top:12px;
`;


export const Current = styled.p`
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #333333;
    background: #FFCB05;
    width: 100%;
    height: 51px;
    justify-content: center;
    position: absolute;
    bottom: 0;
    left: 0;
`;