import React from "react";
import * as Tag from "./styles";
import ContactsFooter from '../ContactsFooter';

const LinksFooter = (props) => {
    return (
        <Tag.Container>
            <Tag.Title>{props?.data?.title}</Tag.Title>
            <Tag.Links>
                {props?.data?.links.map((item, index) =>
                    <Tag.LineLink key={index}>
                        <Tag.Link href={item.link}>{item.title}</Tag.Link>
                    </Tag.LineLink>
                )}
            </Tag.Links>
            {props?.data?.contacts && <ContactsFooter title={props?.textAtendimento ? props.textAtendimento : "Entre em contato"} />}
        </Tag.Container>
    );
}

export default LinksFooter;