import styled from "styled-components";

export const Container = styled.div`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    position:relative;
`;

export const Link = styled.a`
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
`;

export const LinkNotifications = styled.a`
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-top:13px;
    font-size: 16px;
    line-height:24px;
    color:#333333;
    font-weight: 400;
`;

export const Icon = styled.img`
    width: 25px;
    height: 25px;
`;

export const Name = styled.span`
    font-size: 18px;
    line-height: 18px;
    font-weight: 700;
    color:#ffffff;
    margin:0 12px;
    display: inline-block;
`;

export const Count = styled.span`
    width: 20px;
    height: 20px;
    background:#FFCB05;
    align-items: center;
    justify-content: center;
    display:inline-flex;
    position:absolute;
    top: -8px;
    right: -8px;
    border-radius: 50px;
`;

export const CountText = styled.span`
    font-size: 14px;
    line-height: 21px;
    font-weight: 500;
    color:#333333;
`;

export const Notifications = styled.div`
    background: #FBFBFB;
    border: 1px solid #CBCBCB;
    border-radius: 5px;
    padding: 3px 10px 13px 10px;
    width: 445px;
    position: absolute;
    right: 0;
    top: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    display: inline-flex;
`;