import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 100vh;
    flex-direction: column;
`;

export const Content = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 33px 120px 120px;
    background:#fff;
    width: 100%;
    max-width: 1440px;
    flex: 1;
    
    @media(max-width: 1280px) {
        padding: 33px 40px 120px;
    }
    
    @media(max-width: 1180px) {
        padding: 33px 15px 40px;
    }
`;

export const ButtonsSearch = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    flex-direction: row;
    margin-top: 58px;

    @media(max-width: 960px) {
        flex-direction: column;
    }

    @media(max-width: 768px) {
        margin-top: 35px;
    }
`;

export const Link = styled.a`
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #0000FF;
    cursor:pointer;
    text-decoration:none;
`;

export const DoubleBanner = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    flex-direction: row;
    margin-top: 72px;
    
    @media(max-width: 960px) {
        flex-direction: column;
        margin-top: 35px;
    }
`;