import styled from "styled-components";
import BgHighlightProduct from "../../assets/bg_highlight_product.svg";

export const Container = styled.div`
    background: #FFFFFF;
    border: 1px solid #CBCBCB;
    border-radius: 10px;
    width: 100%;
    height: auto;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    display: flex;
    position:relative;
`;

export const BoxImages = styled.div`
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    overflow:hidden;
    width: 200px;
    height: 200px;
    display: flex;
`;

export const Right = styled.div`
    flex: 1;
    display:flex;
    flex-direction:column;
    align-items: center;
    justify-content: flex-start;
`;


export const Texts = styled.div`
    padding: 21px 16px 0;
    display:flex;
    width: 100%;
    flex-direction:column;
    height: 100%;
    position:relative;
`;

export const Highlight = styled.div`
    position:absolute;
    right:17px;
    top:-5px;
    width:32px;
    height:41px;
    background: url(${BgHighlightProduct}) no-repeat 50%;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    display: flex;
`;

export const IconHighlight = styled.img`
    width: 30px;
    height: 30px;
    object-fit: contain;
    margin-bottom: 5px;
`;

export const BoxTitle = styled.div`
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    display: flex;
`;

export const Title = styled.a`
    width: 100%;
    line-height: 27px;
    font-weight: 500;
    font-size: 18px;
    color: #000;
    height: 54px;
    overflow: hidden;
    margin-right: 45px;
    cursor:pointer;
    text-decoration:none;
`;

export const Description = styled.p`
    width: 100%;
    line-height: 21px;
    font-weight: 500;
    font-size: 14px;
    color: #000;
    height: 42px;
    overflow: hidden;
    margin-top: 16px;
`;

export const Date = styled.p`
    margin-top: 4px;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color:#000;
`;

export const Address = styled.p`
    margin-top: 4px;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color:#000;
    margin-left:20px;
    display:flex;
    flex-direction:row;
    align-items: center;
    justify-content: flex-start;
`;

export const IconAddress = styled.img`
    margin-right: 5px;
    width: 15px;
    height:15px;
    object-fit: contain;
`;

export const Bottom = styled.div`
    margin-top: 16px;
    padding: 10px 16px 10px;
    display:flex;
    width: 100%;
    flex-direction:row;
    align-items: center;
    justify-content: space-between;
    position:relative;
`;

export const Left = styled.div`
    display:flex;
    flex-direction:row;
    align-items: center;
    justify-content: space-between;
`;


export const Prices = styled.div`
    display:flex;
    flex-direction:column;
    align-items: flex-start;
    justify-content: center;
`;

export const OldPrice = styled.p`
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color:#828282;
    text-decoration: line-through;
    white-space: nowrap;
`;

export const NewPrice = styled.p`
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color:#0000FF;
    white-space: nowrap;
`;

export const Price = styled.p`
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color:#000;
    white-space: nowrap;
`;

export const Buttons = styled.div`
    display:flex;
    flex-direction:row;
    align-items: center;
    justify-content: center;
`;

export const Link = styled.a`
    margin-left: 14px;
    cursor:pointer;
`;

export const Icon = styled.img`
    width: 20px;
    height: 20px;
    background-size: contain;
`;

