import styled from "styled-components";

export const TitleBox = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    margin-top:76px;

    @media(max-width: 768px) {
        margin-top:47px;
    }
`;

export const Title = styled.h3`
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    color: #333333;

    @media(max-width: 768px) {
        font-size: 18px;
        line-height: 27px;
    }
`;

export const Link = styled.a`
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #0000FF;
    cursor:pointer;
    text-decoration:none;
`;