import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;
    width: 100%;
    flex-direction: column;
    background: #F5F5F5;
`;

export const ContLinks = styled.div`
    display: inline-flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    padding: 35px 60px 90px;
    width: 100%;
    max-width: 1440px;

    @media(max-width: 1280px) {
        padding: 35px 15px 90px;
    }

    @media(max-width: 560px) {
        padding-top: 25px;
        padding-bottom: 50px;
    }
`;

export const Bottom = styled.div`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-top: solid 1px #CBCBCB;
    width: 100%;
`;

export const ContainerBottom = styled.div`
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding: 24px 60px;
    max-width: 1440px;
    flex-wrap: wrap;

    @media(max-width: 1280px) {
        padding: 20px 15px;
    }
`;

export const Link = styled.a`
    font-size: 14px;
    line-height: 21px;
    font-weight: 500;
    color:#333333;
    text-decoration: none;
    white-space: nowrap;
    margin-right: 45px;
    cursor:pointer;
    text-decoration:none;

    @media(max-width: 560px) {
        font-size: 12px;
        line-height: 18px;
        margin-right: 0;
    }
`;

export const Separator = styled.span`
    width: 1px;
    height: 14px;
    background:#BDBDBD;
    margin: 0 20px;
    display:none;

    @media(max-width: 560px) {
        display:inline-block;
    }
`;

export const Copyright = styled.span`
    font-size: 14px;
    line-height: 21px;
    font-weight: 500;
    color:#333333;
    text-decoration: none;
    white-space: nowrap;
    margin-right: 45px;

    @media(max-width: 560px) {
        font-size: 12px;
        line-height: 18px;
        margin-top:16px;
        width: 100%;
    }
`;