import React, { useState } from "react";
import * as Tag from "./styles";
import MenuAccountTop from '../MenuAccountTop';
import ButtonSmall from '../ButtonSmall';
import IcoUser from "../../assets/ico_user.svg";
import IcoDown from "../../assets/ico_arrow_down.svg";
import IcoChat from "../../assets/ico_chat.svg";
import IcoPackage from "../../assets/ico_package.svg";
import IcoHeart from "../../assets/ico_heart.svg";
import IcoBriefcase from "../../assets/ico_briefcase.svg";
import IcoProfile from "../../assets/ico_user_menu.svg";
import IcoSettings from "../../assets/ico_settings.svg";
import IcoLogout from "../../assets/ico_logout.svg";
import { useNavigate } from "react-router-dom";
import { logout } from "../../services/auth";
import ImageUser from "../../assets/foto_user.png";

const UserTop = (props) => {
    const [showAccount, setShowAccount] = useState(false);
    const history = useNavigate();

    function logOut(){
        logout();
        history('/');
    }

    function goToLink(link){
        history(link);
    }
    
    return (
        <Tag.Container {...props}>
            <Tag.Link href="#" onMouseEnter={() => setShowAccount(true)} onMouseLeave={() => setShowAccount(false)}>
                <Tag.Icon src={props.account ? ImageUser : IcoUser} width={32} height={32} />
                {!props.account && <Tag.Name>{props?.user?.name}</Tag.Name>}
                {!props.account && <Tag.Icon src={IcoDown} width={13} height={13} />}
            </Tag.Link>
            {showAccount &&
                <Tag.Menus onMouseEnter={() => setShowAccount(true)} onMouseLeave={() => setShowAccount(false)}>
                    <Tag.ContButtonTopMenu>
                        <ButtonSmall href="/account/ads-type" type="link" text="Anunciar" />
                    </Tag.ContButtonTopMenu>
                    <MenuAccountTop icon={IcoChat} onClick={() => goToLink("/account/chat")} text="Chat" />
                    <MenuAccountTop icon={IcoPackage} onClick={() => goToLink("/account/my-ads")} text="Meus anúncios" />
                    <MenuAccountTop icon={IcoHeart} onClick={() => goToLink("/account/wishlist")} text="Favoritos" />
                    <MenuAccountTop icon={IcoBriefcase} onClick={() => goToLink("/account/my-plans")} text="Meus planos" />
                    <MenuAccountTop icon={IcoProfile} onClick={() => goToLink("/account/my-profile")} text="Perfil" />
                    <MenuAccountTop icon={IcoSettings} onClick={() => goToLink("/account/my-cadastre")} text="Configurações" />
                    <MenuAccountTop icon={IcoLogout} text="Sair" onClick={() => logOut()} />
                </Tag.Menus>
            }
        </Tag.Container>
    );
}

export default UserTop;