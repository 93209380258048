import styled from "styled-components";

export const Container = styled.form`
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    background:#ffffff;
    width: 100%;
    max-width: ${props => props.width ? props.width : '825px'};
    padding: 0 20px;
    border-radius: 58px;
    margin:0 10px;
`;

export const Input = styled.input`
    height: 40px;
    background:#ffffff;
    width: 100%;
    border:0;
    font-size: 14px;
    line-height: 21px;
    font-weight: 500;
    ::placeholder,
    ::-webkit-input-placeholder {
        color: #BDBDBD;
    }
    :-ms-input-placeholder {
        color: #BDBDBD;
    }
`;

export const Icon = styled.img`
    height: 20px;
    width: 20px;
    margin-left: 10px;
    cursor:pointer;
`;

export const Button = styled.button`
    border:0;
    cursor:pointer;
    background:none;
    width:auto;
    height:auto;
    outline:none;
`;

