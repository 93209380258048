import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: #FAFAFA;
    border: 1px solid #CBCBCB;
    border-radius: 10px;
    margin-top: 20px;
`;

export const Row = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    margin-top: 20px;
`;

export const Image = styled.img`
    width: 42px;
    height: 42px;
    border-radius: 50%;
    margin-right:20px;
`;

export const Name = styled.p`
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #333333;
`;

export const LinkBottom = styled.a`
    width: 100%;
    margin-top: 16px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    align-items: center;
    text-align: center;
    color: #0000FF;
    padding: 15px 0;
    text-decoration:none;
`;