
import React from "react";
import * as Tag from "./styles";
import Button from "../Button";

const SuccessMsg = (props) => {
    return (
        <Tag.Container>
            <Tag.Text>{props.text}</Tag.Text>
            {props.icon && <Tag.Image src={props.icon} maxWidth={props.maxWidthImg} />}
            <Button href="/" type="link" text={props.textButton} style={{marginTop: 60}} width={230} />
        </Tag.Container>
    );
}

export default SuccessMsg;