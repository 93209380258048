import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;

    @media(max-width: 768px) {
        align-items: center;
        justify-content: center;
    }
`;

export const Label = styled.p`
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    color: #333333;
    margin-top:24px;

    @media(max-width: 768px) {
        width: 100%;
    }
`;

export const Description = styled.p`
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #333333;
    margin-top:5px;
    
    @media(max-width: 768px) {
        width: 100%;
    }
`;