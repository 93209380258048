import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction:row;
    margin-top:43px;
`;

export const Row = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction:row;
`;

export const Image = styled.img`
    margin-right:16px;
    width:36px;
    height:36px;
    border-radius: 50%;
`;

export const Texts = styled.div`
`;

export const Data = styled.p`
    font-weight: 500;
    font-size: 12px;
    line-height: 28px;
    color:#000000;
    margin-left:17px;
`;

export const Name = styled.p`
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color:#000000;
`;

export const Description = styled.p`
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color:#000000;
    margin-top:13px;
`;

export const ImageComment = styled.img`
    max-width: 100%;
    height:auto;
    margin-top:8px;
`;

export const Answers = styled.div`
    border-left:solid 4px #C4C4C4;
    background: #F3F3F3;
    padding: 12px;
    margin-top: 18px;
`;

export const NameAnswers = styled.p`
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color:#000000;
`;

export const DescriptionAnswers = styled.p`
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color:#000000;
    margin-top:4px;
`;

export const ReadyMore = styled.a`
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #0000FF;
    cursor:pointer;
    text-decoration:none;
`;