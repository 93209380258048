
import React from "react";
import * as Tag from "./styles";
import Button from "../Button";

const PersonalData = (props) => {
    
    return (
        <Tag.Container>
            <Tag.Label>Nome completo</Tag.Label>
            <Tag.Description>João Vitor da SIlva</Tag.Description>

            <Tag.Label>Apelido</Tag.Label>
            <Tag.Description>João</Tag.Description>
            <Tag.Description>Os outros usuários verão você assim</Tag.Description>

            <Tag.Label>CPF</Tag.Label>
            <Tag.Description>1111111111-00</Tag.Description>

            <Tag.Label>Gênero</Tag.Label>
            <Tag.Description>Masculino</Tag.Description>

            <Button onClick={() => props.onEdit()} text="Editar" width={195} style={{marginTop: '32px'}} />
        </Tag.Container>
    );
}

export default PersonalData;