import styled from "styled-components";

export const Container = styled.div`
    display: inline-flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
`;

export const Link = styled.a`
    font-size: 14px;
    line-height: 21px;
    font-weight: 500;
    color:#333;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap; 
`;

export const Text = styled.span`
    font-size: 14px;
    line-height: 21px;
    font-weight: 500;
    color:#333;
    white-space: nowrap; 
    margin-right:5px;
`;

export const Icon = styled.img`
    width: 24px;
    height: 24px;
    background-size:contain;
    margin: 0 8px;
`;

export const Links = styled.span`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    margin-top:33px;
`;