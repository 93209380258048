import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction:column;
    background: rgba(0,0,0,0.8);
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 5;
    left:0;
    top:0;
`;

export const Contain = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction:column;
    background: #ffffff;
    max-width: 685px;
    border-radius: 10px;
    padding: 40px 27px;
`;

export const Row = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction:column;
    margin-top: 44px;
`;

export const Link = styled.a`
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color:#0000FF;
    position:absolute;
    left: 20px;
    top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction:row;
    cursor:pointer;
    text-decoration:none;
`;

export const Icon = styled.img`
    width: 13px;
    height: 13px;
    object-fit: contain;
    margin-right:12px;
`;

export const Title = styled.p`
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color:#4F4F4F;
`;