import styled from "styled-components";

export const Container = styled.div`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: ${props => props.background ? props.background : '#FFFFFF'};
    border: 1px solid #BDBDBD;
    border-radius: ${props => props.round ? props.round : '5'}px;
    width: 100%;
    max-width: ${props => props.width ? `${props.width}px` : '100%'};
    height: ${props => props.height ? props.height : '48'}px;
    padding: 16px 14px;


    input {
        border:0!important;
        width: 100%;
        outline:none;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        font-family: 'Montserrat',sans-serif!important;
        background:transparent;

        ::placeholder,
        ::-webkit-input-placeholder {
            color: #BDBDBD;
        }
        :-ms-input-placeholder {
            color: #BDBDBD;
        }
    }
`;

export const Input = styled.input`
    border:0!important;
    width: 100%;
    outline:none;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    font-family: 'Montserrat',sans-serif!important;
    background:transparent;

    ::placeholder,
    ::-webkit-input-placeholder {
        color: #BDBDBD;
    }
    :-ms-input-placeholder {
        color: #BDBDBD;
    }
`;

export const Icon = styled.img`
    width: ${props => props.height ? props.height/2 : 48/2}px;
    height: ${props => props.height ? props.height/2 : 48/2}px;
    background-size:contain;
    margin-right:16px;
`;

export const IconRight = styled.img`
    width: ${props => props.height ? props.height/2 : 48/2}px;
    height: ${props => props.height ? props.height/2 : 48/2}px;
    background-size:contain;
    margin-left:16px;
`;