
import React, { useState } from "react";
import Rating from "../Rating";
import Answers from "../Answers";
import * as Tag from "./styles";
import { CropString } from "../../lib/Util";

const Comment = (props) => {
    const [readyMore, setReadyMore] = useState(false);
    return (
        <Tag.Container>
            <Tag.Image src={props.item.image} />
            <Tag.Texts>
                <Tag.Name>{props.item.name}</Tag.Name>
                <Tag.Row>
                    <Rating hideNumber align="flex-start" marginTop={0} />
                    <Tag.Data>{props.item.date}</Tag.Data>
                </Tag.Row>
                {props.item.comment && <Tag.Description>{!readyMore ? CropString(props.item.comment, 100, '...') : props.item.comment} {!readyMore && <Tag.ReadyMore onClick={() => setReadyMore(true)}>Ler Mais</Tag.ReadyMore>}</Tag.Description>}
                {props.item.attacheds && <Tag.Attacheds>{props.item.attacheds.map((item, index) => <Tag.ImageComment src={item} key={index} />)}</Tag.Attacheds>}
                {props.item.answers && props.item.answers.map((item,index) => <Answers item={item} key={index} />)}
            </Tag.Texts>
        </Tag.Container>
    );
}

export default Comment;