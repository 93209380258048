import styled from "styled-components";

export const Content = styled.div`
    display:flex;
    min-width: 200px;
    height: 145px;
    background: #FFFFFF;
    border: 1px solid #BDBDBD;
    border-radius: 10px;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    margin-left: 25px;

    @media(max-width: 768px) {
        min-width: 148px;
        height: 106px;
        margin-left: 17px;
    }
`;

export const BoxImage = styled.a`
    width: 100%;
    height: 91px;
    overflow:hidden;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    display:inline-block;
    position:relative;
    cursor:pointer;

    @media(max-width: 768px) {
        height: 66px;
    }
`;

export const Image = styled.img`
    width: 100%;
    height: 91px;
    object-fit:cover;
    box-shadow: 2px 0px 2px rgba(0, 0, 0, 0.25);
    position:absolute;

    @media(max-width: 768px) {
        height: 66px;
    }
`;

export const BoxBottom = styled.div`
    display:flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
    padding: 0 12px 14px;

    @media(max-width: 768px) {
        padding: 0 10px 7px;
    }
`;

export const Title = styled.span`
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #333333;
    white-space: nowrap;
    flex: 1;
    overflow: hidden;
`;

export const Icon = styled.img`
    width: 21px;
    height: 21px;
    object-fit: contain;
`;
