
import React, { useState } from "react";
import * as Tag from "./styles";
import IcoArchive from  "../../assets/ico_archive.svg";
import IcoSearch from  "../../assets/ico_search.svg";
import InputText from "../InputText";
import Tabs from "../Tabs";
import PreviewMessageChat from "../PreviewMessageChat";
import MessageActions from "../MessageActions";

const tabs = [
    {
        id : 1,
        title : 'Comprar',
    },
    {
        id : 2,
        title : 'Vender'
    }
]

const SidebarChat = (props) => {
    const [showActions, setShowActions] = useState(false);
    const [positionAction, setPositionAction] = useState({x : 0, y: 0});
    function showActionsItem(x,y){
        setPositionAction({x, y});
        setShowActions(true);
    }
    return (
        <Tag.Container onClick={() => props.showChatDetail()}>
            <Tag.Top>
                <Tag.Icon src={IcoArchive} />
                <InputText placeholder="Buscar" iconRight={IcoSearch} round={50} height={40} />
            </Tag.Top>

            <Tabs style={{marginTop: '24px'}} items={tabs} active={1} height={32} round={50} inativeColorText="#828282" />

            <Tag.ContentMessages>
                {showActions && <MessageActions style={{top: positionAction.y - 180, right: positionAction.x, zIndex : 3}} onBlur={() => setShowActions(false)} />}
                <Tag.Messages>
                    <PreviewMessageChat showActionsItem={(x,y) => showActionsItem(x,y)} />
                    <PreviewMessageChat showActionsItem={(x,y) => showActionsItem(x,y)} />
                    <PreviewMessageChat showActionsItem={(x,y) => showActionsItem(x,y)} active />
                    <PreviewMessageChat showActionsItem={(x,y) => showActionsItem(x,y)} active />
                    <PreviewMessageChat showActionsItem={(x,y) => showActionsItem(x,y)} active />
                </Tag.Messages>
            </Tag.ContentMessages>

        </Tag.Container>
    );
}

export default SidebarChat;