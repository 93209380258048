import { SET_LOCATION } from '../actions/actionTypes';
const initialState = {
    latitude: '', 
    longitude: ''
};
export const locationReducer = (state = initialState, action) => {
    switch (action.type) {
    case SET_LOCATION:
        return {
            ...state,
            ...action.data
        };
    default:
        return state;
    }
};