import React from "react";
import Header from  '../../components/Header';
import Footer from  '../../components/Footer';
import * as Tag  from "./styles";
import MyPlan from "../../components/MyPlan";
import { paymentStatus } from "../../enum/paymentStatus.ts";

const plans = [
    {
        title : "Imóveis - Plano Plus",
        price : "R$ 80,00",
        expiration : "12/02/2022",
        items : ["0/5 anúncios usados", "1/2 destaques usados"],
        status : paymentStatus.PENDING
    },
    {
        title : "Automóveis - Plano Premium",
        price : "R$ 120,00",
        expiration : "12/02/2022",
        items : ["0/5 anúncios usados", "1/2 destaques usados"],
        status : paymentStatus.PAID
    }
]

const MyPlans = (props) => {
    return (
        <Tag.Container>
            <Header account />
            <Tag.Content>
                <Tag.Top>
                    <Tag.Title>Seus planos</Tag.Title>
                    <Tag.Link>Ver outros planos disponíveis</Tag.Link>
                </Tag.Top>
                <Tag.Plans>
                    {plans.map((item,index) => <MyPlan item={item} key={index} />)}
                </Tag.Plans>
            </Tag.Content>
            <Footer />
        </Tag.Container>    
    );
}

export default MyPlans;