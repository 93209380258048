import React, { useState } from "react";
import * as Tag from "./styles";
import SliderImages from "../SliderImages";
import ProductActions from "../ProductActions";
import IcoFav from "../../assets/ico_fav.svg";
import IcoFavAct from "../../assets/ico_fav_act.svg";
import IcoMore from "../../assets/ico_more.svg";
import IcoViews from "../../assets/ico_views.svg";
import ProductActionsOwner from "../ProductActionsOwner";
import { useNavigate } from "react-router-dom";

const ProductThumb = ({ product, owner, showPopupActions }) => {
    const [actions, setActions] = useState(false);
    const history = useNavigate();

    return (
        <Tag.Container>
            {product.images && <SliderImages images={product.images} height={225} />}
            <Tag.Texts>
                <Tag.Title href={`/detail/${product.id}`}>{product.name}</Tag.Title>
                {!owner &&<Tag.Date>{product.date}</Tag.Date>}
            </Tag.Texts>

            {product.company && <Tag.Company><Tag.CompanyIcon src={product.company.icon} />{product.company.title}</Tag.Company>}
            {product.seller && <Tag.Company><Tag.CompanyIcon src={product.seller.icon} />{product.seller.title}</Tag.Company>}
            
            {owner &&
            <Tag.Bottom>

                <Tag.Views><Tag.Icon src={IcoViews} style={{marginRight: '8px'}} />{product.views}</Tag.Views>

                <Tag.Buttons>
                    <Tag.Date style={{margin : '0 0 5px 0'}}>{product.date}</Tag.Date>
                    <Tag.Link onClick={() => setActions(true)} >
                        <Tag.Icon src={IcoMore} />
                    </Tag.Link>
                </Tag.Buttons>

                {actions && <ProductActionsOwner
                    actionHideAll={() => { showPopupActions("Deseja ocultar todos os anúncios deste vendedor? Está ação não pode ser revertida.")}}
                    actionDenounce={() => { showPopupActions("Deseja denunciar o anúncio?")}}
                    actionDenounceHide={() => { showPopupActions("Deseja denunciar o anúncio e ocultar todas as publicações deste vendedor? Está ação não pode ser revertida.")}}
                    onBlur={() => setActions(false)}
                />}

            </Tag.Bottom>}

            {!owner &&
            <Tag.Bottom>

                {product.old_price &&
                <Tag.Prices>
                    <Tag.OldPrice>{product.old_price}</Tag.OldPrice>
                    <Tag.NewPrice>{product.price}</Tag.NewPrice>
                </Tag.Prices>
                }
                
                {!product.old_price && <Tag.Price>{product.price}</Tag.Price>}

                <Tag.Buttons>
                    <Tag.Link>
                        <Tag.Icon src={product.favorite ? IcoFavAct : IcoFav} />
                    </Tag.Link>
                    <Tag.Link onClick={() => setActions(true)} >
                        <Tag.Icon src={IcoMore} />
                    </Tag.Link>
                </Tag.Buttons>

                {actions && <ProductActions
                    actionHideAll={() => { showPopupActions("Deseja ocultar todos os anúncios deste vendedor? Está ação não pode ser revertida.")}}
                    actionDenounce={() => { showPopupActions("Deseja denunciar o anúncio?")}}
                    actionDenounceHide={() => { showPopupActions("Deseja denunciar o anúncio e ocultar todas as publicações deste vendedor? Está ação não pode ser revertida.")}}
                    onBlur={() => setActions(false)}
                />}

            </Tag.Bottom>}

        </Tag.Container>
    );
}

export default ProductThumb;