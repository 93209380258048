import React from "react";
import * as Tag from "./styles";
import ButtonSmall from '../ButtonSmall';
import MenuAccountTop from '../MenuAccountTop';
import UserTopMobile from '../UserTopMobile';
import LinkAppsMobile from '../LinkAppsMobile';

import IcoChat from "../../assets/ico_chat.svg";
import IcoPackage from "../../assets/ico_package.svg";
import IcoHeart from "../../assets/ico_heart.svg";
import IcoBriefcase from "../../assets/ico_briefcase.svg";
import IcoProfile from "../../assets/ico_user_menu.svg";
import IcoSettings from "../../assets/ico_settings.svg";
import IcoLogout from "../../assets/ico_logout.svg";
import { useNavigate } from "react-router-dom";
import { logout } from "../../services/auth";

const MenuMobile = (props) => {
    const history = useNavigate();

    function logOut(){
        props.setShowMenu(false);
        setTimeout(() => {
            logout();
            history('/');
        }, 200);
    }

    function goToLink(link){
        props.setShowMenu(false);
        setTimeout(() => {
            history(link);
        }, 200);
    }

    return (
        <Tag.Container active={props.active}>
            <Tag.Top>
                <Tag.TopUser>
                    <UserTopMobile {...props} />
                </Tag.TopUser>
                <Tag.ContButtonTopMenu>
                    {props.isAuthenticated() ?
                        <ButtonSmall href="/acccount/ads-type" type="link" text="Anunciar" width={255} />
                        :
                        <Tag.Link onClick={() => goToLink("/account-type")}>Cadastre-se</Tag.Link>}
                </Tag.ContButtonTopMenu>
            </Tag.Top>
            {props.isAuthenticated() &&
            <Tag.Center>
                <MenuAccountTop icon={IcoChat} onClick={() => goToLink("/account/chat")} text="Chat" />
                <MenuAccountTop icon={IcoPackage} onClick={() => goToLink("/account/my-ads")} text="Meus anúncios" />
                <MenuAccountTop icon={IcoHeart} onClick={() => goToLink("/account/wishlist")} text="Favoritos" />
                <MenuAccountTop icon={IcoBriefcase} onClick={() => goToLink("/account/my-plans")} text="Meus planos" />
                <MenuAccountTop icon={IcoProfile} onClick={() => goToLink("/account/my-profile")} text="Perfil" />
                <MenuAccountTop icon={IcoSettings} onClick={() => goToLink("/account/my-cadastre")} text="Configurações" />
                <MenuAccountTop icon={IcoLogout} text="Sair" onClick={() => logOut()} />
            </Tag.Center>
            }
            <Tag.Bottom>
                <LinkAppsMobile />
            </Tag.Bottom>
        </Tag.Container>
    );
}

export default MenuMobile;