import styled from "styled-components";

export const Container = styled.div`
    max-width: 100%;
    width: 384px;
    height: auto;
    background: #FFFFFF;
    border: 1px solid #CBCBCB;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    margin-right:24px;
    padding: 20px 17px;
    position:relative;

    @media(max-width: 768px) {
        width: 100%;
        margin-right:0;
        border: 0;
        padding: 30px 17px;
        box-shadow: 0px 0px 0px rgb(0 0 0 / 5%);
    }
`;

export const Top = styled.div`
    width: 100%;
    display:flex;
    align-items:center;
    justify-content: space-between;
`;

export const Icon = styled.img`
    width: 28px;
    height: 28px;
    object-fit:contain;
    margin-right: 19px;
`;

export const ContentMessages = styled.div`
    width: calc(100% + 32px);
    height: auto;
    margin-left:-16px;
    margin-top:40px;

    @media(max-width: 768px) {
        height: auto;
    }
`;

export const Messages = styled.div`
    width: 100%;
    height: auto;
    height: 360px;
    overflow-x:hidden;
    overflow-y:auto;

    @media(max-width: 768px) {
        height: auto;
    }
`;

export const ActionsChat = styled.div`
    width: 152px;
    height: 213px;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    position:absolute;
    right:0;
    bottom:50px;
    z-index: 3;
`;
