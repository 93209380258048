
import React, { useState, useEffect } from "react";
import * as Tag from "./styles";
import Button from "../Button";
import IcoPhone from '../../assets/ico_phone-gray.svg';
import LinkButton from "../LinkButton";

const VerificationPhone = (props) => {
    const [counter, setCounter] = useState(59);
    const [enableVerification, setEnableVerification] = useState(false);
    const [codeVerification, setCodeVerification] = useState(['', '','','','','']);

    const handleEnter = (event) => {
        if (event.target.value.length >= 1) {
            const form = event.target.form;
            const index = [...form].indexOf(event.target);
            form.elements[index + 1].focus();
            var code = codeVerification;
            code[index] = event.target.value;
            setCodeVerification(code);
            event.preventDefault();
        }
    };

    useEffect(() => {
        counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
        if(codeVerification.join('').length === 6){
            setEnableVerification(true);
        }
    }, [counter, codeVerification]);

    function sendCode(){
        props.sendCode();
        setCounter(59);
    }

    function validateCode(){
        const code = codeVerification.join('');
        props.validateCode(code);
    }

    return (
        <Tag.Container>
            <Tag.Text titleBig={props.titleBig}>{props.title}</Tag.Text>

            <Tag.Phone><Tag.Icon src={IcoPhone} /> {props.phone}</Tag.Phone>

            <Tag.Form>

                <Tag.Numbers>
                    <Tag.Number onKeyUp={handleEnter} placeholder="0" maxLength={1} />
                    <Tag.Number onKeyUp={handleEnter} placeholder="0" maxLength={1} />
                    <Tag.Number onKeyUp={handleEnter} placeholder="0" maxLength={1} />
                    <Tag.Number onKeyUp={handleEnter} placeholder="0" maxLength={1} />
                    <Tag.Number onKeyUp={handleEnter} placeholder="0" maxLength={1} />
                    <Tag.Number onKeyUp={handleEnter} placeholder="0" maxLength={1} />
                </Tag.Numbers>

                {counter !== 0 ? <Tag.Resend>Reenviar código em 0:{counter}</Tag.Resend> : <Tag.Link onClick={() => sendCode()}>Reenviar código</Tag.Link>}

                {props.invalidCode && <Tag.Error>O código digitado está inválido</Tag.Error>}

                <Button onClick={() => validateCode()} type="button" text="Verificar código" style={{marginTop: 60}} width={230} disabled={!enableVerification} />

            </Tag.Form>

            <Tag.Separator />

            <LinkButton onClick={() => props.changeNumber()} text="Inserir outro número" />
        </Tag.Container>
    );
}

export default VerificationPhone;