
import React from "react";
import * as Tag from "./styles";
import IcoClose from "../../assets/ico_close_blue.svg";

const TagFilter = (props) => {
    return (
        <Tag.Container>
            <Tag.Title>{props.text}</Tag.Title>
            <Tag.Icon src={IcoClose} />
        </Tag.Container>
    );
}

export default TagFilter;