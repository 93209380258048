import styled from "styled-components";

export const Container = styled.div`
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    width: auto;
    position: fixed;
    height: 100vh;
    background:#fff;
    left: ${props => props.active ? `0` : `-100`}%;
    top:0;
    z-index:999;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    transition: 0.5s;
`;

export const Top = styled.div`
    width: 100%;
    padding: 19px;
`;

export const TopUser = styled.div`
    width: 100%;
    padding-bottom: 15px;
`;

export const Center = styled.div`
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    height: 100%;
`;

export const Bottom = styled.div`
    width: 100%;
    padding: 19px;
`;

export const ContButtonTopMenu = styled.div`
    border-top: 1px solid #E5E5E5;
    border-bottom: 1px solid #E5E5E5;
    padding: 20px 0;
    width: 100%;
`;

export const Link = styled.a`
    min-width: 184px;
    display:inline-block;
    font-size:16px;
    line-height:24px;
    font-weight: 400;
    color:#333333;
    text-decoration:none;
`;

export const LinkBottom = styled.a`
    font-size:14px;
    line-height:21px;
    font-weight: 500;
    color:#333333;
    text-decoration:none;
`;