
import React, { useState } from "react";
import * as Tag from "./styles";
import CardCheckout from "../CardCheckout";
import IcoVisa from "../../assets/ico_visa.svg";
import IcoMastercard from "../../assets/ico_mastercard.svg";

const items = [
    {
        id : 'visa',
        icon: IcoVisa,
        title: '**** **** **** 4087'
    },
    {
        id : 'mastercard',
        icon: IcoMastercard,
        title: '**** **** **** 3456'
    }
]

const CardListCheckout = (props) => {
    const [active, setActive] = useState(null);

    return (
        <Tag.Container>
            {items.map((item, index) => 
                <CardCheckout key={index} item={item} active={active === item.id} setActive={(id) => setActive(id)} noCheck={props.noCheck} />
            )}
        </Tag.Container>
    );
}

export default CardListCheckout;