import React from "react";
import Button from "../Button";
import * as Tag from "./styles";
import DropDownList from "../DropDownList";
import CheckBoxList from "../CheckBoxList";
import InputSlider from "../InputSlider";
import InputText from "../InputText";
import orderBy from "../../constants/ordersby";
import estados from "../../constants/estados";
import tipoVendedor from "../../constants/tipoVendedor";

const FiltersSearch = (props) => {
    return (
        <Tag.Container>
            <Tag.Row>
                <DropDownList items={orderBy} placeholder={"Ordenar por"} style={{zIndex: "2"}} />
            </Tag.Row>
            <Tag.Row>
                <DropDownList items={orderBy} placeholder="Localização" style={{zIndex: "1"}} />
            </Tag.Row>
            <Tag.Row style={{marginBottom: "30px"}}>
                <Tag.Label>Raio de busca</Tag.Label>
                <InputSlider step={1} start={5} min={1} max={30} width="100%" toltipRight=" km" />
            </Tag.Row>
            <Tag.Row>
                <Tag.Label>Estado</Tag.Label>
                <CheckBoxList items={estados} />
            </Tag.Row>
            <Tag.Row>
                <Tag.Label>Tipo de vendedor</Tag.Label>
                <CheckBoxList items={tipoVendedor} />
            </Tag.Row>
            <Tag.Row>
                <Tag.Label>Preço</Tag.Label>
                <Tag.Col>
                    <InputText placeholder="Mínimo" />
                    <InputText placeholder="Máximo" />
                </Tag.Col>
            </Tag.Row>
            <Tag.Row>
                <DropDownList items={orderBy} placeholder="Categoria" style={{zIndex: "0", marginTop: "20px"}} />
            </Tag.Row>
            <Button text="Filtrar" width={184} style={{marginTop: '36px'}} onClick={() => props.onFilter()} />
        </Tag.Container>
    );
}

export default FiltersSearch;