import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    background: #FFFFFF;
    border: 1px solid #CBCBCB;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    padding:24px 16px;
    width: 100%;
    margin-top:24px;
`;

export const Title = styled.p`
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #333333;
`;