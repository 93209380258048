
import React from "react";
import * as Tag from "./styles";

const NumbersProfile = (props) => {
    return (
        <Tag.Container>
            {props.numbers.map((item, index) =>
            <Tag.Number key={index}>
                <Tag.Title>{item.title}</Tag.Title>
                <Tag.Num>{item.number}</Tag.Num>
            </Tag.Number>)}
           
        </Tag.Container>
    );
}

export default NumbersProfile;