import styled from "styled-components";

export const Buttons = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction:row;
    margin-top:36px;

    >a{
        margin-right: 24px;
    }

    @media(max-width: 768px) {
        flex-direction:column-reverse;
        align-items: center;
        justify-content: center;

        >a{
            margin-right: 0px;
            margin-top: 5px;
        }
    }
`;

export const Icon = styled.img`
    width:70px;
    height:70px;
    object-fit:contain;
`;

export const Title = styled.p`
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #333333;
    margin-top:17px;
`;

export const Description = styled.p`
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #333333;
    margin-top:20px;
`;