
import React from "react";
import * as Tag from "./styles";
import Button from "../Button";
import ButtonOutline from "../ButtonOutline";
import LinkButton from "../LinkButton";

const SuccessDraft = (props) => {
    return (
        <Tag.Container>
            <Tag.Text>Deseja salvar como rascunho?</Tag.Text>
            <Button onClick={() => props.setDraft()} text="Criar rascunho" style={{marginTop: 40}} width={230} />
            <ButtonOutline onClick={() => props.setDraft()} text="Não salvar" style={{marginTop: 20}} width={230} />
            <LinkButton onClick={() => props.setDraft()} text="Voltar" style={{marginTop: 20}} color="#333333" width={230} />
        </Tag.Container>
    );
}

export default SuccessDraft;