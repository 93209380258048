import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 100vh;
    flex-direction: column;
    overflow:hidden;
`;

export const Content = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    padding: 77px 20px 120px;
    background:#fff;
    width: 100%;
    max-width: 1020px;
    flex: 1;

    @media(max-width: 1280px) {
        padding: 77px 40px 120px;
    }

    @media(max-width: 1200px) {
        padding: 77px 20px 120px;
    }

    @media(max-width: 768px) {
        padding: 20px 20px 40px;
        align-items: center;
        justify-content: flex-start;
    }
`;

export const BoxTop = styled.div`
    flex: 1;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: row;
`;

export const BoxInfos = styled.div`
    flex: 1;
    max-width: 670px;
    margin-left:24px;

    @media(max-width: 960px) {
        display:none;
    }
`;

export const BoxChart = styled.div`
    width: 100%;
    background: #FFFFFF;
    border: 1px solid #E5E5E5;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    padding: 20px 0;
    margin-top:38px;
`;

export const Filters = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    padding:0 20px 15px;

    @media(max-width: 960px) {
        flex-direction: column;
    }
`;

export const Date = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;

    @media(max-width: 960px) {
        margin-bottom:20px;
    }
`;