import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
    margin-top:55px;
    position:relative;

    @media(max-width: 768px) {
        margin-top:35px;
    }
`;

export const IconMore = styled.img`
    width:22px;
    height:22px;
    object-fit:contain;
    cursor:pointer;
`;


export const Texts = styled.p`
    width: 100%;
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    display: flex;
    align-items: center;
    color: #333333;
    
    @media(max-width: 768px) {
        font-size: 18px;
        line-height: 27px;
    }
`;

export const Icon = styled.img`
    width: 85px;
    height: 36px;
    margin-right: 10px;
    object-fit:contain;

    @media(max-width: 768px) {
        width: 75px;
        height: 26px;
    }
`;
