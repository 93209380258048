import React, { useState, useEffect } from "react";
import Header from  '../../components/Header';
import Footer from  '../../components/Footer';
import Button from  '../../components/Button';
import SuccessMsg from  '../../components/SuccessMsg';
import InputPassword from "../../components/InputPassword";
import IcoPassword from '../../assets/ico_lock.svg';
import IcoEmail from '../../assets/ico_email.svg';
import ImgSuccessPsswordRecovery from '../../assets/success_password_recovery.svg';
import * as Tag  from "./styles";
import LoaderFullScreen from "../../components/LoaderFullScreen";
import api from "../../services/api";
import { useParams } from 'react-router-dom';

const ChangePassword = (props) => {
    const { token } = useParams();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [passwordConfirm, setPasswordConfirm] = useState("");
    const [errors, setErrors] = useState([]);
    const [success, setSuccess] = useState(false);
    const [loader, setLoader] = useState(false);

    async function handleChangePassword(e){
        e.preventDefault();

        if (!password || !passwordConfirm) {
            setErrors(["Preencha todos os dados para se continuar"]);
        } else {
            if(passwordConfirm !== password){
                setErrors(["As senhas digitadas não conferem"]);
                return;
            }
            try {
                setLoader(true);
                const result = await api.post("/user/reset/password", { token, email, password });
                setLoader(false);
                console.log(result);
                setErrors([]);
                setSuccess(true);
            } catch (err) {
                console.log(err);
                if(err.response.data.errors){
                    setErrors(err.response.data.errors);
                }else{
                    setErrors([err.response.data.error]);
                }
            }finally{
                setLoader(false);
            }
        }

    };
    
    useEffect(() => {
        checkToken();
    },[]);

    async function checkToken(){
        try {
            setLoader(true);
            const result = await api.post("/user/password/check/token", { token });
            setLoader(false);
            if(result?.data?.status){
                setEmail(result.data.email);
            }
        } catch (err) {
            console.log(err);
        }finally{
            setLoader(false);
        }
    }

    return (
        <Tag.Container>
            <Header />
            <Tag.Content>

                {loader && <LoaderFullScreen />}
                
                {!success && <Tag.Form onSubmit={handleChangePassword}>
                    <Tag.Title>Pronto! Agora só falta definir uma nova senha</Tag.Title>
                    <Tag.Email><Tag.Icon src={IcoEmail} /> {email}</Tag.Email>

                    <Tag.Row style={{flexDirection: "row"}}>
                        <InputPassword icon={IcoPassword} placeholder="Senha" onChange={e => setPassword(e.target.value)} value={password} togglePassword />
                    </Tag.Row>

                    <Tag.Row style={{flexDirection: "column"}}>
                        <InputPassword icon={IcoPassword} placeholder="Repitir senha" onChange={e => setPasswordConfirm(e.target.value)} value={passwordConfirm} togglePassword />
                        <Tag.Obs>Mínimo 8 caracteres</Tag.Obs>
                    </Tag.Row>

                    {errors?.length > 0 && <Tag.Row>
                        <Tag.Errors>{errors.map((error) => <>{`- ${error}`}<br/></> )}</Tag.Errors>
                    </Tag.Row>}

                    <Button type="submit" text="Definir senha" style={{marginTop: 58}} width={230} />
                </Tag.Form>}

                {success && <SuccessMsg text="Pronto! Sua senha foi atualizada com sucesso." icon={ImgSuccessPsswordRecovery} textButton="Ir à home" />}
            </Tag.Content>
            <Footer />
        </Tag.Container>
    );
}

export default ChangePassword;