import React, { useEffect, useState } from "react";
import * as Tag from "./styles";
import InputText from "../InputText";
import DropDownList from "../DropDownList";
import Button from "../Button";

const BannerSearch = (props) => {
    const [width, setWidth] = useState(window.innerWidth);
    const updateDimensions = () => {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    }, []);

    return (
        <Tag.Container image={props.image}>
            <Tag.Content>
                <Tag.Title>{props.title}</Tag.Title>
                <Tag.Filters action="/search">

                    {width > 768 && props.filters && props.filters.map((item, index) => 
                        <DropDownList items={item.filters} placeholder={item.title} name={item.name} key={index} round={20} width={197} height={36} style={{marginRight:'16px'}} />
                    )}

                    <InputText placeholder="O que está procuranto?" id="term" name="term" round={50} height={36} style={width > 768 ? { marginRight: '32px'} : null} />

                    <Button text="Buscar" height={36} width={136} style={width > 768 ? null : { marginTop: '32px'}} />

                    <input type="hidden" id="category" name="category" value={props.category} />

                </Tag.Filters>
            </Tag.Content>
        </Tag.Container>
    );
}

export default BannerSearch;