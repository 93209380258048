import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
`;

export const Line = styled.span`
    display:inline-block;   
    width: 100px;
    height: 1px;
    background: #BDBDBD;
    margin: 0 10px;
`;

export const Text = styled.span`
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    color: #BDBDBD;
`;