import styled from "styled-components";

export const Container = styled.div`
    display: inline-flex;
    align-items: flex-start;
    justify-content: flex-start;
    background: #FFFFFF;
    border: 1px solid #C4C4C4;
    border-radius: 10px;
    margin-top:12px;
    padding: 12px;
    display: inline-flex;
    width: 100%;
`;

export const Image = styled.img`
    width: ${props => (props.width ? props.width : `20`)}px;
    height: ${props => (props.height ? props.height : `20`)}px;
    margin-top: 4px;
`;

export const Texts = styled.div`
    display: inline-flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin-left: 16px;
`;

export const Title = styled.p`
    font-size: 16px;
    line-height:24px;
    color:#333333;
    font-weight: 400;
    display: inline-block;
`;

export const Date = styled.p`
    font-size: 14px;
    line-height:21px;
    color:#333333;
    font-weight: 400;
    display: inline-block;
`;

export const Link = styled.a`
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    line-height:24px;
    color:#0000FF;
    font-weight: 400;
    margin-top:6px;
`;