import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction:column;
    width: 100%;
    overflow-y: scroll;
    max-height: 495px;
`;

export const Qty = styled.p`
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color:#000;
`;