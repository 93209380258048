import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 100vh;
    flex-direction: column;
`;

export const Content = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    padding: 52px 60px 80px;
    background:#fff;
    width: 100%;
    max-width: 1440px;
    flex: 1;

    @media(max-width: 768px) {
        padding: 45px 20px;
    }
`;

export const Title = styled.h2`
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    color: #333333;
    margin-top:64px;

    @media(max-width: 768px) {
        font-size: 18px;
        line-height: 27px;
        margin-top:48px;
    }
`;

export const Row = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
    margin-top: 40px;

    @media(max-width: 768px) {
        flex-direction: column;
    }
`;

export const BoxFaq = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    width: 100%;
`;

export const Link = styled.a`
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #0000FF;
    margin-top:30px;
    text-decoration:none;
    cursor:pointer;
`;

export const Categories = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 20px;

    @media(max-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
    }
`;