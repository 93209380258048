
import React, { useState } from "react";
import * as Tag from "./styles";
import IcoUp from "../../assets/ico_arrow_up_gray.svg";
import IcoDown from "../../assets/ico_arrow_down_gray.svg";

const BoxFormAccount = (props) => {
    const [showDetails, setShowDetail] = useState(false);

    return (
        <Tag.Container>
            {props.title &&
                <Tag.BoxTop>
                    <Tag.Title>{props.title}</Tag.Title>
                    <Tag.IconToggle src={showDetails ? IcoUp : IcoDown} onClick={() => setShowDetail(!showDetails)} />
                </Tag.BoxTop>
            }

            <Tag.BoxContent open={showDetails || props.active}>
                {props.children}
            </Tag.BoxContent>

        </Tag.Container>
    );
}

export default BoxFormAccount;