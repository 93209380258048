import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: row;
    padding:20px 0;
    padding-bottom: ${props => props.first ? '0': '20'}px;
    width: 100%;
    border-top: ${props => props.first ? '1px solid #E5E5E5': '0'};
    margin-top: ${props => props.first ? '22': '0'}px;

    @media(max-width: 768px) {
        flex-direction: column;
    }
`;

export const Text = styled.p`
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    display: flex;
    align-items: center;
    color: #333333;
`;

export const Checks = styled.div`
    width: 150px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
`;

export const CheckCont = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex:1;
    
    @media(max-width: 768px) {
        margin-top: 10px;
    }
`;

export const TextCheck = styled.p`
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #333333;
    display:none;
    
    @media(max-width: 768px) {
        margin: 0px 30px 0 10px;
        display:flex;
    }
`;

export const Check = styled.span`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 24px;
    height: 24px;
    background: #FFFFFF;
    border: 1px solid #4F4F4F;
    border-radius: 2px;

    :after{
        content: "";
        background-color: ${props => props.active ? "#0000FF" : "transparent"};
        width: 16px;
        height: 16px;
        border-radius: 2px;
    }
`;