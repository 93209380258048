
import React from "react";
import * as Tag from "./styles";
import SuccessAds from '../../assets/ico_ads_success.svg';
import Button from "../Button";
import ButtonOutline from "../ButtonOutline";

const SuccessMsgCreateAds = (props) => {
    return (
        <Tag.Container>
            <Tag.Text>Seu anúncio está em moderação e em breve será publicado!</Tag.Text>
            <Tag.Image src={SuccessAds} />
            <Tag.Links style={{textAlign:"center"}}>
                <Button href="/account/my-ads" type="link" text="Ver meus anúncios" width={242} />
                <ButtonOutline href="/account/ads-type" type="link" text="Criar outro anúncio" width={242} style={{marginTop: '20px'}} />
            </Tag.Links>
        </Tag.Container>
    );
}

export default SuccessMsgCreateAds;