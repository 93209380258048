import React from "react";
import * as Tag from "./styles";
import IcoUser from "../../assets/ico_user.svg";
import IcoUserBlue from "../../assets/ico_user_blue.svg";
import IcoClose from "../../assets/ico_close.svg";
import ButtonSmall from "../ButtonSmall";
import { useNavigate } from "react-router-dom";

const UserTopMobile = (props) => {
    const history = useNavigate();

    function signin(){
        props.setShowMenu(false);
        setTimeout(() => {
            history('/signin');
        }, 200);
    }
    
    return (
        <Tag.Container>
            {props.isAuthenticated() ?
            <Tag.Link>
                <Tag.Icon src={IcoUserBlue} width={32} height={32} />
                <Tag.Name>{props?.user?.name}</Tag.Name>
            </Tag.Link> : 
                <ButtonSmall onClick={() => signin()} width={131} height={40} icon={IcoUser} text="Entrar" background="#0000FF" color="#ffffff" />
            }
            <Tag.Link onClick={() => props.setShowMenu(false)}>
                <Tag.Icon src={IcoClose} width={25} height={25} right={0} />
            </Tag.Link>
        </Tag.Container>
    );
}

export default UserTopMobile;