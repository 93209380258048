
import React from "react";
import * as Tag from "./styles";
import Button from "../Button";
import Popup from "../Popup";

const PopupSuccessMsg = (props) => {
    return (
        <Popup maxWidth={800} onBlur={() => props.onOk()} noCloseBlur>
            <Tag.Container>
                <Tag.Text>{props.text}</Tag.Text>
                {props.icon && <Tag.Image src={props.icon} maxWidth={props.maxWidthImg} />}
                <Button onClick={() => props.onOk()} text={props.textButton} style={{marginTop: 60}} width={230} />
            </Tag.Container>
        </Popup>
    );
}

export default PopupSuccessMsg;