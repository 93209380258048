import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    height: auto;
    padding: 0 12px 36px;
    background: #FAFAFA;
    border: 1px solid #CBCBCB;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;

export const Row = styled.div`
    width: 100%;
    height: auto;
    margin-top: 16px;
`;

export const Col = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    flex: 1;

    div{
        width: calc(50% - 6px);
    }
`;

export const Label = styled.label`
    width: 100%;
    height: auto;
    margin-top: 16px;
    padding-bottom: 10px;
    display:inline-block;
`;