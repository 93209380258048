
import React, { useRef, useEffect, useState } from "react";
import * as Tag from "./styles";
import IcoClose from "../../assets/ico_close_gray.svg";

const Popup = (props) => {
    const containerRef = useRef(null);
    const [opacity, setOpacity] = useState(0);

    useEffect(() => {
        containerRef.current.focus();
        setOpacity(1);
    },[containerRef]);

    function focus(){
        containerRef.current.focus();
    }
    function blur(){
        setOpacity(0);
        setTimeout(() => {
            props.onBlur();
        },500)

    }

    return (
        <Tag.Container style={{opacity: opacity}}>
            <Tag.Contain maxWidth={props.maxWidth} ref={containerRef} onLoad={focus} tabIndex={0} onBlur={props.noCloseBlur ? null : blur}
            paddingTop={props.paddingTop}
            paddingLeft={props.paddingLeft}
            paddingRight={props.paddingRight}
            paddingBottom={props.paddingBottom}
            >
                {props.closeButton && <Tag.Close src={IcoClose} onClick={blur} />}
                {props.title && <Tag.Title closeButton={props.closeButton} smallTitle={props.smallTitle}>{props.title}</Tag.Title>}
                {props.children}
            </Tag.Contain>
        </Tag.Container>
    );
}

export default Popup;