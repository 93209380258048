import React from "react";
import * as Tag from "./styles";
import IcoPhone from "../../assets/ico_phone.svg";
import IcoMail from "../../assets/ico_mail.svg";
import IcoWhatsApp from "../../assets/ico_whatsapp.svg";

const ContactsFooter = (props) => {
    return (
        <Tag.Container>
            <Tag.Title>{props.title}</Tag.Title>
            <Tag.Link><Tag.Icon src={IcoPhone} />0880 234 133 255</Tag.Link>
            <Tag.Link><Tag.Icon src={IcoMail} />foifacil@atendimento.com</Tag.Link>
            <Tag.Link><Tag.Icon src={IcoWhatsApp} />Abrir Whatsapp</Tag.Link>
        </Tag.Container>
    );
}

export default ContactsFooter;