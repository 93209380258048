import styled from "styled-components";

export const Container = styled.div`
    max-width: 100%;
    width: 384px;
    height: auto;
    background: #FFFFFF;
    border: 1px solid #E5E5E5;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    margin-left:24px;
    padding:18px 18px;

    @media(max-width: 768px) {
        margin-left:0;
        border: 0;
        box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.05);
    }

`;

export const Top = styled.div`
    max-width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    display: flex;
`;

export const Link = styled.a`
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #0000FF;
    margin-right: 15px;
    text-decoration:none;
    cursor:pointer;
`;

export const Share = styled.img`
    width: 20px;
    height: 20px;
    object-fit:contain;
`;

export const Buttons = styled.div`
    max-width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    display: flex;
    margin-top:32px;
`;

export const Views = styled.span`
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #333333;
`;

export const IconViews = styled.img`
    width: 20px;
    height: 20px;
    object-fit:contain;
    margin-right:10px;
`;

export const Title = styled.h4`
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    color: #333333;
    margin-top:10px;

    @media(max-width: 768px) {
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
    }
`;

export const Data = styled.p`
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #333333;
    margin-top:6px;
`;

export const Description = styled.p`
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #333333;
    margin-top:42px;
`;

export const Category = styled.p`
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #333333;
    margin-top:21px;
`;

export const SubCategory = styled.p`
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #333333;
    margin-top:13px;
`;

export const Location = styled.p`
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #333333;
    margin-top:13px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
`;

export const IconLocation = styled.img`
    width: 12px;
    height: 15px;
    object-fit:contain;
    margin-right: 5px;
`;


export const Company = styled.p`
    cursor:pointer;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color:#000;
    display:flex;
    flex-direction:row;
    align-items: center;
    justify-content: flex-start;
    margin: 20px 0 10px;
`;

export const CompanyIcon = styled.img`
    width: 24px;
    height: 24px;
    object-fit: contain;
    border-radius:50px;
    border:solid 1px #E5E5E5;
    margin-right:10px;
`;