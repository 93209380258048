import React, { useState, useEffect } from "react";
import Header from  '../../components/Header';
import Footer from  '../../components/Footer';
import * as Tag  from "./styles";
import api from "../../services/api";
import { useParams, useNavigate } from 'react-router-dom';
import { login } from "../../services/auth";

const AccountVerification = (props) => {
    const history = useNavigate();
    const { token } = useParams();
    
    const [showVerificationEmailError, setVerificationEmailError] = useState(false);

    useEffect(() => {

        async function activeAccount(){
            try {
                const result = await api.post("/user/active", { token });
                if(result?.data?.auth){
                    login(result.data.auth.token);
                    history("/account-validate-phone");
                }
            } catch (err) {
                console.log(err);
                setVerificationEmailError(true);
            }
        }

        activeAccount();
        
    },[history,token]);

    return (
        <Tag.Container>
            <Header />
            <Tag.Content>
                {showVerificationEmailError &&
                    <Tag.Title>Ops, não foi possível verificar a sua conta</Tag.Title>
                }
            </Tag.Content>
            <Footer />
        </Tag.Container>
    );
}

export default AccountVerification;