import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    padding-right:20px;
`;

export const Row = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
    margin-top:32px;
`;

export const Col = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    margin-top:44px;

    @media(max-width: 768px) {
        flex-direction: column;
        a{
            margin-top:10px;
            margin-left:0!important;
        }
    }
`;

export const Left = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
`;

export const Icon = styled.img`
    width:28px;
    height:28px;
    object-fit:contain;
    margin-right: 15px;
`;

export const Text = styled.p`
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    display: flex;
    align-items: center;
    color: #333333;
`;

export const LinkText = styled.a`
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    display: flex;
    align-items: center;
    color: #0000FF;
    cursor:pointer;
    text-decoration:none;
`;

export const Link = styled.a`
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    color: #0000FF;
    cursor:pointer;
    text-decoration:none;
`;

export const FormPopup = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    width: 100%;
`;

export const TitlePopup = styled.p`
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    display: flex;
    align-items: center;
    color: #333333;
`;