
import React, { useState, useRef, useEffect } from "react";
import * as Tag from "./styles";

const InputSlider = (props) => {
    const [state, setMin] = useState(props.start);
    const [percent, setPercent] = useState(0);
    const range = useRef();
    const bubble = useRef();

    useEffect(() => {
        setBubble();
    },[state])

    function setBubble() {
        const val = range.current.value;
        const min = range.current.min ? range.current.min : 0;
        const max = range.current.max ? range.current.max : 100;
        const newVal = Number(((val - min) * 100) / (max - min));
        setPercent(newVal);
        bubble.current.style.left = `calc((${newVal}% + (${8 - newVal * 0.15}px) - ${bubble.current.offsetWidth / 2}px))`;
    }

    function onChange(e){
        setMin(e.target.value);
        props.onChange(e.target.value);
    }

    return (
        <Tag.Container>
            <Tag.Range percent={percent} ref={range} value={state} min={props.min} step={props.step} max={props.max} onLoad={setBubble} onChange={(e) => onChange(e)} type="range" />
            <Tag.Text ref={bubble}>{props.toltipLeft}{state}{props.toltipRight}</Tag.Text>
        </Tag.Container>
    );
}

export default InputSlider;