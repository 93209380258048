
import React from "react";
import * as Tag from "./styles";
import InputSlider from "../InputSlider";
import PreviewFinancing from "../PreviewFinancing";

const FormFinancing = (props) => {
    return (
        <Tag.Container>

            <Tag.Form>
                <Tag.Row>
                    <Tag.Label>Valor da entrada</Tag.Label>
                    <InputSlider step={1000} start={145885} min={1000} max={223343} width="100%" toltipLeft="R$ " />
                    <Tag.RowText>
                        <Tag.Text>R$ 1000,00</Tag.Text>
                        <Tag.Text>R$ 223.343,00</Tag.Text>
                    </Tag.RowText>
                </Tag.Row>
                
                <Tag.Row>
                    <Tag.Label>Em quantas parcelas quer pagar?</Tag.Label>
                    <InputSlider step={2} start={12} min={6} max={48} width="100%" toltipRight=" parcelas" />
                    <Tag.RowText>
                        <Tag.Text>6</Tag.Text>
                        <Tag.Text>48</Tag.Text>
                    </Tag.RowText>
                </Tag.Row>
            </Tag.Form>

            <PreviewFinancing {...props} />
            
        </Tag.Container>
    );
}

export default FormFinancing;