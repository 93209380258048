import styled from "styled-components";

export const Container = styled.div`
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    background: #FFFFFF;
    border: 1px solid #BDBDBD;
    border-radius: ${props => props.round ? props.round : '5'}px;
    width: 100%;
    max-width: ${props => props.width ? `${props.width}px` : '100%'};
    height: ${props => props.height ? props.height : '48'}px;
    padding: 16px 14px;
    cursor:pointer;
    position:relative;
`;

export const Title = styled.span`
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color:#333333;
    white-space: nowrap;
    overflow: hidden;
`;

export const Icon = styled.img`
    width: 20px;
    height: 10px;
    background-size:contain;
    margin-left:13px;
`;

export const Iems = styled.div`
    width: calc(100% + 2px);
    min-height: 20px;
    max-height: 107px;
    border-radius:${props => props.round === 0 ? props.round : '5'}px;
    position: absolute;
    left: -1px;
    top: calc(100% - 13px);
    border: solid 1px #BDBDBD;
    display: inline-flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction:column;
    margin-top:10px;
    overflow:hidden;
    overflow-y:scroll;
    background:#fff;
`;

export const Item = styled.span`
    padding: 8px 15px;
    background: ${props => props.selected ? '#0000ff69' : '#fff'};
    color: ${props => props.selected ? '#fff' : '#000'};

    width: 100%;
    :hover{
        background:#0000FF;
        color:#fff;
    }
`;