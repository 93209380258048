import styled from "styled-components";

export const BoxFull = styled.div`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: auto;
    background:#0000FF;
    width: 100%;
`;

export const Container = styled.div`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: auto;
    padding: 12px 60px;
    background:#0000FF;
    width: 100%;
    max-width: 1440px;
    flex-direction: column;

    @media(max-width: 1280px) {
        padding: 12px 15px;
    }
`;

export const Top = styled.div`
    width: 100%;
    flex-direction: row;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
`;

export const Bottom = styled.div`
    width: 100%;
    flex-direction: row;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    margin-top:30px;
`;

export const Logo = styled.img`
    width: 125px;
    height: 50px;
    object-fit: contain;
    cursor:pointer;
`;

export const LinksTopLogged = styled.div`
    flex-direction: row;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
`;

