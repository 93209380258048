import styled from "styled-components";

export const Container = styled.div`
    padding: 92px 0 66px 0;
    position:relative;
    z-index: 1;
    display: flex;
    width: 100%;
    height: auto;
    align-items: center;
    justify-content: center;
    background: url(${props => props.image ? props.image : ''}) no-repeat 50%;
    background-size:cover;

    :before{
        content:"";
        z-index: 2;
        background: rgba(0, 0, 0, 0.7);
        width: 100%;
        height: 100%;
        position:absolute;
        left:0;
        top:0;
    }

    @media(max-width: 1200px) {
        padding: 92px 20px 66px 20px;
    }
    
    @media(max-width: 768px) {
        padding: 60px 20px;
    }
`;

export const Content = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    max-width: 1200px;
    flex: 1;
    position:relative;
    z-index: 3;
`;

export const Title = styled.p`
    color:#fff;
    font-weight: 500;
    font-size: 48px;
    line-height: 59px;

    @media(max-width: 768px) {
        font-size: 24px;
        line-height: 29px;
        text-align:center;
        padding:0 40px;
    }
`;

export const Filters = styled.form`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    width: 100%;
    height: 56px;
    background: #FFFFFF;
    border: 1px solid #CBCBCB;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
    border-radius: 51px;
    padding: 15px;
    margin-top:35px;

    @media(max-width: 768px) {
        flex-direction: column;
        background: transparent;
        border:0;
        box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.15);
        height: auto;
        margin-top:20px;
    }
`;