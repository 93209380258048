import styled from "styled-components";

export const Button = styled.div`
    display: inline-flex;
    align-items: center;
    justify-content: ${props => props.icon ? 'flex-start' : 'center'};
    background: ${props => props.background ? props.background : '#FFCB05'};
    border-radius: 30px;
    width: ${props => props.width ? `${props.width}px` : '100%'};
    flex:direction: row;
    height: ${props => props.height ? `${props.height}px` : '36px'};
    padding:0 16px;
    text-decoration:none;
    cursor:pointer;
`;

export const Link = styled.a`
    display: inline-flex;
    align-items: center;
    justify-content: ${props => props.icon ? 'flex-start' : 'center'};
    background: ${props => props.background ? props.background : '#FFCB05'};
    border-radius: 30px;
    width: ${props => props.width ? `${props.width}px` : '100%'};
    flex:direction: row;
    height: ${props => props.height ? `${props.height}px` : '36px'};
    padding:0 16px;
    text-decoration:none;
    cursor:pointer;
`;

export const Text = styled.span`
    font-size: 16px;
    line-height: 24px;
    color: ${props => props.color ? props.color : '#333333'};
    font-weight: 500;
`;

export const Icon = styled.img`
    width: 24px;
    height: 24px;
    background-size: contain;
    margin-right: 8px;
`;
