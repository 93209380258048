import React, { useState } from "react";
import Header from  '../../components/Header';
import Footer from  '../../components/Footer';
import Button from  '../../components/Button';
import VerificationPhone from  '../../components/VerificationPhone';
import InputText from  '../../components/InputText';
import IcoPhone from '../../assets/ico_phone-gray.svg';
import IcoEmail from '../../assets/ico_email.svg';
import ImgRecoveryEmailSend from "../../assets/recovery_email_send.svg";
import ImgRecoveryPasswordSend from "../../assets/recovery_password_send.svg";
import * as Tag  from "./styles";
import api from "../../services/api";
import LoaderFullScreen from "../../components/LoaderFullScreen";

const EmailRecovery = (props) => {
    const [phone, setPhone] = useState("");
    const [errors, setErrors] = useState([]);
    const [email, setEmail] = useState("");
    const [showVerification, setShowVerification] = useState(false);
    const [recoveryOk, setRecoveryOk] = useState(false);
    const [invalidCode, setInvalidCode] = useState(false);
    const [loader, setLoader] = useState(false);

    async function handleRecovery(e){
        e.preventDefault();
        sendCode();
    };

    async function sendCode(){
        try {
            setLoader(true);
            var phoneTemp = phone.replace('(', '');
            phoneTemp = phoneTemp.replace(')', '');
            phoneTemp = phoneTemp.replace(' ', '');
            phoneTemp = phoneTemp.replace('-', '');
            const result = await api.post("/user/recovery/email", { phone : phoneTemp });
            setLoader(false);
            setShowVerification(true);
            console.log(result);
        } catch (err) {
            console.log(err);
            if(err.response.data.errors){
                setErrors(err.response.data.errors);
            }else{
                setErrors([err.response.data.error]);
            }
        }finally{
            setLoader(false);
        }
    }

    async function validateCode(code){
        try {
            setLoader(true);
            const result = await api.post("/user/validate/sms", { phone, code, recovery_email : true });
            setLoader(false);
            if(result?.data?.status){
                setRecoveryOk(true);
                setEmail(result.data.email);
            }
            console.log(result);
        } catch (err) {
            console.log(err);
            setInvalidCode(true);
        }finally{
            setLoader(false);
        }
    }

    return (
        <Tag.Container>
            <Header />
            <Tag.Content>

                {loader && <LoaderFullScreen />}

                {!showVerification &&
                    <Tag.Form onSubmit={handleRecovery}>
                        <Tag.Title>Informe seu número de celular para validar via SMS</Tag.Title>

                        <Tag.Row style={{flexDirection: "row"}}>
                            <InputText mask="(99) 99999-9999" icon={IcoPhone} placeholder="Celular" onChange={e => setPhone(e.target.value)} value={phone} />
                        </Tag.Row>

                        {errors?.length > 0 && <Tag.Row>
                            <Tag.Errors>{errors.map((error) => <>{`- ${error}`}<br/></> )}</Tag.Errors>
                        </Tag.Row>}

                        <Tag.Image src={ImgRecoveryEmailSend} style={{marginTop: 40}} />

                        <Button type="submit" text="Recuperar email" style={{marginTop: 20}} width={230} />
                    </Tag.Form>
                }

                {!recoveryOk && showVerification &&
                    <VerificationPhone validateCode={(code) => validateCode(code)} sendCode={() => sendCode()} title="Preencha com o código que enviamos a" invalidCode={invalidCode} phone={phone} changeNumber={() => setShowVerification(false)} />
                }

                {recoveryOk &&
                    <>
                        <Tag.Title>Pronto! Seu número foi verificado.</Tag.Title>
                        <Tag.Subtitle>Esse é seu email para login:</Tag.Subtitle>

                        <Tag.Email><Tag.Icon src={IcoEmail} /> {email}</Tag.Email>

                        <Tag.Image src={ImgRecoveryPasswordSend} style={{marginTop: 40}} />

                        <Button href="/signin" type="link" text="Fazer login" style={{marginTop: 20}} width={230} />
                    </>
                }

            </Tag.Content>
            <Footer />
        </Tag.Container>
    );
}

export default EmailRecovery;