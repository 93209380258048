
import React from "react";
import * as Tag from "./styles";
import Button from "../Button";
import InputText from "../InputText";
import LinkButton from "../LinkButton";
import DropDownList from "../DropDownList";

const PersonalDataForm = (props) => {
    
    return (
        <Tag.Container>
            <Tag.Row>
                <Tag.Label>Nome completo</Tag.Label>
                <InputText placeholder="Digite seu nome" />
            </Tag.Row>

            <Tag.Row>
                <Tag.Label>Apelido</Tag.Label>
                <InputText placeholder="Digite seu apelido" />
                <Tag.Obs>Os outros usuários verão você assim</Tag.Obs>
            </Tag.Row>

            <Tag.Row>
                <Tag.Label>CPF</Tag.Label>
                <InputText placeholder="Digite seu email" />
            </Tag.Row>

            <Tag.Row>
                <Tag.Label>Gênero</Tag.Label>
                <DropDownList placeholder="Selecione uma opção" />
            </Tag.Row>
            
            <Tag.Col>
                <Button text="Salvar" onClick={() => props.onSave()} width={195} />
                <LinkButton text="Cancelar" onClick={() => props.onCancel()} width={195} color="#333333" style={{marginLeft: '20px'}} />
            </Tag.Col>
        </Tag.Container>
    );
}

export default PersonalDataForm;