import styled from "styled-components";

export const Container = styled.a`
    margin-top:12px;
    margin-right:16px;
    background: #FFFFFF;
    border: 1px solid #0606BB;
    border-radius: 38px;
    padding:6px 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    cursor:pointer;
`;
export const Title = styled.span`
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color:#0606BB;
    white-space: nowrap;
`;
export const Icon = styled.img`
    width: 18px;
    height: 18px;
    margin-left:24px;
`;