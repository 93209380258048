import React from "react";
import * as Tag from "./styles";
import CheckBox from "../CheckBox";

const CheckBoxList = (props) => {
   
    return (
        <Tag.Container horizontal={props.horizontal}>
            {props.items && props.items.map((item, index) => <CheckBox title={item.title} value={item.id} key={index} marginTop={props.marginTop} />)}
        </Tag.Container>
    );
}

export default CheckBoxList;