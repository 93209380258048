import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    height: 375px;
    background: #FFFFFF;
    border: 1px solid #CBCBCB;
    border-radius: 5px;
    margin:0 10px;
    overflow:hidden;

    :first-child{
        margin-left:0;
    }
    :last-child{
        margin-right:0;
    }

    @media(max-width: 768px) {
        width: 240px;
        height: 280px;
    }
`;

export const BoxImage = styled.div`
    width: 100%;
    height: 200px;

    @media(max-width: 768px) {
        height: 120px
    }
`;

export const Image = styled.img`
    width: 100%;
    height: 100%;
    object-fit:cover;
`;

export const Texts = styled.div`
    padding:24px 16px;
    padding: 24px 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
`;

export const Title = styled.h2`
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: #333333;

    @media(max-width: 768px) {
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
    }
`;

export const Category = styled.a`
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #000000;
    background: #EDEDED;
    border-radius: 54px;
    height: 36px;
    padding: 10px 20px;
    margin-top:24px;
    cursor:pointer;
    text-decoration:none;
`;