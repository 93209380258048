import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction:column;
    width: 100%;
    padding:0;
`;

export const Row = styled.div`
    width: 100%;
    height: auto;
    margin-top: 16px;
`;

export const Label = styled.label`
    width: 100%;
    height: auto;
    margin-top: 16px;
    padding-bottom: 10px;
    display:inline-block;
`;

export const Link = styled.a`
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #0000FF;
    cursor:pointer;
    margin-top:10px;
    display:inline-block;
`;

export const Top = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction:row;
    width: 100%;
    padding: 10px 0;
`;

export const Title = styled.span`
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #4F4F4F;
    width: 100%;
    text-align:center;
`;

export const IconBack = styled.img`
    width: 18px;
    height: 18px;
    object-fit: contain;
`;