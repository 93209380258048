import React from "react";
import * as Tag from "./styles";
import Header from  '../../components/Header';
import Footer from  '../../components/Footer';
import BannerSearch from  '../../components/BannerSearch';
import ProductsCarousel from  '../../components/ProductsCarousel';
import IconGold from  "../../assets/ico_gold_loan.svg";
import IconPersonal from  "../../assets/ico_personal_loan.svg";
import UndrawBuyVehicle from  "../../assets/undraw_buy_vehicle.png";
import Button from "../../components/Button";
import Brand from "../../components/Brand";
import ImageSearchVehicle from  "../../assets/banner_search_vehicle.jpg";
import Hyundai from  "../../assets/hyundai-logo.png";
import Mercedes from  "../../assets/mercedes-benz-logo.png";
import Renault from  "../../assets/renault-logo.png";
import Volkswagen from  "../../assets/volkswagen-logo.png";
import Chevrolet from  "../../assets/chevrolet-logo.png";
import Ford from  "../../assets/ford-logo.png";
import TitleBox from "../../components/TitleBox";

const filters = [
    {
        title : "Marcas",
        name : "brand",
        filters : [
            {
                title : "Marca 1",
                value : "1"
            },
            {
                title : "Marca 2",
                value : "2"
            },{
                title : "Marca 3",
                value : "3"
            }
        ]
    },
    {
        title : "Modelos",
        name : "model",
        filters : [
            {
                title : "Categoria 1",
                value : "1"
            },
            {
                title : "Categoria 2",
                value : "2"
            },{
                title : "Categoria 3",
                value : "3"
            }
        ]
    },
    {
        title : "Anos",
        name : "year",
        filters : [
            {
                title : "Ano 1",
                value : "1"
            },
            {
                title : "Ano 2",
                value : "2"
            },{
                title : "Ano 3",
                value : "3"
            }
        ]
    }
];

const SearchVehicle = (props) => {
    return (
        <Tag.Container>
            <Header />

            <BannerSearch category="veiculos" filters={filters} image={ImageSearchVehicle} title="Encontre aqui seu próximo veículo!" />

            <Tag.Content>

                <Tag.ButtonsSimulate>
                    <Tag.LinkSimulate href="/simulator#emprestimo">
                        <Tag.BoxIcon>
                            <Tag.Icon src={IconGold} />
                        </Tag.BoxIcon>
                        <Tag.TitleLink>Simule um emprestimo</Tag.TitleLink>
                    </Tag.LinkSimulate>
                    <Tag.LinkSimulate href="/simulator#financiamento">
                        <Tag.BoxIcon>
                            <Tag.Icon src={IconPersonal} />
                        </Tag.BoxIcon>
                        <Tag.TitleLink>Simule um financiamento</Tag.TitleLink>
                    </Tag.LinkSimulate>
                </Tag.ButtonsSimulate>

                <TitleBox title="Procure por marca" link="#" />
                <Tag.Brands>
                    <Brand icon={Hyundai} title="Hyundai" />
                    <Brand icon={Mercedes} title="Mercedes-Benz" />
                    <Brand icon={Renault} title="Renault" />
                    <Brand icon={Volkswagen} title="Volksvagen" />
                    <Brand icon={Chevrolet} title="Chevrolet" />
                    <Brand icon={Ford} title="Ford" />
                </Tag.Brands>

                <TitleBox title="Mais relevantes" />
                <ProductsCarousel />

                <Tag.BannerCenter>
                    <Tag.BannerCenterContent>
                        <Tag.TextsBanner>
                            <Tag.TitleBanner>Você é vendedor profissional?</Tag.TitleBanner>
                            <Tag.TextBanner>Crie uma conta empresarial e comece a vender!</Tag.TextBanner>
                            <Button href="#" type="link" text="Criar conta empresarial" width={263} />
                        </Tag.TextsBanner>
                        <Tag.ImageBanner src={UndrawBuyVehicle} />
                    </Tag.BannerCenterContent>
                </Tag.BannerCenter>

                <TitleBox title="Baixaram o preço" />
                <ProductsCarousel />

            </Tag.Content>
            <Footer />
        </Tag.Container>
    );
}

export default SearchVehicle;