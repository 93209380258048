import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 100vh;
    flex-direction: column;
`;

export const Content = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 52px 60px;
    background:#fff;
    width: 100%;
    max-width: 1440px;
    flex: 1;

    @media(max-width: 768px) {
        padding: 40px 20px;
    }
`;

export const Title = styled.h2`
    font-size:24px;
    line-height: 29px;
    color:#333333;
    font-weight: 500;
    width: 140%;
    text-align:center;

    @media(max-width: 768px) {
        width: 100%;
    }
`;

export const Subtitle = styled.h3`
    font-size:16px;
    line-height: 24px;
    color:#333333;
    font-weight: 400;
    width: 140%;
    text-align:center;
    margin-top:12px;

    @media(max-width: 768px) {
        width: 100%;
    }
`;

export const Row = styled.div`
    width: 100%;
    margin-top:36px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
`;

export const Errors = styled.p`
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color:#333333;
    text-decoration:none;
    display:inline-block;  
    border:solid 1px #f00;
    width: 100%;
    padding:5px 10px; 
`;

export const Image = styled.img`
    margin-bottom: 32px;
    max-width: 100%;
`;

export const Form = styled.form`
    width: 385px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    max-width: 100%;
`;