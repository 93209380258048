import styled from "styled-components";

export const Container = styled.div`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: auto;
    padding: 12px 15px;
    background:#0000FF;
    width: 100%;
    flex-direction: column;
`;

export const Top = styled.div`
    width: 100%;
    flex-direction: row;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;
`;

export const Link = styled.a`
    display: inline-flex;
    align-items: center;
    justify-content: center;
`;

export const Icon = styled.img`
    width: ${props => props.width ? props.width : `32`}px;
    height: ${props => props.height ? props.height : `32`}px;
    background-size:contain;
`;