import styled from "styled-components";
import IcoArrowLeft from '../../assets/ico_arrow_left_white.svg';
import IcoArrowRight from '../../assets/ico_arrow_right_white.svg';

export const Container = styled.div`
    width: ${props => props.width ? `${props.width}px` : '100%'};
    height: ${props => props.height ? `${props.height}px` : '100%'};
    position: relative;

    .slick-list{
        width: 100%;
        height: 100%;
        display:inline-block;
        overflow:hidden;
    }

    .slick-slide{
        display:inline-block;
    }

    .slick-arrow{
        width: 34px;
        height: 34px;
        background:transparent;
        box-shadow: none;
        border:0;
        border-radius: 50px;
        top: calc(50% - 17px);
        position:absolute;
        z-index: 2;
        text-indent: -9999em;
        cursor:pointer;
    }

    .slick-prev{
        left:0;
        background:url(${IcoArrowLeft}) no-repeat 50%; 
    }

    .slick-next{
        right:0;
        background:url(${IcoArrowRight}) no-repeat 50%; 
    }

    .slick-arrow.slick-disabled{
        display:none!important;
    }

    .slick-dots{
        position:absolute;
        display: flex!important;
        align-items: center;
        justify-content: center;
        left: 0;
        bottom: 10px;
        width: 100%;
    }
    .slick-dots li{
        display:inline-block;
        margin:0 3px;
        height: 12px;
    }
    .slick-dots li button{
        background: #FFFFFF;
        border: 1px solid #828282;
        width: 10px;
        height: 10px;
        border-radius: 10px;
        text-indent: -500em;
        margin:0;
    }
    .slick-dots .slick-active button{
        background:#0000FF;
        border-color:#0000FF;
    }
`;

export const BoxImage = styled.div`
    width: ${props => props.width ? `${props.width}px` : '100%'};
    height: ${props => props.height ? `${props.height}px` : '100%'};
`;


export const Image = styled.img`
    width: 100%;
    height: 100%;
    background-size: cover;
    object-fit: cover;
`;