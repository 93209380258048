import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;

    @media(max-width: 960px) {
        flex-wrap: wrap;
        padding:0 10px 20px;
    }
`;


export const Number = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: cecnter;
    flex-direction: column;
    width: 150px;
    height: 80px;
    background: #FFFFFF;
    border: 1px solid #E5E5E5;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
    border-radius: 3px;
    padding: 10px 12px;

    @media(max-width: 960px) {
        width: 50%;
        height: auto;
        border: 0;
        box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.05);
    }
`;

export const Title = styled.p`
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    color: #333333;
`;

export const Num = styled.p`
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    display: flex;
    align-items: center;
    color: #333333;
    margin-top:5px;
`;