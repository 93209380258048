import styled from "styled-components";

export const Link = styled.a`
    cursor:pointer;
    text-decoration:none;
`;

export const Content = styled.span`
    display:flex;
    width: 180px;
    height: 145px;
    background: #FFFFFF;
    border: 1px dashed #BDBDBD;
    border-radius: 10px;
    align-items: center;
    justify-content: center;
    flex-direction: row;

    @media(max-width: 768px) {
        width: 113px;
        height: 106px;
    }
`;

export const Text = styled.span`
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #333333;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    display:inline-flex;
    
    @media(max-width: 768px) {
        flex-direction: column;
    }
`;

export const Icon = styled.img`
    width: 21px;
    height: 21px;
    object-fit: contain;
    margin-right:15px;
    
    @media(max-width: 768px) {
        margin-right:0;
        margin-bottom:5px;
    }
`;
