import React, { useRef, useState } from "react";
import * as Tag from "./styles";
import Header from  '../../components/Header';
import Footer from  '../../components/Footer';
import BannerHome from  '../../components/BannerHome';
import BannerHomeSmall from  '../../components/BannerHomeSmall';
import ButtonSearchHome from  '../../components/ButtonSearchHome';
import Categories from  '../../components/Categories';
import ProductsCarousel from  '../../components/ProductsCarousel';
import TitleBox from "../../components/TitleBox";
import LoaderComponent from "../../components/LoaderComponent";
import { useEffect } from "react";
import api from "../../services/api";

const Home = (props) => {
    const [buttonsSearch, setButtonsSearch] = useState([]);
    const [loadButtonsSearch, setLoadButtonsSearch] = useState(false);
    const [categories, setCategories] = useState([]);
    const [loadCategories, setLoadCategories] = useState(false);
    const didMount = useRef(true);

    async function getButtonsSearch(){
        try {
            setLoadButtonsSearch(true);
            const response = await api.get("/contents/home/buttonsCategory");
            console.log(response);
            if(response.data.status){
                setButtonsSearch(response.data.buttons);
            }
            setLoadButtonsSearch(false);

        } catch (err) {
            console.log(err);
        }finally{
            setLoadButtonsSearch(false);
        }
    }

    async function getHighlightsCategory(){
        try {
            setLoadCategories(true);
            const response = await api.get("/contents/home/highlightsCategory");
            console.log(response);
            if(response.data.status){
                setCategories(response.data.data);
            }
            setLoadCategories(false);

        } catch (err) {
            console.log(err);
        }finally{
            setLoadCategories(false);
        }
    }

    useEffect(() => {
        if(didMount.current){
            didMount.current = false;
            getButtonsSearch();
            getHighlightsCategory();
        }
    }, [])

    return (
        <Tag.Container>
            <Header />
            <Tag.Content>

                <BannerHome />

                <Tag.ButtonsSearch>
                    {buttonsSearch && buttonsSearch.map((button, index) =>
                        <ButtonSearchHome
                            key={index}
                            icon={button.image?.url}
                            title={button.title}
                            link={button.url}
                            links={button.links}
                        />
                    )}
                    {loadButtonsSearch && <LoaderComponent />}
                </Tag.ButtonsSearch>
                
                <TitleBox title="Outras categorias" />
                {categories && <Categories categories={categories} />}
                {loadCategories && <LoaderComponent />}

                <TitleBox title="Recomendados para você" link="#" />
                <ProductsCarousel />

                <Tag.DoubleBanner>
                    <BannerHomeSmall />
                    <BannerHomeSmall />
                </Tag.DoubleBanner>

                <TitleBox title="Baixaram o preço" link="#" />
                <ProductsCarousel />

                <TitleBox title="Em alta" link="#" />
                <ProductsCarousel />

            </Tag.Content>
            <Footer />
        </Tag.Container>
    );
}

export default Home;