import React, { useEffect, useRef } from "react";
import * as Tag from "./styles";

const CardActions = (props) => {
    const containerRef = useRef(null);

    useEffect(() => {
        containerRef.current.focus();
    },[containerRef]);

    function focus(){
        containerRef.current.focus();
    }

    return (
        <Tag.Container {...props} ref={containerRef} onLoad={focus} tabIndex={0} onBlur={() => props.onBlur()}>
            <Tag.Link onClick={() => props.actionsFav()}>Favoritar</Tag.Link>
            <Tag.Link onClick={() => props.actionDelete()}>Apagar</Tag.Link>
            <Tag.Link onClick={() => props.actionEdit()}>Editar</Tag.Link>
        </Tag.Container>
    );
}

export default CardActions;