import { createGlobalStyle } from "styled-components";
import "font-awesome/css/font-awesome.css";

export const Globalstyle = createGlobalStyle`
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  outline: 0;
}
body, html {
  background: #ededed;
  background: #ffffff;
  font-family: 'Montserrat', sans-serif!important;
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  height: 100%;
  width: 100%;
}
`;