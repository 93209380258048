import React from "react";
import Header from  '../../components/Header';
import Footer from  '../../components/Footer';
import ImgAccountType from "../../assets/account_type.svg";
import ImgAccountTypeCompany from "../../assets/account_type_company.svg";
import * as Tag  from "./styles";
import ButtonOutline from "../../components/ButtonOutline";

const AccountType = (props) => {
    return (
        <Tag.Container>
            <Header />
            <Tag.Content>
                <Tag.Title>Que tipo de conta deseja?</Tag.Title>

                <Tag.Row>
                    <Tag.ButtonType href="/signup-company">
                        <Tag.Image src={ImgAccountTypeCompany} />
                        <Tag.Text>Conta profissional</Tag.Text>
                    </Tag.ButtonType>
                    <Tag.ButtonType href="/signup">
                        <Tag.Image src={ImgAccountType} />
                        <Tag.Text>Conta pessoal</Tag.Text>
                    </Tag.ButtonType>
                </Tag.Row>

                <ButtonOutline href="/signin" type="link" text="Voltar a login" style={{marginTop: 72}} width={230} />
            </Tag.Content>
            <Footer />
        </Tag.Container>
    );
}

export default AccountType;