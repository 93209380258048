import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction:column;
    background: rgba(0,0,0,0.8);
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 5;
    left:0;
    top:0;
    transition: opacity 0.5s;
`;

export const Contain = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction:column;
    background: #ffffff;
    max-width: ${props => props.maxWidth ? props.maxWidth : '685'}px;
    border-radius: 10px;
    padding-top: ${props => props.paddingTop ? props.paddingTop : '40'}px;
    padding-bottom: ${props => props.paddingBottom ? props.paddingBottom : '40'}px;
    padding-left: ${props => props.paddingLeft ? props.paddingLeft : '27'}px;
    padding-right: ${props => props.paddingRight ? props.paddingRight : '27'}px;
    position:relative;

    @media(max-width: 768px) {
        width: 90%;
    }
`;

export const Close = styled.img`
    width: 24px;
    height: 24px;
    object-fit: contain;
    position:absolute;
    right: 20px;
    top: 20px;
    cursor:pointer;
`;

export const Title = styled.p`
    font-weight: 500;
    font-size: ${props => props.smallTitle ? '18': '24'}px;
    line-height: ${props => props.smallTitle ? '27': '29'}px;
    color:#333333;
    text-align:center;
    margin-top: ${props => props.closeButton ? '32': '0'}px;

    @media(max-width: 768px) {
        font-weight: 400;
        font-size: ${props => props.smallTitle ? '14': '17'}px;
        line-height: ${props => props.smallTitle ? '22': '24'}px;
    }
`;