import styled from "styled-components";

export const Container = styled.ul`
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    min-height: 40px;
    width: 100%;
    flex-wrap: wrap;
    margin-bottom: 70px;
    
    @media(max-width: 768px) {
        display: inline-flex;
        align-items: flex-start;
        justify-content: flex-start;
        overflow-x:scroll;
        flex-wrap: nowrap;
        padding-bottom: 20px;
    }
`;

export const Category = styled.li`
    width: calc(100% / 6);
    list-style-type: none;
    list-style-position: inside;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 64px;
    padding:0 20px;
    
    @media(max-width: 768px) {
        width: auto;
        margin-right:10px;
        margin-top: 24px;
    }
`;

export const Link = styled.a`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-decoration:none;
`;

export const BoxImage = styled.span`
    width: 72px;
    height: 72px;
    background:#f2f2f2;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const Icon = styled.img`
    width: 48px;
    height: 48px;
    background-size:contain;
`;

export const Title = styled.span`
    font-weight: 500;
    font-size: 14px;
    color:#333333;
    line-height: 21px;
    margin-top:12px;
    text-align:center;

    @media(max-width: 768px) {
        min-width: 100px;
    }
    
`;