import React from "react";
import * as Tag from "./styles";
import IcoSearch from "../../assets/ico_search.svg";

const BoxSearch = (props) => {
    return (
        <Tag.Container width={props.width} action="/search">
            <Tag.Input placeholder={props.placeholder} id="term" name="term" />
            <Tag.Button type="submit"><Tag.Icon src={IcoSearch}/></Tag.Button>
        </Tag.Container>
    );
}

export default BoxSearch;