import React from "react";
import HeaderHelpCenter from  '../../components/HeaderHelpCenter';
import CategoryBox from  '../../components/CategoryBox';
import Footer from  '../../components/Footer';
import * as Tag  from "./styles";
import Business from "../../assets/ico_sidebar_business.svg";

const HelpCenterBusiness = (props) => {
    return (
        <Tag.Container>
            <HeaderHelpCenter image={Business} />
            <Tag.Content>
                <Tag.Row>
                    <Tag.BoxFaq>
                        <Tag.Title style={{margin: 0}}>Dúvidas frequentes de empresas</Tag.Title>
                        <Tag.Link href="#">Como desativar contaComo desativar conta</Tag.Link>
                        <Tag.Link href="#">Porque é preciso fazer a erificação de identidade</Tag.Link>
                        <Tag.Link href="#">Desativar conta</Tag.Link>
                        <Tag.Link href="#">Est etiam non quis ut elementum vulputate nisl, quisque varius</Tag.Link>
                        <Tag.Link href="#">Lorem ipsEst etiam non quis utum</Tag.Link>
                        <Tag.Link href="#">Desativar conta</Tag.Link>
                        <Tag.Link href="#">Pharetra commodo et lectus pulvinar volutpat vitae mauris</Tag.Link>
                        <Tag.Link href="#">Lorem ipsum</Tag.Link>
                    </Tag.BoxFaq>
                </Tag.Row>
                <Tag.Title>Procure com base nas categorias</Tag.Title>
                <Tag.Categories>
                    <CategoryBox title="Acesso à plataforma" link="#" />
                    <CategoryBox title="Planos e destaques" link="#" />
                    <CategoryBox title="Dicas de segurança" link="#" />
                    <CategoryBox title="Dicas de segurança" link="#" />
                </Tag.Categories>
            </Tag.Content>
            <Footer />
        </Tag.Container>
    );
}

export default HelpCenterBusiness;