import React from "react";
import * as Tag from "./styles";
import ImgNotification from "../../assets/img_notification.svg";

const Message = (props) => {
    return (
        <Tag.Container>
            <Tag.Image src={ImgNotification} width={32} height={32} />
            <Tag.Texts>
                <Tag.Title>Nova mensagem de Usuário</Tag.Title>
                <Tag.Date>12/02/2022</Tag.Date>
                <Tag.Link href="#">Ir para tela de pagamento</Tag.Link>
            </Tag.Texts>
        </Tag.Container>
    );
}

export default Message;